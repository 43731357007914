<template>
  <span>
    <h3>Entrada/Saida Estoque</h3>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="2">
          <v-select
            @change="defineReason(stockData.type)"
            v-model="stockData.type"
            :items="types"
            item-text="name"
            item-value="value"
            label="Tipo *"
            color="amber darken-4"
            :rules="rulesFunction('Tipo', 1)"
          ></v-select>
          <strong class="red--text">{{ errors.type }}</strong>
        </v-col>
        <v-col cols="1">
          <v-text-field
            :rules="rulesFunction('Quantidade', 1)"
            type="number"
            label="Qtd *"
            v-model="stockData.amount"
          ></v-text-field>
          <strong class="red--text">{{ errors.amount }}</strong>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="stockData.reason"
            :items="reasons"
            item-text="name"
            item-value="value"
            label="Motivo *"
            color="amber darken-4"
            :rules="rulesFunction('Motivo', 1)"
          ></v-select>
          <strong class="red--text">{{ errors.reason }}</strong>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="stockData.note"
            label="Observação *"
            color="amber darken-4"
          ></v-text-field>
        </v-col>
      </v-row>
      <strong class="red--text">{{ errors.negativeStock }}</strong> <br />
      <v-btn
        color="green darken-2 white--text"
        class="mr-4"
        @keyup.enter="saveContent()"
        @click="saveContent()"
        >Alterar Estoque</v-btn
      >
    </v-form>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    gridId: Number,
  },
  data: function () {
    return {
      valid: false,
      reasons: "",
      types: [
        { name: "Entrada", value: "i" },
        { name: "Saida", value: "o" },
      ],
      stockData: {
        note: null,
      },
    };
  },
  methods: {
    ...mapActions("Grid", ["stockChange", "cleanErrors"]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    saveContent() {
      let data = {
        ...this.stockData,
        grid_id: this.gridId,
        product_id: this.$route.params.id,
      };
      this.stockChange(data);
    },
    defineReason(value) {
      if (value == "i") {
        this.reasons = this.reasonIn;
      } else {
        this.reasons = this.reasonOut;
      }
    },
  },
  computed: {
    ...mapState("Grid", ["reasonIn", "reasonOut", "reasonAll", "errors"]),
  },
  mounted() {
    this.reasons = this.reasonAll;
  },
  destroyed() {
    this.cleanErrors();
  },
};
</script>

<style>
</style>