<template>
  <span>
    <span v-show="false">{{ setNullPeriod() }}</span>
    <v-form style="padding-left: 20px" ref="form" v-model="valid">
      <v-row>
        <v-col cols="12">
          <v-switch
            color="amber darken-4"
            v-model="period"
            label="Sem Periodo"
          ></v-switch>
        </v-col>
        <v-col v-if="formDates" cols="12" md="3">
          <!-- <h3>Data Inicio</h3> -->

          <div v-if="isFirefox" class="">
            <v-text-field
              type="date"
              v-model="fireFoxStartDate"
              :rules="rulesFunction(' data início', 10)"
            />
            <v-text-field
              type="time"
              v-model="fireFoxStartTime"
              :rules="rulesFunction(' um horário de início', 5)"
            />
          </div>

          <v-text-field
            v-else
            label="Data Inicio"
            type="datetime-local"
            v-model="formData.start_date"
            :rules="rulesFunction(' Data Início', 10)"
          >
          </v-text-field>
          <!-- <v-text-field
            label="Data Inicio"
            v-model="formData.start_date"
            type="datetime-local"
          ></v-text-field> -->
          <strong class="red--text">{{ errors.start_date }}</strong>
        </v-col>
        <v-col v-if="formDates" cols="1"></v-col>
        <v-col v-if="formDates" cols="12" md="3">
          <!-- <h3>Data Fim</h3> -->
          <!-- <v-datetime-picker
            label="Data Fim"
            locale="pt-br"
            :time-picker-props="{
              useSeconds: false,
              ampmInTitle: false,
              format: '24hr',
            }"
            :textFieldProps="{ 'prepend-icon': 'mdi-calendar-month' }"
            timeFormat="HH:mm"
            v-model="formData.end_date"
            :rules="rulesFunction(' Data Fim', 10)"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar-month</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker> -->

          <div v-if="isFirefox">
            <v-text-field
              type="date"
              v-model="fireFoxEndDate"
              :rules="rulesFunction(' data fim', 10)"
            />
            <v-text-field
              type="time"
              v-model="fireFoxEndTime"
              :rules="rulesFunction(' um horário de fim', 5)"
            />
          </div>

          <v-text-field
            v-else
            label="Data Fim"
            v-model="formData.end_date"
            type="datetime-local"
          ></v-text-field>

          <strong class="red--text">{{ errors.end_date }}</strong>
        </v-col>
        <v-col cols="12">
          <label>
            <strong>Procentagem Desconto</strong>(%)
            <span class="mr-5"></span>
            <money
              :rules="rulesFunction('Preço', 3)"
              class="money"
              v-bind="money"
              v-model="discountRate"
              id="discountRateField"
            ></money>
          </label>
          <strong class="red--text">{{ errors.discount_value }}</strong>
        </v-col>
        <v-col cols="12">
          <label>
            <strong>Valor Desconto</strong>(R$)
            <span class="mr-5"></span>
            <money
              :rules="rulesFunction('Preço', 3)"
              class="money"
              v-bind="money"
              v-model="discountValue"
              id="discountValueField"
            ></money>
          </label>
          <strong class="red--text">{{ errors.discount_value }}</strong>
        </v-col>
        <v-col cols="12">
          <v-col v-if="id" cols="12">
            <v-switch
              color="amber darken-4"
              v-model="formData.active"
              label="Ativo"
            ></v-switch>
          </v-col>
        </v-col>
        <v-col cols="12">
          <p>
            <strong>Preço Cheio: </strong>R$
            {{
              productInfo.price.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}
          </p>
          <p v-if="formData.discount_value">
            <strong>Preço com Desconto: </strong>R$
            {{
              (productInfo.price - formData.discount_value).toLocaleString(
                "pt-BR",
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <p v-if="formData.updated_at">
            <strong>Ultima Modificação:</strong>
            {{ formData.updated_at }}
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <p v-if="formData.created_at">
            <strong>Data Cadastro:</strong>
            {{ formData.created_at }}
          </p>
        </v-col>
      </v-row>
      <v-btn
        v-if="!id"
        :disabled="!valid"
        class="mr-4 register-btn"
        @click="saveContent()"
        >Salvar</v-btn
      >
      <v-btn v-if="id" class="mr-4 change-btn" @click="updateContent()"
        >Salvar Alteração</v-btn
      >
      <v-btn
        color="red darken-2 white--text"
        class="mr-4"
        @click="setModal(false)"
        >Cancelar</v-btn
      >
    </v-form>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  watch: {
    discountRate: function () {
      if (this.discountRateField === document.activeElement) {
        if (this.discountRate > 100) {
          this.discountRate = 100;
          this.discountRateField.value = "100.00";
        }
        if (this.discountRate < 0) {
          this.discountRate = 0;
          this.discountRateField.value = "0.00";
        }
        this.setValueBasedInRate(this.discountRate);
      }
    },
    discountValue: function () {
      if (this.discountValueField === document.activeElement) {
        if (this.discountValue > this.productInfo.price) {
          this.discountValue = this.productInfo.price;
          this.discountValueField.value = this.productInfo.price.toLocaleString(
            "pt-BR",
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }
          );
        }
        if (this.discountValue < 0) {
          this.discountValue = 0;
          this.discountValueField.value = "0.00";
        }
        this.setRateBasedInValue(this.discountValue);
      }
      this.formData.discount_value = this.discountValue;
    },

    fireFoxStartDate: function () {
      this.formData.start_date =
        this.fireFoxStartDate + "T" + this.fireFoxStartTime;
    },
    fireFoxStartTime: function () {
      this.formData.start_date =
        this.fireFoxStartDate + "T" + this.fireFoxStartTime;
    },
    fireFoxEndDate: function () {
      this.formData.end_date = this.fireFoxEndDate + "T" + this.fireFoxEndTime;
    },
    fireFoxEndTime: function () {
      this.formData.end_date = this.fireFoxEndDate + "T" + this.fireFoxEndTime;
    },

    formData: function () {
      // this.discountValue = this.formData.discount_value;
      // this.setRateBasedInValue(this.formData.discount_value);
      // this.discountValue = this.formData.discount_value;
      // if (
      //   this.formData &&
      //   this.formData.end_date &&
      //   this.formData.end_date.includes("T")
      // ) {
      //   this.formData.end_date = this.formData.end_date.replace("T", " ");
      // }
      // if (
      //   this.formData &&
      //   this.formData.start_date &&
      //   this.formData.end_date.includes("T")
      // ) {
      //   this.formData.start_date = this.formData.start_date.replace("T", " ");
      // }
    },
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  components: {},
  data: function () {
    return {
      discountRate: 0,
      discountValue: 0,
      formDates: true,
      period: false,
      valid: false,
      codeColor: null,
      updateEnable: false,
      money: {},
      fireFoxStartDate: "",
      fireFoxStartTime: "",
      fireFoxEndDate: "",
      fireFoxEndTime: "",
    };
  },
  computed: {
    ...mapState("Promotion", ["errors", "formData"]),
    ...mapState("Product", {
      productInfo: "formData",
    }),

    discountRateField: function () {
      return document.getElementById("discountRateField");
    },
    discountValueField: function () {
      return document.getElementById("discountValueField");
    },

    isFirefox: function () {
      return navigator.userAgent.indexOf("Firefox") != -1;
    },
  },
  methods: {
    ...mapActions("Promotion", [
      "save",
      "setModal",
      "show",
      "cleanItem",
      "update",
      "cleanErrors",
    ]),
    setValueBasedInRate(value) {
      if (typeof value == "number") {
        this.formData.discount_value = this.productInfo.price * (value / 100);
        this.discountValue = this.productInfo.price * (value / 100);
      }
    },
    setRateBasedInValue(value) {
      if (typeof parseFloat(value) == "number") {
        this.discountRate = (this.discountValue * 100) / this.productInfo.price;
      }
    },
    setNullPeriod() {
      if (this.period !== false) {
        this.formDates = false;
        this.formData.start_date = null;
        this.formData.end_date = null;
      } else {
        this.formDates = true;
      }
    },
    editForm() {
      if (!this.updateEnable && this.id) {
        return true;
      } else {
        return false;
      }
    },
    updateContent() {
      this.formData.discount_value = this.discountValue;
      this.formData.product_promotion_id = this.formData.id;

      let data = {
        ...this.formData,
        product_id: this.$route.params.id,
      };
      this.update(data);
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    saveContent() {
      this.formData.discount_value = this.discountValue;
      let data = {
        ...this.formData,
        product_id: this.$route.params.id,
      };
      this.save(data);
    },
  },
  mounted() {
    if (this.id) {
      this.show({
        product_promotion_id: this.id,
        product_id: this.$route.params.id,
      });
    }
  },
  beforeDestroy() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>