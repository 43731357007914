export default {
    formData: {
        id: null,
        installments: null,
        rule_type: null,
        payment_type: null,
        rate: null,
        active: true
    },
    list: [],
    errors: [],
    modal: false
}