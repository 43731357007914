const SET_LIST = (state, obj) => {
    state.list = obj
}

const SET_BANNERS = (state, obj) => {
    state.carouselItems = obj
}

const SET_INFO_BANNER = (state, obj) => {
    state.infoBanner = obj
}

const SET_MODAL = (state, obj) => {
    state.dialog = obj
}

const SET_MODAL_TYPE = (state, obj) => {
    state.dialogType = obj
}

const SET_ID_BANNER = (state, obj) => {
    state.bannerId = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_SNACKBAR = (state, obj) => {
    state.snackbar = obj
}

const SET_ERROS = (state, obj) => {
    state.errors = obj
}

const SET_CLEAN_ERROS = (state) => {
    state.errors = null
}

const SET_MOBILE = (state, obj) => {
    state.mobileAction = obj
}

const SET_ID_COMPANY = (state, obj) => {
    state.id_company = obj
}



export default {
    SET_ERROS,
    SET_CLEAN_ERROS,
    SET_LIST,
    SET_BANNERS,
    SET_INFO_BANNER,
    SET_MODAL,
    SET_MODAL_TYPE,
    SET_ID_BANNER,
    SET_ERRORS,
    SET_SNACKBAR,
    SET_MOBILE, SET_ID_COMPANY
}