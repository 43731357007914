export default {
    size:[],
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        definition_id: null
    },
    list: [],
    errors: [],
    modal: false,
    allGridModal: false,
    allGridModalErrors: null,
    reasonAll: [
        { name: 'Venda(on-line)', value: 'se', type: 'o' },
        { name: 'Cancelado', value: 'c', type: 'o' },
        { name: 'Venda(Loja Fisica)', value: 'ss', type: 'o' },
        { name: 'Compra', value: 'b', type: 'i' },
        { name: 'Troca', value: 'ch', type: 'i' },
    ],
    reasonIn: [
        { name: 'Compra', value: 'b', type: 'i' },
        { name: 'Troca', value: 'ch', type: 'i' },
    ],
    reasonOut: [
        { name: 'Venda(on-line)', value: 'se', type: 'o' },
        { name: 'Cancelado', value: 'c', type: 'o' },
        { name: 'Venda(Loja Fisica)', value: 'ss', type: 'o' }
    ],
    showInactive: false
}