<template>
  <span>
    <h1>Formulário Exceção Envio</h1>
    <v-form v-show="formData" class="mt-5" v-model="valid">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="formData.type"
            label="Tipo/ Nome"
          ></v-text-field>
          <strong class="red--text">{{ errors.type }}</strong>
        </v-col>
        <v-col cols="2">
          <v-text-field
            type="number"
            v-model="formData.term"
            label="Prazo"
          ></v-text-field>
          <strong class="red--text">{{ errors.term }}</strong>
        </v-col>
        <v-col cols="3">
          <v-text-field
            type="number"
            v-model="formData.init_cep_rate"
            label="Faixa Cep Inicial"
          ></v-text-field>
          <strong class="red--text">{{ errors.init_cep_rate }}</strong>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="formData.final_cep_rate"
            label="Faixa Cep Final"
          ></v-text-field>
          <strong class="red--text">{{ errors.final_cep_rate }}</strong>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="formData.minimum_buy_value"
            label="Valor Minimo Da Compra"
          ></v-text-field>
          <strong class="red--text">{{ errors.minimum_buy_value }}</strong>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="formData.delivery_price"
            label="Valor Entrega"
          ></v-text-field>
          <strong class="red--text">{{ errors.delivery_price }}</strong>
        </v-col>
        <v-col sm="6" cols="12">
          <p v-if="formData.updated_at">
            <strong>Ultima Modificação:</strong>
            {{ formData.updated_at }}
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <p v-if="formData.created_at">
            <strong>Data Cadastro:</strong>
            {{ formData.created_at }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4" cols="12">
          <v-switch
            color="amber darken-4"
            v-model="formData.more_quote"
            label="Cotar Correios"
          ></v-switch>
        </v-col>
        <v-col sm="2" cols="12">
          <v-switch
            color="amber darken-4"
            v-if="formData.id"
            v-model="formData.active"
            label="Ativo"
          ></v-switch>
        </v-col>
      </v-row>
      <v-btn
        v-if="formData.id"
        color="green darken-2 white--text"
        class="mr-4"
        @click="update(formData)"
        >Salvar Alterações</v-btn
      >
      <v-btn
        v-else
        color="green darken-2 white--text"
        class="mr-4"
        @click="saveData()"
        >Salvar</v-btn
      >
      <v-btn @click="setModal(false)" color="red" class="black--text"
        >Cancelar</v-btn
      >
    </v-form>
    <div class="mt-5">
      <a
        href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaFaixaCep.cfm"
        >Abrir Buscador Faixa Cep</a
      >
    </div>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    companyId: Number,
    idExeption: {
      type: Number,
      required: false,
    },
  },
  components: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapState("DeliveryRestriction", ["errors", "formData"]),
  },
  methods: {
    saveData() {
      let data = {
        ...this.formData,
        company_id: this.companyId,
      };
      this.save(data);
    },

    // updateData() {
    //   let data = {
    //     ...this.formData,
    //   };
    //   this.save(data);
    // },
    ...mapActions("DeliveryRestriction", [
      "setModal",
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    if (this.idExeption) {
      this.show({ id: this.idExeption });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>