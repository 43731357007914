<template>
  <admin-blade
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/delivery-settings"
  >
    <span>
      <v-form
        v-if="formData"
        class="mt-5 default-form"
        ref="form"
        v-model="valid"
      >
        <!-- Opcoes para Formulario -->
        <span>
          <v-row>
            <v-col sm="3" cols="12" v-if="adm">
              <v-autocomplete
                v-model="formData.company_id"
                :items="companyList"
                item-text="fantasy_name"
                item-value="id"
                label="Loja *"
                color="amber darken-4"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.company_id }}</strong>
            </v-col>

            <v-col sm="4" cols="12">
              <v-text-field
                v-model="formData.cd_empresa_correios"
                label="Usuario Correios"
              ></v-text-field>
              <strong class="red--text">{{
                errors.cd_empresa_correios
              }}</strong>
            </v-col>
            <v-col sm="4" cols="12">
              <v-text-field
                v-model="formData.sds_senha_correios"
                label="Senha Correios"
              ></v-text-field>
              <strong class="red--text">{{ errors.sds_senha_correios }}</strong>
            </v-col>
            <!-- TODO Colocar if pra nao aparecer pra usuario -->
            <v-col sm="4" cols="12">
              <v-switch
                color="amber darken-4"
                v-model="formData.open_delivery"
                label="Delivery Aberto"
              ></v-switch>
            </v-col>
            <v-col sm="4" cols="12">
              <v-switch
                color="amber darken-4"
                v-model="formData.withdrawal"
                label="Retirada no Local"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" cols="12">
              <v-autocomplete
                :companyId="formData.company_id"
                v-model="formData.intermediary"
                :items="intermediaryList"
                item-text="name"
                item-value="value"
                label="Intermediador de Envio"
                color="amber darken-4"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.company_id }}</strong>
            </v-col>
            <v-col
              cols="12"
              v-if="formData.intermediary == 1 && formData.company_id"
            >
              <melhor-envio :company="formData.company_id" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Adicional no Frete</h3>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="formData.additional_value"
                label="Adicional no Frete"
                type="number"
              ></v-text-field>
              <v-switch
                v-model="formData.apply_additional_all"
                label="aplicar para fretes com no valor de R$ 0,00"
              >
              </v-switch>
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="formData.product_rule"
                label="Aplicar regra de produto"
              >
              </v-switch>
            </v-col>
          </v-row>
        </span>
        <!-- Opcoes para Formulario -->

        <!-- Default -->
        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="updateSetting()"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
          >Salvar</v-btn
        >
      </v-form>
      <div v-if="formData.id" class="default-form mt-4">
        <delivery-restriction :companyId="formData.company_id" />
      </div>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
import DeliveryRestriction from "@/views/CompanySettings/Pages/DeliverySettings/Components/DeliveryRestriction/list";
import MelhorEnvio from "@/views/CompanySettings/Pages/DeliverySettings/Components/Intermediaries/melhorEnvio";

export default {
  components: {
    DeliveryRestriction,
    AdminBlade,
    MelhorEnvio,
  },
  data: function () {
    return {
      valid: false,
      adm: null,
      intermediaryList: [
        { value: null, name: "Nenhum" },
        { value: 1, name: "Melhor Envio" },
      ],
    };
  },
  computed: {
    ...mapState("DeliverySettings", ["userType", "errors", "formData"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    removeButtonIfNotAdmin(value) {
      if (value == true) {
        return "mdi-bookmark";
      }
    },
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("DeliverySettings", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    updateSetting() {
      this.update(this.formData);
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
    if (this.$route.params.id) {
      this.show({ company_id: this.$route.params.id });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>