export default {

    formBanners: {
        frameRate: "",
        interval: null,
    },
    items:
    {
        src: '',
        link: '',
        img_id: '',
        base64: '',
    },
    itemsMobile: {
        src: '',
        link: '',
        img_id: '',
        base64: '',
    },
    id_company: null,
    
    formBannersUpdate: {
        frameRate: "",
        interval: null,
        items:
        {

        },
    },
    carouselItems: [],

    dialog: false,
    dialogType: '',
    mobileAction: false,
    bannerId: 0,
    infoBanner: [],

}