<template>
  <admin-blade iconBtnTop="fas fa-calendar" routeBtnTop="/variation-group">
    <span class="default-form-bg">
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <h1 class="title-topo">Formulário de Grupo de Variações</h1>
        <v-row>
          <v-col cols="11">
            <v-text-field
              v-model="formData.variation_name"
              :rules="rulesFunction('Nome', 2)"
              label="Nome *"
            ></v-text-field>
            <strong class="red--text">{{ errors.variation_name }}</strong>
          </v-col>

          <v-col cols="1">
            <v-switch
              v-if="formData.id"
              color="amber darken-4"
              v-model="formData.active"
              label="Ativo"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row v-if="adm" no-gutters v-f="adm">
          <v-col cols="6">
            <v-autocomplete
              height="36"
              v-model="formData.company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
              :rules="rulesFunction('Loja', 0)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>

        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
          >Salvar</v-btn
        >
      </v-form>

      <div v-if="formData.id" class="mt-5 default-form">
        <variation-products
          :variationGroupId="formData.id"
          :companyId="formData.company_id"
        />
      </div>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
import VariationProducts from "./VariationProductGroup/components/list";

export default {
  components: {
    AdminBlade,
    VariationProducts,
  },
  watch: {
    routeId() {
      this.onload();
    },
  },
  computed: {
    ...mapState("VariationGroup", ["userType", "errors", "formData"]),
    ...mapState("Company", {
      companyList: "list",
    }),
    // ...mapState("Product", {
    //   productList: "list",
    // }),
  },
  methods: {
    ...mapActions("Product", [{ productSetList: "setList" }]),
    ...mapActions("VariationGroup", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    // ...mapActions("Product", {
    //   setProductList: "setList",
    // }),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    onload() {
      this.adm = JSON.parse(localStorage.getItem("user")).admin;
      // if (this.adm) {
      //   this.setList();
      // }
      if (this.adm) {
        this.setCompany();
      }
      // if (!this.adm) {
      //   this.setProductGroupList();
      // }
      if (this.$route.params.id) {
        this.show({ id: this.$route.params.id });
      }
    },
  },
  mounted() {
    this.onload();
    // this.setProductList();
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>