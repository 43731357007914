<template>
  <v-row>
    <v-col style="margin-top: 12%" align="center">
      <v-img
        max-width="70px"
        src="https://granshopping.s3.amazonaws.com/files/logoCortado.png"
      ></v-img>
      <h1>
        Carregando
        <span class="fadeInAndOut" style="animation-delay: 0.2s">.</span
        ><span class="fadeInAndOut" style="animation-delay: 0.4s">.</span
        ><span class="fadeInAndOut" style="animation-delay: 0.5s">.</span>
      </h1>
      <p class="grey--text" style="font-size: 13px">
        Isso pode levar alguns segundos.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

.fadeInAndOut {
  opacity: 1;
  animation: fade 2s infinite alternate;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>