<template>
  <admin-blade iconBtnTop="mdi-bookmark" routeBtnTop="/definition">
    <span>
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <v-row>
          <v-col sm="5" cols="12" v-if="adm">
            <v-autocomplete
              v-model="formData.company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
              color="amber darken-4"
              :rules="rulesFunction('Loja', 0)"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.company_id }}</strong>
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field v-model="formData.name" label="Nome *"></v-text-field>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
          >Salvar</v-btn
        >
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      adm: false,
    };
  },
  computed: {
    ...mapState("AtributeGroup", ["userType", "errors", "formData"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("AtributeGroup", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.show(this.$route.params.id);
    }
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>