<template>
  <span>
    <h1>Atributes</h1>
    <v-form v-model="valid">
      <v-row>
        <!-- {{ atributedList }}
        {{ selected }} -->
        <v-col v-for="item in list" :key="item.id" cols="4">
          <v-checkbox
            v-model="selected"
            :label="`${item.id} - ${item.group_name} - ${item.name}`"
            :value="item.id"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-btn
        v-if="atributedList.length > 0"
        color="green darken-2 white--text"
        class="mr-4"
        @click="updateData()"
        >Salvar Alterações</v-btn
      >
      <v-btn
        v-else
        :disabled="!valid"
        color="green darken-2 white--text"
        class="mr-4"
        @click="saveData()"
        >Salvar</v-btn
      >
      <v-btn
        color="red darken-2 white--text"
        class="mr-4"
        @click="setModal(false)"
        >Cancelar</v-btn
      >
    </v-form>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  components: {},
  data: function () {
    return {
      selected: [],
    };
  },
  watch: {
    atributedList: function () {
      this.selected = this.mapIds(this.atributedList);
    },
  },
  computed: {
    ...mapState("ProductAtribute", ["userType", "errors"]),
    ...mapState("ProductAtribute", {
      atributedList: "list",
    }),
    ...mapState("Atribute", ["list"]),
  },
  methods: {
    saveData() {
      let data = {
        product_id: this.$route.params.id,
        atributes: this.selected,
      };
      this.save(data);
    },
    updateData() {
      let data = {
        product_id: this.$route.params.id,
        atributes: this.selected,
      };
      this.update(data);
    },
    mapIds(value) {
      let response = value.map(function (item) {
        return item.atribute_id;
      });
      // console.log(response);
      return response;
    },
    ...mapActions("ProductAtribute", [
      "save",
      "cleanItem",
      "update",
      "setModal",
    ]),
    ...mapActions("ProductAtribute", {
      setAtributedList: "setList",
    }),
    ...mapActions("Atribute", ["setList"]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    this.setList();
    this.setAtributedList({ product_id: this.$route.params.id });
    if (this.$route.params.id) {
      // this.show(this.$route.params.id);
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>