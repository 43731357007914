export default{
    formData:{
        id: null,
        created_at: null,
        updated_at: null,
        variation_name: null,
        field_name: null,
        company_id: null,
        active: null
    },
    list:[],
    errors:[],
}