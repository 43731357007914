<template>
  <span>
    <apex-chart
      :height="height"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </span>
</template>

<script>
import ApexChart from "vue-apexcharts";
export default {
  props: {
    data: Array,
    height: String,
  },
  components: {
    ApexChart,
  },
  data: function () {
    return {
      series: [4],
      chartOptions: {
        chart: {
          type: "pie",
        },
        labels: null,
      },
    };
  },
  methods: {
    changeCharacterFromName(value) {
      if (value == "f") {
        return "Feminino";
      }
      if (value == "u") {
        return "Unisex";
      }
      if (value == "m") {
        return "Masculino";
      } else {
        return value;
      }
    },
    isolateGenderName(arr) {
      let self = this;
      let response = arr.map(function (obj) {
        return self.changeCharacterFromName(obj.gender);
      });
      return response;
    },
    isolateTotal(arr) {
      let response = arr.map(function (obj) {
        return obj.total;
      });
      return response;
    },
  },
  beforeMount() {
    this.series = this.isolateTotal(this.data);
    this.chartOptions.labels = this.isolateGenderName(this.data);
  },
};
</script>

<style>
.teste {
  color: rgb(0, 205, 150);
}
</style>