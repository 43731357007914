<template>
  <div class="main-order-report">
    <report-modal
      reportTitle="Relatorio de Pedidos"
      informationName="Periodo:"
      optionalInformationName="Status: "
    >
      <div v-if="!modalReportSpinner" class="order-report-row-top">
        <h1 class="text-center mt-3 m-5">ANÁLISES</h1>

        <v-row
          v-if="$vuetify.breakpoint.name != 'xs'"
          style="padding-top: 20px"
        >
          <v-col align="center">
            <h2 class="mb-1">
              Vendas Por Estado (Total
              {{ getFinishedBuys.amount + getUnfinishedBuys.amount }})
            </h2>
            <pie-chart
              v-if="list.order && list.order.length > 0"
              :data="list.order"
              :height="300"
            />
          </v-col>

          <v-col align="center" class="sale-information-class">
            <h2>Vendas Fechadas</h2>
            <br />
            <span v-if="checkUserPermissions()">
              <h2 class="mt-2 green--text">
                R$
                {{
                  getFinishedBuys.productsValue.toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
              </h2>
            </span>
            <p style="margin: 0 !important" v-if="checkUserPermissions()">
              Total:
              {{ getFinishedBuys.amount }}
            </p>
            <p v-if="checkUserPermissions()" style="margin: 40px 0 0 0">
              <strong>Frete: </strong> R$
              {{
                getFinishedBuys.deliveryValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p v-if="checkUserPermissions()">
              <strong>Vendas + Frete: </strong> R$
              {{
                (
                  getFinishedBuys.productsValue + getFinishedBuys.deliveryValue
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p
              style="
                padding: 0 0px 0 0 0 !important;
                margin: 0 0px 0 0 0 !important;
              "
            >
              <strong>Peças(Qtd.): </strong>
              {{ getFinishedBuys.items }}
            </p>
            <p
              v-if="getFinishedBuys.productsValue > 0 && checkUserPermissions()"
              style="padding: 0 0px 0 0 0 !important"
            >
              <strong style="color: green">Ticket Médio: </strong>

              R$
              {{
                (
                  parseFloat(getFinishedBuys.productsValue) /
                  parseFloat(getFinishedBuys.amount)
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
          </v-col>

          <v-col align="center" class="sale-information-class">
            <h2>Não Finalizadas</h2>
            <br />
            <h2 class="mt-2 red--text" v-if="checkUserPermissions()">
              R$
              {{
                getUnfinishedBuys.productsValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </h2>
            <p style="margin: 0 !important" v-if="checkUserPermissions()">
              Total: {{ getUnfinishedBuys.amount }}
            </p>
            <p style="margin: 40px 0 0 0" v-if="checkUserPermissions()">
              <strong>Frete: </strong> R$
              {{
                getUnfinishedBuys.deliveryValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p v-if="checkUserPermissions()">
              <strong>Vendas + Frete: </strong> R$
              {{
                (
                  getUnfinishedBuys.productsValue +
                  getUnfinishedBuys.deliveryValue
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p style="padding: 0 0px 0 0 0 !important">
              <strong>Peças(Qtd.): </strong>
              {{ getUnfinishedBuys.items }}
            </p>
            <p
              v-if="
                getUnfinishedBuys.productsValue > 0 && checkUserPermissions()
              "
              style="padding: 0 0px 0 0 0 !important"
            >
              <strong style="color: red">Ticket Médio: </strong>

              R$
              {{
                (
                  parseFloat(getUnfinishedBuys.productsValue) /
                  parseFloat(getUnfinishedBuys.amount)
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
          </v-col>

          <v-col
            justify="center"
            align="center"
            cols="11"
            style="margin: 30px auto 0 auto"
          >
            <v-simple-table v-if="list.list && list.list.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Status</th>
                    <th class="text-left">Qtd</th>
                    <th class="text-left" v-if="checkUserPermissions()">
                      Valor S/ Frete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="counntItenByStatys(0).amount > 0">
                    <td>Pagto Pendente</td>
                    <td>{{ counntItenByStatys(0).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(0).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(1).amount > 0">
                    <td>Pagto em Analise</td>
                    <td>{{ counntItenByStatys(1).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(1).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(2).amount > 0">
                    <td>Separação pra Entrega</td>
                    <td>{{ counntItenByStatys(2).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(2).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(3).amount > 0">
                    <td>Em transito</td>
                    <td>{{ counntItenByStatys(3).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(3).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(4).amount > 0">
                    <td>Entregues</td>
                    <td>{{ counntItenByStatys(4).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(4).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(5).amount > 0">
                    <td>Expirados</td>
                    <td>{{ counntItenByStatys(5).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(5).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(6).amount > 0">
                    <td>Cancelados</td>
                    <td>{{ counntItenByStatys(6).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(6).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(7).amount > 0">
                    <td>Pagamento Recusado</td>
                    <td>{{ counntItenByStatys(7).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(7).productsValue }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row v-else style="padding-top: 20px">
          <v-col align="center" cols="12">
            <h2 class="mb-1">
              Vendas Por Estado (Total
              {{ getFinishedBuys.amount + getUnfinishedBuys.amount }})
            </h2>
            <pie-chart
              v-if="list.order && list.order.length > 0"
              :data="list.order"
            />
          </v-col>

          <v-col
            v-if="checkUserPermissions()"
            cols="12"
            align="center"
            class="sale-information-class"
          >
            <span>
              <h2>Vendas Fechadas</h2>

              <h2 class="mt-2 green--text">
                R$
                {{
                  getFinishedBuys.productsValue.toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
              </h2>
            </span>
            <p style="margin: 0 !important">
              Total:
              {{ getFinishedBuys.amount }}
            </p>
            <p v-if="checkUserPermissions()" style="margin: 40px 0 0 0">
              <strong>Frete: </strong> R$
              {{
                getFinishedBuys.deliveryValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p v-if="checkUserPermissions()">
              <strong>Vendas + Frete: </strong> R$
              {{
                (
                  getFinishedBuys.productsValue + getFinishedBuys.deliveryValue
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p
              style="
                padding: 0 0px 0 0 0 !important;
                margin: 0 0px 0 0 0 !important;
              "
            >
              <strong>Peças(Qtd.): </strong>
              {{ getFinishedBuys.items }}
            </p>
            <p
              v-if="getFinishedBuys.productsValue > 0"
              style="padding: 0 0px 0 0 0 !important"
            >
              <strong style="color: green">Ticket Médio: </strong>

              R$
              {{
                (
                  parseFloat(getFinishedBuys.productsValue) /
                  parseFloat(getFinishedBuys.amount)
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
          </v-col>

          <v-col cols="12" align="center" class="sale-information-class">
            <h2>Não Finalizadas</h2>
            <h2 class="mt-2 red--text">
              R$
              {{
                getUnfinishedBuys.productsValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </h2>
            <p style="margin: 0 !important">
              Total: {{ getUnfinishedBuys.amount }}
            </p>
            <p style="margin: 40px 0 0 0">
              <strong>Frete: </strong> R$
              {{
                getUnfinishedBuys.deliveryValue.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p>
              <strong>Vendas + Frete: </strong> R$
              {{
                (
                  getUnfinishedBuys.productsValue +
                  getUnfinishedBuys.deliveryValue
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
            <p style="padding: 0 0px 0 0 0 !important">
              <strong>Peças(Qtd.): </strong>
              {{ getUnfinishedBuys.items }}
            </p>
            <p
              v-if="getUnfinishedBuys.productsValue > 0"
              style="padding: 0 0px 0 0 0 !important"
            >
              <strong style="color: red">Ticket Médio: </strong>

              R$
              {{
                (
                  parseFloat(getUnfinishedBuys.productsValue) /
                  parseFloat(getUnfinishedBuys.amount)
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </p>
          </v-col>

          <v-col
            justify="center"
            align="center"
            cols="11"
            style="margin: 30px auto 0 auto"
          >
            <v-simple-table v-if="list.list && list.list.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Status</th>
                    <th class="text-left">Qtd</th>
                    <th class="text-left" v-if="checkUserPermissions()">
                      Valor S/ Frete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="counntItenByStatys(0).amount > 0">
                    <td>Pagto Pendente</td>
                    <td>{{ counntItenByStatys(0).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(0).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(1).amount > 0">
                    <td>Pagto em Analise</td>
                    <td>{{ counntItenByStatys(1).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(1).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(2).amount > 0">
                    <td>Separação pra Entrega</td>
                    <td>{{ counntItenByStatys(2).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(2).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(3).amount > 0">
                    <td>Em transito</td>
                    <td>{{ counntItenByStatys(3).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(3).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(4).amount > 0">
                    <td>Entregues</td>
                    <td>{{ counntItenByStatys(4).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(4).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(5).amount > 0">
                    <td>Expirados</td>
                    <td>{{ counntItenByStatys(5).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(5).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(6).amount > 0">
                    <td>Cancelados</td>
                    <td>{{ counntItenByStatys(6).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(6).productsValue }}
                    </td>
                  </tr>
                  <tr v-if="counntItenByStatys(7).amount > 0">
                    <td>Pagamento Recusado</td>
                    <td>{{ counntItenByStatys(7).amount }}</td>
                    <td v-if="checkUserPermissions()">
                      R$ {{ counntItenByStatys(7).productsValue }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <br />
        <div v-if="getProductsGroup !== null">
          <div style="text-align: left; margin-left: 80px" class="title-table">
            <h2>Vendas Produtos X Quantidade</h2>
            <br />
            <span class="txt-relatorio"
              >*Vendas não finalizadas serão contabilizadas caso houver</span
            >
          </div>
          <v-row>
            <v-col
              justify="center"
              align="center"
              cols="11"
              style="margin: 30px auto 0 auto"
            >
              <!-- <v-col align="center" cols="12" md="4"> -->
              <h2 class="mb-1">Por Categoria</h2>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="[
                      {
                        align: 'start',
                        value: 'id',
                      },
                      { text: 'Categoria', value: 'category_name' },
                      { text: 'Qtd', value: 'amount' },
                    ]"
                    :items="list.product_categories"
                    :items-per-page="50"
                    :search="searchTableGroup"
                    item-key="grid_id"
                    class="elevation-1 table"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <!-- </v-col> -->
          </v-row>

          <v-col
            justify="center"
            align="center"
            cols="11"
            style="margin: 30px auto 0 auto"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="searchTableGroup"
                  class="mb-5 ml-2"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headersTableGroupProduct"
              :items="getProductsGroup"
              :items-per-page="50"
              :search="searchTableGroup"
              item-key="grid_id"
              class="elevation-1 table"
            ></v-data-table>
          </v-col>
        </div>
        <br />
        <v-row align="center">
          <v-col cols="12" align="center">
            <div v-if="getDataPeople.new_buyers_list.length > 0">
              <div
                style="text-align: left; margin-left: 80px"
                class="title-table"
              >
                <h2>Listagem de Novos Clientes</h2>
              </div>
              <v-col
                justify="center"
                align="center"
                cols="11"
                style="margin: 30px auto 0 auto"
              >
                <v-data-table
                  :headers="headersTablePeople"
                  :items="getDataPeople.new_buyers_list"
                  :items-per-page="50"
                  item-key="grid_id"
                  class="elevation-1 table"
                >
                  <template v-slot:item.total_products="{ item }">
                    <span>
                      R$
                      {{
                        parseFloat(item.total_products).toLocaleString(
                          "pt-BR",
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )
                      }}
                    </span>
                  </template>
                  <template v-slot:item.birthday="{ item }">
                    <span v-if="item.birthday">
                      {{ convertBirthday(item.birthday) }}</span
                    >
                  </template>
                  <template v-slot:item.people_created="{ item }">
                    <span> {{ convertDate(item.people_created) }}</span>
                  </template>

                  <template v-slot:item.first_buy="{ item }">
                    <span> {{ convertDate(item.first_buy) }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" align="center">
            <div v-if="getDataPeople.old_buyers_list.length > 0">
              <div
                style="text-align: left; margin-left: 80px"
                class="title-table"
              >
                <h2>Listagem de Antigos Clientes</h2>
              </div>
              <v-col
                justify="center"
                align="center"
                cols="11"
                style="margin: 30px auto 0 auto"
              >
                <v-data-table
                  :headers="headersTablePeople"
                  :items="getDataPeople.old_buyers_list"
                  :items-per-page="50"
                  item-key="grid_id"
                  class="elevation-1 table"
                >
                  <template v-slot:item.total_products="{ item }">
                    <span
                      >R$
                      {{
                        parseFloat(item.total_products).toLocaleString(
                          "pt-BR",
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )
                      }}</span
                    >
                  </template>
                  <template v-slot:item.people_created="{ item }">
                    <span> {{ convertDate(item.people_created) }}</span>
                  </template>
                  <template v-slot:item.birthday="{ item }">
                    <span v-if="item.birthday">
                      {{ convertBirthday(item.birthday) }}</span
                    >
                  </template>
                  <template v-slot:item.first_buy="{ item }">
                    <span> {{ convertDate(item.first_buy) }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" align="center">
            <h1 class="text-center mt-5 mb-5">Lista de Pedidos</h1>
          </v-col>

          <v-col cols="12">
            <v-btn @click="csvExport(list.list)">Exportar CSV</v-btn>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  class="mb-5 ml-2"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-data-table
              :items-per-page="100"
              :headers="headers"
              :items="list.list"
              :search="search"
              @click:row="editItem"
              item-key="id"
              :single-select="true"
            >
              <template v-slot:item.status="{ item }">
                <span>{{ setStatusName(item.status) }}</span>
              </template>
              <template v-slot:item.idade="{ item }">
                <span v-if="item.birthday">{{ calcIdade(item.birthday) }}</span>
              </template>
              <template v-slot:item.status="{ item }">
                <span>{{ setStatusName(item.status) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </report-modal>
  </div>
</template>

<script>
import PieChart from "@/views/Reports/Pages/Order/Components/Charts/PieChart";
// import BarChart from "@/views/Reports/Pages/Order/Components/Charts/BarChart";
import ReportModal from "@/views/Reports/Components/ReportModal";
// import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  components: {
    ReportModal,
    PieChart,
    // VueApexCharts,
    // BarChart,
  },

  watch() {},
  data() {
    return {
      search: null,
      userAllPermissions: false,
      new_people: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["1 Compra", "2 Compra", "3 Compra", "4+ Compras", "Team E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Nome", value: "fantasy_name" },
        { text: "Idade", value: "idade" },
        { text: "Status", value: "status" },
        { text: "Data", value: "created_at" },
        { text: "Tipo Envio", value: "send_type" },
        { text: "Cidade", value: "city" },
        { text: "UF", value: "state" },
        { text: "Qtd. Peças", value: "amount" },
        { text: "Desconto(R$)", value: "discount_value" },
        { text: "Cupom", value: "coupon_discount" },

        { text: "Intermediador Pgto", value: "payment_source" },
        { text: "Meio Pgto", value: "type" },
        { text: "Parcelas", value: "installments" },
        { text: "Tentativas Pgto", value: "payment_try" },
        { text: "Vl Entr.(R$)", value: "delivery_price" },
        { text: "Vl Prod.(R$)", value: "products_value" },
      ],

      searchTableGroup: null,
      headersTableGroupProduct: [
        {
          align: "start",
          value: "id",
        },
        { text: "Nome", value: "name" },
        { text: "Qtd", value: "amount" },
        { text: "Cor", value: "color" },
        { text: "Tamanho", value: "size" },
      ],

      headersTablePeople: [
        {
          text: "ID",
          align: "start",
          value: "people_id",
        },
        { text: "Nome", value: "people_name" },
        { text: "Telefone", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Gênero", value: "people_gender" },
        { text: "Dt Nasc", value: "birthday" },
        { text: "Qtd de Compras", value: "buys" },
        { text: "Data Cadastro", value: "people_created" },
        { text: "Data Primeira Compra", value: "first_buy" },
        { text: "Data Ultima Compra", value: "last_buy" },
        { text: "Total Gasto", value: "total_products" },
      ],
    };
  },
  methods: {
    ...mapActions("Auth", ["checkLoged"]),

    calcIdade(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    editItem(value) {
      return window.open(`/detailOrder/${value.id}`, "_blank");
      // let routeData = this.$router.resolve({ name: "/detailOrder/442" });
      // window.open(routeData.href, "_blank");
      // // console.log(value);
      // return routeData;
    },
    counntItenByStatys(status) {
      let self = this;
      let filteredData = this.list.list.filter(function (e) {
        return e.status == status;
      });
      let sumValue = filteredData.reduce(function (prevVal, elem) {
        return (
          parseFloat(prevVal) +
          parseFloat(self.checkIfValueIsNull(elem.products_value))
        );
      }, 0);

      return {
        amount: filteredData.length,
        productsValue: sumValue.toLocaleString("pt-BR", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
      };
    },
    checkIfValueIsNull(value) {
      if (value == null || value == undefined || !value) {
        return 0;
      } else {
        return value;
      }
    },
    setStatusName(value) {
      let response = this.paymentStatus.filter(function (item) {
        return item.value == value;
      });

      return response[0].name;
    },
    renameObjectCsv(arrData) {
      const newKeys = {
        amount: "qtd",
        birthday: "aniversario",
        city: "cidade",
        coupon_discount: "cupom desconto",
        delivery_price: "preço entrega",
        discount_value: "valor desconto",
        fantasy_name: "nome",
        installments: "parcelas",
        phone_number: "numero telefone",
        products_value: "valor produto",
        state: "estado",
        payment_source: "tipo pagamento",
        send_type: "tipo envio",
        payment_try: "tentativas",
      };
      var newArrData = [];

      arrData.forEach(function (val) {
        //esse loop ira acessar os atributos e converter cada atributo em um objeto traduzido {qtd:1},{aniversario:'2000-01-01'},etc...
        var newkeyValues = Object.keys(val).map((key) => {
          const newKey = newKeys[key] || key;
          return { [newKey]: val[key] };
        });
        //O método Object.assign() copia todas as propriedades próprias enumeráveis de um ou mais objetos de origem para um objeto alvo.
        //Ele retorna o objeto alvo modificado. {qtd:1,aniversario:'2000-01-01',etc}
        newkeyValues = Object.assign({}, ...newkeyValues);
        //Conatena o objeto traduzido na nossa vareavel
        newArrData = newArrData.concat(newkeyValues);
      });

      return newArrData;
    },
    csvExport(arrData) {
      let renameObj = this.renameObjectCsv(arrData);
      for (let i = 0; i < arrData.length; i++) {
        switch (renameObj[i].type) {
          case 0:
            renameObj[i].type = "Cartão Crédito";
            break;
          case 1:
            renameObj[i].type = "Cartão Débto";
            break;
          case 2:
            renameObj[i].type = "Dinheiro";
            break;
          case 3:
            renameObj[i].type = "Boleto";
            break;
          case 4:
            renameObj[i].type = "Pix";
            break;
        }
      }
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += [
        Object.keys(renameObj[0]).join(";"),
        ...renameObj.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    convertDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZone: "America/Sao_Paulo", // Especificando o fuso horário de Brasília
      };

      const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(
        new Date(date)
      );
      return formattedDate;
    },

    convertBirthday(date) {
      // Criando uma nova instância de Date a partir da data fornecida
      const birthday = new Date(date);

      // Adicionando um dia à data de aniversário
      birthday.setDate(birthday.getDate() + 1);

      // Opções de formatação da data
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Sao_Paulo", // Especificando o fuso horário de Brasília
      };

      // Formatando a data de aniversário ajustada
      const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(
        birthday
      );

      return formattedDate;
    },

    checkUserPermissions() {
      let user = JSON.parse(localStorage.getItem("user"));
      console.log(user.full_permission);

      return user.full_permission;
    },
  },
  computed: {
    ...mapState("Spinner", ["modalReportSpinner"]),
    ...mapState("OrderReport", ["list"]),
    ...mapState("Utils", ["paymentStatus"]),
    ...mapGetters("OrderReport", [
      "getFinishedBuys",
      "getUnfinishedBuys",
      "getProductsGroup",
      "getDataPeople",
    ]),
  },
  mounted() {
    this.checkUserPermissions();
  },
  destroyed() {
    this.checkLoged();
  },
};
</script>

<style scoped>
.order-report-row-top {
  border: solid rgba(128, 128, 128, 0.137) 1px !important;
  /* max-height: 600px; */
  background: #f4f7fc !important;
  margin: 0 10px 0 10px;
  padding: 10px 0 10px 0;
}
.txt-relatorio {
  color: red;
}
.main-order-report {
  background: #f4f7fc !important;
}

.sale-information-class {
  border-left: solid grey 1px;
  padding: auto 0 auto 0 !important;
}

@media (max-width: 959px) {
  .sale-information-class {
    border-left: none;
    border-top: solid grey 1px;
    padding: auto 0 auto 0 !important;
  }
  .txt-relatorio {
    font-size: 13px !important;
    color: red;
  }
  .table >>> i {
    font-size: 20px !important;
  }
  .title-table {
    text-align: center !important;
    margin-left: 0 !important;
  }
}
</style>