<template>
  <span>
    <h2 class="mt-4 mb-4">
      Regras p/ Pagamento
      <v-btn
        depressed
        rounded
        dense
        v-if="!show"
        @click="show = !show"
        style="color: blue; text-decoration: underline"
      >
        +
      </v-btn>
      <v-btn
        depressed
        rounded
        dense
        v-else
        @click="show = !show"
        style="color: blue; text-decoration: underline"
      >
        -
      </v-btn>
    </h2>
    <span v-if="show">
      <v-row>
        <v-col cols="12">
          <v-btn class="mr-4 mb-5 register-btn" @click="setModal(!modal)"
            >Cadastrar Nova Regra</v-btn
          >
        </v-col>
      </v-row>
      <span>
        <v-data-table
          class="default-table"
          :headers="headers"
          :items="list"
          :search="search"
          :footer-props="footer"
        >
          <template v-slot:item.payment_type="{ item }">
            <span>{{ getPaymentDescription(item.payment_type) }}</span>
          </template>
          <template v-slot:item.rate="{ item }">
            <span>{{ item.rate }} %</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
              >mdi-pencil</v-icon
            >
          </template>
          <template v-slot:item.rule_type="{ item }">
            <span>
              {{ getRuleDescription(item.rule_type) }}
            </span>
          </template>
          <template v-slot:item.active="{ item }">
            <span v-if="item.active"> Sim </span>
            <span v-else> Não </span>
          </template>
        </v-data-table>
      </span>
      <spinner-dialog persistent v-if="modal" :dialog="modal">
        <v-card class="dialog-default">
          <payment-rule-form :item="editItem" />
        </v-card>
      </spinner-dialog>
    </span>
  </span>
</template>

<script>
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import PaymentRuleForm from "./form";
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";

export default {
  watch: {
    modal: function () {
      if (!this.modal) {
        this.editItem = null;
      }
    },
    list: function () {
      if (this.list.length > 0) {
        this.show = true;
      }
    },
  },
  components: { spinnerDialog, PaymentRuleForm },
  data: function () {
    return {
      editItem: null,
      show: false,
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Parcelas", value: "installments", class: "black--text" },
        { text: "Meio Pgto", value: "payment_type", class: "black--text" },
        { text: "Desc/Juros", value: "rule_type", class: "black--text" },
        { text: "Porcentagem", value: "rate", class: "black--text" },
        { text: "Ativo", value: "active", class: "black--text" },
        {
          text: "Ações",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("ProductDiscountByPaymentMethod", ["list", "modal"]),
  },

  methods: {
    getRuleDescription(value) {
      switch (value) {
        case 0:
          return "Desconto";
        case 1:
          return "Acrescimo";
        default:
          return "Regra inválida!!";
      }
    },
    getPaymentDescription(value) {
      switch (value) {
        case 0:
          return "Cartão de Crédito";
        case 1:
          return "Cartão de Débito";
        case 2:
          return "Dinheiro";
        case 3:
          return "Boleto";
        case 4:
          return "Pix";
        default:
          return "Tipo de pagamento inválido!!";
      }
    },
    ...mapActions("ProductDiscountByPaymentMethod", ["setList", "setModal"]),
    edit(item) {
      this.editItem = item;
      this.setModal(true);
    },
  },
  mounted() {
    this.setList(this.$route.params.id);
    toastAlert("success");
  },
};
</script>