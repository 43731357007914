<template>
  <span>
    <h1>Produtos da Variação</h1>

    <v-row>
      <v-col cols="4">
        <v-autocomplete
          height="36"
          v-model="formData.product_id"
          :items="productList"
          item-text="name"
          item-value="id"
          label="Produtos *"
        />
        <strong class="red--text">{{ errors.product_id }}</strong>
        <strong class="red--text">{{ errors.variation_group_id }}</strong>
      </v-col>
      <v-col align-self="center" cols="4">
        <v-text-field
          v-model="formData.value"
          label="Valor p/ Variação"
        ></v-text-field>
        <strong class="red--text">{{ errors.value }}</strong>
      </v-col>
      <v-col cols="4" align-self="center">
        <v-btn
          @click="
            save({
              ...formData,
              variation_group_id: variationGroupId,
            })
          "
          color="green"
          class="white--text"
          >Adicionar Produto</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="default-table"
          :headers="headers"
          :items="list"
          :search="search"
          :footer-props="footer"
        >
          <template v-slot:item.main="{ item }">
            <span v-if="item.main">Sim</span>
            <span v-else>Não</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              title="Excluir"
              small
              class="mr-2"
              @click="
                deleteFunction({
                  variation_group_id: variationGroupId,
                  id: item.id,
                })
              "
              >mdi-delete-outline</v-icon
            >
            <v-icon
              title="Excluir"
              small
              class="mr-2"
              @click="
                becomeMain({
                  variation_group_id: variationGroupId,
                  id: item.id,
                })
              "
              >mdi-check-circle</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    companyId: Number,
    variationGroupId: Number,
  },

  computed: {
    ...mapState("VariationProductGroup", [
      "productList",
      "errors",
      "formData",
      "list",
    ]),
  },
  data: function () {
    return {
      productId: null,
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        {
          text: "Produto",
          value: "name",
          class: "black--text",
        },
        {
          text: "Valor",
          value: "value",
          class: "black--text",
        },
        { text: "Principal", value: "main", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
    };
  },

  methods: {
    ...mapActions("VariationProductGroup", [
      "setProductList",
      "save",
      "setList",
      "deleteFunction",
      "becomeMain",
    ]),
  },
  mounted() {
    this.setProductList({ companyId: this.companyId });
    this.setList({ variation_group_id: this.variationGroupId });
  },
};
</script>