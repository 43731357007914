export default{
    formData:{
        id: null,
        created_at: null,
        updated_at: null,
        images:[],
        product_id: null
    },
    list:[],
    errors:[],
    modal: false,
    editModal: false
}