<template>
  <span style="position: relative" v-if="reportModal">
    <v-dialog
      v-model="reportModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-text style="margin-top: 20px">
          <v-row style="height: 100% !important" class="toolbar-row">
            <v-col cols="12" md="3">
              <h3 style="height: 100% !important" class="mb-2">
                {{ reportTitle }}
              </h3>
            </v-col>
            <v-col cols="12" md="7">
              <p v-if="reportInfo.title" class="information-class">
                <strong> {{ reportInfo.title }} </strong> {{ reportInfo.text }}
              </p>
              <p v-if="reportOptionalInfo.title" class="information-class">
                <strong> {{ reportOptionalInfo.title }} </strong>
                {{ reportOptionalInfo.text }}
              </p>
            </v-col>
            <v-col> </v-col>
            <v-col
              align="right"
              class="no-padding"
              style="margin-top: 10px"
              cols="1"
            >
              <v-btn class="mr-5" @click="setReportModal(false)" icon>
                <i class="far fa-window-close close-report-icon"></i>
                <!-- <v-icon>mdi-close</v-icon> -->
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="modalReportSpinner">
            <logo-loader></logo-loader>
          </v-row>
          <div class="content-report-class" v-else>
            <slot></slot>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoLoader from "@/Layouts/Components/logoLoader";
export default {
  props: {},
  components: {
    LogoLoader,
  },
  computed: {
    ...mapState("Reports", [
      "reportModal",
      "reportTitle",
      "reportInfo",
      "reportOptionalInfo",
    ]),
    ...mapState("Spinner", ["modalReportSpinner"]),
  },
  methods: {
    ...mapActions("Reports", ["setReportModal"]),
  },
  data: function () {
    return {};
  },
};
</script>
<style  scoped>
.toolbar-row {
  max-height: 100px !important;
  padding: 0 0px 0 10px;
}

.information-class {
  margin: 0px !important;
}
.close-report-icon {
  font-size: 25px !important;
}

@media (max-width: 345px) {
  .close-report-icon {
    top: -162px;
  }
}

@media (max-width: 959px) {
  .information-class {
    font-size: 10px !important;
  }
  .content-report-class {
    margin-top: 50px;
  }
  .close-report-icon {
    position: absolute;
    top: -132px;
    right: 13px;
  }
}
</style>