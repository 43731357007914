const SET_MELHORENVIOERRORS = (state, obj) => {
    state.melhorEnvio.errors = obj
}

const SET_MELHORENVIOID = (state, obj) => {
    state.melhorEnvio.keyId = obj
}

const SET_MELHORENVIOCHANGEKEY = (state, obj) => {
    state.melhorEnvio.changeKey = obj
}

export default {
    SET_MELHORENVIOERRORS,
    SET_MELHORENVIOID,
    SET_MELHORENVIOCHANGEKEY
}