<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-user">
      <h1 class="title-topo">Lista de Contatos</h1>
      <v-expansion-panels v-model="panel" v-if="adm">
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header>
            <h4>Filtros</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="margin: 0 auto !important">
            <v-row align="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.company_id"
                  :items="companyList"
                  item-text="fantasy_name"
                  item-value="id"
                  label="Loja *"
                  color="amber darken-4"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="filter-col-class" cols="13" md="6" align="left">
                <v-btn
                  @click="setList(form.company_id)"
                  color="green darken-2 white--text mb-3"
                  >Filtrar</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <v-col cols="12">
        <v-btn :disabled="list.length == 0" @click="csvExport(list)"
          >Exportar CSV</v-btn
        >
      </v-col>
      <v-text-field
        class="default-search"
        v-model="search"
        label="Busca"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.admin="{ item }">
          <span :class="`mr-2 ${getAcessType(item.admin)}`" title="Edit">{{
            item.admin
          }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
        <template v-slot:item.whatsapp="{ item }">
          <a
            :href="generateWhatsAppLink(item.phone, item.name)"
            target="_blank"
          >
            <v-icon small>mdi-whatsapp</v-icon>
          </a>
        </template>
      </v-data-table>
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      adm: false,
      panel: 0,
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        { text: "Telefone", value: "phone", class: "black--text" },
        { text: "WhatsApp", value: "whatsapp", sortable: false },
      ],
      form: {
        company_id: null,
      },
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("ContactList", ["list", "userType"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("ContactList", ["setList"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    edit(item) {
      this.$router.push(`form-user/${item}`);
    },
    cleanItem() {
      this.form = {
        init_date: null,
        finish_date: null,
        state: null,
        gender: null,
        // show_product_detail: false,
      };
    },

    getAcessType(value) {
      if (value == true) {
        return "admin-table-class";
      } else {
        return "store-table-class";
      }
    },

    csvExport(arrData) {
      let renameObj = this.renameObjectCsv(arrData);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(renameObj[0]).join(";"),
        ...renameObj.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    renameObjectCsv(arrData) {
      const newKeys = {
        created_at: "Data Cadastro",
        updated_at: "Atualização Cadastro",
        name: "Nome",
        phone: "Numero telefone",
        email: "Email",
      };
      const keysToExclude = [
        "panel_access",
        "id",
        "note",
        "company_name",
        "user_id",
        "admin",
        "company",
      ]; // adicionando as chaves que não deseja incluir
      var newArrData = [];

      arrData.forEach(function (val) {
        //esse loop ira acessar os atributos e converter cada atributo em um objeto traduzido {qtd:1},{aniversario:'2000-01-01'},etc...
        var newkeyValues = Object.keys(val)
          .filter((key) => !keysToExclude.includes(key)) // Remove as chaves que não deseja incluir
          .map((key) => {
            const newKey = newKeys[key] || key;
            return { [newKey]: val[key] };
          });
        //O método Object.assign() copia todas as propriedades próprias enumeráveis de um ou mais objetos de origem para um objeto alvo.
        //Ele retorna o objeto alvo modificado. {qtd:1,aniversario:'2000-01-01',etc}
        newkeyValues = Object.assign({}, ...newkeyValues);
        //Conatena o objeto traduzido na nossa vareavel
        newArrData = newArrData.concat(newkeyValues);
      });

      return newArrData;
    },

    generateWhatsAppLink(phone, name) {
      // Replace the following with your actual WhatsApp link generation logic
      const message = `Olá, ${name}!`; // You can customize the message
      const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
        message
      )}`;
      return whatsappLink;
    },
  },

  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }

    this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
.admin-table-class {
  background: green;
  border-radius: 10px;
  padding: 5px;
  color: white;
}

.store-table-class {
  background: blue;
  border-radius: 10px;
  padding: 5px;
  color: white;
}
</style>