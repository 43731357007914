<template>
  <div>
    <v-dialog persistent v-model="dialog" max-width="700">
      <v-card v-if="dialogType == 'add' || dialogType == 'addMobile'">
        <v-card-title style="margin-bottom: 10px !important"
          >Adicionar item
        </v-card-title>
        <v-row>
          <!-- {{ formData }} -->

          <span v-if="items.base64">
            <td width="5%" class="text-center">
              <button class="close-btn" @click="removeItem(items)">X</button>
            </td>
            <v-img
              contain
              :src="items.base64"
              height="250px"
              width="300px"
              style="
                margin-left: 30px !important;
                margin-bottom: 10px !important;
              "
              lazy-src="https://picsum.photos/id/11/100/60"
            ></v-img>
          </span>
        </v-row>
        <v-card-text v-if="dialogType == 'add'">
          <image-uploader
            v-if="reset"
            id="imgpicker"
            class="mb-4"
            :maxWidth="1800"
            :maxHeight="1800"
            :quality="1.0"
            outputFormat="verbose"
            @input="setImage"
            @onUpload="setSpinnerLoadImage(true)"
            :preview="false"
            :capture="false"
            accept="video/*, image/*"
          />

          <v-text-field
            v-model="items.link"
            prepend-icon="mdi-link"
            label="URL do site"
          />
        </v-card-text>

        <v-card-text v-else>
          <span v-if="itemsMobile.base64">
            <td width="5%" class="text-center">
              <button class="close-btn-mobile" @click="removeItem(itemsMobile)">
                X
              </button>
            </td>
            <v-img
              contain
              :src="itemsMobile.base64"
              height="250px"
              width="300px"
              style="
                margin-left: 30px !important;
                margin-bottom: 10px !important;
              "
              lazy-src="https://picsum.photos/id/11/100/60"
            ></v-img>
          </span>
          <image-uploader
            v-if="reset"
            id="imgpicker"
            class="mb-4"
            :maxWidth="1000"
            :maxHeight="1000"
            :quality="1.0"
            outputFormat="verbose"
            @input="setImageMobile"
            :preview="false"
            :capture="false"
            accept="video/*, image/*"
          />

          <v-text-field
            v-model="itemsMobile.link"
            prepend-icon="mdi-link"
            label="URL do site"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer v-if="$vuetify.breakpoint.name !== 'xs'" />
          <span style="margin: 0 auto">
            <v-btn
              color="red"
              @click="
                setModal({
                  status: false,
                  type: '',
                  id: null,
                })
              "
            >
              <v-icon color="white"> mdi mdi-cancel </v-icon>
              <h4 v-if="$vuetify.breakpoint.name !== 'xs'" style="color: white">
                cancelar
              </h4>
            </v-btn>
            <v-btn color="orange" @click="clearFields()" class="mx-3">
              <v-icon color="white"> mdi mdi-trash-can </v-icon>
              <h4 v-if="$vuetify.breakpoint.name !== 'xs'" style="color: white">
                limpar
              </h4>
            </v-btn>
            <v-btn color="green" @click="saveBanner()">
              <v-icon color="white"> mdi mdi-content-save </v-icon>
              <h4 v-if="$vuetify.breakpoint.name !== 'xs'" style="color: white">
                salvar
              </h4>
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>

      <v-card v-else-if="dialogType == 'delete'">
        <v-card-title>Deletar item </v-card-title>
        <v-card-text>
          <h3 style="color: red">
            <v-icon color="red"> mdi mdi-alert-outline</v-icon> Deseja mesmo
            deletar a imagem?
          </h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green "
            @click="
              setModal({
                status: false,
                type: '',
                id: null,
              })
            "
          >
            <v-icon color="white"> mdi mdi-cancel </v-icon>
            <h4 style="color: white">cancelar</h4>
          </v-btn>
          <v-btn color="red darken-4" @click="deleteItem(bannerId)">
            <v-icon color="white"> mdi mdi-trash-can </v-icon>
            <h4 style="color: white">deletar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      reset: true,
    };
  },
  methods: {
    setImage: function (output) {
      this.items.base64 = output.dataUrl;
    },

    setImageMobile: function (output) {
      this.itemsMobile.base64 = output.dataUrl;
    },
    ...mapActions("BannerPersonalization", [
      "sendItem",
      "deleteItem",
      "setModal",
    ]),

    setSpinnerLoadImage(value) {
      this.setModalSpiner(value);
    },
    ...mapActions("Spinner", ["setModalSpiner"]),

    async clearFields() {
      this.reset = false;
      await this.$nextTick();
      this.reset = true;
      this.items.link = null;
      this.items.base64 = null;
      this.itemsMobile.base64 = null;
      this.itemsMobile.link = null;
    },

    removeItem(item) {
      item.base64 = null;
    },

    saveBanner() {
      if (this.dialogType == "add") {
        let holder = this.formBanners;
        holder.items = this.items;
        delete this.formBanners.itemsMobile;
        this.sendItem(this.formBanners);
        this.clearFields();
      } else if (this.dialogType == "addMobile") {
        let holder = this.formBanners;
        holder.itemsMobile = this.itemsMobile;
        delete this.formBanners.items;
        this.sendItem(this.formBanners);
        this.clearFields();
      }
    },
  },
  computed: {
    ...mapState("BannerPersonalization", [
      "dialog",
      "dialogType",
      "formBanners",
      "items",
      "itemsMobile",
      "bannerId",
      "mobile",
    ]),
  },
  mounted() {
    // this.clearFields();
  },
};
</script>

<style>
.close-btn {
  position: absolute;
  top: 80px;
  font-size: 20px;
  left: 290px;
  background: rgba(255, 255, 255, 0.534);
  border-radius: 20px;
  color: rgb(20, 19, 19);
  height: 32px;
  width: 32px;
  z-index: 100;
}
.close-btn-mobile {
  position: absolute;
  top: 60px;
  font-size: 20px;
  left: 300px;
  background: rgba(255, 255, 255, 0.534);
  border-radius: 20px;
  color: rgb(20, 19, 19);
  height: 32px;
  width: 32px;
  z-index: 100;
}
</style>