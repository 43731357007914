var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list)?_c('span',[(_vm.$vuetify.breakpoint.name !== 'xs')?_c('span',[(_vm.filter.show_img === true)?_c('div',{staticClass:"card-list"},[(_vm.list.product_image)?_c('div',[_c('v-card',{staticClass:"card"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"image-product",attrs:{"contain":"","src":_vm.generateImageFromLink()}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"product-name",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.list.name))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-price",attrs:{"cols":"12"}},[_c('span',[_vm._v("R$ "+_vm._s(parseFloat(_vm.list.price).toLocaleString( ("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }) )))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-relevance",attrs:{"cols":"12"}},[_c('span',[_vm._v(" Relevância atual: "+_vm._s(_vm.list.relevance))]),_c('v-text-field',{staticClass:"input-relevance",attrs:{"label":"Nova relevância","type":"number","min":"0","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","hide-details":"auto"},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"btn-alter-changes",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.alterRelevance({
                    id: _vm.list.id,
                    name: _vm.list.name,
                    relevance: _vm.relevance,
                  })}}},[_vm._v("Alterar")])],1)],1)],1)],1):_c('div',[_c('v-card',{staticClass:"card-noimage"},[_c('v-row',[_c('v-col',{staticClass:"product-name",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.list.name))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-price",attrs:{"cols":"12"}},[_c('span',[_vm._v("R$ "+_vm._s(parseFloat(_vm.list.price).toLocaleString( ("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }) )))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-relevance",attrs:{"cols":"12"}},[_c('span',[_vm._v(" Relevância atual: "+_vm._s(_vm.list.relevance))]),_c('v-text-field',{staticClass:"input-relevance",attrs:{"label":"Nova relevância","type":"number","min":"0","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","hide-details":"auto"},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"btn-alter-changes",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.alterRelevance({
                    id: _vm.list.id,
                    name: _vm.list.name,
                    relevance: _vm.relevance,
                  })}}},[_vm._v("Alterar")])],1)],1)],1)],1)]):_c('div',[_c('v-card',{staticClass:"card-noimage"},[_c('v-row',[_c('v-col',{staticClass:"product-name",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.list.name))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-price",attrs:{"cols":"12"}},[_c('span',[_vm._v("R$ "+_vm._s(parseFloat(_vm.list.price).toLocaleString( ("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }) )))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-relevance",attrs:{"cols":"12"}},[_c('span',[_vm._v(" Relevância atual: "+_vm._s(_vm.list.relevance))]),_c('v-text-field',{staticClass:"input-relevance",attrs:{"label":"Nova relevância","type":"number","min":"0","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","hide-details":"auto"},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"btn-alter-changes",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.alterRelevance({
                  id: _vm.list.id,
                  name: _vm.list.name,
                  relevance: _vm.relevance,
                })}}},[_vm._v("Alterar")])],1)],1)],1)],1)]):_c('span',[(_vm.list.product_image)?_c('div',[_c('v-card',{staticClass:"card-mobile"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"image-product-mobile",attrs:{"contain":"","src":_vm.generateImageFromLink()}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"product-name-mobile",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.list.name))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-price-mobile",attrs:{"cols":"12"}},[_c('span',[_vm._v("R$ "+_vm._s(parseFloat(_vm.list.price).toLocaleString( ("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }) )))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-relevance-mobile",attrs:{"cols":"12"}},[_c('span',[_vm._v(" Relevância atual: "+_vm._s(_vm.list.relevance))]),_c('v-text-field',{staticClass:"input-relevance",attrs:{"label":"relevância","type":"number","min":"0","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","hide-details":"auto"},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"btn-alter-changes",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.alterRelevance({
                  id: _vm.list.id,
                  name: _vm.list.name,
                  relevance: _vm.relevance,
                })}}},[_vm._v("Alterar")])],1)],1)],1)],1):_c('div',[_c('v-card',{staticClass:"card-noimage-mobile"},[_c('v-row',[_c('v-col',{staticClass:"product-name-mobile-noimage",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.list.name))])])],1),_c('v-row',[_c('v-col',{staticClass:"product-price-mobile-noimage",attrs:{"cols":"12"}},[_c('span',[_vm._v("R$ "+_vm._s(_vm.list.price.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }))+" "),_c('br')])])],1),_c('v-row',[_c('v-col',{staticClass:"product-relevance",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" Relevância atual: "+_vm._s(_vm.list.relevance))]),_c('v-text-field',{staticClass:"input-relevance",attrs:{"label":"Nova relevância","type":"number","min":"0","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","hide-details":"auto"},model:{value:(_vm.relevance),callback:function ($$v) {_vm.relevance=$$v},expression:"relevance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"btn-alter-changes",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.alterRelevance({
                  id: _vm.list.id,
                  name: _vm.list.name,
                  relevance: _vm.relevance,
                })}}},[_vm._v("Alterar")])],1)],1)],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }