export default {
    showInactive: 0,

    pagination: {
        itemsPerPage: 25,
        currentPage: 1,
    },

    order: {
        field: "",
        value: "",
    },

    filter: {
        definition: "",
        category: "",
        subcategory: "",
        qtd_cards: 1,
        show_img: false,
        ordenate_field: "relevance",
        ordenate_type: "DESC",
        promotionRule: null,
        gender: null,
        company_id: ""
    },

    formData: {
        relevance: null,
    },
    list: [],
    errors: [],

    promotionRule: [
        { name: 'Nenhum', value: null },
        { name: 'Somente promoções', value: 'only_sale' },
        { name: 'Não trazer promoções', value: 'no_sale' }
    ],

    gender: [
        { name: 'Nenhum', value: null },
        { name: 'Masculino', value: 'm' },
        { name: 'Feminino', value: 'f' },
        { name: 'Unissex', value: 'u' },
    ],


}