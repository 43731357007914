<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-brand">
    <h1 class="title-topo">Lista de Marcas</h1>

    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <span>
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </span>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@//Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Nome", value: "name", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Brand", ["list"]),
  },

  methods: {
    ...mapActions("Brand", ["setList"]),
    edit(item) {
      this.$router.push(`form-brand/${item}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(2, 0, {
        text: "Cliente",
        value: "fantasy_name",
        class: "black--text",
      });
    }
  },
};
</script>