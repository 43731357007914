import store from '@/store/index'


import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";


export const showReport = function ({ commit }, payload) {

    let headers = {
        reportTitle: 'Relatorio de Pedidos',
        reportInfo: {
            title: 'Periodo: ',
            text: `${formatData(payload.init_date)} - ${formatData(payload.finish_date)}
        `
        },
        reportOptionalInfo: {
            title: 'Status: ',
            text: convertStatusNumberInName(payload.status)
        },
    }
    store.dispatch('Spinner/setModalReportSpinner', true)
    store.dispatch('Reports/setReportHeader', headers)
    store.dispatch('Reports/setReportModal', true)
    commit('SET_INIT_DATE', payload.init_date)
    axios.post(`${baseApiUrl}/report/order`, payload)
        .then(res => {
            commit('SET_LIST', {
                ...cleanList(res.data),
                products: res.data.products,
                buyed_people_data: res.data.buyed_people_data,
                product_categories: res.data.product_categories
            })
        })
        .catch(e => {
            catchError(e.status)

        })
        .finally(() => {
            store.dispatch('Spinner/setModalReportSpinner', false)
        })
}

const formatData = function (value) {
    let date = new Date(value);
    return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
}

const convertStatusNumberInName = function (value) {
    let items = [
        { value: 0, name: "Pendente" },
        { value: 1, name: "Pgto em Analise" },
        { value: 2, name: "Separação" },
        { value: 3, name: "Transito" },
        { value: 4, name: "Entregue" },
        { value: 5, name: "Expirado" },
        { value: 6, name: "Cancelado" },
        { value: 7, name: "Pgto Recusado" },
    ]

    let response = null
    for (let index = 0; index < value.length; index++) {
        let filtered = items.filter((data) => {
            return data.value === value[index];
        })
        if (response == null) {
            response = filtered[0].name
        } else {

            response = `${response}, ${filtered[0].name}`
        }

    }
    return response

}


//Private
const cleanList = function (value) {
    let response = [];
    for (let i = 0; i < value.list.length; i++) {

        // let lastIndex = i
        // console.log(response[i].id)
        if (response.length < 1) {
            response.push({ ...value.list[i] })
        } else {
            // let lastElement = arry[arry.length - 1]
            if (response[response.length - 1].id !== value.list[i].id) {
                response.push(value.list[i])
            } else {
                response[response.length - 1].amount = parseFloat(response[response.length - 1].amount) + parseFloat(value.list[i].amount)
            }
            // console.log(response[i - 1].id)
            // console.log(i - 1)
            // 
            // if (response[index].id !== value.list[i].id) {
            //     response.push(value.list[i])
            // }
        }
        // text += value[i] + "<br>";
    }


    return { order: value.order, list: response }
}
//END PRIVATE

export default {
    showReport
}