<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-product">
    <h1 class="title-topo">Lista de Produtos</h1>
    <v-switch
      color="amber darken-4"
      @change="setList()"
      v-model="showInactive"
      label="Mostar Inativos"
    ></v-switch>

    <span>
      <filter-form :list="list" />
      <list-iterator :list="list" class="d-block d-md-none" />
      <list-table :list="list" class="d-none d-md-block" />
    </span>
  </admin-blade>
</template>

<script>
import AdminBlade from "@//Layouts/Blade";
import FilterForm from "@/views/ProductSession/Pages/Product/filterForm.vue";
import ListIterator from "@/views/ProductSession/Pages/Product/iterator.vue";
import ListTable from "@/views/ProductSession/Pages/Product/table.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
    FilterForm,
    ListIterator,
    ListTable,
  },
  computed: {
    showInactive: {
      get() {
        return this.$store.state.Product.filter.showInactive;
      },
      set(value) {
        this.$store.commit("Product/SET_SHOWINACTIVE", value);
      },
    },
    ...mapState("Product", ["list"]),
  },
  methods: {
    ...mapActions("Product", ["setList"]),
  },
};
</script>