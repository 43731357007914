import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'

export const loadChartData = function ({ commit }) {

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/dashboard/home`)
        .then(res => {
            commit("SET_CHARTDATA", res.data)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

export const changeDelivery = function ({ commit }, value) {

    spinnerChoice('modal', true)

    axios.post(`${baseApiUrl}/delivery-settings/change-open-delivery`, { open_delivery: value })
        .then(res => {
            commit("SET_OPENDELIVERY", res.data.open_delivery)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

export const showDeliveryOpen = function ({ commit }) {

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/delivery-settings/show`)
        .then(res => {
            commit("SET_OPENDELIVERY", res.data[0].open_delivery)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

export default {
    loadChartData,
    showDeliveryOpen,
    changeDelivery
}