<template>
  <admin-blade iconBtnTop="mdi-bookmark" routeBtnTop="/atribute">
    <span>
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <!-- Opcoes para Formulario -->
        <span></span>

        <!-- Opcoes para Formulario -->

        <!-- Default -->
        <v-row>
          <v-col sm="6" cols="12">
            <v-autocomplete
              v-model="formData.atribute_group_id"
              :items="AtributeGroupList"
              item-text="name"
              item-value="id"
              label="Grupo Atributo *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.atribute_group_id }}</strong>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="formData.name" label="Nome *"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label>
              <strong>Adicional Valor Produto</strong>(R$)
              <span class="mr-5"></span>
              <money
                class="money"
                v-bind="money"
                v-model.lazy="formData.additional_price"
              ></money>
            </label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-switch
              color="amber darken-4"
              v-model="formData.typeable"
              label="Campo Digitavel"
            ></v-switch>
          </v-col>

          <v-col cols="6">
            <v-switch
              color="amber darken-4"
              v-model="formData.active"
              label="Ativo"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
          >Salvar</v-btn
        >
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
import { Money } from "v-money";

export default {
  components: {
    AdminBlade,
    Money,
  },
  data: function () {
    return {
      valid: true,
      money: {},
    };
  },
  computed: {
    ...mapState("Atribute", ["userType", "errors", "formData"]),
    ...mapState("AtributeGroup", {
      AtributeGroupList: "list",
    }),
  },
  methods: {
    ...mapActions("Atribute", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("AtributeGroup", {
      setAtributeGroup: "setList",
    }),
    // ...mapActions("", ["setList"]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    this.setAtributeGroup();
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>