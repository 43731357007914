<template>
  <v-card style="margin-bottom: 10px">
    <h2 class="text-center">Pré Visualização Navbar</h2>
    <br />

    <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
      <div
        class="topbar"
        style="border-top: solid rgba(128, 128, 128, 0.214) 1px"
      >
        <span
          style=" 
          font-family: 'Bree Serif', serif,
          background-color: white
          "
        >
          <v-row
          align="center"
            style="
              max-width: 1130px !important;
              align-items: center;
              margin: auto;
            "
          >
            <v-col cols="3" class="mr-5 psb-3" align="center">
              <img alt="Logo da loja" />
            </v-col>
            <v-col cols="4" align="center">
              <v-text-field
                flat
                solo
                hide-details
                v-on:keydown.enter="search()"
                style="
                  width: 100%;
                  color: black !important;
                  border: 1px solid black;
                "
                placeholder="O quê você procura?"
              >
                <template v-slot:append>
                  <v-btn icon style="font-size: 22px">
                    <i class="mdi mdi-magnify"></i>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-row>
              <v-col cols="4" align="right" align-self="center">
                <i
                  class="far fa-user user-icon"
                  style="color: black; margin-top: 4px"
                >
                </i>
                <span
                  style="color: black,
                      font-size: 16px"
                >
                  Entrar
                </span>
              </v-col>
              <v-col cols="4" align="center" align-self="center">
                <i
                  class="mdi mdi-clipboard-list-outline order-class"
                  style="  color: black,
                      font-size: 16px"
                ></i>
                <span
                  style="  color: black,
                      font-size: 16px"
                >
                  Meus Pedidos
                </span>
              </v-col>
              <v-col cols="4" align="rigth">
                <button>
                  <i
                    class="mdi mdi-shopping-outline cart-icon"
                    style="color: black; font-size: 20px"
                  ></i>
                  <span style="color: black">0</span>
                </button>
              </v-col>
            </v-row>
          </v-row>
        </span>
      </div>

      <span
        v-if="list.length >= 1"
        class="nav-list"
        style="
          display: flex;
          height: 65px;
          justify-content: center;
          background-color: white;
          border-top: solid rgba(128, 128, 128, 0.214) 1px;
          border-bottom: solid rgba(128, 128, 128, 0.214) 1px;
          font-family: 'Montserrat', sans-serif;
        "
      >
        <span v-for="item in list" :key="item.id">
        
       
          <div v-if="item.active">
            <v-menu
              v-if="item.childrens.length >= 1"
              transition="slide-x-transition"
              open-on-hover
              offset-y
            >
              <template v-slot:activator="{ on }">
                <p class="navText" style="" v-on="on">
                  {{ item.data.name }}
                </p>
              </template>
              <v-list
                class="nav-subitens-main-class navbar-sub"
                style="background-color: white"
                v-if="item.childrens"
              >
                <v-list-item
                  v-for="detail in item.childrens"
                  :key="detail.name"
                >
                  <v-list-item-title
                    class="ml-5 mr-5 nav-subitens subText"
                    style="color: black; "
                    >{{ detail.data.name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu v-else>
              <template v-slot:activator="{ on }">
                <p
                  class="navText"
                  style="
                    color: black !important;
                    line-height: 40px;
                    padding: 10px 10px 10px 10px;
                    text-transform: uppercase;
                  "
                  v-on="on"
                >
                  {{ item.data.name }}
                </p>
              </template>
            </v-menu>
          </div>
        </span>
      </span>

      

      <div v-else>
        <center>
          <v-icon> mdi-emoticon-sad-outline </v-icon>
          <h3>Nenhum item encontrado</h3>
        </center>
      </div>
    </div>

    <div v-else>
      <v-container v-if="list.length >= 1">
        <v-expansion-panels>
          <v-expansion-panel v-for="items in list" :key="items.ordenate">
            <v-expansion-panel-header v-if="items.childrens.length > 0">
              <v-btn
                v-if="items.active"
                solo
                depressed
                block
                tile
                small
                height="40"
              >
                <span>{{ items.data.name }}</span>
              </v-btn>
            </v-expansion-panel-header>

            <v-expansion-panel-header v-else expand-icon="">
              <v-btn
                v-if="items.active"
                solo
                depressed
                block
                tile
                small
                height="40"
              >
                <span >{{ items.data.name }}</span>
              </v-btn>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="items.childrens.length > 0">
              <div v-for="item in items.childrens" :key="item.id">
                <v-btn solo depressed block tile small>
                  <span>{{ item.data.name }}</span>
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>

      <div v-else>
        <center>
          <v-icon> mdi-emoticon-sad-outline </v-icon>
          <h3>Nenhum item encontrado</h3>
        </center>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

  data: function () {
    return {
    };
  },

  computed: {
    ...mapState("NavBarPersonalization", ["list"]),
  },
  methods: {
    ...mapActions("NavBarPersonalization", ["setList"]),
  },
  // mounted() {
  //   this.setList();
  // },
};
</script>

<style>
.layout {
  background-color: white;
  width: "100%";
  height: "150px" !important;
}

.nav-list {
  display: flex;
  justify-content: center;
  background-color: "white";
  border: "solid rgba(128, 128, 128, 0.214) 1px";
  font-family: "'Montserrat', sans-serif";
}
.navbar-sub {
  background-color: white;
}
.navText {
  color: black !important;
  font-size: 16px !important;
  line-height: 40px;
  padding: 10px 15px 10px 15px;
  text-transform: uppercase;
}
.subText {
  color: black;
}
.nav-list li {
  text-decoration: none !important;
  list-style-type: none;
}

@media screen and (max-width: 1400px) {
  .navText {
    font-size: 14px !important;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 1200px) {
  .navText {
    font-size: 12px !important;
    padding: 10px 10px;
  } 
}
@media screen and (max-width: 1000px) {
  .navText {
    font-size: 10px !important;
    padding: 10px 10px;
  } 
}
@media screen and (max-width: 900px) {
  .navText {
    font-size: 8px !important;
    padding: 10px 10px;
  } 
}
</style>