const defaultPath = '/sub-category'
const toastName = 'Sub Categoria'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null,
    name: null,
    category: null
}

import { diferentIndexFunction, saveFunction, indexFunction, updateFunction, showFunction} from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit }, payload) {
    saveFunction(commit, defaultPath, payload, `${toastName + payload.name}`, 'full')
}

const update = function ({ commit }, payload) {
    updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.name}`, 'full')
}

const show = function ({ commit }, payload) {
    showFunction(commit, defaultPath, `id=${payload}`, 'full')
}

const setList = ({ commit }) => {
    indexFunction(commit, defaultPath, null, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const setFilteredList = ({ commit }, id) => {
    diferentIndexFunction(commit, `${defaultPath}/index-with-filter`, `category_id=${id}`, 'full')
}


export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show,
    setFilteredList
}