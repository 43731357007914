import DeliverySettings from '@/views/CompanySettings/Pages/DeliverySettings/list'
import FormDeliverySettings from '@/views/CompanySettings/Pages/DeliverySettings/form'


const routes = [{
    path: '/delivery-settings',
    props: true,
    component: DeliverySettings,
},
{
    path: '/form-delivery-settings',
    props: true,
    component: FormDeliverySettings,
    children: [
        { path: ':id', component: FormDeliverySettings, props: true, }
    ]
}]

export default routes