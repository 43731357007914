<template>
  <v-card class="delivery-info-card">
    <span v-if="getDeliverySource(data.delivery_source)[0] == 'melhor_envio'">
      <!-- <span> -->
      <h1>Melhor Envio</h1>
      <v-btn
        @click="setCartMelhorEnvioModal(true)"
        color="green"
        class="mt-5 mb-3"
        >Enviar p/ Carrinho Melhor Envio</v-btn
      >
      <spinner-dialog
        persistent
        v-if="cartMelhorEnvioModal"
        :dialog="cartMelhorEnvioModal"
      >
        <v-card>
          <add-delivery-in-melhor-envio-cart />
        </v-card>
      </spinner-dialog>
      <!-- {{ data.delivery_source }} -->
      <!-- {{ getDeliverySource(data.delivery_source)[0] }} -->
    </span>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddDeliveryInMelhorEnvioCart from "@/views/OrderSession/pages/DetailOrder/components/Delivery/addDeliveryInMelhorEnvioCart";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";

export default {
  components: {
    spinnerDialog,
    AddDeliveryInMelhorEnvioCart,
  },
  props: {
    data: Object,
  },
  computed: {
    ...mapState("DetailOrder", ["cartMelhorEnvioModal"]),
  },
  methods: {
    ...mapActions("DetailOrder", ["setCartMelhorEnvioModal"]),
    getDeliverySource(value) {
      if (value) {
        return value.split(" - ");
      }
    },
  },
};
</script>

<style scoped>
.delivery-info-card {
  padding: 30px;
  box-shadow: none;
  border: solid rgba(128, 128, 128, 0.438) 1px;
}
</style>