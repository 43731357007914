<template>
  <admin-blade iconBtnTop="fas fa-calendar" routeBtnTop="/category">
    <span class="default-form-bg">
      <v-form class="mt-5 default-form" ref="form" v-model="valid">
        <h1 class="title-topo">Formulario Categoria</h1>
        <v-row>
          <v-col sm="4" cols="12">
            <v-autocomplete
              v-model="formData.definition_id"
              :items="list"
              item-text="name"
              item-value="id"
              label="Definição *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.definition_id }}</strong>
          </v-col>
          <v-col sm="3" cols="12">
            <v-text-field
              v-model="formData.name"
              :rules="rulesFunction('Nome', 2)"
              label="Nome *"
            ></v-text-field>
            <strong class="red--text">{{ errors.name }}</strong>
          </v-col>
          <v-col sm="3" cols="12">
            <v-text-field
              v-model="formData.google_product_category"
              label="Google Category"
            ></v-text-field>
            <strong class="red--text">{{
              errors.google_product_category
            }}</strong>
          </v-col>
          <v-col sm="2" cols="12">
            <v-autocomplete
              v-model="formData.gender_types"
              :items="genders"
              item-text="name"
              item-value="value"
              label="Genero *"
              color="amber darken-4"
            ></v-autocomplete>
            <strong class="red--text">{{ errors.gender_types }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" cols="12">
            <p v-if="formData.updated_at">
              <strong>Ultima Modificação:</strong>
              {{ formData.updated_at }}
            </p>
          </v-col>
          <v-col sm="6" cols="12">
            <p v-if="formData.created_at">
              <strong>Data Cadastro:</strong>
              {{ formData.created_at }}
            </p>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          :disabled="!valid"
          color="green darken-2 white--text"
          class="mr-4"
          @click="save(formData)"
          >Salvar</v-btn
        >
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("Category", ["userType", "errors", "formData"]),
    ...mapState("Definition", ["list"]),
    ...mapState("Utils", ["genders"]),
  },
  methods: {
    ...mapActions("Definition", ["setList"]),
    ...mapActions("Category", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    this.setList();
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>