<template>
  <div>
    <layout-blade>
      <v-btn
        @click="openModal(true)"
        color="green"
        dark
        absolute
        fixed
        bottom
        right
        fab
        style="bottom: 50px"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <span v-if="!checkUser()">
        <navbarcomponent />
      </span>
      <span v-else>
        <form-adm />
      </span>

      <alert-vue></alert-vue>
      <listVue :adm="checkUser()" />

      <modalVue />
    </layout-blade>
  </div>
</template>

<script>
import LayoutBlade from "@/Layouts/BladeLayoutOrganization";
import alertVue from "@/views/LayoutPersonalization/Pages/NavbarOrganization/alert";
import formAdm from "@/views/LayoutPersonalization/Pages/NavbarOrganization/formAdmin";
import listVue from "@/views/LayoutPersonalization/Pages/NavbarOrganization/list";
import navbarcomponent from "@/views/LayoutPersonalization/Pages/NavbarOrganization/navbarComponent";
import modalVue from "@/views/LayoutPersonalization/Pages/NavbarOrganization/modal";
import { mapActions } from "vuex";

export default {
  components: {
    LayoutBlade,
    listVue,
    navbarcomponent,
    modalVue,
    alertVue,
    formAdm,
  },

  methods: {
    ...mapActions("NavBarPersonalization", ["openModal"]),

    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>