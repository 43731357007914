<template>
  <v-card>
    <!-- <v-simple-table class="default-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.value" class="text-left">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="row-class"
            @click="edit(item)"
            v-for="item in list"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td v-if="checkUser()">{{ item.fantasy_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->

    <v-data-table
      class="default-table"
      :headers="headers"
      :items="list"
      :search="search"
      :footer-props="footer"
      @click:row="edit"
    >
      <template v-slot:item="{ item }">
        <tr class="row-class" @click="edit(item)">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td v-if="checkUser()">{{ item.fantasy_name }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapActions } from "vuex";

export default {
  data: function () {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Grupo",
          value: "name",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  props: {
    list: {},
    search: null,
  },
  methods: {
    ...mapActions("ProductGroup", ["setList"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    edit(item) {
      this.$router.push(`form-product-group/${item.id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");

    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(3, 0, {
        text: "Cliente",
        value: "fantasy_name",
      });
    }
  },
};
</script>

<style scoped>
.row-class:hover {
  cursor: pointer !important;
  background-color: #f2f2f2 !important;
}
</style>