<template>
  <span>
    <pagar-me v-if="intermediary == 'pagarme'" />
  </span>
  <!-- <h1>Boleto</h1> -->
</template>

<script>
import PagarMe from "./Intermediaries/PagarMe";
export default {
  components: {
    PagarMe,
  },
  props: {
    intermediary: String,
  },
};
</script>

<style>
</style>