const defaultPath = '/layout/ecommerce-image';
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { baseApiUrl, catchError } from "@/global";
import { toastAlert } from "@/utils/Alerts/toast";

import axios from 'axios'

const sendItem = function ({ commit, state }, item) {
    spinnerChoice('full', true)

    let data = {};
    let companyId = getCompanyId({ state });

    data = {
        company_id: companyId,
        ...item
    }

    commit('SET_MODAL', false)

    axios.post(`${baseApiUrl + defaultPath}/save`, data)
        .then(res => {
            if (res.status == 200 || res.status == 201) {
                index({ commit, state });
                sessionStorage.setItem(
                    "toastMsg",
                    res.data.message
                );
                toastAlert('success')

            }
        })
        .catch(e => {
            catchError(e)
            commit('SET_ERRORS', e.response.data.errors)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const update = function ({ commit, state }, item) {
    spinnerChoice('full', true)

    let data = {};
    let companyId = getCompanyId({ state });

    data = {
        company_id: companyId,
        ...item
    }

    commit('SET_MODAL', false)

    axios.post(`${baseApiUrl + defaultPath}/update`, data)
        .then(res => {
            if (res.status == 200 || res.status == 201) {
                index({ commit, state });
                sessionStorage.setItem(
                    "toastMsg",
                    res.data.message
                );
                toastAlert('success')
            }


        })
        .catch(e => {
            catchError(e)
            commit('SET_ERRORS', e.response.errors)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const index = function ({ state, commit }) {
    spinnerChoice('full', true)
    let data = {};
    let companyId = getCompanyId({ state });

    data = {
        company_id: companyId,
    }

    axios.post(`${baseApiUrl + defaultPath}/index`, data)
        .then(res => {
            commit('SET_BANNERS', res.data)

        })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}
const deleteItem = function ({ commit, state }, payload) {
    spinnerChoice('full', true)
    commit('SET_MODAL', false)
    let data = {};
    let companyId = getCompanyId({ state });

    if (state.mobileAction == true) {
        data = {
            company_id: companyId,
            id: payload,
            mobile: true
        }
    } else {
        data = {
            company_id: companyId,
            id: payload
        }
    }

    axios.post(`${baseApiUrl + defaultPath}/delete`, data)
        .then(res => {
            if (res.status == 200 || res.status == 201) {
                index({ commit, state });
                sessionStorage.setItem(
                    "toastMsg",
                    res.data.message
                );
                toastAlert('success')
            }
        })

        .catch(e => {
            catchError(e)
            commit('SET_ERROS', '')
        })
        .finally(() => {
            spinnerChoice('full', false)
        })
}

const getCompanyId = function ({ state }) {
    let companyId;
    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        companyId = JSON.parse(localStorage.getItem("user")).company_id
    } else {
        companyId = state.id_company
    }

    return companyId;
}

const setModal = function ({ commit }, payload) {
    commit('SET_MODAL', payload.status)
    commit('SET_MODAL_TYPE', payload.type)
    commit('SET_ID_BANNER', payload.id)
    commit('SET_MOBILE', payload.mobile)
}



const setIdCompany = function ({ commit }, data) {
    commit("SET_ID_COMPANY", data)
}


export default {
    sendItem,
    index,
    setModal,
    deleteItem,
    update,
    setIdCompany

}