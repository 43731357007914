
import Coupon from '@/views/RecordSession/Pages/Coupon/list'
import FormCoupon from '@/views/RecordSession/Pages/Coupon/form'

const routes = [
    {
        path: '/coupon',
        props: true,
        component: Coupon
    },
    {
        path: '/form-coupon',
        props: true,
        component: FormCoupon,
        children: [
            { path: ':id', component: FormCoupon, props: true, }
        ]
    },
]

export default routes