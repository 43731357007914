const defaultPath = '/delivery-settings'
const toastName = 'Configuração Envio'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null,
    cd_empresa_correios: null,
    sds_senha_correios: null
}

import { saveFunction, indexFunction, updateFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";


//Melhor Envio
export const save = function ({ commit }, payload) {
    saveFunction(commit, defaultPath, payload, `${toastName + payload.name}`, 'full')
}




const update = function ({ commit }, payload) {
    updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.company_name}`, 'full')
}

const show = function ({ commit }, payload) {
    showFunction(commit, defaultPath, payload, 'full')
}

const setList = ({ commit }) => {
    indexFunction(commit, defaultPath, null, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}


export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show
}