<template>
  <div>
    <v-card-title>Organização</v-card-title>
    <v-alert v-if="!carouselItems[0]" dismissible type="info">
      Clique no botão <v-icon> mdi mdi-plus </v-icon> para enviar seu primeiro
      banner
    </v-alert>

    <v-card elevation="0" style="margin: 10px auto; padding: 10px">
      <div
        v-if="$vuetify.breakpoint.name !== 'xs'"
        style="overflow-x: scroll; height: 170px"
      >
        <Container
          v-if="carouselItems[0]"
          @drop="onDrop"
          orientation="horizontal"
          style="padding-right: 10px"
        >
          <Draggable
            v-for="(item, i) in carouselItems[0].data.items"
            :key="i"
            style="padding-left: 15px"
          >
            <v-card
              width="220px"
              height="150"
              v-if="carouselItems[0]"
              @click="getScaleFromDb()"
            >
              <v-img height="115px" width="305px" :src="item.src" />
              <div>
                <v-row align="center" no-gutters>
                  <v-col align="center">
                    <v-btn
                      icon
                      color="black"
                      @click="
                        setModal({
                          status: true,
                          type: 'delete',
                          id: item.img_id,
                          mobile: false,
                        })
                      "
                    >
                      <v-icon> mdi mdi-trash-can </v-icon>
                    </v-btn>
                  </v-col>

                  <v-col align="center">
                    {{ i + 1 }}
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </Draggable>

          <v-card
            v-if="carouselItems[0].data.items.length <= 5"
            style="margin-left: 15px"
            width="220px"
            height="150"
            @click="setModal({ status: true, type: 'add' }), getScaleFromDb()"
          >
            <div
              style="
                height: 140px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-icon> mdi mdi-plus </v-icon>
            </div>
          </v-card>
        </Container>

        <div v-else>
          <v-card
            style="margin-left: 15px"
            width="220px"
            height="150"
            @click="
              firstItemAdd(),
                setModal({ status: true, type: 'add' }),
                getScaleFromDb()
            "
          >
            <div
              style="
                height: 140px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-icon> mdi mdi-plus </v-icon>
            </div>
          </v-card>
        </div>
      </div>

      <div v-else>
        <v-expansion-panels v-model="panel" flat>
          <v-expansion-panel>
            <v-expansion-panel-header> Ordenar </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(item, i) in carouselItems[0].data.items"
                :key="item"
                style="padding-left: 15px"
              >
                <v-row align="center">
                  <v-col cols="2">
                    <div v-if="i >= 1">
                      <v-btn
                        icon
                        @click="
                          tradeItensPosition(
                            carouselItems[0].data.items,
                            i,
                            i - 1
                          )
                        "
                      >
                        <v-icon color="black">mdi mdi-arrow-up</v-icon>
                      </v-btn>
                    </div>
                    <div v-if="i < carouselItems[0].data.items.length - 1">
                      <v-btn
                        icon
                        @click="
                          tradeItensPosition(
                            carouselItems[0].data.items,
                            i,
                            i + 1
                          )
                        "
                      >
                        <v-icon color="black">mdi mdi-arrow-down</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="8">
                    <v-card width="220px" height="150">
                      <v-img height="115px" width="305px" :src="item.src" />
                      <div>
                        <v-row align="center" no-gutters>
                          <v-col align="center">
                            <v-btn
                              icon
                              color="black"
                              @click="
                                setModal({
                                  status: true,
                                  type: 'delete',
                                  id: item.img_id,
                                  mobile: false,
                                }),
                                  getScaleFromDb()
                              "
                            >
                              <v-icon> mdi mdi-trash-can </v-icon>
                            </v-btn>
                          </v-col>

                          <v-col align="center">
                            {{ i + 1 }}
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="carouselItems[0].data.items.length <= 5">
                <v-col cols="2" />
                <v-col cols="8">
                  <v-card style="margin-left: 15px" width="220px" height="150">
                    <div
                      style="
                        height: 140px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      @click="setModal({ status: true, type: 'add' })"
                    >
                      <v-icon> mdi mdi-plus </v-icon>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>

    <span
      v-if="
        (carouselItems[0] && carouselItems[0].data.items.length >= 1) ||
        (carouselItems[0] && carouselItems[0].data.itemsMobile.length >= 1)
      "
    >
      <v-checkbox label="Mobile" v-model="mobile" />

      <span v-if="mobile">
        <v-card
          v-if="mobile"
          @click="getScaleFromDb()"
          elevation="0"
          style="margin: 10px auto; padding: 10px"
        >
          <div
            v-if="$vuetify.breakpoint.name !== 'xs'"
            style="overflow-x: scroll; height: 160px"
          >
            <Container
              v-if="carouselItems[0]"
              @drop="onDropMobile"
              orientation="horizontal"
              style="padding-right: 10px"
            >
              <Draggable
                v-for="(item, i) in carouselItems[0].data.itemsMobile"
                :key="i"
                style="padding-left: 15px"
              >
                <v-card width="220px" height="150" v-if="carouselItems[0]">
                  <v-img height="115px" width="305px" :src="item.src" />
                  <div>
                    <v-row align="center" no-gutters>
                      <v-col align="center">
                        <v-btn
                          icon
                          color="black"
                          @click="
                            setModal({
                              status: true,
                              type: 'delete',
                              id: item.img_id,
                              mobile: true,
                            })
                          "
                        >
                          <v-icon> mdi mdi-trash-can </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col align="center">
                        {{ i + 1 }}
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </Draggable>

              <v-card
                v-if="carouselItems[0].data.itemsMobile.length <= 5"
                style="margin-left: 15px"
                width="220px"
                height="150"
                @click="setModal({ status: true, type: 'addMobile' })"
              >
                <div
                  style="
                    height: 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-icon> mdi mdi-plus </v-icon>
                </div>
              </v-card>
            </Container>

            <div v-else>
              <v-card
                style="margin-left: 15px"
                width="220px"
                height="150"
                @click="
                  firstItemAdd(), setModal({ status: true, type: 'addMobile' })
                "
              >
                <div
                  style="
                    height: 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <v-icon> mdi mdi-plus </v-icon>
                </div>
              </v-card>
            </div>
          </div>

          <div v-else>
            <v-expansion-panels flat v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header> Ordenar </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- <div v-if="carouselItems[0]"> -->
                  <div
                    v-for="(item, i) in carouselItems[0].data.itemsMobile"
                    :key="item"
                    style="padding-left: 15px"
                  >
                    <v-row align="center">
                      <v-col cols="2">
                        <div v-if="i >= 1">
                          <v-btn
                            icon
                            @click="
                              tradeItensPosition(
                                carouselItems[0].data.itemsMobile,
                                i,
                                i - 1
                              )
                            "
                          >
                            <v-icon color="black">mdi mdi-arrow-up</v-icon>
                          </v-btn>
                        </div>
                        <div
                          v-if="
                            i < carouselItems[0].data.itemsMobile.length - 1
                          "
                        >
                          <v-btn
                            icon
                            @click="
                              tradeItensPosition(
                                carouselItems[0].data.itemsMobile,
                                i,
                                i + 1
                              )
                            "
                          >
                            <v-icon color="black">mdi mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8">
                        <v-card width="220px" height="150">
                          <v-img height="115px" width="305px" :src="item.src" />
                          <div>
                            <v-row align="center" no-gutters>
                              <v-col align="center">
                                <v-btn
                                  icon
                                  color="black"
                                  @click="
                                    setModal({
                                      status: true,
                                      type: 'delete',
                                      id: item.img_id,
                                      mobile: true,
                                    })
                                  "
                                >
                                  <v-icon> mdi mdi-trash-can </v-icon>
                                </v-btn>
                              </v-col>

                              <v-col align="center">
                                {{ i + 1 }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="carouselItems[0].data.items.length <= 5">
                    <v-col cols="2" />
                    <v-col cols="8">
                      <v-card
                        style="margin-left: 15px"
                        width="220px"
                        height="150"
                      >
                        <div
                          style="
                            height: 140px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                          @click="setModal({ status: true, type: 'addMobile' })"
                        >
                          <v-icon> mdi mdi-plus </v-icon>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- </div> -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <h4 class="mt-5">
            <v-icon> mdi mdi-information-outline </v-icon> Sugerimos a
            utilização de 3 a 4 banners
          </h4>
        </v-card>
      </span>
    </span>
  </div>
</template>

<script>
import { applyDrag } from "./utils";

import { Container, Draggable } from "vue-dndrop";
import { mapState, mapActions } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";

export default {
  data() {
    return {
      mobile: false,
      panel: 0,
    };
  },

  props: {
    aditional: Object,
  },

  components: {
    Container,
    Draggable,
  },

  computed: {
    ...mapState("BannerPersonalization", [
      "formBanners",
      "formBannersUpdate",
      "items",
      "itemsMobile",
      "dialog",
      "carouselItems",
    ]),
  },
  methods: {
    ...mapActions("BannerPersonalization", ["saveItem", "index", "setModal"]),

    tradeItensPosition(input, from, to) {
      let numberOfDeletedElm = 1;

      const elm = input.splice(from, numberOfDeletedElm)[0];

      numberOfDeletedElm = 0;

      input.splice(to, numberOfDeletedElm, elm);
    },
    getScaleFromDb() {
      if (this.carouselItems[0]) {
        this.getRealTime();
        this.aditional.height = parseInt(
          this.carouselItems[0].data.frameRate.split("/")[0]
        );
        this.aditional.width = parseInt(
          this.carouselItems[0].data.frameRate.split("/")[1]
        );
      }
    },

    getRealTime() {
      if (this.carouselItems[0]) {
        this.aditional.interval = this.carouselItems[0].data.interval / 1000;
      }
    },

    onDrop(dropResult) {
      this.carouselItems[0].data.items = applyDrag(
        this.carouselItems[0].data.items,
        dropResult
      );
    },
    onDropMobile(dropResult) {
      this.carouselItems[0].data.itemsMobile = applyDrag(
        this.carouselItems[0].data.itemsMobile,
        dropResult
      );
    },
    checkMobile() {
      if (this.carouselItems[0].data.itemsMobile.length >= 1) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    firstItemAdd() {
      this.formBanners.frameRate = "3/8";
      this.formBanners.interval = 5000;
    },
  },

  mounted() {
    toastAlert("success");
    // if (this.carouselItems[0] !== undefined) {
    //   this.checkMobile();
    // }
    // if (this.carouselItems[0] !== undefined) {
    //   console.log(this.carouselItems[0]);
    // }
  },

  watch: {
    carouselItems: function () {
      this.checkMobile();
    },
  },
};
</script>

<style>
</style>