export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null
    },
    list: [],
    init_date: "",
    errors: [],
    // product_categories: []
}