import Vue from 'vue'
import VueRouter from 'vue-router'
import AdmRoutes from '@/views/Administration/Router'
import ProductRoutes from '@/views/ProductSession/Router'
import RecordRoutes from '@/views/RecordSession/Router'
import OrderRoutes from '@/views/OrderSession/Router'
import StoreSettingsRoutes from '@/views/StoreSettings/Router'
import CompanySettings from '@/views/CompanySettings/Router'
import Home from '@/views/Main/Home'
import Login from '@/views/Main/Login'
import checkToken from './middleware/Auth/login'
import Reports from '@/views/Reports/Router'
import EcommerceAdministration from '@/views/EcommerceAdministration/Router'
import LayoutOrganization from '@/views/LayoutOrganization/Router'
import NavBarPersonalization from '@/views/LayoutPersonalization/Router'
import StockIntegration from '@/views/Integration/Stock/Router'
import MarketingFacebookIntegration from '@/views/Integration/Marketing/Router'
import IntegrationSettings from '@/views/Integration/Settings/Router'

Vue.use(VueRouter)

const routes = [
  ...AdmRoutes,
  ...ProductRoutes,
  ...RecordRoutes,
  ...OrderRoutes,
  ...StoreSettingsRoutes,
  ...CompanySettings,
  ...Reports,
  ...EcommerceAdministration,
  ...LayoutOrganization,
  ...NavBarPersonalization,
  ...StockIntegration,
  ...MarketingFacebookIntegration,
  ...IntegrationSettings,
  {
    path: '/',
    props: true,
    component: Home,

  },
  {
    path: '/login',
    props: true,
    component: Login,
    meta: {
      middleware: checkToken,
    },

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});





export default router
