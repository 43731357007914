const defaultPath = '/buy'

import { diferentIndexFunction} from "@/utils/DefaultCrud/defaultsFunctions";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


const setList = function ({ commit }) {
    spinnerChoice('full', true)
    let user = JSON.parse(localStorage.getItem('user'));

    diferentIndexFunction(commit, `${defaultPath}/getInTransitBuys`, `company_id=${user.company_id}`, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', [])
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

export default {
    cleanItem,
    cleanErrors,
    setList
}