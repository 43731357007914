<template>
  <span>
    <br />
    <br />

    <!-- <v-card class="card-payment-infos-class"> -->
    <h2 class="text-center">Informações de Pagamento</h2>
    <hr class="mt-5" />
    <v-row
      v-if="
        paymentsData.reprovedPaymentTimes > 0 ||
        (paymentsData.paymentsInfo[0] &&
          paymentsData.paymentsInfo[0].analyzeScore)
      "
    >
      <v-col cols="12">
        <h4 class="ml-4 mt-5 mb-5">Analise Geral</h4>
      </v-col>
      <v-col class="ml-4" cols="3">
        <h5>Tentativas Recusadas</h5>
        <p>{{ paymentsData.reprovedPaymentTimes }}</p>
      </v-col>
      <v-col
        v-if="
          paymentsData.paymentsInfo[0] &&
          paymentsData.paymentsInfo[0].analyzeScore
        "
        class="ml-4"
        cols="4"
      >
        <h5>Pontuação Antifraude Ultima Tentiva</h5>
        <p>{{ paymentsData.paymentsInfo[0].analyzeScore }}</p>
      </v-col>
    </v-row>
    <span
      v-if="
        paymentsData.paymentsInfo[0] &&
        paymentsData.paymentsInfo[0].type !== undefined
      "
    >
      <span v-for="(item, index) in paymentsData.paymentsInfo" :key="index">
        <!-- {{ item }} -->
        <span v-if="item.payment_source == 'withdrawal'">
          <withdrawl-paymnet :data="item" />
        </span>
        <!-- <span v-else> -->
        <br />
        <div
          style="border-top: solid grey 1px"
          v-if="
            item.type == 'payment_slip' && item.payment_source !== 'withdrawal'
          "
        >
          <p>
            <strong>Boleto</strong> - {{ item.created_at }} <br />
            <strong>TID: </strong> {{ item.tid }} <br />
            <strong>Valor: </strong
            >{{
              item.value.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}
            <br />
            <strong>Status: </strong>{{ item.status }} <br />
            <strong v-if="item.source == 'pagarme'">Pagar Me</strong>
            <br />
            <a :href="item.payment_slip_url" target="_blank">Ver Boleto</a>
          </p>
        </div>
        <div
          style="border-top: solid grey 1px"
          v-if="item.type == '4' && item.payment_source !== 'withdrawal'"
        >
          <p>
            <strong>Pix </strong> - {{ item.created_at }}
            <br />

            <span v-if="item.value">
              <strong>Valor: </strong
              >{{
                item.value.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </span>
          </p>
        </div>
        <div
          style="border-top: solid grey 1px"
          v-if="
            item.type == 'credit_card' && item.payment_source !== 'withdrawal'
          "
        >
          <p>
            <strong
              >Cartão Crédito - {{ changeSourceName(item.source) }}</strong
            >
            - {{ item.created_at }} <br />
            <span v-if="item.analyzeStatus">
              <strong>Resultado AF: </strong> {{ item.analyzeStatus }}
            </span>
            <span v-if="item.analyzeScore">
              <br />
              <strong>Pontuação AF: </strong> {{ item.analyzeScore }}
            </span>
            <br />
            <strong>TID: </strong> {{ item.tid }} <br />
            <span v-if="item.installments">
              <strong>Parcelas: </strong>
              {{ item.installments }} <br />
            </span>
            <span v-if="item.cardLast">
              <strong>Final Cartão: </strong>
              {{ item.cardLast }} <br />
            </span>
            <span v-if="item.value">
              <strong>Valor: </strong
              >{{
                item.value.toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </span>
            <br />
            <strong>Status: </strong>
            <span> {{ item.status[0] }} <br /> </span>
            <strong></strong>
            <br />
            <v-btn
              v-if="
                item.status[1] === 0 ||
                (item.status[1] === 1 && orderData[0].status == 1)
              "
              :disabled="checkStatusFromButon(orderData[0].status)"
              color="indigo"
              class="white--text"
              @click="(dialog = true), (selectedPayment = item.id)"
              >Ações</v-btn
            >
          </p>
        </div>
      </span>
    </span>
    <v-data-table
      v-else
      class="default-table"
      :headers="headers"
      :items="paymentsData.paymentsInfo"
      :search="search"
      :footer-props="footer"
    >
      <template v-slot:item.action="{ item }">
        <v-btn
          v-if="
            item.status[1] === 0 ||
            (item.status[1] === 1 && orderData[0].status == 1)
          "
          :disabled="checkStatusFromButon(orderData[0].status)"
          color="indigo"
          class="white--text"
          @click="(dialog = true), (selectedPayment = item.id)"
          >Ações</v-btn
        >

        <span v-else>
          <h4>N/A</h4>
        </span>
      </template>
      <template v-slot:item.value="{ item }">
        <span v-if="item.value"
          >R$
          {{
            parseFloat(item.value).toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          }}</span
        >
        <span v-else>-</span>
      </template>
    </v-data-table>

    <hr />
    <!-- </v-card> -->
    <v-dialog v-model="dialog" max-width="590">
      <v-card>
        <v-row>
          <v-col>
            <h3 class="text-center">Selecione a Opção Desejada</h3>
          </v-col>
          <v-col align="center" cols="12">
            <v-btn @click="capture()">Capturar Pagamento</v-btn>
            <br />
            <br />

            <v-btn @click="reject()">Recusar Pagamento</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col cols="8"></v-col>
          <v-col cols="4" align="center">
            <v-btn color="red" @click="dialog = false">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import withdrawlPaymnet from "@/views/OrderSession/pages/DetailOrder/components/withdrawlPaymnet";

export default {
  components: {
    withdrawlPaymnet,
  },
  props: {
    orderData: Array,
  },
  methods: {
    ...mapActions("DetailOrder", [
      "capturePayment",
      "rejectPayment",
      "formData",
    ]),
    changeSourceName(name) {
      if (name == "pagarme - creditcard") {
        return "Pagar Me";
      } else {
        return name;
      }
    },
    checkStatusFromButon(status) {
      if (status == 1) {
        return false;
      } else {
        return true;
      }
    },
    capture() {
      let data = {
        payment_id: this.selectedPayment,
        buy_id: this.orderData[0].buy_id,
      };
      let test = confirm("Tem certeza que deseja capturar o pagamento ? ");

      if (test) {
        this.dialog = false;
        this.capturePayment(data);
      }
    },
    reject() {
      let data = {
        payment_id: this.selectedPayment,
        buy_id: this.orderData[0].buy_id,
      };
      let test = confirm("Tem certeza que deseja rejeitar o pagamento ? ");

      if (test) {
        this.dialog = false;
        this.rejectPayment(data);
      }
    },
  },
  computed: {
    ...mapState("DetailOrder", ["paymentsData"]),
  },
  data: function () {
    return {
      selectedPayment: null,
      dialog: false,
      search: "",
      headers: [
        { text: "#", value: "id", class: "black--text" },
        { text: "Data", value: "created_at", class: "black--text" },
        { text: "Final Cartão", value: "cardLast", class: "black--text" },
        { text: "Status", value: "status[0]", class: "black--text" },
        { text: "Parcelas", value: "installments", class: "black--text" },
        { text: "Status AF*", value: "analyzeStatus", class: "black--text" },
        { text: "Pontuação AF*", value: "analyzeScore", class: "black--text" },
        { text: "Intermediador", value: "source", class: "black--text" },
        { text: "TID", value: "tid", class: "black--text" },
        { text: "Valor Captura", value: "value", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
};
</script>

<style scoped>
.card-payment-infos-class {
  margin: 40px 0 0 0 !important;
  padding: 10px;
}

.recused-payment-card {
  background: blue;
}
</style>