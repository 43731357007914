<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-delivery-settings">
    <h1 class="title-topo">Lista de Comentários</h1>

    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <span>
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.action="{ item }">
          <a
            @click="setEnabledDisabled({ show: true, id: item.id })"
            v-if="!item.show"
            >Ativar</a
          >
          <a @click="setEnabledDisabled({ show: false, id: item.id })" v-else
            >Desativar</a
          >
          <!-- <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          > -->
        </template>
        <template v-slot:item.show="{ item }">
          <span v-if="item.show"> Sim </span>
          <span v-else> Não </span>
        </template>
      </v-data-table>
    </span>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@//Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "name", class: "black--text" },
        { text: "MENSAGEM", value: "message", class: "black--text" },
        { text: "ENDEREÇO", value: "address", class: "black--text" },
        { text: "ATIVO", value: "show", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("CustomerComment", ["list"]),
  },

  methods: {
    ...mapActions("CustomerComment", ["setList", "setEnabledDisabled"]),
  },
  mounted() {
    this.setList();
    toastAlert("success");

    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(1, 0, {
        text: "Cliente",
        value: "company_name",
        class: "black--text",
      });
    }
  },
};
</script>