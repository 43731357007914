export default{
    formData:{
        id: null,
        created_at: null,
        updated_at: null,
        name: null,
        deifinition_id: null,
        gender_types: null
    },
    list:[],
    errors:[],
}