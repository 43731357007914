const defaultPath = '/delivery-restriction'
const toastName = 'Restrição'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null,
    init_cep_rate: null,
    final_cep_rate: null,
    minimum_buy_value: null,
    delivery_price: null,
    type: null,
    term: null

}

import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";
import axios from 'axios'

import { diferentIndexFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit }, payload) {
    spinnerChoice('modal', true)

    axios.post(`${baseApiUrl + defaultPath}/save`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` ${toastName} foi cadastrado com sucesso!`
                );
                // router.push({ path: defaultPath });
                toastAlert('success')
                setModal({ commit })
                setList({ commit }, payload.company_id)
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

const update = function ({ commit }, payload) {
    spinnerChoice('modal', true)

    axios.post(`${baseApiUrl + defaultPath}/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` ${toastName} foi cadastrado com sucesso!`
                );
                // router.push({ path: defaultPath });
                toastAlert('success')
                setModal({ commit })
                setList({ commit }, payload.company_id)
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

const show = function ({ commit }, payload) {
    showFunction(commit, defaultPath, payload, 'full')
}

const setList = ({ commit }, payload) => {
    diferentIndexFunction(commit, `${defaultPath}/index`, `company_id=${payload}`, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const setModal = function ({ commit }, payload) {
    commit('SET_MODAL', payload)
    if (payload == false) {
        commit('SET_FORMDATA', listDefault)
    }
}


export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show,
    setModal
}