<template>
  <span>
    <v-card class="pagar-me-card">
      <h3 class="mb-5">Configurações Boleto Pagar Me</h3>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!keyId" color="green" v-bind="attrs" v-on="on"
            >Cadastrar Chave</v-btn
          >
          <v-btn v-else color="red" v-bind="attrs" v-on="on"
            >Alterar Chave</v-btn
          >
        </template>
        <v-card v-if="!spinner">
          <h1>Teste</h1>
        </v-card>
        <v-card
          v-else
          style="overflow: hidden !important; padding: 20px !important"
        >
          <v-row>
            <v-col cols="12" align="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- <h1>Teste</h1> -->
  </span>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("PaymentSlip", ["spinner", "dialog", "keyId"]),
    ...mapState("ReceiveData", ["formData"]),
  },
  methods: {
    ...mapActions("PaymentSlip", ["check"]),
  },
  mounted() {
    // let self = this;
    // console.log(this.formData.company_id);
    this.check({ company_id: this.formData.company_id });
  },
};
</script>

<style scoped>
.pagar-me-card {
  padding: 20px !important;
}
</style>