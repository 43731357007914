// import IntegrationList from '@/views/Integration/Stock/Pages/StockIntegration/list';
import Form from '@/views/Integration/Settings/Pages/Settings/form';



const routes = [{
    path: '/integrations/settings/add-key-name',
    props: true,
    component: Form,
},

]

export default routes