<template>
  <admin-blade iconBtnTop="fas fa-cart-plus" routeBtnTop="/product-group">
    <span>
      <v-form class="mt-5" ref="form" id="formProductGroup" v-model="valid">
        <div class="default-form" id="formProductGroup">
          <h3 class="title-topo d-block d-sm-none">
            Formulario Grupo Produtos
          </h3>
          <h1 class="title-topo d-none d-sm-block">
            Formulario Grupo Produtos
          </h1>
          <br />
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.name"
                label="Grupo"
                :rules="rulesFunction('Grupo', 0)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="adm">
            <v-col cols="6">
              <v-autocomplete
                height="36"
                v-model="formData.company_id"
                :items="companyList"
                :disabled="formData.company_id"
                item-text="fantasy_name"
                item-value="id"
                label="Loja *"
                @change="setList(formData.company_id)"
                :rules="rulesFunction('Loja', 0)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" cols="12">
              <p>
                <strong>Ultima Modificação: {{ formData.updated_at }}</strong>
              </p>
            </v-col>
            <v-col sm="6" cols="12">
              <p>
                <strong>Data Cadastro: {{ formData.created_at }}</strong>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" :align="align">
              <v-btn
                v-if="formData.id"
                class="mr-4 change-btn"
                @click="updateContent()"
                >Salvar Alterações</v-btn
              >
              <v-btn
                v-else
                :disabled="!valid"
                class="mr-4 register-btn"
                @click="saveContent()"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      adm: false,
      valid: false,
    };
  },
  components: {
    AdminBlade,
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    ...mapState("ProductGroup", ["formData", "errors"]),
    ...mapState("Company", {
      companyList: "list",
    }),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },
  watch: {
    routeId() {
      this.onload();
    },
  },
  methods: {
    ...mapActions("ProductGroup", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("Company", ["setList"]),
    saveContent() {
      this.save(this.formData);
    },
    updateContent() {
      this.update(this.formData);
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    onload() {
      this.adm = JSON.parse(localStorage.getItem("user")).admin;
      if (this.adm) {
        this.setList();
      }

      if (this.$route.params.id) {
        this.show(this.$route.params.id);
      }
    },
  },
  mounted() {
    this.onload();
  },
  beforeDestroy: function () {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>