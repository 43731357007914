
const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_LIST = (state, obj) => {
    state.list = obj
}

const SET_CLEAN_LIST = (state, obj) => {
    state.filter = obj
}

const SET_CURRENTPAGE = (state, obj) => {
    state.pagination.currentPage = obj
}

const SET_ORDERFIELD = (state, obj) => {
    state.order.field = obj
}

const SET_ORDERVALUE = (state, obj) => {
    state.order.value = obj
}

const SET_ITEMSPERPAGE = (state, obj) => {
    state.pagination.itemsPerPage = obj
}



export default {
    SET_FORMDATA,
    SET_ERRORS,
    SET_LIST,
    SET_CLEAN_LIST,
    SET_CURRENTPAGE,
    SET_ORDERFIELD,
    SET_ORDERVALUE,
    SET_ITEMSPERPAGE
}