<template>
  <span>
    <v-row>
      <span>
        <section class="flex">
          <!-- {{ formData }} -->
          <span v-if="formData.images">
            <div
              class="mother"
              v-for="(item, index) in formData.images"
              :key="index"
              sm="3"
              cols="3"
            >
              <v-img
                contain
                :src="item.base64"
                height="250px"
                width="125px"
                lazy-src="https://picsum.photos/id/11/100/60"
              ></v-img>
              <td width="5%" class="text-center">
                <button class="close-btn" @click="removeItem(item)">
                  X{{ item.index }}
                </button>
              </td>
              <v-switch v-model="is_video" label="Cadastrar Vídeo"></v-switch>
              <v-autocomplete
                v-model="item.color"
                :items="colorList()"
                item-text="name"
                item-value="id"
                label="Cor"
                color="amber darken-4"
                class="list-product"
                id="color"
              ></v-autocomplete>
              <v-autocomplete
                v-model="item.relevance"
                :items="relevance"
                item-text="value"
                item-value="value"
                label="Relevancia"
                color="amber darken-4"
                class="list-product"
                id="relevance"
              ></v-autocomplete>
              <span v-if="is_video">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="item.link_video" label="Link Vídeo">
                    </v-text-field>
                  </v-col>
                </v-row>
              </span>
            </div>
          </span>
        </section>
      </span>
    </v-row>
    <br />
    <!-- Input para capturar arquivos diretamente -->
    <input type="file" @change="handleFileUpload" accept="image/*" multiple class="mb-4" />
    <br />
    <v-btn class="mr-4 register-btn" @click="saveContent()">Salvar</v-btn>
    <v-btn class="mr-4 register-btn" @click="setModal(false)">Cancelar</v-btn>
    <v-btn class="mr-4 register-btn" @click="clearForm()">Limpar</v-btn>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import heic2any from "heic2any";
import EXIF from 'exif-js';
export default {
  data: function () {
    return {
      is_video: false,
      relevance: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
        { value: 11 },
        { value: 12 },
        { value: 13 },
        { value: 14 },
        { value: 15 },
      ],
    };
  },
  computed: {
    ...mapState("ProductImage", ["formData"]),
    ...mapState("Utils", ["colors"]),
    ...mapState("Grid", ["list"]),
  },
  methods: {
    ...mapActions("ProductImage", [
      "setFormData",
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
      "setModal",
    ]),
    ...mapActions("ProductImage", ["save", "setModal"]),
    ...mapActions("Spinner", ["setModalSpiner"]),

    

    async handleFileUpload(event) {
      const files = event.target.files;
      if (!files.length) return;

      this.setSpinnerLoadImage(true);

      for (const file of files) {
        let processedImage = null;

        if (file.type === "image/heic" || file.type === "image/heif") {
          console.log("Convertendo imagem HEIC...");
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8,
          });

          processedImage = await this.correctImageOrientation(convertedBlob);
        } else {
          processedImage = await this.correctImageOrientation(file);
        }

        
        if (processedImage) {
          this.formData.images = [{ base64: processedImage }];
          await this.saveContent();  
        }
      }

      this.setSpinnerLoadImage(false);
    },


    correctImageOrientation(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          EXIF.getData(img, function() {
            const orientation = EXIF.getTag(this, "Orientation");
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (orientation === 6) { 
              canvas.width = img.height;
              canvas.height = img.width;
              ctx.rotate(90 * Math.PI / 180);
              ctx.drawImage(img, 0, -img.height);
            } else if (orientation === 3) {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.rotate(180 * Math.PI / 180);
              ctx.drawImage(img, -img.width, -img.height);
            } else if (orientation === 8) {
              canvas.width = img.height;
              canvas.height = img.width;
              ctx.rotate(270 * Math.PI / 180);
              ctx.drawImage(img, -img.width, 0);
            } else {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);
            }

            const base64Image = canvas.toDataURL("image/jpeg", 0.5);

            this.formData.images.push({ base64: base64Image });
            this.setSpinnerLoadImage(false);
          }.bind(this));
        };
      };

      reader.readAsDataURL(file);
    },



    saveContent() {
      this.formData.product_id = this.$route.params.id;
      this.save(this.formData);
    },

    colorList() {
      let colors = this.list.map((a) => a.color);
      return colors;
    },

    removeItem(item) {
      let response = this.formData.images.filter((base64) => {
        return base64 !== item;
      });
      this.formData.images = response;
    },

    clearForm() {
      this.formData.images.map((item) => {
        this.removeItem(item);
      });
    },

    setSpinnerLoadImage(value) {
      this.setModalSpiner(value);
    },
  },
  destroyed() {
    this.clearForm();
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin: 0 auto;
}
.flex > div {
  flex: 1 1 200px;
  margin: 10px;
  max-width: 25%;
}
.close-btn {
  position: absolute;
  top: 2px;
  font-size: 23px;
  right: 5px;
  background: rgba(255, 255, 255, 0.534);
  border-radius: 20px;
  color: rgb(20, 19, 19);
  height: 32px;
  width: 32px;
  z-index: 100;
}
.mother {
  position: relative;
}
</style>
