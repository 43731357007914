import Definition from "@/views/ProductSession/Pages/Definition/list";
import Users from "@/views/EcommerceAdministration/Pages/Users/list";
import FormDefinition from "@/views/ProductSession/Pages/Definition/form";
import Category from "@/views/ProductSession/Pages/Category/list";
import FormCategory from "@/views/ProductSession/Pages/Category/form";
import Product from "@/views/ProductSession/Pages/Product/listV2";
import FormProduct from "@/views/ProductSession/Pages/Product/form";
import ProductGroup from "@/views/ProductSession/Pages/ProductGroup/list";
import FormProductGroup from "@/views/ProductSession/Pages/ProductGroup/form";
import Brand from "@/views/ProductSession/Pages/Brand/list";
import FormBrand from "@/views/ProductSession/Pages/Brand/form";
import SubCategory from "@/views/ProductSession/Pages/SubCategory/list";
import FormSubCategory from "@/views/ProductSession/Pages/SubCategory/form";
import AtributeGroup from "@/views/ProductSession/Pages/AtributeGroup/list";
import FormAtributeGroup from "@/views/ProductSession/Pages/AtributeGroup/form";
import Atribute from "@/views/ProductSession/Pages/Atribute/list";
import FormAtribute from "@/views/ProductSession/Pages/Atribute/form";
import VariationGroup from "@/views/ProductSession/Pages/VariationGroup/list";
import FormVariation from "@/views/ProductSession/Pages/VariationGroup/form";
import ExactSearch from "@/views/ProductSession/Pages/Product/exactSearch";
import RelevanceOrganization from "@/views/LayoutOrganization/Pages/LayoutOrganization/list";
import StockIntegration from "@/views/Integration/Stock/Pages/StockIntegration/form";
import ContactUsers from "@/views/EcommerceAdministration/Pages/ContactList/list";


const routes = [
  {
    path: "/atribute",
    props: true,
    component: Atribute,
  },
  {
    path: "/form-atribute",
    props: true,
    component: FormAtribute,
    children: [{ path: ":id", component: FormAtribute, props: true }],
  },
  {
    path: "/atribute-group",
    props: true,
    component: AtributeGroup,
  },
  {
    path: "/form-atribute-group",
    props: true,
    component: FormAtributeGroup,
    children: [{ path: ":id", component: FormAtributeGroup, props: true }],
  },
  {
    path: "/sub-category",
    props: true,
    component: SubCategory,
  },
  {
    path: "/form-subcategory",
    props: true,
    component: FormSubCategory,
    children: [{ path: ":id", component: FormSubCategory, props: true }],
  },
  {
    path: "/definition",
    props: true,
    component: Definition,
  },
  {
    path: "/users-consumer",
    props: true,
    component: Users,
  },
  {
    path: "/contact-list",
    props: true,
    component: ContactUsers,
  },
  {
    path: "/form-definition",
    props: true,
    component: FormDefinition,
    children: [{ path: ":id", component: FormDefinition, props: true }],
  },
  {
    path: "/category",
    props: true,
    component: Category,
  },
  {
    path: "/form-category",
    props: true,
    component: FormCategory,
    children: [{ path: ":id", component: FormCategory, props: true }],
  },
  {
    path: "/product",
    props: true,
    component: Product,
  },
  {
    path: "/form-product",
    props: true,
    component: FormProduct,
    children: [{ path: ":id", component: FormProduct, props: true }],
  },
  {
    path: "/exact-search",
    props: true,
    component: ExactSearch,
    // children: [{ path: ":id", component: FormProduct, props: true }],
  },

  {
    path: "/layout-relevance",
    props: true,
    component: RelevanceOrganization,
  },
  {
    path: "/product-group",
    props: true,
    component: ProductGroup,
  },
  {
    path: "/form-product-group",
    props: true,
    component: FormProductGroup,
    children: [{ path: ":id", component: FormProductGroup, props: true }],
  },
  {
    path: "/brand",
    props: true,
    component: Brand,
  },
  {
    path: "/form-brand",
    props: true,
    component: FormBrand,
    children: [{ path: ":id", component: FormBrand, props: true }],
  },
  {
    path: "/variation-group",
    props: true,
    component: VariationGroup,
  },
  {
    path: "/form-variation-group",
    props: true,
    component: FormVariation,
    children: [{ path: ":id", component: FormVariation, props: true }],
  },

  {
    path: "/integrations/stock",
    props: true,
    component: StockIntegration,
  },
];

export default routes;
