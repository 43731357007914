import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";
import router from '@/router/index'


const defaultPath = '/users'
const toastName = 'O Usuario'
const listDefault = {
  id: null,
  name: null,
  email: null,
  admin: false,
  user_type: 0,
  created_at: null,
  updated_at: null,
  people_id: null
}

import { showFunction } from "@/utils/DefaultCrud/defaultsFunctions";

const setList = ({ commit }) => {
  // indexFunction(commit, defaultPath, null, 'full')   
  spinnerChoice('full', true)

  axios.post(`${baseApiUrl + defaultPath}/index-store`).then(res => {
    commit('SET_LIST', res.data)
  })
    .catch(e => {
      catchError(e)
    })

    .finally(() => {
      spinnerChoice('full', false)
    })
}

export const save = function ({ commit }, payload) {
  // saveFunction(commit, defaultPath, payload, `${toastName + payload.name}`, 'full')

  spinnerChoice('full', true)

  axios.post(`${baseApiUrl + defaultPath}/save-if-admin`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName} foi cadastrado com sucesso!`
        );
        router.push({ path: '/users-store' });
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('full', false)

    })
}

const update = function ({ commit }, payload) {
  // updateFunction(commit, '/users', payload, `${toastName + ' ' + payload.name}`, 'full')
  spinnerChoice('full', true)

  axios
    .post(`${baseApiUrl + defaultPath}/update-if-admin`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          `${toastName + ' ' + payload.name} foi atualizado com sucesso!`
        );
        router.push({ path: '/users-store' });
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('full', false)

    })
}

const show = function ({ commit }, payload) {
  showFunction(commit, defaultPath, payload, 'full')
}

const cleanItem = function ({ commit }) {
  commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
  commit('SET_ERRORS', '')
}

export default {
  show,
  update,
  save,
  cleanItem,
  cleanErrors,
  setList
}