const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_KEYGOOGLEID = (state, obj) => {
    state.google.key_id = obj
}

const SET_GOOGLECHANGEKEY = (state, obj) => {
    state.google.changeKey = obj
}

const SET_GOOGLEERRORS = (state, obj) => {
    state.google.errors = obj
}

const SET_CLEANFORMDATA = (state, obj) => {
    state.formData = obj
    state.google = obj
}


export default {
    SET_FORMDATA,
    SET_ERRORS,
    SET_KEYGOOGLEID,
    SET_GOOGLECHANGEKEY,
    SET_GOOGLEERRORS,
    SET_CLEANFORMDATA
}