<template>
  <span>
    <h2 class="mt-4 mb-4">Promoção/Sale</h2>
    <v-row>
      <v-col cols="12" :align="align">
        <v-btn
          v-if="list.length < 1"
          class="mr-4 mb-5 register-btn"
          @click="setNewModal()"
          >Cadastrar Promoção</v-btn
        >
      </v-col>
    </v-row>
    <span>
      <v-data-table :headers="headers" :items="list" :footer-props="footer">
        <template v-slot:item.start_date="{ item }">
          <div v-if="item.start_date">
            {{ item.start_date.replace("T", " ") }}
          </div>
        </template>
        <template v-slot:item.end_date="{ item }">
          <div v-if="item.end_date">{{ item.end_date.replace("T", " ") }}</div>
        </template>
        <template v-slot:item.active="{ item }">
          <div :class="` ${item.active}`">{{ checkTrue(item.active) }}</div>
        </template>
        <template v-slot:item.active="{ item }">
          <div :class="` ${item.active}`">{{ checkTrue(item.active) }}</div>
        </template>
        <template v-slot:item.discount_value="{ item }">
          <div>R$ {{ item.discount_value }}</div>
        </template>
        <template v-slot:item.productWithDiscount="{ item }">
          <div>
            R$
            {{
              (item.price - item.discount_value).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}
          </div>
        </template>
        <!-- moment(testDate).format('YYYY-MM-DD'); -->
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </span>
    <spinner-dialog :dialog="modal">
      <v-card class="dialog-default">
        <promotion-form :id="editId"></promotion-form>
      </v-card>
    </spinner-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PromotionForm from "@/views/ProductSession/Pages/Product/Promotion/form";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";

export default {
  components: {
    PromotionForm,
    spinnerDialog,
  },
  data: function () {
    return {
      editId: "",
      search: "",
      headers: [
        { text: "#", value: "id", class: "black--text" },
        { text: "Inicio", value: "start_date", class: "black--text" },
        { text: "Fim", value: "end_date", class: "black--text" },
        { text: "Desconto", value: "discount_value", class: "black--text" },
        {
          text: "Valor Produto",
          value: "productWithDiscount",
          class: "black--text",
        },
        { text: "Ativo", value: "active", class: "black--text" },

        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Promotion", ["modal", "list"]),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },

  methods: {
    ...mapActions("Promotion", ["setModal", "setList"]),
    checkTrue(value) {
      let response;
      value == true ? (response = "Sim") : (response = "Não");

      return response;
    },
    setNewModal() {
      this.setModal(!this.modal);
      this.editId = null;
    },
    edit(item) {
      this.setModal(!this.modal);
      this.editId = item;
    },
  },
  mounted() {
    this.setList(this.$route.params.id);
  },
};
</script>

<style scoped>
.true {
  background: rgb(230, 230, 233);
  border-radius: 10px;
  width: 60%;
  color: black;
  padding: 5px 0 5px 7px;
}

.false {
  background: rgb(120, 112, 112);
  border-radius: 10px;
  width: 60%;
  color: white;
  padding: 5px 0 5px 9px;
}
</style>