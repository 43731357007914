const defaultPath = '/buy'

import { diferentIndexFunction } from "@/utils/DefaultCrud/defaultsFunctions";
// import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


const setList = ({ commit }, payload) => {

    diferentIndexFunction(commit, `${defaultPath}/get-buys`, { status: payload }, 'full')

}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', [])
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}


export default {
    cleanItem,
    cleanErrors,
    setList
}