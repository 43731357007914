const SET_SPINNERFULLSCREEN = (state, obj) => {
    state.spinnerFullScreen = obj
}

const SET_MODALSPINNER = (state, obj) => {
    state.modalSpinner = obj
}

const SET_MODALREPORTSPINNER = (state, obj) => {
    state.modalReportSpinner = obj
}


export default {
    SET_MODALSPINNER,
    SET_SPINNERFULLSCREEN,
    SET_MODALREPORTSPINNER
}