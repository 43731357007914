<template>
  <admin-blade-integration
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/integrations/stock"
  >
    <span>
      <!-- {{ formData }} -->
      <v-form
        class="mt-5 default-form"
        style="height: 90%"
        ref="form"
        v-model="valid"
      >
        <v-card-title> Gerar Link Integração </v-card-title>

        <v-row>
          <v-col sm="6" cols="12" v-if="adm">
            <v-autocomplete
              v-model="formData.company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
              color="amber darken-4"
            ></v-autocomplete>
          </v-col>
          <v-col sm="6" cols="12">
            <v-autocomplete
              v-model="formData.key_name"
              :items="integrators"
              item-text="name"
              item-value="value"
              label="Integrador *"
              color="amber darken-4"
            ></v-autocomplete>
            <!-- <span v-if="errors && errors.length > 0">
              <strong class="red--text">
                {{ errors.key_name }}
              </strong>
            </span> -->
          </v-col>

          <v-col align="right" class="my-3">
            <v-btn
              v-if="formData.id"
              color="green darken-2 white--text"
              class="mr-4"
              :disabled="!formData.key_name"
              @click="update(formData)"
              >Salvar Alterações</v-btn
            >
            <v-btn
              v-else
              :disabled="!formData.key_name"
              color="green darken-2 white--text"
              class="mr-4"
              @click="save(formData)"
            >
              Salvar
            </v-btn>
          </v-col>

          <v-col cols="12">
            <div v-if="errors.message">
              <h3
                v-if="$vuetify.breakpoint.name != 'xs'"
                style="color: red; text-align: end"
              >
                {{ errors.message }}
              </h3>
              <p v-else style="color: red; text-align: end">
                {{ errors.message }}
              </p>
            </div>

            <div v-if="dialog" max-width="600" class="mb-8">
              <h4 class="ml-7">Link:</h4>

              <div v-if="$vuetify.breakpoint.name != 'xs'">
                <v-card-text align="left">
                  <v-row
                    align="center"
                    style="border: solid black 1px; border-radius: 10px"
                  >
                    <v-col md="11" cols="9" align="left">
                      {{ linkApi }}
                    </v-col>
                    <v-col md="1" cols="3">
                      <v-btn icon @click="copy(linkApi)">
                        <v-icon> mdi mdi-content-copy </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>

              <div v-else class="mx-5 my-10">
                <v-row
                  align="center"
                  style="
                    font-size: 12px;
                    max-width: 1500px;
                    border: solid black 1px;
                    border-radius: 10px;
                  "
                >
                  <v-col cols="9">
                    <p style="word-break: break-all">
                      {{ linkApi }}
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <v-btn icon @click="copy(linkApi)">
                      <v-icon> mdi mdi-content-copy </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </span>
  </admin-blade-integration>
</template>

<script>
import AdminBladeIntegration from "@/Layouts/BladeIntegrations";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBladeIntegration,
  },
  watch: {
    linkApi() {
      return this.linkApi;
    },
  },
  data: function () {
    return {
      money: {},
      valid: true,
      adm: false,
      link: "",
    };
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("StockIntegration", [
      "formData",
      "errors",
      "integrators",
      "linkApi",
      "dialog",
    ]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    removeButtonIfNotAdmin(value) {
      if (value == true) {
        return "fas fa-file-invoice-dollar";
      }
    },
    setModal(value) {
      this.$store.commit("StockIntegration/SET_DIALOG", value);
    },
    copy(info) {
      navigator.clipboard.writeText(info);
    },
    ...mapActions("StockIntegration", ["save", "show", "update"]),
    // ...mapActions("", ["setList"]),
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    } else {
      this.show({
        company_id: JSON.parse(localStorage.getItem("user")).company_id,
      });
    }
  },
};
</script>

<style>
</style>