<template>
  <span>
    <h2 class="d-block d-sm-none">Imagens</h2>
    <h1 class="d-none d-sm-block">Imagens</h1>
    <section class="flex">
      <div v-for="(item, index) in list" :key="index" style="background: white">
        <v-card
          @click="
            editItem(
              item.id,
              item.img_name,
              item.color,
              item.relevance,
              item.link_video
            )
          "
        >
          <v-img
            :src="`${url + item.img_name}300.jpg`"
            min-height="250px"
            lazy-src="/img/bgWhite.jpg"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        <p v-if="item.color" class="mt-3">
          <strong>Cor:</strong>
          {{ item.color }}
        </p>
        <p v-if="item.relevance" class="mt-3">
          <strong>Relevancia:</strong>
          {{ item.relevance }}
        </p>
      </div>
    </section>
    <p />
    <v-row>
      <v-col cols="12" :align="align">
        <v-btn class="mr-4 mb-5 register-btn" @click="setModal(!modal)"
          >Cadastrar Imagens</v-btn
        >
      </v-col>
    </v-row>
    <spinner-dialog :dialog="modal" v-if="modal">
      <v-card class="dialog-default">
        <v-container>
          <image-form :id="editId"> </image-form>
        </v-container>
      </v-card>
    </spinner-dialog>
    <spinner-dialog :dialog="editModal" v-if="editModal">
      <v-card class="dialog-default">
        <v-img contain height="500px" :src="`${url + editImg}1000.jpg`">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-autocomplete
          v-model="editImgColor"
          :items="colorList()"
          item-text="name"
          item-value="id"
          label="Cor"
          color="amber darken-4"
          class="list-product"
        ></v-autocomplete>
        <v-autocomplete
          v-model="editRelevance"
          :items="relevance"
          item-text="value"
          item-value="value"
          label="Relevancia"
          color="amber darken-4"
          class="list-product"
        ></v-autocomplete>
        <v-text-field v-model="editLinkVideo" label="Link Vídeo" />

        <v-btn
          color="orange white--text"
          class="mt-4 mr-4"
          @click="updateChanges()"
          >Alterar</v-btn
        >
        <v-btn color="red white--text" class="mt-4 mr-4" @click="deleteImg()"
          >Apagar Imagem</v-btn
        >
        <v-btn
          color="red darken-2 white--text"
          class="mt-4"
          @click="setEditModal(false)"
          >Cancelar</v-btn
        >
      </v-card>
    </spinner-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { s3Url } from "@/global";
import ImageForm from "@/views/ProductSession/Pages/Product/Image/form";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";

export default {
  props: {
    companyId: Number,
  },
  components: {
    ImageForm,
    spinnerDialog,
  },
  data: function () {
    return {
      url: String,
      editId: "",
      editImg: "",
      editImgColor: "",
      editRelevance: "",
      editLinkVideo: "",
      relevance: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
        {
          value: 9,
        },
        {
          value: 10,
        },
        {
          value: 11,
        },
        {
          value: 12,
        },
        {
          value: 13,
        },
        {
          value: 14,
        },
        {
          value: 15,
        },
      ],
    };
  },

  computed: {
    ...mapState("ProductImage", ["modal", "list", "editModal"]),
    ...mapState("Auth", ["user"]),
    ...mapState("Grid", {
      gridList: "list",
    }),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },

  methods: {
    ...mapActions("ProductImage", [
      "setEditModal",
      "setModal",
      "setList",
      "deleteItem",
      "changeColor",
    ]),
    updateChanges() {
      let data = {
        relevance: this.editRelevance,
        color: this.editImgColor,
        id: this.editId,
        link_video: this.editLinkVideo,
        product_id: this.$route.params.id,
      };

      this.changeColor(data);
    },
    colorList() {
      let colors = this.gridList.map((a) => a.color);
      colors = [...colors, null];
      // console.log(colors);
      return colors;
    },
    editItem(id, image, color, relevance, link_video) {
      this.setEditModal(true),
        (this.editId = id),
        (this.editImg = image),
        (this.editImgColor = color);
      this.editRelevance = relevance;
      this.editLinkVideo = link_video;
    },
    deleteImg() {
      let data = {
        product_image_id: this.editId,
        product_id: this.$route.params.id,
      };
      this.deleteItem(data);
    },
  },
  mounted() {
    if (!this.user.admin) {
      this.url = s3Url + this.user.company_id + "/";
    } else {
      this.url = s3Url + this.companyId + "/";
    }
    // this.url = s3Url;
    this.setList(this.$route.params.id);
  },
};
</script>
<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
}
.flex > div {
  flex: 1 1 200px;
  margin: 10px;
  padding: 5px;
  max-width: 200px;
}
</style>