

import Navbar from '@/views/LayoutPersonalization/Pages/NavbarOrganization/navbar';
import Banner from '@/views/LayoutPersonalization/Pages/BannerOrganization/banner'


const routes = [

    {
        path: '/layout/personalization/navbar',
        props: true,
        component: Navbar,
    },
    {
        path: '/layout/personalization/banner',
        props: true,
        component: Banner,
    },
    // {
    //     path: '/form-coupon',
    //     props: true,
    //     component: FormCoupon,
    //     children: [
    //         { path: ':id', component: FormCoupon, props: true, }
    //     ]
    // },
]

export default routes