<template>
  <div>
    <v-alert
      v-if="this.alert == true"
      dismissible
      type="success"
    >
      {{ message }}
    </v-alert>

    <v-alert
      v-if="this.alertError == true"
      dismissible
      type="error"
    >
      {{ errors }}
    </v-alert>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: function () {
    return {

    };
  },
  computed: {
    ...mapState("NavBarPersonalization", [
      "list",
      "alert",
      "message",
      "errors",
      "alertError",
    ]),
  },

  watch: {
    alert() {
      if (this.alert == true) {
        setTimeout(() => {
          this.$store.commit("NavBarPersonalization/SET_ALERT", false);
          this.$store.commit("NavBarPersonalization/SET_ALERT_ERROR", false);
        }, 5000);
      }
    },

    alertError() {
      if (this.alertError == true) {
        setTimeout(() => {
          this.$store.commit("NavBarPersonalization/SET_ALERT_ERROR", false);
        }, 5000);
      }
    },
  },
};
</script>

<style>
</style>