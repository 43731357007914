const setSpinnerFullScreen = ({ commit }, payload) => {
  commit('SET_SPINNERFULLSCREEN', payload)
}

const setModalSpiner = ({ commit }, payload) => {
  commit('SET_MODALSPINNER', payload)
}

const setModalReportSpinner = ({ commit }, payload) => {
  commit('SET_MODALREPORTSPINNER', payload)
}

export default {
  setSpinnerFullScreen,
  setModalSpiner,
  setModalReportSpinner
}