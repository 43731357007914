export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        active: true
    },
    list: [],
    company_id: null,
    errors: [],
}