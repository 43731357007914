export default {
    formData: {
    },
    list: [],
    errors: [],
    integrators: [
        { value: 'google_analytics_4_token', name: 'Google Analytics 4' },
    ],
    google: {
        errors: {

        },
        key_id: null,
        changeKey: false
    }
}