<template>
  <v-form flat class="v-form mt-5">
    <div class="default-form">
      <v-row>
        <v-col cols="12">
          <h2 style="text-align: center">Filtros</h2>
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="filter.definition"
            @keyup.enter="setList(filter)"
            label="Definição"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="filter.category"
            @keyup.enter="setList(filter)"
            v-on:keydown.enter="resetPage(), setList()"
            label="Categoria"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="filter.subcategory"
            @keyup.enter="setList(filter)"
            label="Sub Categoria"
          />
        </v-col>

        <v-col
          v-if="$vuetify.breakpoint.name !== 'xs'"
          class="py-0"
          cols="12"
          sm="4"
        >
          <v-select
            v-model="filter.qtd_cards"
            :items="items"
            @keyup.enter="setList(filter)"
            label="Quantidade de cards em cada linha"
          ></v-select>
        </v-col>

        <v-col class="py-0" cols="12" sm="4">
          <v-autocomplete
            v-model="filter.promotionRule"
            @keyup.enter="setList(filter)"
            :items="promotionRule"
            item-text="name"
            item-value="value"
            label="Opções promoção"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <v-col class="py-0" cols="12" sm="4">
          <v-autocomplete
            v-model="filter.gender"
            :items="gender"
            item-text="name"
            item-value="value"
            label="Gênero"
            @keyup.enter="setList(filter)"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <v-col sm="6" cols="12" v-if="this.admin">
          <v-autocomplete
            v-model="filter.company_id"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0" cols="12" sm="4">
          <v-switch
            v-model="filter.show_img"
            label="Trazer imagens?"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="9" lg="10" align="center">
          <v-btn dark color="green" @click="setList()">Filtrar</v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2" align="center">
          <v-btn dark rounded small color="red" @click="cleanItem()"
            >Limpar filtros <v-icon>mdi-trash-can</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      items: [2, 3, 4, 6],
    };
  },
  props: {
    list: null,
    admin: null,
  },
  computed: {
    ...mapState("LayoutOrganization", ["filter", "promotionRule", "gender"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("LayoutOrganization", ["setList", "cleanItem"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    cleanFilters() {
      this.definition = "";
      this.category = "";
      this.subcategory = "";
      this.client = "";
    },
    resetPage() {
      this.page = 1;
    },
  },

  mounted() {
    this.setList();
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
    if (this.adm) {
      this.setCompany();
    }
  },
  destroyed() {
    this.cleanItem();
  },
};
</script>

<style>
</style>