
import Users from '@/views/Administration/Pages/User/Users'
import FormUser from '@/views/Administration/Pages/User/FormUser'
import Company from '@/views/Administration/Pages/Company/list'
import formCompany from '@/views/Administration/Pages/Company/form'
import Adm from '@/router/middleware/Auth/Adm'

const routes = [

    {
        path: '/company',
        props: true,
        component: Company,
        meta: {
            middleware: [Adm]
        },
    },
    {
        path: '/form-company',
        props: true,
        component: formCompany,
        meta: {
            middleware: [Adm]
        },
        children: [
            { path: ':id', component: formCompany, props: true, }
        ]
    },

    {
        path: '/users-store',
        props: true,
        component: Users,
        meta: {
            middleware: [Adm]
        },
    },
    {
        path: '/form-user',
        props: true,
        component: FormUser,
        meta: {
            middleware: [Adm]
        },
        children: [
            { path: ':id', component: FormUser, props: true, }
        ]
    },





]

export default routes