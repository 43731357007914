<template>
  <div>
    <admin-blade-exact-search routeBtnTop="/exact-search">
      <br />
      <span
        v-if="
          $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'
        "
      >
        <div style="text-align: center">
          <v-row align="center">
            <v-col align-self="center" v-if="errors" cols="12">
              <strong class="red--text">{{ errors.negativeStock }}</strong>
            </v-col>
          </v-row>
        </div>
        <v-simple-table class="default-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="checkUser()" colspan="6" />
                <th v-else colspan="5" />

                <!-- <th>Total de Itens: {{ listExactProducts.total }}</th> -->
              </tr>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.value"
                  style="text-align: center"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody
              v-if="listExactProducts.data && listExactProducts.data.length > 0"
              style="margin: 0 auto !important"
            >
              <tr
                class="row-class"
                v-for="(item, index) in listExactProducts.data"
                :key="index"
              >
                <td style="text-align: justify">
                  {{ item.product_name }}
                </td>
                <td style="text-align: center">{{ item.ref }}</td>
                <td>{{ item.code }}</td>
                <td>{{ item.color }}</td>
                <td>{{ item.size }}</td>

                <td>
                  <v-row style="display: flex">
                    <v-col cols="3">
                      <v-btn
                        class="btn-stock"
                        @click="openModal('remove', [item])"
                        elevation="0"
                        >-</v-btn
                      ></v-col
                    >

                    <v-col cols="6">
                      <v-text-field
                        :label="`Atual: ${item.balance}`"
                        class="input-stock"
                        type="number"
                        v-model="item.amount"
                        min="0"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-btn
                        class="btn-stock"
                        @click="openModal('add', [item])"
                        elevation="0"
                        >+</v-btn
                      >
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  v-if="listExactProducts.total > 10"
                  style="color: black"
                >
                  <v-pagination
                    v-model="page"
                    @input="exactSearchFilter()"
                    :length="listExactProducts.last_page"
                    total-visible="10"
                    style="color: black"
                  >
                  </v-pagination>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">
                  <v-alert type="error"> Nenhum produto encontrado!! </v-alert>
                </td>
              </tr>
            </tbody>
            <strong>Total de itens: </strong>
            <span>{{ listExactProducts.total }}</span>
          </template>
        </v-simple-table>

        <div data-app>
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Alterar Estoque
              </v-card-title>

              <v-card-text>
                Você tem certeza que deseja alterar o estoque deste produto?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="saveStock()"> Sim </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="(dialog = false), (this.gridToChange = {})"
                >
                  Não
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </span>

      <!-- MOBILE -->
      <span v-else
        ><v-container>
          <v-data-iterator
            :items="listExactProducts.data"
            items-per-page.sync="4"
            hide-default-footer
          >
            <template v-slot:header>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="[25, 50, 100]"
                    @input="exactSearchFilter()"
                    v-model="pagination.itemsPerPage"
                    style="max-width: 150px"
                  >
                    <template v-slot:prepend>
                      <small>Ítens por Página: </small>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="5" class="d-none d-sm-block" />
                <v-col cols="12" sm="3"
                  ><small
                    >Total de Itens: {{ listExactProducts.total }}</small
                  ></v-col
                >
              </v-row>
              <v-row justify="center">
                <v-col cols="12" v-if="listExactProducts.total > 10">
                  <v-pagination
                    class="mobile-pagination"
                    v-model="page"
                    @input="exactSearchFilter()"
                    :length="listExactProducts.last_page"
                  >
                  </v-pagination>
                </v-col>
              </v-row>
            </template>

            <template v-slot:default>
              <v-row>
                <v-col
                  v-for="(item, index) in listExactProducts.data"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card>
                    <v-card-title class="sub-header">
                      <div class="sub-header-text">
                        {{ item.product_name }}
                      </div>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content
                          >Referência produto:</v-list-item-content
                        >
                        <v-list-item-content class="align-start">
                          {{ item.ref }}
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>Código grade:</v-list-item-content>
                        <v-list-item-content class="align-start">
                          {{ item.code }}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="item-row" v-if="checkUser()">
                        <v-list-item-content>Cor:</v-list-item-content>
                        <v-list-item-content class="align-start item-text">
                          <v-row
                            no-gutters
                            style="height: 100%; width: 100%"
                            align="center"
                          >
                            <v-col>
                              {{ item.color }}
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="item-row" v-if="checkUser()">
                        <v-list-item-content>Tamanho:</v-list-item-content>
                        <v-list-item-content class="align-start item-text">
                          <v-row
                            no-gutters
                            style="height: 100%; width: 100%"
                            align="center"
                          >
                            <v-col>
                              {{ item.size }}
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="item-row">
                        <v-list-item-content>Estoque:</v-list-item-content>
                        <v-list-item-content class="align-start">
                          <v-row align="center">
                            <v-col cols="3">
                              <v-btn
                                @click="openModal('remove', [item])"
                                style="background: none"
                                class="btn-stock-mobile"
                                elevation="0"
                                ><span style="text-align: center !important"
                                  >-</span
                                ></v-btn
                              >
                            </v-col>

                            <v-col cols="6">
                              <v-text-field
                                :label="`${item.balance}`"
                                class="input-stock-mobile"
                                style="font-size: 10px"
                                type="number"
                                v-model="item.amount"
                                min="0"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                hide-details="auto"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-btn
                                class="btn-stock-mobile"
                                @click="openModal('add', [item])"
                                elevation="0"
                                style="background: none"
                                ><span>+</span></v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <div data-app>
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Alterar Estoque
                </v-card-title>

                <v-card-text>
                  Você tem certeza que deseja alterar o estoque deste produto?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="saveStock()"> Sim </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="(dialog = false), (this.gridToChange = {})"
                  >
                    Não
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-container></span
      >
    </admin-blade-exact-search>
  </div>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBladeExactSearch from "@//Layouts/BladeExactSearch";

import { mapState, mapActions } from "vuex";
export default {
  components: {
    AdminBladeExactSearch,
  },
  data: function () {
    return {
      dialog: false,
      add: null,
      search: "",
      gridToChange: {
        grid_id: null,
        product_id: null,
      },
      currentHeader: "",
      headers: [
        {
          text: "Nome",
          value: "product_name",

          sortOrder: "",
          sortIcon: "",
        },
        {
          text: "Referência produto",
          value: "ref",

          sortOrder: "",
          sortIcon: "",
        },
        {
          text: "Código Grade",
          value: "code",

          sortOrder: "",
          sortIcon: "",
        },
        {
          text: "Cor",
          value: "color",

          sortOrder: "",
          sortIcon: "",
        },
        {
          text: "Tamanho",
          value: "size",

          sortOrder: "",
          sortIcon: "",
        },
        {
          text: "Estoque",
          value: "balance",

          sortOrder: "",
          sortIcon: "",
        },
      ],
      stockData: {
        note: null,
      },
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  props: {
    listExactProducts: {},
    dataProduct: {},
  },
  computed: {
    ...mapState("Product", ["pagination", "errors"]),
    itemsPerPage: {
      get() {
        return this.$store.state.Product.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    orderField: {
      get() {
        return this.$store.state.Product.order.field;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERFIELD", value);
      },
    },
    orderValue: {
      get() {
        return this.$store.state.Product.order.value;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERVALUE", value);
      },
    },

    list: function () {
      return this.$store.state.Product.listExactProducts;
    },
  },

  methods: {
    ...mapActions("Product", ["exactSearchFilter", "stockChangeSearch"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    updateItemsPerPage(value) {
      this.pagination.itemsPerPage = value;
      this.exactSearchFilter();
    },
    clearIcon(header) {
      this.currentHeader = header.value;
      for (let i = 0; i < this.headers.length; i++) {
        if (this.headers[i].value != this.currentHeader) {
          this.headers[i].sortOrder = "";
          this.headers[i].sortIcon = "";
        }
      }
    },
    saveStock() {
      this.stockChangeSearch(this.gridToChange);
      this.gridToChange = {};

      this.dialog = false;

      // window.location.reload();
    },
    negativeStock(value) {
      if (value < 1) {
        return "negative-stock-class";
      } else {
        return "stock-class";
      }
    },

    openModal(decision, gridToBeChanged) {
      this.dialog = true;
      this.gridToChange.product_id = gridToBeChanged[0].product_id;
      this.gridToChange.grid_id = gridToBeChanged[0].grid_id;
      this.gridToChange.amount = gridToBeChanged[0].amount;
      this.gridToChange.note = null;
      if (decision == "add") {
        this.gridToChange.type = "i";
        this.gridToChange.reason = "ch"; //Troca
        this.add = true;
      } else {
        this.gridToChange.type = "o";
        this.gridToChange.reason = "c"; //cancelado
        this.add = false;
      }
    },
  },
  mounted() {
    this.exactSearchFilter();
    toastAlert("success");
  },
};
</script>

<style scoped>
.row-class:hover {
  cursor: pointer !important;
  background-color: #f2f2f2 !important;
}

.negative-stock-class {
  background: red !important;
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
}
td {
  color: rgb(0, 0, 0);
  /* text-justify: auto; */
}
.red--text {
  color: red;
  text-align: center;
}
.default-table {
  margin: 0 auto !important;
  align-items: center !important;
  max-width: 1200px !important;
}

.txt-balance {
  font-size: 14px;
  text-align: center;
  margin-left: 30px !important;
}

@media screen and (max-width: 1200px) {
  .default-table {
    width: 80%;
  }
  .balance {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 1080px) {
  .btn-stock {
    height: 20px !important;
    /* background-color: black !important; */
  }
}

@media screen and (max-width: 918px) {
  .txt-balance {
    font-size: 14px;
    margin-left: 10px !important;
  }
  .btn-stock-mobile {
    margin-left: -20px;
  }
}

@media screen and (max-width: 726px) {
  .txt-balance {
    font-size: 12px;
    margin-left: -5px !important;
  }
  .btn-stock-mobile {
    margin-left: -30px;
  }
}

@media screen and (max-width: 417px) {
  .txt-balance {
    font-size: 11.5px;
    width: 100%;
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 375px) {
  .txt-balance {
    font-size: 12px;
    margin-left: 10px !important;
  }
  .btn-stock-mobile {
    margin-left: -20px;
  }
}

@media screen and (max-width: 336px) {
  .txt-balance {
    font-size: 12px;
    margin-left: -10px !important;
  }
  .btn-stock-mobile {
    margin-left: -30px;
  }
}
</style>