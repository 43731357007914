import { baseApiUrl, catchError } from "@/global";
import axios from "axios";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'

const defaultPath = '/customer-comments'

const setList = ({ commit }) => {

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl + defaultPath}/index-panel-store`).then(res => {
        console.log('FOI')
        commit('SET_LIST', res.data)
    })
        .catch(e => {
            console.log('Não foi')
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}


const setEnabledDisabled = ({ commit }, payload) => {

    spinnerChoice('full', true)

    let data = {
        ...payload
    }

    axios.post(`${baseApiUrl + defaultPath}/active-comment`, data).then(res => {
        if (res.data.status == 201) {
            setList({ commit })
        }
        // commit('SET_LIST', res.data)
    })
        .catch(e => {
            console.log('Não foi')
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}
export default {
    setList,
    setEnabledDisabled
}