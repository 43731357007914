export default {
    reportModal: false,
    reportTitle: null,
    reportInfo: {
        title: null,
        text: null
    },
    reportOptionalInfo: {
        title: null,
        text: null
    }
}