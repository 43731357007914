import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
// import { v4 as uuidv4 } from 'uuid';


import { toastAlert } from "@/utils/Alerts/toast";


export const save = function ({ commit, state }, payload) {
    commit('SET_ERRORS', null)
    let token = generateRandomToken();

    let companyId = getCompanyId({ state });

    let data = {
        key_name: payload.intermediary,
        value: token,
        company_id: companyId,
        ...payload
    };
    let link = createLinkIntegrator(data);
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {

                commit('SET_ERRORS', '')

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
                commit('SET_DIALOG', true)

                commit('SET_LINK_API', link)
            } else {
                commit('SET_ERRORS', res.data.errors)
                console.log(state.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            spinnerChoice('full', false)

        })
}

const createLinkIntegrator = function (payload) {
    let integrator = getIntegratorName(payload.key_name);
    let link = baseApiUrl + '/integration/stock/' + payload.company_id + '/update' + '?' + 'integrator=' + integrator + '&' + 'token=' + payload.value;
    return link;

}

const generateRandomToken = function () {
    var rand = function () {
        return Math.random().toString(36).substr(2); // remove `0.`
    };

    var token = function () {
        return rand() + rand() + rand() + rand() + rand() + rand() + rand() + rand() + rand(); // to make it longer
    };

    return token();

}

const getIntegratorName = function (key_name) {
    switch (key_name) {
        case 'bling_token':
            return 'bling';
        case 'global_stock_token':
            return 'global'
        default:
            return 'bling';
    }

}

// const show = function ({ commit }, payload) {

//     spinnerChoice('full', true)

//     axios
//         .post(`${baseApiUrl + defaultPath}/show`, payload)
//         .then(res => {
//             if (res.data[0].id) {
//                 commit('SET_FORMDATA', res.data[0]);
//             }
//         })
//         .catch(e => {
//             catchError(e);
//         })
//         .finally(() => {

//             spinnerChoice('full', false)

//         })
// }

// const cleanItem = function ({ commit }) {
//     commit('SET_FORMDATA', listDefault)
// }

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const getCompanyId = function ({ state }) {
    let companyId;
    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        companyId = JSON.parse(localStorage.getItem("user")).company_id
    } else {
        companyId = state.id_company
    }

    return companyId;
}


export default {
    save, cleanErrors

}