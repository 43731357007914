import store from '@/store/index'


import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";


export const showReport = function ({ commit }, payload) {
    let headers = {
        reportTitle: 'Catalogo p/ Facebook',
        reportInfo: {
            title: null,
            text: null

        },
        reportOptionalInfo: {
            title: null,
            text: null
        },
    }
    commit('SET_DESCRIPTION_FACEBOOK', payload.only_facebook_description);
    store.dispatch('Spinner/setModalReportSpinner', true)
    store.dispatch('Reports/setReportHeader', headers)
    store.dispatch('Reports/setReportModal', true)

    axios.post(`${baseApiUrl}/report/facebook-catalog`, payload)
        .then(res => {
            commit('SET_LIST', res.data)
        })
        .catch(e => {
            catchError(e.status)

        })
        .finally(() => {
            store.dispatch('Spinner/setModalReportSpinner', false)
        })
}

// const formatData = function (value) {
//     let date = new Date(value);
//     return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
// }




export default {
    showReport
}