<template>
  <span>
    <h1 class="title-topo">Exceções de Pagamento</h1>
    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <v-row justify="end">
      <v-col cols="4">
        <v-btn @click="registerNewException()" color="green" class="black--text"
          >Cadastrar Nova Exceção</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      class="default-table"
      :headers="headers"
      :items="list"
      :search="search"
      :footer-props="footer"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
          >mdi-pencil</v-icon
        >
      </template>
      <template v-slot:item.installments="{ item }">
        <span>{{ item.installments }}X</span>
      </template>
      <template v-slot:item.rule_type="{ item }">
        <span>{{ getRuleDescription(item.rule_type) }}</span>
      </template>
      <template v-slot:item.payment_type="{ item }">
        <span>{{ getPaymentDescription(item.payment_type) }}</span>
      </template>
      <template v-slot:item.rate="{ item }">
        <span>{{ item.rate }}%</span>
      </template>
      <template v-slot:item.active="{ item }">
        <div
          style="background: green; border-radius: 10px; padding: 5px"
          v-if="item.active"
        >
          Ativo
        </div>
        <div style="background: red; border-radius: 10px; padding: 5px" v-else>
          Inativo
        </div>
      </template>
      <!-- <template v-slot:item.active="{ item }">
        <span v-if="item.active">
          <strong>Ativo</strong>
        </span>
        <span v-else>
          <strong>Inativo</strong>
        </span>
      </template> -->
    </v-data-table>

    <spinner-dialog persistent :dialog="modal">
      <v-card class="dialog-default">
        <receive-data-rule-form
          :receiveDataId="receiveDataId"
          v-if="modal"
          :idExeption="idExeption"
        />
      </v-card>
    </spinner-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import ReceiveDataRuleForm from "@/views/StoreSettings/Pages/ReceiveData/Components/ReceiveDataRule/form";
export default {
  components: {
    spinnerDialog,
    ReceiveDataRuleForm,
  },
  props: { receiveDataId: null },
  data: function () {
    return {
      idExeption: null,
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Parcelado", value: "installments", class: "black--text" },
        { text: "Regra", value: "rule_type", class: "black--text" },
        { text: "Pagamento", value: "payment_type", class: "black--text" },
        {
          text: "Desconto/Acrescimo",
          value: "rate",
          class: "black--text",
        },
        {
          text: "Ativo",
          value: "active",
          class: "black--text",
        },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  computed: {
    ...mapState("ReceiveDataRule", ["list", "modal"]),
  },
  methods: {
    ...mapActions("ReceiveDataRule", ["setList", "setModal"]),
    getRuleDescription(value) {
      switch (value) {
        case 0:
          return "Desconto";
        case 1:
          return "Acrescimo";
        default:
          return "Regra inválida!!";
      }
    },
    getPaymentDescription(value) {
      switch (value) {
        case 0:
          return "Cartão de Crédito";
        case 1:
          return "Cartão de Débito";
        case 2:
          return "Dinheiro";
        case 3:
          return "Boleto";
        case 4:
          return "Pix";
        default:
          return "Tipo de pagamento inválido!!";
      }
    },
    registerNewException() {
      this.idExeption = null;
      this.setModal(true);
    },
    edit(item) {
      this.idExeption = item;
      this.setModal(true);
    },
  },
  mounted() {
    this.setList(this.receiveDataId);
    toastAlert("success");
  },
};
</script>