<template>
  <span>
    <h2 class="text-center">Informações de Antifraude</h2>
    <br />
    <div v-for="(item, index) of getAnalysesResponses" :key="index">
      <hr class="hr-class-antifraud mb-4" v-if="index == 0" />
      <strong v-if="item.status">Status: </strong>
      <span v-if="item.status">{{ item.status }}</span> <br />
      <strong>Data: </strong>
      <span>{{ item.created_at }}</span
      ><br />
      <strong>Resposta Completa: </strong>
      <span>{{ item.apiResponse }}</span>
      <br />
      <br />

      <hr class="hr-class-antifraud mb-4" />
    </div>
    <hr />
    <br />
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      descriptionFinal: null,
      date: null,
    };
  },

  methods: {},

  computed: {
    ...mapGetters("DetailOrder", ["getAnalysesResponses"]),
  },
  mounted() {
    this.getAntifraudResponse();
  },
};
</script>

<style scoped>
.hr-class-antifraud {
  /* color: rgba(128, 128, 128, 0.482) !important; */
  border: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.482);
}
</style>