var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('br'),_c('br'),_c('h2',{staticClass:"text-center"},[_vm._v("Informações de Pagamento")]),_c('hr',{staticClass:"mt-5"}),(
      _vm.paymentsData.reprovedPaymentTimes > 0 ||
      (_vm.paymentsData.paymentsInfo[0] &&
        _vm.paymentsData.paymentsInfo[0].analyzeScore)
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"ml-4 mt-5 mb-5"},[_vm._v("Analise Geral")])]),_c('v-col',{staticClass:"ml-4",attrs:{"cols":"3"}},[_c('h5',[_vm._v("Tentativas Recusadas")]),_c('p',[_vm._v(_vm._s(_vm.paymentsData.reprovedPaymentTimes))])]),(
        _vm.paymentsData.paymentsInfo[0] &&
        _vm.paymentsData.paymentsInfo[0].analyzeScore
      )?_c('v-col',{staticClass:"ml-4",attrs:{"cols":"4"}},[_c('h5',[_vm._v("Pontuação Antifraude Ultima Tentiva")]),_c('p',[_vm._v(_vm._s(_vm.paymentsData.paymentsInfo[0].analyzeScore))])]):_vm._e()],1):_vm._e(),(
      _vm.paymentsData.paymentsInfo[0] &&
      _vm.paymentsData.paymentsInfo[0].type !== undefined
    )?_c('span',_vm._l((_vm.paymentsData.paymentsInfo),function(item,index){return _c('span',{key:index},[(item.payment_source == 'withdrawal')?_c('span',[_c('withdrawl-paymnet',{attrs:{"data":item}})],1):_vm._e(),_c('br'),(
          item.type == 'payment_slip' && item.payment_source !== 'withdrawal'
        )?_c('div',{staticStyle:{"border-top":"solid grey 1px"}},[_c('p',[_c('strong',[_vm._v("Boleto")]),_vm._v(" - "+_vm._s(item.created_at)+" "),_c('br'),_c('strong',[_vm._v("TID: ")]),_vm._v(" "+_vm._s(item.tid)+" "),_c('br'),_c('strong',[_vm._v("Valor: ")]),_vm._v(_vm._s(item.value.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }))+" "),_c('br'),_c('strong',[_vm._v("Status: ")]),_vm._v(_vm._s(item.status)+" "),_c('br'),(item.source == 'pagarme')?_c('strong',[_vm._v("Pagar Me")]):_vm._e(),_c('br'),_c('a',{attrs:{"href":item.payment_slip_url,"target":"_blank"}},[_vm._v("Ver Boleto")])])]):_vm._e(),(item.type == '4' && item.payment_source !== 'withdrawal')?_c('div',{staticStyle:{"border-top":"solid grey 1px"}},[_c('p',[_c('strong',[_vm._v("Pix ")]),_vm._v(" - "+_vm._s(item.created_at)+" "),_c('br'),(item.value)?_c('span',[_c('strong',[_vm._v("Valor: ")]),_vm._v(_vm._s(item.value.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }))+" ")]):_vm._e()])]):_vm._e(),(
          item.type == 'credit_card' && item.payment_source !== 'withdrawal'
        )?_c('div',{staticStyle:{"border-top":"solid grey 1px"}},[_c('p',[_c('strong',[_vm._v("Cartão Crédito - "+_vm._s(_vm.changeSourceName(item.source)))]),_vm._v(" - "+_vm._s(item.created_at)+" "),_c('br'),(item.analyzeStatus)?_c('span',[_c('strong',[_vm._v("Resultado AF: ")]),_vm._v(" "+_vm._s(item.analyzeStatus)+" ")]):_vm._e(),(item.analyzeScore)?_c('span',[_c('br'),_c('strong',[_vm._v("Pontuação AF: ")]),_vm._v(" "+_vm._s(item.analyzeScore)+" ")]):_vm._e(),_c('br'),_c('strong',[_vm._v("TID: ")]),_vm._v(" "+_vm._s(item.tid)+" "),_c('br'),(item.installments)?_c('span',[_c('strong',[_vm._v("Parcelas: ")]),_vm._v(" "+_vm._s(item.installments)+" "),_c('br')]):_vm._e(),(item.cardLast)?_c('span',[_c('strong',[_vm._v("Final Cartão: ")]),_vm._v(" "+_vm._s(item.cardLast)+" "),_c('br')]):_vm._e(),(item.value)?_c('span',[_c('strong',[_vm._v("Valor: ")]),_vm._v(_vm._s(item.value.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, }))+" ")]):_vm._e(),_c('br'),_c('strong',[_vm._v("Status: ")]),_c('span',[_vm._v(" "+_vm._s(item.status[0])+" "),_c('br')]),_c('strong'),_c('br'),(
              item.status[1] === 0 ||
              (item.status[1] === 1 && _vm.orderData[0].status == 1)
            )?_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.checkStatusFromButon(_vm.orderData[0].status),"color":"indigo"},on:{"click":function($event){(_vm.dialog = true), (_vm.selectedPayment = item.id)}}},[_vm._v("Ações")]):_vm._e()],1)]):_vm._e()])}),0):_c('v-data-table',{staticClass:"default-table",attrs:{"headers":_vm.headers,"items":_vm.paymentsData.paymentsInfo,"search":_vm.search,"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [(
          item.status[1] === 0 ||
          (item.status[1] === 1 && _vm.orderData[0].status == 1)
        )?_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.checkStatusFromButon(_vm.orderData[0].status),"color":"indigo"},on:{"click":function($event){(_vm.dialog = true), (_vm.selectedPayment = item.id)}}},[_vm._v("Ações")]):_c('span',[_c('h4',[_vm._v("N/A")])])]}},{key:"item.value",fn:function({ item }){return [(item.value)?_c('span',[_vm._v("R$ "+_vm._s(parseFloat(item.value).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2, })))]):_c('span',[_vm._v("-")])]}}])}),_c('hr'),_c('v-dialog',{attrs:{"max-width":"590"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v("Selecione a Opção Desejada")])]),_c('v-col',{attrs:{"align":"center","cols":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.capture()}}},[_vm._v("Capturar Pagamento")]),_c('br'),_c('br'),_c('v-btn',{on:{"click":function($event){return _vm.reject()}}},[_vm._v("Recusar Pagamento")])],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4","align":"center"}},[_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }