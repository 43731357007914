<template>
  <span v-if="list">
    <span v-if="$vuetify.breakpoint.name !== 'xs'">
      <div class="card-list" v-if="filter.show_img === true">
        <div v-if="list.product_image">
          <v-card class="card">
            <v-row>
              <v-col cols="12">
                <v-img
                  contain
                  class="image-product"
                  :src="generateImageFromLink()"
                ></v-img>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="product-name">
                <span>{{ list.name }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="product-price">
                <span
                  >R$
                  {{
                    parseFloat(list.price).toLocaleString(
                      ("pt-BR",
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    )
                  }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="product-relevance">
                <span> Relevância atual: {{ list.relevance }}</span>
                <v-text-field
                  label="Nova relevância"
                  class="input-relevance"
                  type="number"
                  v-model="relevance"
                  min="0"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="btn-alter-changes">
                <v-btn
                  @click="
                    alterRelevance({
                      id: list.id,
                      name: list.name,
                      relevance,
                    })
                  "
                  >Alterar</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div v-else>
          <v-card class="card-noimage">
            <v-row>
              <v-col cols="12" class="product-name">
                <span>{{ list.name }}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="product-price">
                <span
                  >R$
                  {{
                    parseFloat(list.price).toLocaleString(
                      ("pt-BR",
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    )
                  }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="product-relevance">
                <span> Relevância atual: {{ list.relevance }}</span>
                <v-text-field
                  label="Nova relevância"
                  class="input-relevance"
                  type="number"
                  v-model="relevance"
                  min="0"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="btn-alter-changes">
                <v-btn
                  @click="
                    alterRelevance({
                      id: list.id,
                      name: list.name,
                      relevance,
                    })
                  "
                  >Alterar</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
      <div v-else>
        <v-card class="card-noimage">
          <v-row>
            <v-col cols="12" class="product-name">
              <span>{{ list.name }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-price">
              <span
                >R$
                {{
                  parseFloat(list.price).toLocaleString(
                    ("pt-BR",
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )
                }}</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-relevance">
              <span> Relevância atual: {{ list.relevance }}</span>
              <v-text-field
                label="Nova relevância"
                class="input-relevance"
                type="number"
                v-model="relevance"
                min="0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="btn-alter-changes">
              <v-btn
                @click="
                  alterRelevance({
                    id: list.id,
                    name: list.name,
                    relevance,
                  })
                "
                >Alterar</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </span>

    <!-- MOBILE -->
    <span v-else>
      <div v-if="list.product_image">
        <v-card class="card-mobile">
          <v-row>
            <v-col cols="12">
              <v-img
                contain
                class="image-product-mobile"
                :src="generateImageFromLink()"
              ></v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-name-mobile">
              <span>{{ list.name }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-price-mobile">
              <span
                >R$
                {{
                  parseFloat(list.price).toLocaleString(
                    ("pt-BR",
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )
                }}</span
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-relevance-mobile">
              <span> Relevância atual: {{ list.relevance }}</span>
              <v-text-field
                label="relevância"
                class="input-relevance"
                type="number"
                v-model="relevance"
                min="0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="btn-alter-changes">
              <v-btn
                @click="
                  alterRelevance({
                    id: list.id,
                    name: list.name,
                    relevance,
                  })
                "
                >Alterar</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div v-else>
        <v-card class="card-noimage-mobile">
          <v-row>
            <v-col cols="12" class="product-name-mobile-noimage">
              <span>{{ list.name }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-price-mobile-noimage">
              <span
                >R$
                {{
                  list.price.toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
                <br />
                <!-- {{
                  parseFloat(list.price).toLocaleString(
                    ("pt-BR",
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )
                }} -->
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="product-relevance">
              <span style="font-size: 14px">
                Relevância atual: {{ list.relevance }}</span
              >
              <v-text-field
                label="Nova relevância"
                class="input-relevance"
                type="number"
                v-model="relevance"
                min="0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="btn-alter-changes">
              <v-btn
                @click="
                  alterRelevance({
                    id: list.id,
                    name: list.name,
                    relevance,
                  })
                "
                >Alterar</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </span>
  </span>
</template>

<script>
import { s3Url } from "@/global";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    list: Object,
  },
  data: function () {
    return {
      linkImage: null,
      relevance: null,
      value: null,
    };
  },

  computed: {
    ...mapState("LayoutOrganization", ["formData", "filter"]),
  },

  methods: {
    ...mapActions("LayoutOrganization", ["alterRelevance"]),
    generateImageFromLink() {
      let image_name = this.list.product_image;

      this.linkImage =
        s3Url +
        this.list.company_id +
        "/" +
        image_name[0].img_name +
        "300" +
        ".jpg";
      return this.linkImage;
    },
  },
};
</script>

<style>
.card {
  height: 650px !important;
  width: 100% !important;
}
.card-mobile {
  height: 480px !important;
  width: 100% !important;
}
.card-noimage {
  height: 350px !important;
}
.card-noimage-mobile {
  height: 330px !important;
}
.product-name {
  text-align: center !important;
  min-height: 75px !important;
    overflow: hidden !important;
  text-overflow: ellipsis;
  height: 8ch;
}
.product-name-mobile {
  text-align: center !important;
  min-height: 30px !important;
  max-height: 55px !important;
  font-size: 14px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.product-name-mobile-noimage {
  text-align: center !important;
  min-height: 60px !important;
  max-height: 80px !important;
  font-size: 14px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.product-price {
  text-align: center !important;
  min-height: 55px !important;
}
.product-price-mobile {
  text-align: center !important;
}

.product-price-mobile-noimage {
  text-align: center !important;
  min-height: 40px;
}
.image-product {
  height: 300px !important;
}
.image-product-mobile {
  height: 180px;
}
.input-relevance {
  width: 50%;
  margin: 10px auto !important;
}
.product-relevance {
  text-align: center !important;
  font-size: 15px;
}
.product-relevance-mobile {
  text-align: center !important;
  font-size: 14px;
}
.btn-alter-changes {
  margin: 0 auto !important;
  text-align: center !important;
}

@media screen and (max-width: 980px) {
  .product-name {
    font-size: 14px;
    min-height: 65px !important;
  }
  .product-price {
    font-size: 14px;
    min-height: 50px !important;
  }
  .product-relevance {
    font-size: 14px;
  }
}

@media screen and (max-width: 355px) {
  .product-name-mobile {
    min-height: 20px !important;
    max-height: 40px !important;
    font-size: 12px;
  }
  .product-price {
    text-align: center !important;
    min-height: 30px !important;
    font-size: 12px;
  }
  .product-relevance-mobile {
    font-size: 12px;
  }
  .product-name-mobile-noimage {
    text-align: center !important;
    font-size: 13px;
  }
  .product-price-mobile-noimage {
    font-size: 13px;
  }
}
</style>