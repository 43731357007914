var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pagarme-pgto-card"},[_c('h3',[_vm._v("Chave Pagarme")]),(!_vm.tokenId)?_c('p',{staticClass:"red--text"},[_vm._v(" *Cadastre seu Token p/ começar a receber ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.tokenId)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green"},on:{"click":function($event){return _vm.setDialog('token')}}},'v-btn',attrs,false),on),[_vm._v("Cadastrar Token")]):_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.setDialog('token')}}},'v-btn',attrs,false),on),[_vm._v("Alterar Token")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.spinner)?_c('v-card',{staticStyle:{"overflow":"hidden !important","padding":"20px !important"}},[(_vm.type == 'token')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Valor Token Pagarme"},model:{value:(_vm.tokenValue),callback:function ($$v) {_vm.tokenValue=$$v},expression:"tokenValue"}}),_c('strong',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.message))]),_c('strong',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.value))])],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.tokenId)?_c('v-btn',{staticClass:"ml-2 mr-2",attrs:{"color":"green"},on:{"click":function($event){_vm.saveToken({ value: _vm.tokenValue, company_id: _vm.companyId }),
                    (_vm.dialog = false)}}},[_vm._v(" Salvar Token ")]):_c('v-btn',{staticClass:"ml-2 mr-2",attrs:{"color":"green"},on:{"click":function($event){_vm.updateValue({
                    id: _vm.tokenId,
                    value: _vm.tokenValue,
                    company_id: _vm.companyId,
                  }),
                    (_vm.dialog = false)}}},[_vm._v(" Alterar Token ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")])],1)],1):_vm._e()],1):_c('v-card',{staticStyle:{"overflow":"hidden !important","padding":"20px !important"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }