import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import router from '@/router/index'
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
// import { toastAlert } from "@/utils/Alerts/toast";

export const save = function ({ commit }, payload) {
    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl}/discount-cupons/save`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `O Cupom ${payload.code} foi salvo com sucesso!`
                );
                router.push({
                    path: '/coupon'
                });
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const update = function ({ commit }, payload) {
    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl}/discount-cupons/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `O Cupom ${payload.code} foi atualizado com sucesso!`
                );
                router.push({
                    path: '/coupon'
                });
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const show = function ({ commit }, payload) {
    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl}/discount-cupons/show`, `id=${payload}`)
        .then(res => {
            commit('SET_FORMDATA', res.data[0]);
            return res.data[0]
        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const setList = function ({ commit }, payload) {
    spinnerChoice('full', true)
    let data = {
        ...payload
    }

    axios.post(`${baseApiUrl}/discount-cupons/index`, data).then(res => {
        commit('SET_LIST', res.data)
    })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', {
        id: null,
        created_at: null,
        updated_at: null,
        active: null,
        code: null,
        discount_rate: null,
        dicount_value: null,
        init_date: null,
        expire_date: null,
        company_id: null,
        remove_sale: null,
        fantasy_name: null,
    })
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

export default {
    save,
    update,
    show,
    setList,
    cleanItem,
    cleanErrors
}