<template>
  <span>
    <apex-chart
      :height="height"
      class="pt-5 category-chart"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </span>
</template>

<script>
import ApexChart from "vue-apexcharts";
export default {
  props: {
    data: Array,
    height: String,
  },
  components: {
    ApexChart,
  },
  data: function () {
    return {
      chartOptions: {
        dataLabels: {
          enabled: true,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        chart: {
          type: "bar",
        },
        xaxis: {
          tickAmount: undefined,
          tickPlacement: "between",
          labels: {
            offsetX: 0,
            offsetY: 0,
            hideOverlappingLabels: true,
            trim: true,
            rotate: -45,
          },
          type: "category",
          categories: null,
        },
      },
      series: [],
    };
  },
  methods: {
    isolateTotal(arr) {
      let response = arr.map(function (obj) {
        return obj.total;
      });
      return response;
    },
    isolateName(arr) {
      let response = arr.map(function (obj) {
        return obj.name;
      });
      return response;
    },
  },
  beforeMount() {
    this.chartOptions.xaxis.categories = this.isolateName(this.data);
    this.series = [
      {
        name: "Quantidade",
        data: this.isolateTotal(this.data),
      },
    ];
  },
};
</script>

<style>
.CL {
  color: rgb(245, 162, 8);
}
</style>