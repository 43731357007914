<template>
  <div>
    <layout-blade>
      <span v-if="checkUser()">
        <form-admin />
      </span>
      <!-- {{ id_company }} -->
      <v-card
        v-if="
          (checkUser() == true && id_company !== null) || checkUser() == false
        "
        style="margin: 0 auto"
        max-width="1500px"
        elevation="3"
      >
        <h2 class="text-center pt-5">Organização de banners</h2>

        <v-card-text>
          <div>
            <div v-if="carouselItems[0]">
              <preview-vue
                v-if="carouselItems.length >= 1"
                :aditional="aditional"
              />
            </div>

            <ordenate :aditional="aditional" :adm="checkUser()" />

            <extra-options :aditional="aditional" />
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green" @click="saveChanges()">
            <h4 style="color: white">Atualizar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </layout-blade>

    <modal :dialogs="dialogs" />
  </div>
</template>

<script>
import formAdmin from "./formAdmin.vue";
import LayoutBlade from "@/Layouts/BladeLayoutOrganization";
import previewVue from "./preview.vue";
import { mapActions, mapState } from "vuex";
import Modal from "../BannerOrganization/modal.vue";
import Ordenate from "../BannerOrganization/ordenate.vue";
import extraOptions from "../BannerOrganization/extraOptions.vue";

export default {
  computed: {
    ...mapState("BannerPersonalization", [
      "formBanners",
      "formBannersUpdate",
      "items",
      "dialog",
      "carouselItems",
      "id_company",
    ]),
  },

  components: {
    LayoutBlade,
    // Container,
    // Draggable,
    previewVue,
    Modal,
    Ordenate,
    extraOptions,
    formAdmin,
  },
  data() {
    return {
      panel: 0,

      aditional: {
        frameRateHolder: null,
        mobile: false,
        interval: null,
        width: null,
        height: null,
      },

      dialogs: {
        type: "",
        help: false,
        editOrAddImage: false,
        confirmDelete: false,
        firstTime: false,
      },
    };
  },

  methods: {
    ...mapActions("BannerPersonalization", [
      "saveItem",
      "index",
      "setModal",
      "update",
    ]),

    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },

    setImage: function (output) {
      this.formBanners.items.base64 = output.dataUrl;
    },

    saveChanges() {
      this.formBanners.interval = this.aditional.interval * 1000;

      this.setScale(this.aditional.width, this.aditional.height);

      this.formBanners.frameRate = this.aditional.frameRateHolder;

      this.formBannersUpdate.items = this.carouselItems[0].data.items;
      this.formBannersUpdate.itemsMobile =
        this.carouselItems[0].data.itemsMobile;
      this.formBannersUpdate.interval = this.formBanners.interval;
      this.formBannersUpdate.frameRate = this.formBanners.frameRate;

      this.update(this.formBannersUpdate);
    },
    setScale(x, y) {
      this.aditional.frameRateHolder = y + "/" + x;
    },
    checkFirstTime() {
      if (
        this.formBanners.interval !== null &&
        this.formBanners.frameRate !== null
      ) {
        this.dialogs.firstTime = false;
        return false;
      } else {
        this.dialogs.firstTime = true;
        return true;
      }
    },
  },
  created() {
    if (!this.checkUser()) {
      this.index();
    }
  },
};
</script>

<style>
</style>