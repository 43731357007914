export default {
    formData: {
    },
    list: [],
    errors: [],
    integrators: [
        { value: 'facebook_pixel_token', name: 'Facebook Pixel' },
        { value: 'conversion_api_facebook', name: 'Api de Conversão Facebook' },
    ],
    facebook: {
        errors: {

        },
        key_id: null,
        changeKey: false
    }
}