<template>
  <div v-if="carouselItems[0] && carouselItems[0].data.items.length >= 1">
    <v-card-title>Preview</v-card-title>
    <template>
      <v-carousel :height="calcHeight" cycle :interval="aditional.time">
        <v-carousel-item
          v-for="(item, i) in carouselItems[0].data.items"
          :key="i"
          :src="item.src"
        >
        </v-carousel-item>
      </v-carousel>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    aditional: Object,
  },
  data() {
    return {
      windowWidth: null,
      calcHeight: null,
    };
  },
  computed: {
    ...mapState("BannerPersonalization", ["formBanners", "carouselItems"]),
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.calcHeight =
        this.windowWidth *
        (this.carouselItems[0].data.frameRate.split("/")[0] /
          this.carouselItems[0].data.frameRate.split("/")[1]);

      this.calcHeight = this.calcHeight + "px";
    },
  },
  created() {
    if (this.carouselItems[0] !== undefined) {
      this.onResize();
    }
  },
};
</script>

<style>
</style>