import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";

const defaultPath = '/layout/nav-bar';

const showErrors = function ({ state }) {

    let errorsvar = state.errors.join();

    //verifica nome
    if (errorsvar.indexOf('name') !== -1) {
        state.nameError = true;
    } else {
        state.nameError = false;
    }

    //verifica ordenate
    if (errorsvar.indexOf('ordenate') !== -1) {
        state.ordenateError = true;
    } else {
        state.ordenateError = false;
    }
}

const setList = function ({ commit, state }, data) {
    spinnerChoice('full', true)
    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        let company_id = JSON.parse(localStorage.getItem("user")).company_id
        data = {
            id: company_id
        }
    } else {
        data = {
            id: state.company_id
        }
    }
    axios.post(`${baseApiUrl + defaultPath}/index`, data)
        .then(res => {

            let ordenateord = res.data.navBar.sort(function (a, b) {
                return parseInt(a.data.ordenate) < parseInt(b.data.ordenate) ? -1 : parseInt(a.data.ordenate) > parseInt(b.data.ordenate) ? 1 : 0;
            });

            commit('SET_LIST', ordenateord)

        })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const openModal = function ({ commit }, data) {
    spinnerChoice('full', true)
    commit('SET_MODAL', data)
    commit('SET_CLEAN_ERROS')
    if (data == false) {
        commit('SET_FORMDATA', {
            name: null,
            id: null,
            ordenate: null,
            params: {
                definition_name: null,
                many_categories: [],
                gender: null,
                many_subcategories: [],
                kids: false,
                group_name: null,
                only_sale: null,
                grid: null,
            },
            type: 1,
            relationship: null
        })
        commit('SET_ROUTE', '')
        commit('SET_CLEAN_SELECT_CATEGORY')
        commit('SET_CLEAN_SELECT')
    }

    spinnerChoice('full', false)

}

const createChildren = function ({ commit }, payload) {
    spinnerChoice('full', true)
    commit('SET_MODAL', true)

    commit('SET_FORMDATA', {
        company_id: null,
        name: null,
        id: null,
        ordenate: null,
        params: {
            definition_name: null,
            many_categories: payload.data.params.many_categories,
            gender: null,
            many_subcategories: [],
            kids: false,
            group_name: null,
            only_sale: null,
            grid: null,
        },
        type: 2,
        relationship: payload.id
    })
    commit('SET_ROUTE', '')
    spinnerChoice('full', false)


}

const save = function ({ commit, state }, payload) {
    spinnerChoice('full', true)
    if (!payload.company_id) {
        payload.company_id = state.company_id;
    }
    axios.post(`${baseApiUrl + defaultPath}/save`, payload)
        .then(res => {
            if (res.data.status == 200) {
                commit('SET_LIST', res.data)

                toastAlert('success');
            }
            commit('SET_FORMDATA', {
                name: null,
                id: null,
                ordenate: null,
                params: {
                    definition_name: null,
                    many_categories: [],
                    gender: null,
                    many_subcategories: [],
                    kids: false,
                    group_name: null,
                    only_sale: false,
                    grid: null,
                },
                type: 1,
                relationship: null
            })
            commit('SET_CLEAN_SELECT_CATEGORY')
            commit('SET_CLEAN_SELECT')
            commit('SET_MODAL', false)
            commit('SET_CLEAN_ERROS')
            commit('SET_ALERT', true)
            commit('SET_MESSAGE', "Item Cadastrado com sucesso")
            setList({ commit, state }, state.list)
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERROS', e.response.data.errors);
                showErrors({ state });

            } else {
                catchError(e.status);

            }
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const updated = function ({ state, commit }, payload) {
    spinnerChoice('full', true)
    let oldName = state.dataVerified.data.name;
    let oldOrdenate = state.dataVerified.data.ordenate;
    let currentName = payload.name
    let data = {};
    let id_company = 0;

    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        id_company = JSON.parse(localStorage.getItem("user")).company_id;
    } else {
        id_company = state.company_id
    }

    let resultName = oldName.toLowerCase().localeCompare(currentName.toLowerCase());
    let resultOrdenate = payload.ordenate === oldOrdenate
    if (resultName !== 0 && resultOrdenate == false) {
        data = {
            id: payload.id,
            name: payload.name,
            route: state.dataVerified.data.route,
            ordenate: payload.ordenate,
            params: payload.params,
            active: payload.active,
            type: payload.type,
            relationship: payload.relationship,
            company_id: id_company
        }

    } else if (resultName !== 0 && resultOrdenate == true) {
        data = {
            id: payload.id,
            ordenate: state.dataVerified.data.ordenate,
            route: state.dataVerified.data.route,
            name: payload.name,
            params: payload.params,
            active: payload.active,
            type: payload.type,
            relationship: payload.relationship,
            company_id: id_company

        }
    } else if (resultName == 0 && resultOrdenate == false) {
        data = {
            id: payload.id,
            ordenate: payload.ordenate,
            name: state.dataVerified.data.name,
            route: state.dataVerified.data.route,
            params: payload.params,
            active: payload.active,
            type: payload.type,
            relationship: payload.relationship,
            company_id: id_company

        }
    } else if (resultName == 0 && resultOrdenate == true) {
        data = {
            id: payload.id,
            ordenate: payload.ordenate,
            name: state.dataVerified.data.name,
            route: state.dataVerified.data.route,
            params: payload.params,
            active: payload.active,
            type: payload.type,
            relationship: payload.relationship,
            company_id: id_company

        }
    }


    axios.post(`${baseApiUrl + defaultPath}/update`, data)
        .then(res => {
            if (res.data.status == 200) {
                commit('SET_MODAL', false)
                setList({ commit, state }, state.list)
                commit('SET_ALERT', true)
                commit('SET_MESSAGE', "Item atualizado com sucesso")
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ALERT_ERROR', true)
                commit('SET_ERROS', e.response.data.errors)
                setList({ commit, state }, state.list)
            } else {
                catchError(e.status)
            }
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const updatedItem = function ({ state, commit }, payload) {
    spinnerChoice('full', true)
    let id_company = 0;

    if (JSON.parse(localStorage.getItem("user")).admin == false) {
        id_company = JSON.parse(localStorage.getItem("user")).company_id;
    } else {
        id_company = state.company_id;
    }
    let data = {
        ...payload,
        company_id: id_company
    }
    console.log(data);
    axios.post(`${baseApiUrl + defaultPath}/update`, data)
        .then(res => {

            if (res.data.status == 200) {
                commit('SET_MODAL', false)
                setList({ commit, state }, state.list)
            }
            commit('SET_ALERT', true)
            commit('SET_MESSAGE', "Item alterado com sucesso")


        })
        .catch(e => {

            if (e.response.data.status == 422) {
                commit('SET_ALERT_ERROR', true)
                commit('SET_ERROS', e.response.data.errors)
                setList({ commit, state }, state.list)
            } else {
                catchError(e.status)
            }
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const editItem = function ({ commit }, payload) {
    spinnerChoice('full', true)
    commit('SET_VERIFYDATA', payload)
    let info = {
        id: payload.id
    };
    axios.post(`${baseApiUrl + defaultPath}/show`, info)
        .then(res => {

            let dataInfo = res.data.map(a => a.data);
            console.log(dataInfo)
            commit('SET_FORMDATA', {
                company_id: res.data[0].company_id,
                id: res.data[0].id,
                name: dataInfo[0].name,
                ordenate: dataInfo[0].ordenate,
                params: dataInfo[0].params,
                type: res.data[0].type,
                relationship: res.data[0].relationship,
                active: res.data[0].active,

            });


            commit('SET_ROUTE', dataInfo[0].route)
            commit('SET_MODAL', true)
            commit('SET_MESSAGE', "Item alterado com sucesso")

        })
        .catch(e => {
            catchError(e)
            commit('SET_ERROS', e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const cleanItem = function ({ commit }, payload) {
    spinnerChoice('full', true)

    commit('SET_FORMDATA', payload)
    commit('SET_CLEAN_ERROS')

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const deleteItem = function ({ commit, state }, payload) {
    spinnerChoice('full', true)
    let data = {
        id: payload
    }

    axios.post(`${baseApiUrl + defaultPath}/delete`, data)
        .then(res => {
            if (res.status == 200) {
                commit('SET_MODAL', false)
                setList({ commit, state }, state.list)
            }
            commit('SET_ALERT', true)
            commit('SET_MESSAGE', "Item deletado com sucesso")
        })

        .catch(e => {
            catchError(e)
            commit('SET_ERROS', e)
        })
        .finally(() => {
            spinnerChoice('full', false)
        })
}

const saveCompany_id = function ({ commit }, payload) {
    commit('SET_COMPANY_ID', payload)
}


export default {
    setList,
    save,
    editItem,
    openModal,
    updated,
    cleanItem,
    createChildren,
    updatedItem,
    deleteItem,
    saveCompany_id,

}