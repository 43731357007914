export default {
    list: [],
    errors: [],
    showInactive: 0,
    only_delivered: false,
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        fantasy_name: null,
        social_name: null,
        birthday: null,
        cpf_cnpj: null,
        email: null,
        note: null,
        company: true,
    },
}