<template>
  <div>
    <v-dialog v-model="dialog" persistent width="95%">
      <v-card>
        <v-card-title>
          <h3 class="txt-center">Cadastro Navbar</h3>
          <br />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="5" sm="12" cols="12">
                <h4>Nome do item*</h4>
                <v-text-field :rules="rules" v-model="formDataNavbar.name" />
                <h5 style="color: red" v-if="nameError == true">
                  Nome já está em uso
                </h5>
              </v-col>

              <v-col md="3" sm="12" cols="12">
                <h4>Ordenação*</h4>
                <v-text-field
                  :rules="rule"
                  type="number"
                  v-model="formDataNavbar.ordenate"
                  min="1"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
                <h5 style="color: red" v-if="ordenateError == true">
                  Ordenaçao em uso
                </h5>
              </v-col>

              <v-col cols="6" md="2" align="center">
                <h4>Promoção</h4>
                <v-switch
                  style="width: 45px"
                  @click.native.stop
                  v-model="formDataNavbar.params.only_sale"
                />
              </v-col>

              <v-col cols="6" md="2" align="center">
                <h4>Somente Infantil</h4>
                <v-switch
                  style="width: 45px"
                  @click.native.stop
                  v-model="formDataNavbar.params.kids"
                />
              </v-col>
              <v-col md="3" sm="12" cols="12" v-if="formDataNavbar.type == 1">
                <v-autocomplete
                  class="ml-6 mr-6"
                  item-text="name"
                  @change="
                    defineCategory(selected.id),
                      ChangeSelectedCategory(selected)
                  "
                  v-model="selected"
                  item-value1="name"
                  item-value2="id"
                  return-object
                  :items="definitionList"
                  outlined
                  dense
                  chips
                  small-chips
                  label="Definição"
                />
              </v-col>

              <v-col md="3" sm="12" cols="12">
                <v-autocomplete
                  v-if="selectedCategory.length == 1"
                  class="ml-6 mr-6"
                  :items="categoryList"
                  @change="
                    setSubCategory(selectedCategory[0].id),
                      AddItensInSelectedCategory(selectedCategory)
                  "
                  v-model="selectedCategory"
                  outlined
                  item-text="name"
                  item-value1="name"
                  item-value2="id"
                  return-object
                  dense
                  chips
                  small-chips
                  label="Categorias"
                  multiple
              
                />

                <v-autocomplete
                  v-else
                  class="ml-6 mr-6"
                  :items="categoryList"
                  @change="AddItensInSelectedCategory(selectedCategory)"
                  v-model="selectedCategory"
                  outlined
                  item-text="name"
                  item-value1="name"
                  item-value2="id"
                  return-object
                  dense
                  chips
                  small-chips
                  label="Categorias"
                  multiple
              
                />
              </v-col>

              <v-col md="3" sm="12" cols="12">
                <v-autocomplete
                  class="ml-6 mr-6"
                  v-model="formDataNavbar.params.many_subcategories"
                  :items="subCategoryList"
                  item-text="subcategory_name"
                  item-value="name"
                  outlined
                  dense
                  chips
                  small-chips
                  label="Subcategorias"
                  multiple
                  
                />
              </v-col>

              <v-col md="3" sm="12" cols="12">
                <v-autocomplete
                  class="ml-6 mr-6"
                  v-model="formDataNavbar.params.gender"
                  :items="genders"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  chips
                  small-chips
                  label="Gênero"
                />
              </v-col>
              <v-col md="3" sm="12" cols="12">
                <v-text-field
                  class="ml-6 mr-6"
                  v-model="formDataNavbar.params.group_name"
                  :items="genders"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  chips
                  small-chips
                  label="Grupo"
                />
              </v-col>
              <v-col cols="12" v-if="this.route">
                <h4>Rota</h4>
                <v-text-field disabled outlined v-model="route" />
              </v-col>
            </v-row>
            <span style="color: red" v-if="errors">{{ errors }}</span>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="cleanItemsForm(formDataNavbar.type)"
          >
            Limpar
          </v-btn>
          <v-btn color="blue darken-1" text @click="openModal(false)">
            Cancelar
          </v-btn>

          <v-btn
            v-if="formDataNavbar.id !== null"
            color="blue darken-1"
            :disabled="!formDataNavbar.name || !formDataNavbar.ordenate"
            text
            @click="updated(formDataNavbar)"
          >
            Editar
          </v-btn>

          <v-btn
            v-else-if="formDataNavbar.type == 1"
            color="blue darken-1"
            text
            :disabled="
              !selected || !formDataNavbar.name || !formDataNavbar.ordenate
            "
            @click="save(formDataNavbar)"
          >
            Salvar
          </v-btn>

          <v-btn
            v-else
            color="blue darken-1"
            text
            :disabled="
              !selected && !formDataNavbar.name && !formDataNavbar.ordenate
            "
            @click="save(formDataNavbar)"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: function () {
    return {
      rules: [
        (value) => !!value || "Obrigatório",
        (value) => (value && value.length >= 3) || "Pelo menos 3 caracteres",
      ],
      rule: [(value) => !!value || "Obrigatório"],
      rotaexiste: false,
      disabledType: false,
      selected: "",
      selectedCategory: "",
      idDefinition: 1,
      category_id: null,
    };
  },

  computed: {
    ...mapState("Category", {
      categoryList: "list",
    }),
    ...mapState("Definition", {
      definitionList: "list",
    }),
    ...mapState("SubCategory", {
      subCategoryList: "list",
    }),
    ...mapState("NavBarPersonalization", [
      "formDataNavbar",
      "dialog",
      "dataVerified",
      "errors",
      "nameError",
      "ordenateError",
    ]),

    ...mapState("Product", ["formData"]),
    ...mapState("Utils", ["genders"]),
    ...mapState("NavBarPersonalization", ["list", "route"]),
  },

  methods: {
    ...mapActions("NavBarPersonalization", [
      "setList",
      "save",
      "openModal",
      "updated",
      "cleanItem",
    ]),

    ...mapActions("Category", {
      setCategory: "setFilteredList",
    }),
    ...mapActions("Definition", {
      setDefinition: "setList",
    }),
    ...mapActions("SubCategory", {
      setSubCategory: "setFilteredList",
      setAllSubCategory: "setList",
    }),
    defineCategory(definitionId) {
      this.setCategory(definitionId);
      this.selectedCategory = "";
    },
    cleanItemsForm(type) {
      let form = {};
      if (type == 1) {
        form = {
          name: null,
          id: null,
          ordenate: null,
          params: {
            definition_name: null,
            many_categories: [],
            gender: null,
            many_subcategories: [],
            kids: false,
            group_name: null,
            only_sale: null,
            grid: null,
          },
          type: 1,
          relationship: null,
        };
      } else {
        form = {
          name: null,
          id: null,
          ordenate: null,
          params: {
            many_categories: [],
            gender: null,
            many_subcategories: [],
            kids: false,
            group_name: null,
            only_sale: null,
            grid: null,
          },
          type: 2,
          relationship: null,
        };
      }

      (this.selected = ""), (this.selectedCategory = ""), this.cleanItem(form);
    },

    cleanSelect() {
      this.selected = "";
      this.selectedCategory = "";
    },

    AddItensInSelectedCategory(value) {
      let array = [];
      this.category_id = value[0].id;
      console.log(this.category_id);
      if (this.formDataNavbar.id) {
        array = this.formDataNavbar.params.many_categories;
      }
      for (let i = 0; i <= value.length; i++) {
        if (array.indexOf(value[i].name) === -1) {
          array.push(value[i].name);
        }
        let filtered = array.filter((x) => x !== undefined);

        this.formDataNavbar.params.many_categories = filtered;
      }
    },

    ChangeSelectedCategory(newValue) {
      this.formDataNavbar.params.definition_name = newValue.name;
      this.idDefinition = newValue.id;
    },
  },

  watch: {
    definitionList() {
      if (this.definitionList[0] && !this.$route.params.id) {
        this.formData.definition_id = this.definitionList[0].id;
        this.defineCategory(this.formData.definition_id);
      }
    },

    dialog() {
      if (this.formDataNavbar.id || this.formDataNavbar.type == 2) {
        this.selected = {
          name: this.formDataNavbar.params.definition_name,
          id: this.idDefinition,
        };
        if (this.formDataNavbar.params.many_categories !== undefined) {
          this.selectedCategory = this.formDataNavbar.params.many_categories;
        } else {
          this.selectedCategory = "";
        }
      }

      if (this.dialog == false) {
        this.cleanSelect();
      }
    },
  },

  mounted() {
    this.setDefinition();
    this.setAllSubCategory();
  },
};
</script>

<style>
</style>