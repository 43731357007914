export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        name: null,
        category: null
    },
    list: [],
    errors: [],
}