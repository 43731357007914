
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import router from '@/router/index'


export const updateBuy = function ({ commit }, payload) {

    spinnerChoice('modal', true)
    axios
        .post(`${baseApiUrl}/buy/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                show({ commit }, payload.buy_id)
                commit('SET_UPDATEBUYMODAL', false)
            }

        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('modal', false)

        });
}

export const show = function ({ commit }, payload) {
    spinnerChoice('full', true)
    axios
        .post(`${baseApiUrl}/order/getOrdersDetail`, { 'buy_id': payload })
        .then(res => {
            let checkTypeable = [];
            checkTypeable = res.data.order.filter((a) => a.typeable != null);
            if (checkTypeable.length > 0) {
                commit('SET_FIELD_RECORDING', true)
            } else {
                commit('SET_FIELD_RECORDING', false)

            }
            commit('SET_FORMDATA', res.data.order);
            commit('SET_PAYMENTDATA', res.data.payments);
            commit('SET_COSTUMERADDRESSINFORMATION', res.data.costumer_address)
            commit('SET_ANTIFRAUDRESPONSELIST', res.data.antifraud_informations)
            commit('SET_STATUSHISTORY', res.data.status_history)


        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        });
}

export const statusChange = function ({ commit }, payload) {

    spinnerChoice('full', true)
    axios
        .post(`${baseApiUrl}/buy/status-change`, payload)
        .then(res => {
            if (res.data.status == 201) {
                router.push('/inTransitBuys')
                commit('SET_FORMDATA', [])
            }

        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        });
}

export const capturePayment = function ({ commit }, payload) {
    spinnerChoice('full', true)
    // console.log(payload)
    // console.log(payload.payment_id)
    axios
        .post(`${baseApiUrl}/payment/capture-payment`, { payment_id: payload.payment_id })
        .then(res => {
            if (res.data.status == 2) {
                router.push({ path: '/sortingOutBuys' });
                alert('Atualização feita com Sucesso !!!')
                commit('SET_ERRORS', '')
            } else {
                alert('Algo deu errado, consulte seu painel admnistrativo de pagamento !!!')
                show({ commit }, payload.buy_id)
            }

        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        });
}

export const rejectPayment = function ({ commit }, payload) {
    spinnerChoice('full', true)
    // console.log(payload)
    // console.log(payload.payment_id)
    axios
        .post(`${baseApiUrl}/payment/reject-payment`, { payment_id: payload.payment_id })
        .then(res => {
            // console.log(res)
            if (res.data.status == 7) {
                router.push({ path: '/pendingBuys' });
                alert('Pagamento Recusado com Sucesso !!!')
                commit('SET_ERRORS', '')
            } else {
                alert('Algo deu errado, consulte seu painel admnistrativo de pagamento !!!')
                show({ commit }, payload.buy_id)
            }

        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        });
}


const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', [])
    commit('SET_PAYMENTDATA', []);
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const setUpdateBuyModal = function ({ commit }, value) {
    commit('SET_UPDATEBUYMODAL', value)
}

const setCartMelhorEnvioModal = function ({ commit }, value) {
    commit('SET_MELHORENVIOCARTERRORS', {})
    commit('SET_CARTMELHORENVIOMODAL', value)
}


export const addDeliveryInMelhorEnvioCart = function ({ commit }, payload) {
    commit('SET_MELHORENVIOCARTERRORS', {})
    spinnerChoice('modal', true)
    axios
        .post(`${baseApiUrl}/order/delivery/add-buy-in-melhor-envio`, payload)
        .then(res => {
            // console.log(res)
            if (res.data.id) {
                commit('SET_CARTMELHORENVIOMODAL', false)
                alert('Seu Pedido Foi enviado p/ o Melhor Envio')
            }
            if (res.data.errors) {
                commit('SET_MELHORENVIOCARTERRORS', res.data.errors)
                // console.log(res.data.errors)
            }
            if (!res.data.errors && !res.data.id) {
                alert('Algo deu errado !!!')
                show({ commit }, payload.buy_id)
            }

        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_MELHORENVIOCARTERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            spinnerChoice('modal', false)
        });
}

// const getAntifraudResponse = function () {
//     const data = this.$store.state.OrderSession.pages.DetailOrder.antifraudListResponse
//     console.log(data);
// };

const printOrder = function ({ commit }, payload) {


    let ArrayLocalStorage = JSON.parse(localStorage.getItem('printed_orders')) || [];

    if (ArrayLocalStorage == null) {
        localStorage.setItem("printed_orders", JSON.stringify({ id: payload }))
        commit('SET_ERRORS', '')
    } else {
        ArrayLocalStorage.push({ id: payload })

        if (ArrayLocalStorage.length > 60) {
            ArrayLocalStorage.shift();
        }
        localStorage.setItem('printed_orders', JSON.stringify(ArrayLocalStorage));

    }

    commit('CLOSE_MODAL', false)
}

const closeDialog = function ({ commit }) {
    commit('CLOSE_MODAL', false)
}

const openDialog = function ({ commit }) {

    commit('OPEN_MODAL', true)
}


export default {
    cleanItem,
    cleanErrors,
    show,
    capturePayment,
    rejectPayment,
    statusChange,
    setUpdateBuyModal,
    updateBuy,
    setCartMelhorEnvioModal,
    addDeliveryInMelhorEnvioCart,
    printOrder,
    closeDialog,
    openDialog
    // getAntifraudResponse
}