<template>
  <span>
    <h2 class="mt-4 mb-4">Estoque</h2>
    <v-row no-gutters>
      <!-- <v-col cols="12" sm="5" md="4" lg="3" xl="2" :align="align">
        <v-btn class="mr-4 mb-5 register-btn" @click="setNewModal()"
          >Cadastrar Estoque</v-btn
        >
      </v-col> -->
      <v-col cols="12" sm="5" md="4" lg="3" xl="2" :align="align">
        <v-btn class="mr-4 mb-5 register-btn" @click="setAllGrid()"
          >Cadastrar Grade</v-btn
        >
      </v-col>
    </v-row>
    <span>
      <v-switch
        color="amber darken-4"
        @change="setShowInactive(showInactive), setList($route.params.id)"
        v-model="showInactive"
        label="Mostar Inativos"
      ></v-switch>
      <v-data-table :headers="headers" :items="list" :footer-props="footer">
        <template v-slot:item.code_color="{ item }">
          <div
            v-if="item.color !== 'N/A'"
            class="colors-grid-list"
            :style="` ${setBackgroundColorStyle(
              item.code_color,
              item.second_code_color
            )}`"
          >
            {{ item.color }}
          </div>
          <div v-else>
            {{ item.color }}
          </div>
        </template>
        <template v-slot:item.active="{ item }">
          <div :class="` ${item.active}`">{{ checkTrue(item.active) }}</div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
      {{ allGridModal }}
    </span>
    <spinner-dialog persistent v-if="modal" :dialog="modal">
      <v-card class="dialog-default">
        <grid-form :id="editId"></grid-form>
      </v-card>
    </spinner-dialog>
    <spinner-dialog persistent v-if="allGridModal" :dialog="allGridModal">
      <v-card class="dialog-default">
        <save-all-grid></save-all-grid>
      </v-card>
    </spinner-dialog>
  </span>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";
import GridForm from "@/views/ProductSession/Pages/Product/Grid/form";
import SaveAllGrid from "@/views/ProductSession/Pages/Product/Grid/Components/saveAllGrid";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";

export default {
  components: {
    GridForm,
    spinnerDialog,
    SaveAllGrid,
  },
  data: function () {
    return {
      showInactive: false,
      editId: "",
      search: "",
      headers: [
        { text: "Qtd", value: "balance", class: "black--text" },
        { text: "Tamanho", value: "size", class: "black--text" },
        { text: "Cor", value: "code_color", class: "black--text" },
        { text: "Ativo", value: "active", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Grid", ["modal", "list", "allGridModal"]),
    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },

  methods: {
    setAllGrid() {
      this.setAllGridModal(!this.allGridModal);
    },
    setBackgroundColorStyle(firstColor, SecondColor) {
      if (!SecondColor) {
        return `background: ${firstColor}`;
      } else {
        return `background: linear-gradient(90deg, ${firstColor} 50%, ${SecondColor} 50%);`;
      }
    },
    checkTrue(value) {
      let response;
      value == true ? (response = "Sim") : (response = "Não");

      return response;
    },
    setNewModal() {
      this.setModal(!this.modal);
      this.editId = null;
    },
    ...mapActions("Grid", [
      "setModal",
      "setList",
      "setAllGridModal",
      "setShowInactive",
    ]),
    edit(item) {
      this.setModal(!this.modal);
      this.editId = item;
    },
  },
  mounted() {
    this.setList(this.$route.params.id);
    toastAlert("success");
  },
  destroyed() {
    this.setShowInactive(false);
  },
};
</script>
<style scoped>
.colors-grid-list {
  border-radius: 10px;
  padding: 10px;
  color: grey;
}

.true {
  background: rgb(230, 230, 233);
  border-radius: 10px;
  width: 30%;
  color: black;
  padding: 5px 0 5px 7px;
}

.false {
  background: rgb(120, 112, 112);
  border-radius: 10px;
  width: 30%;
  color: white;
  padding: 5px 0 5px 9px;
}
</style>