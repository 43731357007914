const defaultPath = '/variation-group-product'
const toastName = 'Produto'
// const listDefault = {
//     id: null,
//     created_at: null,
//     updated_at: null,
//     name: null,
//     deifinition_id: null,
//     gender_types: null
// }
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { toastAlert } from "@/utils/Alerts/toast";

// import {
//     saveFunction,
// indexFunction,
// updateFunction,
// showFunction,
// diferentIndexFunction
// } from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit, state }, payload) {
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl + defaultPath}/save`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `O ${toastName}, foi cadastrado com sucesso !!`
                );
                setList({ commit, state }, { variation_group_id: payload.variation_group_id })
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

// const update = function ({ commit }, payload) {
//     payload.name = toUpperCaseFirstWord(payload.name)
//     updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.name}`, 'full')
// }

// const show = function ({ commit }, payload) {
//     showFunction(commit, defaultPath, payload, 'full')
// }

const setList = ({ commit }, payload) => {
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl + defaultPath}/index-by-group`, payload).then(res => {
        commit('SET_LIST', res.data)
    })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

// const setFilteredList = ({ commit }, id) => {

//     diferentIndexFunction(commit, '/category/index-with-filter', `definition_id=${id}`, 'full')
// }

// const cleanItem = function ({ commit }) {
//     commit('SET_FORMDATA', listDefault)
// }

// const cleanErrors = function ({ commit }) {
//     commit('SET_ERRORS', '')
// }






////Novas
const deleteFunction = function ({ commit, state }, payload) {
    let confirmLet = confirm("Tem certeza que deseja excluir esse Item da Lista?");
    if (confirmLet) {
        spinnerChoice('full', true)


        axios.post(`${baseApiUrl + defaultPath}/delete`, { id: payload.id }).then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    `O ${toastName}, foi Apagado com sucesso !!`
                );
                setList({ commit, state }, { variation_group_id: payload.variation_group_id })
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
            // commit('SET_PRODUCTLIST', res.data.data)
        })
            .catch(e => {
                catchError(e)
            })

            .finally(() => {
                spinnerChoice('full', false)
            })
    }
}

const setProductList = function ({ commit }, payload) {
    spinnerChoice('full', true)
    let data = {
        ...payload,
        showInactive: false,
        itens_per_page: 1000,
        company: payload.companyId,
        ordenate_field: 'id',
        ordenate_type: 'ASC'
    }

    axios.post(`${baseApiUrl}/product/paginate-index`, data).then(res => {
        commit('SET_PRODUCTLIST', res.data.data)
    })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const becomeMain = function ({ commit, state }, payload) {
    let confirmLet = confirm("Tem certeza que deseja tornar esse item principal?");
    if (confirmLet) {
        spinnerChoice('full', true)


        axios.post(`${baseApiUrl + defaultPath}/become-main`, { id: payload.id }).then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    `O ${toastName}, ê administrador agora !!`
                );
                setList({ commit, state }, { variation_group_id: payload.variation_group_id })
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
            // commit('SET_PRODUCTLIST', res.data.data)
        })
            .catch(e => {
                catchError(e)
            })

            .finally(() => {
                spinnerChoice('full', false)
            })
    }
}


export default {
    setProductList,
    save,
    setList,
    deleteFunction,
    becomeMain
}