<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-company">
      <h1 class="title-topo">Lista de Clientes</h1>
      <br />
      <v-expansion-panels v-model="panel">
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header>
            <h4>Filtros</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="margin: 0 auto !important">
            <v-row align="center">
              <v-col cols="6">
                <v-switch
                  color="amber darken-4"
                  @change="setList()"
                  v-model="showInactive"
                  label="Mostar Inativos"
                ></v-switch>
              </v-col>

              <v-col cols="6">
                <v-switch
                  color="amber darken-4"
                  @change="setList()"
                  v-model="only_delivered"
                  label="Somente entregues"
                ></v-switch>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-text-field
        class="default-search"
        v-model="search"
        label="Busca"
        single-line
        hide-details
      ></v-text-field>

      <span>
        <v-data-table
          class="default-table"
          :headers="headers"
          :items="list"
          :search="search"
          :footer-props="footer"
        >
          <template v-slot:item.contract_close="{ item }">
            <span v-if="item.contract_close">
              {{ toConvertData(item.contract_close) }}
            </span>
          </template>

          <template v-slot:item.project_delivery="{ item }">
            <span v-if="item.project_delivery">
              {{ toConvertData(item.project_delivery) }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="editItem(item.id)" title="Edit"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
      </span>
    </admin-blade>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AdminBlade from "@/Layouts/Blade";
import { toastAlert } from "@/utils/Alerts/toast";
import { dateFormatDMA } from "@/utils/dateFunctions";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      panel: 1,
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "CLIENTE", value: "fantasy_name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        {
          text: "Data Fechamento",
          value: "contract_close",
          class: "black--text",
        },
        {
          text: "Data Entrega",
          value: "project_delivery",
          class: "black--text",
        },

        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Company", ["list"]),

    showInactive: {
      get() {
        return this.$store.state.Company.showInactive;
      },
      set(value) {
        this.$store.commit("Company/SET_SHOWINACTIVE", value);
      },
    },

    only_delivered: {
      get() {
        return this.$store.state.Company.only_delivered;
      },
      set(value) {
        this.$store.commit("Company/SET_ONLY_DELIVERED", value);
      },
    },
  },

  methods: {
    ...mapActions("Company", ["setList"]),
    editItem(item) {
      this.$router.push(`form-company/${item}`);
    },

    toConvertData(data) {
      return dateFormatDMA(data);
    },
  },

  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>