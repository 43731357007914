import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { toastAlert } from "@/utils/Alerts/toast";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


const savePv = function ({ commit }, payload) {
    commit('SET_SPINNER', true)
    let data = {
        key_name: 'rede_pv',
        ...payload
    }

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {
                check({ commit }, {
                    company_id: data.company_id
                })
                commit('SET_DIALOG', false)

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)

        })
}

const updateValue = function ({ commit }, payload) {
    commit('SET_SPINNER', true)

    axios.post(`${baseApiUrl}/integration-key-value/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `Valor alterado com sucesso!`
                );
                commit('SET_DIALOG', false)
                toastAlert('success')
                // commit('SET_MELHORENVIOCHANGEKEY', false)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)
        })
}

const saveToken = function ({ commit }, payload) {
    commit('SET_SPINNER', true)
    let data = {
        key_name: 'rede_token',
        ...payload
    }

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {
                check({ commit }, {
                    company_id: data.company_id
                })
                commit('SET_DIALOG', false)

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)

        })
}

const check = function ({ commit }, payload) {
    let data = {
        key_name: 'rede_pv',
        ...payload
    }
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_PVID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })

    data = {
        key_name: 'rede_token',
        ...payload
    }
    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_TOKENID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })
        .finally(() => {
            spinnerChoice('full', false)
        })
}

const cleanState = function ({ commit }) {
    commit('SET_ERRORS', [])
}
export default {
    savePv,
    cleanState,
    saveToken,
    check,
    updateValue
}