
const getFinishedBuys = function (state) {
    if (state.list.list) {


        let filteredData = state.list.list.filter(function (e) {
            return e.status == 2 || e.status == 3 || e.status == 4;
        });
        let sumValue = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.products_value));
        }, 0);

        let fregth = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.delivery_price));
        }, 0);

        let items = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.amount));
        }, 0);

        return { productsValue: sumValue, amount: filteredData.length, deliveryValue: fregth, items: items }
    } else {
        return 0
    }
}

const getUnfinishedBuys = function (state) {
    if (state.list.list) {
        let filteredData = state.list.list.filter(function (e) {
            return e.status == 0 || e.status == 1 || e.status == 5 || e.status == 6 || e.status == 7;
        });

        let sumValue = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.products_value));
        }, 0);

        let fregth = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.delivery_price))

        }, 0);


        let items = filteredData.reduce(function (prevVal, elem) {
            return parseFloat(prevVal) + parseFloat(checkIfValueIsNull(elem.amount))

        }, 0);

        return { productsValue: sumValue, amount: filteredData.length, deliveryValue: fregth, items: items }
    } else {
        return 0
    }
}

const getProductsGroup = function (state) {
    // let response =[]
    if (state.list.products !== null) {

        let Products = state.list.products;

        let gridArray = Products.map(function (item) {
            return item.grid_id;
        });



        let gridArrayIndex = gridArray.filter((grid, index) => gridArray.indexOf(grid) === index);

        let response = []

        for (let i = 0; i < gridArrayIndex.length; i++) {
            let newValues = {}
            let gridToSum = Products.filter((value) => {
                return value.grid_id == gridArrayIndex[i];
            });
            newValues = gridToSum[0]
            if (gridToSum.length > 1) {
                for (let i = 0; i < gridToSum.length; i++) {
                    if (i > 0) {
                        newValues.amount = parseFloat(gridToSum[i].amount) + parseFloat(newValues.amount)
                    }
                }
            } else {
                newValues.amount = parseFloat(gridToSum[0].amount);
            }
            response.push(newValues)
        }
        return response
    } else {
        return null
    }
}

const getDataPeople = function (state) {
    let data = state.list.buyed_people_data;
    let init_date = state.init_date;

    let old_buyers = data.filter(person => person.first_buy < init_date);
    let new_buyers = data.filter(person => person.first_buy >= init_date);

    return { "old_buyers_list": old_buyers, 'new_buyers_list': new_buyers };
};






// const getNewBuyers = function (old_people_order, new_people_order) {
//     let oldPeopleIds;

//     if (Array.isArray(old_people_order)) {
//         oldPeopleIds = new Set(old_people_order.map(people => people.people_id));
//     } else {
//         oldPeopleIds = new Set();
//     }

//     const new_buyers = [];

//     for (const people of new_people_order) {
//         if (!oldPeopleIds.has(people.people_id)) {
//             new_buyers.push(people);
//         }
//     }
//     console.log('oi');
//     return new_buyers;
// };





//Privates
const checkIfValueIsNull = function (value) {
    if (value == null || value == undefined || !value) {
        return 0
    } else {
        return value
    }
}

export default {
    getFinishedBuys,
    getUnfinishedBuys,
    getProductsGroup,
    getDataPeople
}