<template>
  <span>
    <v-content class="login">
      <spinner v-show="spinnerFullScreen" />
      <v-row align="center">
        <v-col cols="2" sm="4"></v-col>
        <v-col cols="8" sm="4" align="center">
          <v-card class="card-class" max-width="400">
            <v-card-text class="d-flex">
              <h1 class="title">Login</h1>
              <v-form>
                <strong class="red--text">{{ errors.email }}</strong>
                <v-text-field
                  reverse
                  outlined
                  dense
                  color="white"
                  prepend-inner-icon="fas fa-user"
                  autofocus:true
                  placeholder="Email"
                  name="Email"
                  type="text"
                  v-model="formData.email"
                  @keyup.enter="login(formData)"
                ></v-text-field>
                <strong class="red--text">{{ errors.password }}</strong>
                <v-text-field
                  reverse
                  outlined
                  dense
                  color="white"
                  prepend-inner-icon="fas fa-lock"
                  id="password"
                  placeholder="Senha"
                  name="Senha"
                  type="password"
                  v-model="formData.password"
                  @keyup.enter="login(formData)"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn class="btn" color="white" @click="login(formData)"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2" sm="4"></v-col>
      </v-row>
    </v-content>
  </span>
</template>

<script>
import Spinner from "@//Layouts/Components/spinner";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Spinner,
  },

  data: function () {
    return {
      formData: {
        email: null,
        senha: null,
      },
    };
  },
  methods: {
    ...mapActions("Auth", ["login"]),
  },
  computed: {
    ...mapState("Spinner", ["spinnerFullScreen"]),
    ...mapState("Auth", ["errors", "cleanErrors"]),
  },
  destroyed() {
    this.cleanErrors();
  },
};
</script>

<style scoped>
.login {
  background-image: linear-gradient(
      rgba(118, 122, 122, 0.1),
      rgba(93, 75, 143, 0.4)
    ),
    url("/public/img/login.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00243c;
  height: 100vh;
}

.card-class {
  background: linear-gradient(
    rgba(255, 255, 255, 0.95),
    rgba(139, 137, 137, 0.8)
  );
  border-radius: 20px !important;
  margin-top: 28%;
}

.title {
  text-align: center;
  margin: 10% 0 15% 0;
  color: black;
}

.btn {
  color: white;
  background: rgb(81, 62, 255) !important;
  margin: 5% auto 10% auto;
  padding: 0 15px 0 15px;
  border-radius: 20px;
}

.input-type {
  border-radius: 35px;
  background-color: white;
  padding: 4px 15px 0 15px;
  margin: 0 0 15px 0;
}
</style>