<template>
  <span>
    <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-user">
      <h1 class="title-topo">Lista de Consumidores</h1>
      <br />
      <v-expansion-panels v-model="panel">
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header>
            <h4>Filtros</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="margin: 0 auto !important">
            <div class="filter-class">
              <v-row align="center">
                <v-col v-if="adm" cols="12">
                  <v-autocomplete
                    v-model="form.company_id"
                    :items="companyList"
                    item-text="fantasy_name"
                    item-value="id"
                    label="Loja *"
                    color="amber darken-4"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <br />
              <h5>Selecione o período da data de registro do usuário</h5>

              <v-row>
                <v-col
                  align-self="center"
                  class="filter-col-class"
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    style="width: 100% !important"
                    type="date"
                    v-model="form.init_date"
                    label="Data Inicial"
                  ></v-text-field>
                </v-col>
                <v-col class="filter-col-class" cols="12" md="6">
                  <v-text-field
                    type="date"
                    v-model="form.finish_date"
                    label="Data Final"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="filter-col-class" cols="12" md="6">
                  <v-select
                    v-model="form.state"
                    :items="states"
                    attach
                    chips
                    label="Estado"
                    multiple
                    dense
                    class="mt-2"
                    item-text="name"
                    item-value="value"
                  ></v-select
                ></v-col>

                <v-col class="filter-col-class" cols="12" md="6">
                  <v-select
                    v-model="form.gender"
                    :items="genders"
                    attach
                    chips
                    label="Gênero"
                    multiple
                    dense
                    class="mt-2"
                    item-text="name"
                    item-value="value"
                  ></v-select
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="filter-col-class" cols="13" md="6" align="left">
                  <v-btn
                    :disabled="adm && form.company_id == null"
                    @click="setList(form)"
                    color="green darken-2 white--text mb-3"
                    >Filtrar</v-btn
                  >
                </v-col>
                <v-col class="filter-col-class" cols="13" md="6" align="right">
                  <v-btn dark rounded small color="red" @click="cleanItem()"
                    >Limpar filtros <v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />

      <v-col cols="12">
        <v-btn :disabled="list.length == 0" @click="csvExport(list)"
          >Exportar CSV</v-btn
        >
      </v-col>
      <v-text-field
        class="default-search"
        v-model="search"
        label="Busca"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.admin="{ item }">
          <span :class="`mr-2 ${getAcessType(item.admin)}`" title="Edit">{{
            item.admin
          }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import { mapActions, mapState } from "vuex";
import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      adm: false,
      panel: 0,
      form: {
        init_date: new Date(),
        finish_date: null,
        state: null,
        gender: null,
      },
      genders: [
        { value: "m", name: "Masculino" },
        { value: "f", name: "Feminino" },
        { value: "o", name: "Outros" },
      ],
      states: [
        { value: "AC", name: "AC" },
        { value: "AL", name: "AL" },
        { value: "AP", name: "AP" },
        { value: "AM", name: "AM" },
        { value: "BA", name: "BA" },
        { value: "CE", name: "CE" },
        { value: "ES", name: "ES" },
        { value: "GO", name: "GO" },
        { value: "MA", name: "MA" },
        { value: "MT", name: "MT" },
        { value: "MS", name: "MS" },
        { value: "MG", name: "MG" },
        { value: "PA", name: "PA" },
        { value: "PB", name: "PB" },
        { value: "PR", name: "PR" },
        { value: "PE", name: "PE" },
        { value: "PI", name: "PI" },
        { value: "RJ", name: "RJ" },
        { value: "RN", name: "RN" },
        { value: "RS", name: "RS" },
        { value: "RO", name: "RO" },
        { value: "RR", name: "RR" },
        { value: "SC", name: "SC" },
        { value: "SP", name: "SP" },
        { value: "SE", name: "SE" },
        { value: "TO", name: "TO" },
      ],
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "NOME", value: "fantasy_name", class: "black--text" },
        { text: "E-MAIL", value: "email", class: "black--text" },
        { text: "Telefone", value: "phone_number", class: "black--text" },
        { text: "Estado", value: "state", class: "black--text" },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
      msgServer: [],
    };
  },
  computed: {
    ...mapState("Users", ["list", "userType"]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("Users", ["setList"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    edit(item) {
      this.$router.push(`form-user/${item}`);
    },
    cleanItem() {
      this.form = {
        init_date: null,
        finish_date: null,
        state: null,
        gender: null,
        // show_product_detail: false,
      };
    },

    getAcessType(value) {
      if (value == true) {
        return "admin-table-class";
      } else {
        return "store-table-class";
      }
    },

    csvExport(arrData) {
      let renameObj = this.renameObjectCsv(arrData);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(renameObj[0]).join(";"),
        ...renameObj.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    renameObjectCsv(arrData) {
      const newKeys = {
        birthday: "Aniversario",
        cpf_cnpj: "Cpf/Cnpj",
        city: "Cidade",
        created_at: "Data Cadastro",
        updated_at: "Atualização Cadastro",
        fantasy_name: "Nome",
        phone_number: "Numero telefone",
        state: "Estado",
        gender: "Gênero",
        email: "Email",
        active: "Ativo",
        social_name: "Nome Social",
      };
      const keysToExclude = [
        "panel_access",
        "id",
        "note",
        "company_name",
        "user_id",
        "admin",
        "company",
        "name",
      ]; // adicionando as chaves que não deseja incluir
      var newArrData = [];

      arrData.forEach(function (val) {
        //esse loop ira acessar os atributos e converter cada atributo em um objeto traduzido {qtd:1},{aniversario:'2000-01-01'},etc...
        var newkeyValues = Object.keys(val)
          .filter((key) => !keysToExclude.includes(key)) // Remove as chaves que não deseja incluir
          .map((key) => {
            const newKey = newKeys[key] || key;
            return { [newKey]: val[key] };
          });
        //O método Object.assign() copia todas as propriedades próprias enumeráveis de um ou mais objetos de origem para um objeto alvo.
        //Ele retorna o objeto alvo modificado. {qtd:1,aniversario:'2000-01-01',etc}
        newkeyValues = Object.assign({}, ...newkeyValues);
        //Conatena o objeto traduzido na nossa vareavel
        newArrData = newArrData.concat(newkeyValues);
      });

      return newArrData;
    },
  },

  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
    this.form.init_date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    )
      .toISOString()
      .slice(0, 10);
    this.form.finish_date = new Date().toISOString().slice(0, 10);

    // this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
.admin-table-class {
  background: green;
  border-radius: 10px;
  padding: 5px;
  color: white;
}

.store-table-class {
  background: blue;
  border-radius: 10px;
  padding: 5px;
  color: white;
}
</style>