const defaultPath = '/grid'
const toastName = 'Estoque'
const listDefault = {
  id: null,
  created_at: null,
  updated_at: null,
  definition_id: null
}

import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { diferentIndexFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";
import { toastAlert } from "@/utils/Alerts/toast";

export const save = function ({ commit, state }, payload) {
  spinnerChoice('modal', true)
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  payload.color = capitalizeFirstLetter(payload.color);

  axios.post(`${baseApiUrl + defaultPath}/save`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi cadastrado com sucesso !!`
        );
        commit('SET_MODAL', false)
        setList({ commit, state }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

const setListGrid = function ({ commit }) {
  // indexFunction(commit, defaultPath, null, 'full')   
  spinnerChoice('full', true)

  axios.post(`${baseApiUrl}/size/index`).then(res => {
    
    let data = [];
    for(let i=0; i <res.data.length; i++)
  {
    res.data[i].description = JSON.parse(res.data[i].description)
    data.push(res.data[i].description);
  }

    commit('SET_SIZES', data)
  })
    .catch(e => {
      catchError(e)
    })

    .finally(() => {
      spinnerChoice('full', false)
    })
}

export const saveMany = function ({ commit, state }, payload) {
  // commit('SET_MODALALLGRIDERRORS', null)
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/save-many`, payload)
    .then(res => {
      if (res.data.status == 406) {
        alert(`O produto Tamanho ${res.data.errors.size}, e cor Código: ${res.data.errors.code_color} já esta cadastrado.`)
        spinnerChoice('modal', false)
      }
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          `Itens, Cadastrados com Sucesso !!`
        );
        commit('SET_MODALALLGRID', false)

        setList({ commit, state }, payload.product_id)
        toastAlert('success')
        spinnerChoice('modal', false)
      }
      // else {
      //   commit('SET_ERRORS', res.data.errors)
      // }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {
      spinnerChoice('modal', false)
    })
}


export const stockChange = function ({ commit, state }, payload) {
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/stock-change`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi cadastrado com sucesso !!`
        );
        commit('SET_MODAL', false)
        setList({ commit, state }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}



const update = function ({ commit, state }, payload) {

  spinnerChoice('modal', true)

  axios
    .post(`${baseApiUrl + defaultPath}/update`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi cadastrado com sucesso !!`
        );
        commit('SET_MODAL', false)
        setList({ commit, state }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })



  // updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.fantasy_name}`, 'full')
}

const show = function ({ commit }, payload) {
  showFunction(commit, defaultPath, `grid_id=${payload}`, 'modal')
}

const setList = ({ commit, state }, id) => {
  diferentIndexFunction(commit, `${defaultPath}/index`, { product_id: id, show_inactive: state.showInactive }, 'full')
}

const cleanItem = function ({ commit }) {
  commit('SET_FORMDATA', listDefault)
}

const setFormData = function ({ commit }, payload) {
  commit('SET_FORMDATA', payload)
}

const cleanErrors = function ({ commit }) {
  commit('SET_ERRORS', '')
}

const setModal = function ({ commit }, value) {
  commit('SET_MODAL', value)
}

const setAllGridModal = function ({ commit }, value) {
  commit('SET_MODALALLGRID', value)
}

const cleanAllGridModalErrors = function ({ commit }) {
  commit('SET_MODALALLGRIDERRORS', null)
}

const setAllGridModalErrors = function ({ commit }, value) {
  commit('SET_MODALALLGRIDERRORS', value)
}

const setShowInactive = function ({ commit }, value) {
  commit("SET_SHOWINACTIVE", value)
}

export default {
  setFormData,
  save,
  saveMany,
  cleanItem,
  cleanErrors,
  setList,
  update,
  show,
  setListGrid,
  setModal,
  stockChange,
  setAllGridModal,
  cleanAllGridModalErrors,
  setAllGridModalErrors,
  setShowInactive,
}