<template>
  <div>
      <v-dialog v-model="dialog" persistent max-width="700">
        <v-card>
          <v-card-title class="text-h5">
            Gerar URL Catalogo Facebook
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              v-if="user.admin"
              v-model="formDataNavbar.company_id"
              :value="company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
            />

            <v-autocomplete
              label="Genero"
              v-model="selectedGender"
              :items="itemsGender"
            ></v-autocomplete>

            <v-checkbox
              v-model="checkFace"
              label="Exportar apenas descrição do Facebook"
            />

            <v-row
              style="
                outline: 2px green solid;
                max-width: 650px;
                margin: 0 auto 25px;
                border-radius: 15px;
              "
              align="center"
              justify="center"
            >
              <v-col cols="10">
                <h3>{{ mountFacebookUrl() }}{{ facebookUrl }}</h3>
              </v-col>
              <v-col cols="2" align="center">
                <v-btn icon @click="copyFaceUrl()">
                  <v-icon> mdi mdi-content-copy </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <p>
              <v-icon>mdi mdi-alert-circle-outline</v-icon>
              Utilizar as mesmas credenciais utilizadas no Granshopping para
              efetuar login
            </p>

            <v-img
              style="outline: green 1px solid"
              contain
              src="/img/instagramShopping.png"
            ></v-img>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialog = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { baseApiUrl } from "@/global";
export default {
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      itemsGender: ["Masculino", "Feminino", "Unisex", "Nenhum"],
      checkFace: false,

      selectedGender: "",
      facebookGender: "",

      facebookUrl: "www.",
    };
  },

  computed: {
    ...mapState("Auth", ["user"]),

    ...mapState("Company", {
      companyList: "list",
    }),

    ...mapState("NavBarPersonalization", [
      "data",
      "formDataNavbar",
      "company_id",
    ]),
  },

  methods: {
    mountFacebookUrl() {
      this.facebookUrl =
        baseApiUrl.replace("api", "") +
        "facebook-integration/show/" +
        (this.formDataNavbar.company_id
          ? this.formDataNavbar.company_id
          : this.user.company_id) +
        this.checkInterrogation() +
        this.checkIfOnlyFaceDesc() +
        this.checkAmpersand() +
        this.checkGender();
    },

    checkIfOnlyFaceDesc() {
      if (this.checkFace == true) {
        return "face_description=true";
      } else {
        return "";
      }
    },
    checkGender() {
      if (this.selectedGender == "Masculino") {
        return "gender=m";
      } else if (this.selectedGender == "Feminino") {
        return "gender=f";
      } else if (this.selectedGender == "Unisex") {
        return "gender=u";
      } else {
        return "";
      }
    },
    checkInterrogation() {
      if (
        this.checkIfOnlyFaceDesc() == "face_description=true" ||
        this.checkGender() !== ""
      ) {
        return "?";
      } else {
        return "";
      }
    },
    checkAmpersand() {
      if (
        this.checkIfOnlyFaceDesc() == "face_description=true" &&
        this.checkGender() !== ""
      ) {
        return "&";
      } else {
        return "";
      }
    },

    copyFaceUrl() {
      navigator.clipboard.writeText(this.facebookUrl);
    },

    ...mapActions("Company", {
      setCompany: "setList",
    }),

    ...mapActions("NavBarPersonalization", [
      "setList",
      "cleanItem",
      "saveCompany_id",
    ]),
  },

  mounted() {
    this.setCompany();
  },
};
</script>

<style>
</style>