const SET_CHARTDATA = (state, obj) => {
    state.data = obj
}

const SET_OPENDELIVERY = (state, obj) => {
    state.openDelivery = obj
}

export default {
    SET_CHARTDATA,
    SET_OPENDELIVERY
}