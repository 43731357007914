<template>
  <span>
    <h2 class="mt-4 mb-4">
      Atributos
      <v-btn
        depressed
        rounded
        dense
        v-if="!show"
        @click="show = !show"
        style="color: blue; text-decoration: underline"
      >
        +
      </v-btn>
      <v-btn
        depressed
        rounded
        dense
        v-else
        @click="show = !show"
        style="color: blue; text-decoration: underline"
      >
        -
      </v-btn>
    </h2>

    <span v-if="show">
      <v-row>
        <v-col cols="12" :align="align">
          <v-btn class="mr-4 mb-5 register-btn" @click="setModal(!modal)"
            >Cadastrar Atributos</v-btn
          >
        </v-col>
      </v-row>
      <span>
        <v-data-table
          class="default-table"
          :headers="headers"
          :items="list"
          :search="search"
          :footer-props="footer"
        >
        </v-data-table>
      </span>
      <span>
        <spinner-dialog persistent v-if="modal" :dialog="modal">
          <v-card class="dialog-default">
            <product-atribute-form />
          </v-card>
        </spinner-dialog>
      </span>
    </span>
  </span>
</template>

<script>
import ProductAtributeForm from "@/views/ProductSession/Pages/Product/ProductAtribute/form";
("");
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ProductAtributeForm,
    spinnerDialog,
  },
  data: function () {
    return {
      show: false,
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "GRUPO", value: "group_name", class: "black--text" },
        { text: "NOME", value: "atribute_name", class: "black--text" },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("ProductAtribute", ["list", "modal"]),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },

  methods: {
    ...mapActions("ProductAtribute", ["setList", "setModal"]),
    // edit(item) {
    //   this.$router.push(`form-people/${item}`);
    // },
  },
  mounted() {
    this.setList({ product_id: this.$route.params.id });
    toastAlert("success");
  },
};
</script>

<style>
</style>