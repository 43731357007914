<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-delivery-settings">
    <h1 class="title-topo">Lista de Config. Envio</h1>

    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <span>
      <v-data-table
        class="default-table"
        :headers="headers"
        :items="list.data"
        :search="search"
        :footer-props="footer"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="edit(item.company_id)" title="Edit"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </span>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@//Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "CLIENTE", value: "company_name", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("DeliverySettings", ["list"]),
  },

  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("DeliverySettings", ["setList"]),
    edit(item) {
      this.$router.push(`form-delivery-settings/${item}`);
    },
  },
  mounted() {
    if (this.user.admin == false) {
      this.$router.push(`/form-delivery-settings/1`);
    } else {
      // alert(1);
      this.setList();
      toastAlert("success");
    }
    toastAlert("success");
  },
};
</script>