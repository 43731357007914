const getAnalysesResponses = function (state) {
    if (state.antifraudListResponse.length > 0) {
        let response = [];
        for (let i = 0; i < state.antifraudListResponse.length; i++) {
            response.push(
                {
                    created_at: state.antifraudListResponse[i].created_at,
                    status: removepackageID(state.antifraudListResponse[i].response),
                    apiResponse: state.antifraudListResponse[i].response
                }
                // state.antifraudListResponse[i]
            )
        }
        return response;
    }
    // let alo = state.antifraudListResponse;
    // return state.antifraudListResponse[0];

}

const removepackageID = function (data) {
    let dataToWork = JSON.parse(data)
    // return dataToWork.orders
    // return typeof dataToWork.packageID
    if (dataToWork && dataToWork.packageID !== undefined) {
        return getDescriptionForStatusAntifraud(dataToWork.orders[0].status)
    }
    if (dataToWork && dataToWork.code !== undefined) {
        return getDescriptionForStatusAntifraud(dataToWork.status)
    } else {
        return data;
    }


}

const getDescriptionForStatusAntifraud = function (status) {
    let list = [
        { name: 'APA', description: 'Pedido aprovado automaticamente' },
        { name: 'APM', description: 'Pedido aprovado manualmente' },
        { name: 'RPM', description: 'Pedido Reprovado' },
        { name: 'AMA', description: 'Pedido está em fila para análise' },
        { name: 'NVO', description: 'Pedido importado e não classificado Score pela analisadora' },
        { name: 'SUS', description: 'Pedido Suspenso por suspeita de fraude' },
        { name: 'CAN', description: 'Pedido cancelado pelo Cliente' },
        { name: 'FRD', description: 'Fraude Confirmada' },
        { name: 'RPA', description: 'Pedido Reprovado Automaticamente' },
        { name: 'RPP', description: 'Reprovação Por Política' },
        { name: 'APP', description: 'Aprovação Por Política' },
    ]
    let description = list.filter((data) => data.name == status);
    return description[0].description;
    // this.descriptionFinal = description.map((a) => a.description);
    // this.descriptionFinal = this.descriptionFinal.toString();
    // return this.descriptionFinal;
}

export default {
    getAnalysesResponses
}