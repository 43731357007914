<template>
  <span>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row v-show="!openDelivery">
            <v-col cols="12">
              <h1 class="text-center pt-5 pb-5" style="color: red">
                <i style="color: red" class="fas fa-times-circle"></i> Delivery
                Fechado
              </h1></v-col
            >
            <v-col cols="12" align="center">
              <v-btn
                @click="callDialog('open')"
                class="white--text"
                color="green"
                >Abrir Delivery</v-btn
              ></v-col
            >
          </v-row>

          <v-row v-show="openDelivery">
            <v-col cols="12">
              <h1 style="color: green" class="text-center pt-5 pb-5">
                <i style="color: green" class="fas fa-check-circle"></i>
                Delivery Aberto
              </h1>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn
                @click="callDialog('close')"
                class="white--text"
                color="red"
              >
                Fechar Delivery</v-btn
              >
            </v-col>
          </v-row>
          <!-- <p class="text-center">Aberto desde:</p> -->
        </v-card>
      </v-col>
      <spinner-dialog :dialog="dialog" width="500px">
        <v-card class="pt-5 pb-5">
          <v-row v-if="openClose == 'open'" style="width: 100%">
            <v-col cols="12" align="center">
              <p>
                Tem cereteza que deseja <strong>Abrir</strong> o Delivery ??
              </p>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn @click="changeDelivery(true)" color="green" class="mr-5"
                >Sim</v-btn
              >
              <v-btn color="red" @click="dialog = false">Não</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="openClose == 'close'" style="width: 100%">
            <v-col cols="12" align="center">
              <p>
                Tem cereteza que deseja <strong>Fechar</strong> o Delivery ??
              </p>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn @click="changeDelivery(false)" color="green" class="mr-5"
                >Sim</v-btn
              >
              <v-btn color="red" @click="dialog = false">Não</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </spinner-dialog>
    </v-row>
  </span>
</template>

<script>
import SpinnerDialog from "@/Layouts/Components/spinnerDialog";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    SpinnerDialog,
  },
  watch: {
    openDelivery() {
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
      openClose: null,
    };
  },
  computed: {
    ...mapState("Dashboard", ["openDelivery"]),
  },
  methods: {
    ...mapActions("Dashboard", ["showDeliveryOpen", "changeDelivery"]),
    callDialog(decision) {
      (this.openClose = decision), (this.dialog = !this.dialog);
    },
  },
  mounted() {
    this.showDeliveryOpen();
  },
};
</script>

<style scoped>
</style>