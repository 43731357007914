<template>
  <span>
    <admin-blade>
      <dashboard />
    </admin-blade>
  </span>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import Dashboard from "@/views/Main/Dashboard/dashboard";

export default {
  components: {
    AdminBlade,
    Dashboard,
  },
};
</script>

<style>
</style>