<template>
  <v-card class="default-table">
    <v-container>
      <v-data-iterator
        :items="list"
        items-per-page.sync="4"
        hide-default-footer
      >
        <template v-slot:default>
          <v-row>
            <v-col
              v-for="item in list"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="sub-header">
                  <div class="sub-header-text">
                    {{ item.id }} - {{ item.name }}
                  </div>
                </v-card-title>

                <v-divider></v-divider>
                <v-list dense>
                  <v-list-item v-if="checkUser()">
                    <v-list-item-content>Cliente:</v-list-item-content>
                    <v-list-item-content class="align-start item-text">
                      <v-row
                        no-gutters
                        style="height: 100%; width: 100%"
                        align="center"
                      >
                        <v-col>
                          {{ item.fantasy_name }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn fab color="#272727" @click="edit(item)">
                            <v-icon color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapActions } from "vuex";

export default {
  data: function () {
    return {};
  },
  props: {
    list: {},
  },
  methods: {
    ...mapActions("ProductGroup", ["setList"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    edit(item) {
      this.$router.push(`form-product-group/${item.id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
  },
};
</script>

<style scoped>
.mobile-pagination >>> .v-pagination__item {
  min-width: 0 !important;
  width: 25px !important;
  height: 25px !important;
}

.sub-header-text {
  font-size: 16px !important;
  font-weight: bold;
  height: 34px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
}

.item-row {
  border-top: 1px solid lightgray;
}

.item-text {
  height: 64px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>