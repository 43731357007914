const defaultPath = '/product-promotion'
const toastName = 'A Promoção'
const listDefault = {
  id: null,
  created_at: null,
  updated_at: null,
  definition_id: null
}

import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { diferentIndexFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";
// import { toastAlert } from "@/utils/Alerts/toast";

export const save = function ({ commit }, payload) {
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/save`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi cadastrada com sucesso !!`
        );
        commit('SET_MODAL', false)
        setList({ commit }, payload.product_id)
        // toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        if (e.response.data.errors.product_id == "O valor informado para o campo produto j\u00e1 est\u00e1 em uso.") {
          alert('Voce já tem uma promoção ativa para este produto, desative ela pra cadastrar uma nova !!!')
        }
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

const update = function ({ commit }, payload) {

  spinnerChoice('modal', true)

  axios
    .post(`${baseApiUrl + defaultPath}/update`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi alterada com sucesso !!`
        );
        commit('SET_MODAL', false)
        setList({ commit }, payload.product_id)
        // toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        if (e.response.data.errors.product_id == "O valor informado para o campo produto j\u00e1 est\u00e1 em uso.") {
          alert('Voce já tem uma promoção ativa para este produto, desative ela pra alterar outra para ativa !!!')
        }
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

const show = function ({ commit }, payload) {
  showFunction(commit, defaultPath, payload, 'modal')
}

const setList = ({ commit }, id) => {
  diferentIndexFunction(commit, `${defaultPath}/product-index`, { product_id: id, active: 0 }, 'full')
}

const cleanItem = function ({ commit }) {
  commit('SET_FORMDATA', listDefault)
}

const setFormData = function ({ commit }, payload) {
  commit('SET_FORMDATA', payload)
}

const cleanErrors = function ({ commit }) {
  commit('SET_ERRORS', '')
}

const setModal = function ({ commit }, value) {
  commit('SET_MODAL', value)
}


export default {
  setFormData,
  save,
  cleanItem,
  cleanErrors,
  setList,
  update,
  show,
  setModal
}