<template>
  <span class="card-payment-infos-class">
    <!-- <v-card class="card-payment-infos-class"> -->
    <h2 class="text-center">Pagamento na Retirada do Pedido</h2>
    <h3 class="red--text text-center" v-if="!data.paid">Pagamento pendente</h3>
    <hr class="mt-5 mb-5" />
    <!-- {{ data }} -->
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Tipo</th>
            <th class="text-left">Pago</th>
            <th class="text-left">Troco</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ getNameOfPaymentName(data.type) }}</td>
            <td>
              <span v-if="!data.paid"> Não </span>
              <span v-else> Sim </span>
            </td>
            <td>
              <span v-if="data.transshipment !== null">
                R$
                {{
                  parseFloat(data.transshipment).toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                }}
              </span>
              <span v-else> R$ 0,00 </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- <p>
        <strong> Tipo Pagamento : </strong>
        {{ getNameOfPaymentName(data.payment_type) }}
      </p>
      <p>
        <strong>Pago : </strong>
        <span v-if="!data.payment_type.paid"> Não </span>
        <span v-else> Sim </span>
      </p> -->
    <!-- </v-card> -->
  </span>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  methods: {
    getNameOfPaymentName(value) {
      if (value == 0) {
        return "Crédio";
      }
      if (value == 1) {
        return "Débito";
      }
      if (value == 2) {
        return "Dinheiro";
      }
    },
  },
};
</script>

<style scoped>
.card-payment-infos-class {
  /* border: solid 1px rgba(128, 128, 128, 0.336); */
  margin: 40px 0 0 !important;
  padding: 10px;
}
</style>