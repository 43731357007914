<template >
  <span v-if="data.mostSales">
    <p style="font-size: 30px; color: rgba(0, 0, 0, 1)" class="mb-4">
      Análise {{ changeMounthNumberInName() }}
    </p>
    <open-delivery v-if="user.delivery_system"></open-delivery>
    <!-- <hr /> -->
    <v-row>
      <v-col md="7" cols="12">
        <v-card class="pt-2">
          <v-container>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline"> Vendas </v-list-item-title>
                <v-list-item-subtitle class="ml-1">
                  {{ changeMounthNumberInName() }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text class="sellers-card">
              <v-row align="center">
                <v-col cols="6" sm="3" align="center">
                  <p class="green--text">Total</p>
                  <p class="text-sizing display-3 pt-5 green--text">
                    {{ data.sells }}
                  </p>
                </v-col>
                <v-col cols="6" sm="3" align="center">
                  <p class="orange--text">Hoje</p>
                  <p class="text-sizing display-3 pt-5 orange--text">
                    {{ data.todaySells }}
                  </p>
                </v-col>
                <v-col cols="6" sm="3" align="center">
                  <p>Pendentes</p>
                  <p :class="`text-sizing display-3 pt-5`">
                    {{ data.pendingSells }}
                  </p>
                </v-col>
                <v-col cols="6" sm="3" align="center">
                  <p class="red--text">
                    Não <br />
                    Finalizados
                  </p>
                  <p class="text-sizing display-3 red--text">
                    {{ data.unfinished }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col md="5" cols="12">
        <v-row no-gutters style="height: 100%">
          <v-col cols="12" style="height: 50%; padding-bottom: 10px">
            <v-card
              class="card-default-dashboard"
              style="height: 100% !important; width: 100% !important"
            >
              <v-container>
                <v-row justify="center" align="center" style="height: 100%">
                  <v-col
                    class="mt-1 title-dashboard-col"
                    align="center"
                    align-self="center"
                    cols="7"
                  >
                    <h2>
                      {{ data.total_products }}
                    </h2>
                    <p>Total Produtos</p>
                  </v-col>
                  <v-col cols="3">
                    <i class="fas fa-tshirt icon-dashboard"></i>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" style="height: 50%; padding-top: 10px">
            <v-card
              style="height: 100% !important; width: 100% !important"
              class="card-default-dashboard"
            >
              <v-container>
                <v-row s justify="center" align="center" style="height: 100%">
                  <v-col
                    class="mt-1 title-dashboard-col"
                    align="center"
                    align-self="center"
                    cols="7"
                  >
                    <h2>
                      {{ data.users }}
                    </h2>
                    <p>Novos Usuarios</p>
                  </v-col>
                  <v-col cols="3">
                    <i class="fas fa-users icon-dashboard"></i>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <h3 class="pt-2 pl-4 pb-1">Os 10 Mais Vendidos</h3>

          <v-data-table
            style="font-size: 12px !important"
            :headers="[
              {
                text: 'Nome',
                align: 'start',
                value: 'name',
                size: 10,
              },
              {
                text: 'Vendidos',
                align: 'start',
                value: 'total',
              },
            ]"
            :items="data.mostSales"
            :items-per-page="10"
            class="elevation-1"
          ></v-data-table>
          <!-- <category-chart height="350px" :data="data.mostSales" /> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" xs="5">
        <v-card>
          <h3 class="pt-2 pl-4 pb-1">Produtos x Gênero</h3>
          <gender-chart :height="pieChartHeight" :data="data.genders" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" xs="7">
        <v-card>
          <h3 class="pt-2 pl-4 pb-1">
            Produtos x Categoria
            <br class="d-block d-md-none" />
            (os 15 mais)
          </h3>
          <category-chart :height="barChartHeight" :data="data.categorys" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <v-row> </v-row>
    <!-- {{ data }} -->
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
import genderChart from "@/views/Main/Dashboard/Charts/PieChart";
import categoryChart from "@/views/Main/Dashboard/Charts/BarChart";
import openDelivery from "@/views/Main/Dashboard/components/openDelivery";

export default {
  components: {
    genderChart,
    categoryChart,
    openDelivery,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("Dashboard", ["data"]),
    ...mapState("Auth", ["user"]),
    pieChartHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "160px";
        case "sm":
          return "194px";
        default:
          return "250px";
      }
    },
    barChartHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        case "sm":
          return "150px";
        default:
          return "233px";
      }
    },
  },
  methods: {
    ...mapActions("Dashboard", ["loadChartData"]),
    // callBlinking(value) {
    //   if (value > 0) {
    //     return "blinking";
    //   }
    // },
    changeMounthNumberInName() {
      let data = new Date();
      switch (data.getMonth()) {
        case 0:
          return "Janeiro";
        case 1:
          return "Fevereiro";
        case 2:
          return "Março";
        case 3:
          return "Abril";
        case 4:
          return "Maio";
        case 5:
          return "Junho";
        case 6:
          return "Julho";
        case 7:
          return "Agosto";
        case 8:
          return "Setembro";
        case 9:
          return "Outubro";
        case 10:
          return "Novembro";
        case 11:
          return "Dezembro";
      }
    },
  },
  beforeMount() {
    this.loadChartData();
  },
};
</script>

<style scoped>
.card-default-dashboard {
  background: white;
  /* background: rgb(2, 13, 74);
  background: linear-gradient(
    90deg,
    rgba(2, 13, 74, 1) 20%,
    rgba(0, 0, 0, 1) 83%
  ); */
  color: black !important;
  border-radius: 7px !important;
}

.card-default-dashboard h2 {
  color: orange;
  /* padding: 0 0 0 5px; */
}

/* .title-dashboard-col { */
/* border-right: solid 1px grey; */
/* } */

.icon-dashboard {
  color: rgb(255, 140, 0) !important;
  font-size: 50px !important;
}

/* .blinking {
  animation: blinkingText 1.4s infinite;
} */
/* @keyframes blinkingText {
  0% {
    color: red;
  }
  60% {
    color: red;
  }
  62% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  }
} */

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 700px) {
  .text-sizing {
    font-size: 42px !important;
  }
  .sellers-card {
    font-size: 10px;
  }
}
</style>