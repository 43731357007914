
import Layout from '@/views/LayoutOrganization/Pages/LayoutOrganization/list';


const routes = [
    {
        path: '/layout/relevance',
        props: true,
        component: Layout,

    },

]

export default routes