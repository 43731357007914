import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import router from '@/router/index'
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";



const save = function ({ commit }, payload) {

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/product-group/save`, payload)
        .then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` O grupo ${payload.name} foi cadastrado com sucesso!`
                );
                router.push({ path: '/product-group' });
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const update = function ({ commit }, payload) {

    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl}/product-group/update`, payload)
        .then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` O grupo ${payload.name} foi atualizado com sucesso!`
                );
                router.push({ path: '/product-group' });
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const show = function ({ commit }, payload) {

    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl}/product-group/show`, `id=${payload}`)
        .then(res => {
            commit('SET_FORMDATA', res.data[0]);
            return res.data[0]
        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}

const setList = function ({ commit }, payload) {
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/product-group/index`, payload).then(res => {
        commit('SET_LIST', res.data)
    })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })

}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', {
        id: null,
        name: null,
        fantasy_name: null
    })
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

export default {
    save,
    update,
    show,
    setList,
    cleanItem,
    cleanErrors
}