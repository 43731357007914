const defaultPath = '/category'
const toastName = 'A Categoria'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null,
    name: null,
    deifinition_id: null,
    gender_types: null
}

import { toUpperCaseFirstWord } from "@/utils/textFunctions";
import {
    saveFunction,
    indexFunction,
    updateFunction,
    showFunction,
    diferentIndexFunction
} from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit }, payload) {
    payload.name = toUpperCaseFirstWord(payload.name)
    saveFunction(commit, defaultPath, payload, `${toastName + payload.name}`, 'full')
}

const update = function ({ commit }, payload) {
    payload.name = toUpperCaseFirstWord(payload.name)
    updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.name}`, 'full')
}

const show = function ({ commit }, payload) {
    showFunction(commit, defaultPath, payload, 'full')
}

const setList = ({ commit }) => {
    indexFunction(commit, defaultPath, null, 'full')
}

const setFilteredList = ({ commit }, id) => {

    diferentIndexFunction(commit, '/category/index-with-filter', `definition_id=${id}`, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}


export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    setFilteredList,
    update,
    show
}