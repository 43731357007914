

const setReportModal = function ({ commit }, value) {
    commit('SET_REPORTMODAL', value)
}

const setReportHeader = function ({ commit }, payload) {
    commit('SET_REPORTTITLE', payload.reportTitle)
    commit('SET_REPORTINFO', payload.reportInfo)
    commit('SET_REPORTOPTIONALINFO', payload.reportOptionalInfo)
}


export default {
    setReportModal,
    setReportHeader
}