<template>
  <span>
    <router-view />
  </span>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("Auth", ["checkLoged"])
  },
  mounted() {
    // alert(process.env.WEBSERVICE_URL);
  },
  beforeMount() {
    if (window.location.pathname !== "/login") {
      this.checkLoged();
    }
  }
};
</script>
<style>
@import "./assets/css/style.css";
</style>