
import Comment from '@/views/EcommerceAdministration/Pages/CustomerComment/list';


const routes = [
    {
        path: '/comments',
        props: true,
        component: Comment
    },
    // {
    //     path: '/form-coupon',
    //     props: true,
    //     component: FormCoupon,
    //     children: [
    //         { path: ':id', component: FormCoupon, props: true, }
    //     ]
    // },
]

export default routes