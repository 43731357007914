const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_PAYMENTDATA = (state, obj) => {
    state.paymentsData = obj
}

const SET_UPDATEBUYMODAL = (state, obj) => {
    state.updateBuyModal = obj
}

const SET_CARTMELHORENVIOMODAL = (state, obj) => {
    state.cartMelhorEnvioModal = obj
}

const SET_MELHORENVIOCARTERRORS = (state, obj) => {
    state.cartMelhorEnvioErrors = obj
}

const SET_COSTUMERADDRESSINFORMATION = (state, obj) => {
    state.costumerAddressInformation = obj
}

const SET_ANTIFRAUDRESPONSELIST = (state, obj) => {
    state.antifraudListResponse = obj
}

const SET_DIALOG = (state, obj) => {
    state.dialogPrinter = obj
}


const CLOSE_MODAL = (state, obj) => {
    state.dialogPrinter = obj
}

const OPEN_MODAL = (state, obj) => {
    state.dialogPrinter = obj
}

const SET_FIELD_RECORDING = (state, obj) => {
    state.recording = obj
}

const SET_STATUSHISTORY = (state, obj) => {
    state.status_history = obj
}

export default {
    SET_FORMDATA,
    SET_ERRORS,
    SET_PAYMENTDATA,
    SET_UPDATEBUYMODAL,
    SET_CARTMELHORENVIOMODAL,
    SET_MELHORENVIOCARTERRORS,
    SET_COSTUMERADDRESSINFORMATION,
    SET_ANTIFRAUDRESPONSELIST,
    SET_DIALOG,
    CLOSE_MODAL,
    OPEN_MODAL,
    SET_FIELD_RECORDING,
    SET_STATUSHISTORY
}