const SET_SPINNER = (state, obj) => {
    state.spinner = obj
}

const SET_DIALOG = (state, obj) => {
    state.dialog = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_PVID = (state, obj) => {
    state.pvId = obj
}

const SET_TOKENID = (state, obj) => {
    state.tokenId = obj
}

export default {
    SET_SPINNER,
    SET_DIALOG,
    SET_ERRORS,
    SET_PVID,
    SET_TOKENID
}