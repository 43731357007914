
import OrderReport from '@/views/Reports/Pages/Order/report'
import FacebookReport from '@/views/Reports/Pages/FacebookCatalog/report'
// import ReceiveDataList from '@/views/StoreSettings/Pages/ReceiveData/list'

const routes = [
    {
        path: '/reports/orders',
        props: true,
        component: OrderReport,
    },
    {
        path: '/reports/facebook-catalog',
        props: true,
        component: FacebookReport,
    }
]


export default routes
