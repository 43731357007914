<template>
  <div>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header @click="getScaleFromDb()">
          <v-card-title> Opções Extras </v-card-title>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <h3>Tempo de duração:</h3>

                <v-row no-gutters align="center">
                  <v-col class="ma-4" align="right" cols="2" sm="1">
                    <v-text-field
                      v-model="aditional.interval"
                      style="width: 50px"
                      type="number"
                      min="1"
                      max="60"
                    />
                  </v-col>
                  <v-col>
                    <h4>segundos</h4>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical v-if="$vuetify.breakpoint.name !== 'xs'" />
              <v-col cols="12" sm="6">
                <h3>Definir escala dos Banners</h3>

                <v-row class="ma-4" no-gutters align="center">
                  <v-col align="center" cols="2" sm="1"
                    ><v-text-field
                      v-model="aditional.height"
                      type="number"
                      min="1"
                      max="60"
                  /></v-col>
                  <v-col align="center" cols="2" sm="1"> X </v-col>
                  <v-col align="center" cols="2" sm="1"
                    ><v-text-field
                      v-model="aditional.width"
                      type="number"
                      min="1"
                      max="60"
                  /></v-col>
                </v-row>
                <v-btn @click="setDefaultRatio()" depressed class="ma-4">
                  <h5>Padrão</h5>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    aditional: Object,
  },
  computed: {
    ...mapState("BannerPersonalization", [
      "formBanners",
      "formBannersUpdate",
      "dialog",
      "carouselItems",
    ]),
  },
  methods: {
    ...mapActions("BannerPersonalization", ["saveItem", "index", "setModal"]),

    setScale(x, y) {
      this.aditional.frameRateHolder = y + "/" + x;
    },
    intervalValue() {
      return this.carouselItems[0].data.interval / 1000;
    },

    saveExtraOptions() {
      this.formBanners.interval = this.aditional.interval * 1000;

      this.setScale(this.aditional.width, this.aditional.height);

      this.formBanners.frameRate = this.aditional.frameRateHolder;

      this.formBannersUpdate.items = this.carouselItems[0].data.items;
      this.formBannersUpdate.itemsMobile =
        this.carouselItems[0].data.itemsMobile;
      this.formBannersUpdate.interval = this.formBanners.interval;
      this.formBannersUpdate.frameRate = this.formBanners.frameRate;
    },

    setDefaultRatio() {
      this.aditional.height = 3;
      this.aditional.width = 8;
    },

    getRealTime() {
      if (this.carouselItems[0]) {
        this.aditional.interval = this.carouselItems[0].data.interval / 1000;
      }
    },

    getScaleFromDb() {
      if (this.carouselItems[0]) {
        this.getRealTime();
        this.aditional.height = parseInt(
          this.carouselItems[0].data.frameRate.split("/")[0]
        );
        this.aditional.width = parseInt(
          this.carouselItems[0].data.frameRate.split("/")[1]
        );
      }
    },
  },
};
</script>

<style>
</style>