export default{
    user:{
        id: null,
        name: null,
        email: null,
        admin: false,
        user_type: '0',
        created_at: null,
        updated_at: null,
        people_id: null
    },
    errors:[],
}