const defaultPath = '/users'


import { baseApiUrl, catchError } from "@/global";
import axios from 'axios'
import router from '@/router'
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


const login = ({ commit }, user) => {

  spinnerChoice('full', true)

  commit('SET_ERRORS', '')
  axios
    .post(`${baseApiUrl + defaultPath}/store-login`, user)
    .then(res => {
      if (res.data.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`

        commit('SET_USER', res.data)
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data));
        router.push({ path: '/' })
      } else {
        alert('Algo deu errado, confira seus dados !!')
        this.spinnerChoice('full', false)
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.data.errors) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.response.status);
      }
    })

    .finally(() => {
      spinnerChoice('full', false)
    })
}

const cleanErrrors = ({ commit }) => {
  commit('SET_ERRORS', '')
}

const checkLoged = ({ commit }) => {
  spinnerChoice('full', true)
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

  axios
    .post(`${baseApiUrl + defaultPath}/store-check`)
    .then(res => {
      if (res.data.company_id || res.data.admin) {
        localStorage.setItem("user", JSON.stringify(res.data));
        commit('SET_USER', res.data)
      } else {
        localStorage.removeItem("token");
        // alert(`Por favor faça login !!`);
      }
    })
    .catch(e => {
      localStorage.removeItem("token");
      // alert(`Por favor faça login !!`);
      commit('SET_ERRORS', e)
      router.push({ path: '/login' })
    })

    .finally(() => {
      spinnerChoice('full', false)
    })
}

const logout = () => {
  spinnerChoice('full', true)
  axios
    .post(`${baseApiUrl + defaultPath}/logout`)
    .then(res => {
      if (res.data == 200) {
        localStorage.removeItem("token");
        router.push({ path: '/login' })
      }
    })
    .catch(e => {
      catchError(e.response.status);
    })
    .finally(() => {
      spinnerChoice('full', false)
    })
}

export default {
  login,
  cleanErrrors,
  checkLoged,
  logout
}
