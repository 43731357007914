export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        init_cep_rate: null,
        final_cep_rate: null,
        minimum_buy_value: null,
        delivery_price: null,
        active: true
    },
    list: [],
    errors: [],
    modal: false
}