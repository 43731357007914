import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'

const defaultPath = '/receive-data'
const toastName = 'Dado Pagamento'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null
}

import { saveFunction, indexFunction, updateFunction } from "@/utils/DefaultCrud/defaultsFunctions";

const setList = ({ commit }) => {
    indexFunction(commit, defaultPath, null, 'full')
}

export const save = function ({ commit }, payload) {
    saveFunction(commit, defaultPath, payload, `${toastName}`, 'full')
}

const update = function ({ commit }, payload) {
    updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.fantasy_name}`, 'full')
}

const show = function ({ commit }, payload) {

    spinnerChoice('full', true)
    axios
        .post(`${baseApiUrl + defaultPath}/show`, payload)
        .then(res => {
            if (res.data[0].id) {
                // console.log(res.data[0].extra_receive_info.pix)
                commit('SET_FORMDATA', res.data[0]);
                if (res.data[0].extra_receive_info) {
                    let extra_info = JSON.parse(res.data[0].extra_receive_info)
                    console.log(extra_info.pix)
                    commit('SET_EXTRA_INFO', extra_info)

                }
            }
        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        })
}





const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
    commit('SET_EXTRA_INFO', null)

}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}


export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show
}