<template>
  <span>
    <h1>Formulário Exceção Pagamento</h1>
    <v-form v-show="formData" class="mt-5">
      <v-container>
        <v-row>
          <v-col align="center" cols="12" sm="2">
            <v-select
              v-model="formData.installments"
              :items="installments"
              item-text="installmentName"
              item-value="value"
              label="Parcelado"
              :disabled="checkId"
            ></v-select>
            <strong class="red--text">{{ errors.installments }}</strong>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              type="number"
              v-model="formData.rate"
              label="Porcentagem"
            ></v-text-field>
            <strong class="red--text">{{ errors.rate }}</strong>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="formData.rule_type"
              :items="rules"
              item-text="ruleName"
              item-value="value"
              label="Regra"
            ></v-select>
            <strong class="red--text">{{ errors.rule_type }}</strong>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="formData.payment_type"
              :items="payments"
              item-text="paymentName"
              item-value="value"
              label="Pagamento"
              :disabled="checkId"
            ></v-select>
            <strong class="red--text">{{ errors.payment_type }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="2" cols="12">
            <v-switch
              color="amber darken-4"
              v-if="formData.id"
              v-model="formData.active"
              label="Ativo"
            ></v-switch>
          </v-col>
        </v-row>
        <v-btn
          v-if="formData.id"
          color="green darken-2 white--text"
          class="mr-4"
          @click="update(formData)"
          >Salvar Alterações</v-btn
        >
        <v-btn
          v-else
          color="green darken-2 white--text"
          class="mr-4"
          @click="saveData()"
          >Salvar</v-btn
        >
        <v-btn @click="setModal(false)" color="red" class="black--text"
          >Cancelar</v-btn
        >
      </v-container>
    </v-form>
  </span>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    receiveDataId: null,
    idExeption: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      installments: [
        { installmentName: "1X", value: 1 },
        { installmentName: "2X", value: 2 },
        { installmentName: "3X", value: 3 },
        { installmentName: "4X", value: 4 },
        { installmentName: "5X", value: 5 },
        { installmentName: "6X", value: 6 },
        { installmentName: "7X", value: 7 },
        { installmentName: "8X", value: 8 },
        { installmentName: "9X", value: 9 },
        { installmentName: "10X", value: 10 },
        { installmentName: "11X", value: 11 },
        { installmentName: "12X", value: 12 },
      ],
      rules: [
        { ruleName: "Desconto", value: 0 },
        { ruleName: "Acrescimo", value: 1 },
      ],
      payments: [
        { paymentName: "Cartão de Crédito", value: 0 },
        { paymentName: "Cartão de Débito", value: 1 },
        { paymentName: "Dinheiro", value: 2 },
        { paymentName: "Boleto", value: 3 },
        { paymentName: "Pix", value: 4 },
      ],
    };
  },
  computed: {
    ...mapState("ReceiveDataRule", ["errors", "formData"]),
    checkId() {
      if (this.idExeption) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("ReceiveDataRule", [
      "setModal",
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    saveData() {
      let data = {
        ...this.formData,
        receive_data_id: this.receiveDataId,
      };
      this.save(data);
    },
  },
  mounted() {
    if (this.idExeption) {
      this.show({ id: this.idExeption });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>