// import IntegrationList from '@/views/Integration/Stock/Pages/StockIntegration/list';
import IntegrationForm from '@/views/Integration/Stock/Pages/StockIntegration/form';



const routes = [{
    path: '/integrations/stock',
    props: true,
    component: IntegrationForm,
},
{
    path: '/form-integrations/',
    props: true,
    component: IntegrationForm,
    children: [
        { path: ':id', component: IntegrationForm, props: true, }
    ]
},

]

export default routes