<template>
  <span>
    <v-card>
      <h2 style="text-align: center">Selecione a Loja</h2>
      <v-row align="center" justify="center" style="margin: 0 auto !important">
        <v-col sm="4" cols="12" align-self="center">
          <v-autocomplete
            v-model="formDataNavbar.company_id"
            :value="company_id"
            :items="companyList"
            class="mx-3"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <v-col sm="1" cols="12" align="center">
          <v-btn
            color="green darken-2 white--text"
            class="mr-4"
            @click="
              setList(
                { id: formDataNavbar.company_id },
                saveCompany_id(formDataNavbar.company_id)
              )
            "
          >
            Listar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <br />
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),

    ...mapState("NavBarPersonalization", [
      "data",
      "formDataNavbar",
      "company_id",
    ]),
  },

  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),

    ...mapActions("NavBarPersonalization", [
      "setList",
      "cleanItem",
      "saveCompany_id",
    ]),
  },

  mounted() {
    this.setCompany();
  },
};
</script>

<style>
</style>