<template>
  <span>
    <layout-blade>
      <span>
        <filter-form :admin="adm" />
      </span>

      <div class="cards-product" v-if="list.data">
        <v-data-iterator
          :items="list.data"
          items-per-page.sync="4"
          hide-default-footer
        >
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-select
                  :items="[25, 50, 100]"
                  @input="setList()"
                  v-model="pagination.itemsPerPage"
                  style="max-width: 150px"
                >
                  <template v-slot:prepend>
                    <small>Ítens por Página: </small>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="5" class="d-none d-sm-block" />
              <v-col cols="12" sm="3"
                ><small>Total de Itens: {{ list.total }}</small></v-col
              >
            </v-row>
          </v-container>

          <v-row>
            <v-col
              :cols="setColsFromCard()"
              v-for="item in this.list.data"
              :key="item.id"
            >
              <cards :list="item" />
            </v-col>
          </v-row>
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <v-pagination
                  v-model="page"
                  @input="setList()"
                  :length="list.last_page"
                >
                </v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-data-iterator>
      </div>
    </layout-blade>
  </span>
</template>

<script>
import LayoutBlade from "@/Layouts/BladeLayoutOrganization";
// import AdminBlade from "@/Layouts/Blade";
import FilterForm from "@/views/LayoutOrganization/Pages/LayoutOrganization/filterFormOrganization.vue";
import Cards from "@/views/LayoutOrganization/Pages/LayoutOrganization/cards";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    LayoutBlade,
    FilterForm,
    Cards,
    // AdminBlade,
  },

  methods: {
    ...mapActions("LayoutOrganization", ["setList", "cleanItem"]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    setColsFromCard() {
      let qtdCols = this.filter.qtd_cards;
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        return (this.filter.qtd_cards = 6);
      } else {
        switch (qtdCols) {
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          case 6:
            return 2;
          default:
            return 3;
          // code block
        }
      }
    },
  },
  data: function () {
    return {
      adm: false,
    };
  },
  watch: {
    filter() {
      console.log("oii");
      // this.setColsFromCard(qtdCols);
    },
  },

  computed: {
    ...mapState("LayoutOrganization", ["list", "filter"]),
    ...mapState("LayoutOrganization", ["pagination"]),

    itemsPerPage: {
      get() {
        return this.$store.state.LayoutOrganization.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("LayoutOrganization/SET_ITEMSPERPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.LayoutOrganization.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("LayoutOrganization/SET_CURRENTPAGE", value);
      },
    },
    orderField: {
      get() {
        return this.$store.state.LayoutOrganization.order.field;
      },
      set(value) {
        this.$store.commit("LayoutOrganization/SET_ORDERFIELD", value);
      },
    },
    orderValue: {
      get() {
        return this.$store.state.LayoutOrganization.order.value;
      },
      set(value) {
        this.$store.commit("LayoutOrganization/SET_ORDERVALUE", value);
      },
    },

    list: function () {
      return this.$store.state.LayoutOrganization.list;
    },
  },

  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.$route.params.id) {
      this.show({ id: this.$route.params.id });
    }
    if (this.adm) {
      this.setCompany();
    }
  },
};
</script>
<style scoped>
.cards-product {
  margin: 20px auto !important;
  align-items: center;
  max-width: 1200px;
}
</style>


