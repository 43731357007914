const SET_USER = (state, obj) => {
    state.user = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}


export default{
    SET_USER,
    SET_ERRORS
}