const defaultPath = '/product'
const toastName = 'O Estoque'
import { toastAlert } from "@/utils/Alerts/toast";
// const listDefault = {
//     name: null,
//     description: null,
//     category_id: null,
//     price: 0,
//     gender: null,
//     brand_id: null,
//     id: null,
//     created_at: null,
//     updated_at: null,
//     unique_stock: false,
//     stock_control: true
// }
import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


import { toUpperCaseFirstWord } from "@/utils/textFunctions";
import { saveWithReloadFunction, updateFunction } from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit }, payload) {
    payload.name = toUpperCaseFirstWord(payload.name)
    saveWithReloadFunction(commit, defaultPath, payload, `${toastName + payload.name} foi cadastrado, agora insire o estoque para ele`, 'full')
}

const update = function ({ commit }, payload) {
    payload.name = toUpperCaseFirstWord(payload.name)
    updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.fantasy_name}`, 'full')
}

const ExportToCsv = function (data, product_name){

    const itensPerPage = data.itens_per_page; // Acessando o itens_per_page
    const nome = data.product_name
    console.log(nome)
    // const list = data.list; // Acessando a lista de produtos

    // Exibir os valores no console para conferir
    console.log("Itens por página:", product_name);

    // for(items in data){
    //     console.log("Lista de produtos:", list.name);
    // }
    console.log()
    

}

const show = function ({ commit }, payload) {
    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl + defaultPath}/show`, `product_id=${payload}`)
        .then(res => {
            if (res.data[0].actived_promotions.length > 0) {
                res.data[0].price = parseFloat(parseFloat(res.data[0].price) + parseFloat(res.data[0].actived_promotions[0].discount_value))
            }
            commit('SET_FORMDATA', res.data[0]);
            return res.data[0]
        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {

            spinnerChoice('full', false)

        })

}

const setList = function ({ commit, state }, payload) {
    spinnerChoice('full', true)
    let data = {
        ...payload,
        // showInactive: state.showInactive,
        itens_per_page: state.pagination.itemsPerPage,
        page: state.pagination.currentPage,
        ordenate_field: state.order.field,
        ordenate_type: state.order.value,
        showInactive: state.showInactive,
        product_name: state.filter.productName,
        ref: state.filter.reference,
        definition: state.filter.definition.value,
        category: state.filter.category,
        company: state.filter.client,
        subcategory: state.filter.subcategory,
        grid_filter: state.filter.grid.showGrid,
        grid_code: state.filter.grid.code,
        grid_color: state.filter.grid.color,
        grid_size: state.filter.grid.size,
    }

    axios.post(`${baseApiUrl}/product/paginate-index`, data).then(res => {
        commit('SET_LIST', res.data)
        ExportToCsv({
            itens_per_page: state.pagination.itemsPerPage, // Adiciona o itens_per_page
            list: res.data,
            page: state.pagination.currentPage,
            ordenate_field: state.order.field,
            ordenate_type: state.order.value,
            showInactive: state.showInactive,
            product_name: state.filter.productName,
            ref: state.filter.reference,
            definition: state.filter.definition.value,
            category: state.filter.category,
            company: state.filter.client,
            subcategory: state.filter.subcategory,
            grid_filter: state.filter.grid.showGrid,
            grid_code: state.filter.grid.code,
            grid_color: state.filter.grid.color,
            grid_size: state.filter.grid.size, // Envia os dados da resposta para a exportação
        })
    })
    
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', {
        name: null,
        description: null,
        category_id: null,
        price: 0,
        gender: null,
        brand_id: null,
        id: null,
        created_at: null,
        updated_at: null,
        unique_stock: false,
        stock_control: true
    })
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const exactSearchFilter = function ({ commit, state }, payload) {
    let data = {
        ...payload,
        itens_per_page: state.pagination.itemsPerPage,
        page: state.pagination.currentPage,
        ordenate_field: state.order.field,
        ordenate_type: state.order.value,
        product_name: state.exactSearchProducts.product_name,
        ref: state.exactSearchProducts.ref,
        code: state.exactSearchProducts.code,
    };

    spinnerChoice('full', true);

    axios
        .post(`${baseApiUrl + defaultPath}/exact-search`, data)
        .then(res => {
            console.log(res.data);
            // Ordenar a lista pelo campo "relevance"
            res.data.data.sort((a, b) => b.relevance - a.relevance);
            commit('SET_LIST_EXACT_SEARCH', res.data);
        })
        .catch(e => {
            catchError(e);
        })
        .finally(() => {
            spinnerChoice('full', false);
        });
};


export const stockChangeSearch = function ({ commit, state }, payload) {
    spinnerChoice('modal', true)

    console.log(payload);
    axios.post(`${baseApiUrl}/grid/stock-change`, payload)
        .then(res => {
            if (res.data.status == 201) {
                if (res.data.errors) {
                    commit('SET_ERRORS', res.data.errors)

                }
                sessionStorage.setItem(
                    "toastMsg",
                    ` ${toastName}, foi cadastrado com sucesso !!`
                );
                commit('SET_ERRORS', null)
                exactSearchFilter({ commit, state }, state.exactSearchProducts)
                toastAlert('success')

            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

const cleanList = function ({ commit }) {
    commit('CLEAR_LIST_EXACT_SEARCH', {
        product_name: null,
        ref: null,
        code: null,
        grid_id: null
    })
}



export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show,
    exactSearchFilter,
    stockChangeSearch,
    cleanList,
    ExportToCsv
}