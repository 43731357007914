import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
// import { v4 as uuidv4 } from 'uuid';


import { toastAlert } from "@/utils/Alerts/toast";

let facebookKeys =
    [
        {
            key_name: "facebook_pixel_token",
        },
        {
            key_name: "conversion_api_facebook",
        }
    ];

export const save = function ({ commit, state }, payload) {
    commit('SET_ERRORS', null)

    let data = {
        key_name: payload.key_name,
        value: payload.key_value,
        ...payload
    };
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {

                commit('SET_ERRORS', '')

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
                console.log(state.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            spinnerChoice('full', false)

        })
}

// const setList = function ({ commit }) {

//     spinnerChoice('full', true)

//     axios.post(`${baseApiUrl}/integration-key-value/show`, facebookKeys)
//         .then(res => {
//             if (res.data.status == 201) {
//                 commit('SET_KEYFACEBOOKID', res.data.id)
//             }
//         })
//         .catch(e => {
//             catchError(e.status)
//         })
//         .finally(() => {
//             spinnerChoice('full', null)
//         })
// }

const check = function ({ commit }, payload) {
    let data = {
        key_name: payload.key_name,
        ...payload
    }
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_KEYFACEBOOKID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })
        .finally(() => {
            spinnerChoice('full', null)
        })
}

const update = function ({ commit, state }, payload) {
    commit('SET_ERRORS', null)
    spinnerChoice('full', true)
    var key_id = state.facebook.key_id;

    let data = {
        id: key_id,
        ...payload
    }
    console.log(data);
    axios.post(`${baseApiUrl}/integration-key-value/update`, data)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `Token alterado com sucesso!`
                );
                toastAlert('success')
                commit('SET_FACEBOOKCHANGEKEY', false)
            } else {
                commit('SET_FACEBOOKERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_FACEBOOKERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', null)

        })
}

const cleanForm = function ({ commit }) {
    commit('SET_CLEANFORMDATA', '')
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}


export default {
    save, cleanErrors, check, update, cleanForm

}