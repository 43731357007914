<template>
  <span>
    <v-list dense>
      <span v-for="menu of menus" :key="menu.title">
        <router-link
          style="text-decoration: none"
          :to="menu.route"
          v-if="menuRule(menu.admin)"
        >
          <v-list-item @click="menu.show = !menu.show">
            <v-list-item-action>
              <v-icon class="left-menu-icons">{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-left-list">
                {{ menu.name }}
                <v-icon
                  v-if="menu.children"
                  class="icon-active"
                  color="#ffbf14"
                  >{{ chevronChange(menu.show) }}</v-icon
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span v-if="menu.show">
            <v-list>
              <div v-for="submenu of menu.children" :key="submenu.name">
                <v-list-item
                  :to="submenu.route"
                  text
                  color="#000"
                  v-if="menuRule(submenu.admin)"
                >
                  <v-icon v-if="submenu.icon" class="left-submenu-icons">{{
                    submenu.icon
                  }}</v-icon>
                  <v-list-item-title class="submenu-left-text">{{
                    submenu.name
                  }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </span>
        </router-link>
      </span>
    </v-list>
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Auth", ["user"]),
  },
  menuRule(admin) {
    if (!admin) {
      return true;
    }
    if (admin == this.user.admin) {
      return true;
    } else {
      return false;
    }
  },
  data: () => ({
    menus: [
      {
        route: "/",
        name: "Voltar para Home",
        icon: "fas fa-home",
        admin: false,
      },
      {
        route: "",
        admin: true,
        name: "Configurações",
        icon: "fas fa-cog",
        show: false,
        children: [
          {
            route: "/integrations/settings/add-key-name",
            name: "Cadastrar Nova Integração",
            icon: "far fa-dot-circle",
            admin: true,
          },
        ],
      },
      {
        route: "",
        admin: false,
        name: "Estoque",
        icon: "fas fa-boxes",
        show: false,
        children: [
          {
            route: "/integrations/stock",
            name: "Integrar Estoque",
            icon: "far fa-dot-circle",
            admin: false,
          },
        ],
      },
      {
        route: "",
        admin: false,
        name: "Marketing",
        icon: "fas fa-headset",
        show: false,
        children: [
          {
            route: "/integrations/marketing/facebook",
            name: "Facebook",
            icon: "far fa-dot-circle",
            admin: false,
          },

          {
            route: "/integrations/marketing/google",
            name: "Google",
            icon: "far fa-dot-circle",
            admin: false,
          },
        ],
      },
    ],
    drawer: null,
  }),
  methods: {
    menuRule(admin) {
      if (!admin) {
        return true;
      }
      if (admin == this.user.admin) {
        return true;
      } else {
        return false;
      }
    },
    chevronChange(value) {
      if (value == false) {
        return "mdi-chevron-right";
      } else {
        return "mdi-chevron-down";
      }
    },
  },
};
</script>

<style>
.left-submenu-icons {
  font-size: 10px !important;
  color: white !important;
  margin-left: 25px;
}

.left-menu-icons {
  font-size: 20px !important;
  color: white !important;
  text-decoration: none !important;
  z-index: 100;
  margin-right: 0px !important;
  padding: 0px !important;
  opacity: 1 !important;
  /* font-weight: normal !important; */
}

.text-left-list {
  /* font-weight: bold !important; */
  color: white;
  opacity: 1;
  font-size: 17px !important;
}

.submenu-left-text {
  color: white;
  padding-left: 17px;
}

@media only screen and (max-width: 600px) {
  .text-left-list {
    font-size: 12px !important;
  }
  .left-menu-icons {
    font-size: 18px !important;
  }
}
</style>