<template>
  <div style="padding: 16px">
    <form>
      <h1>Formulário Carrinho Melhor Envio</h1>
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="cartMelhorEnvioFormData.height"
            label="Alt. Emb(cm)"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="cartMelhorEnvioFormData.width"
            label="Largura Emb(cm)"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="cartMelhorEnvioFormData.length"
            label="Comp. Emb(cm)"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="cartMelhorEnvioFormData.document_fiscal_number"
            label="Numero NF(Opcional p/ Correios)"
          ></v-text-field>
        </v-col>

        <v-col cols="5">
          <label>
            <span>Valor da Nota/Seguro</span>(R$)
            <money
              label="Valor da Nota/Seguro"
              class="money"
              v-bind="money"
              v-model.lazy="cartMelhorEnvioFormData.insurance_value"
            ></money>
          </label>
        </v-col>
        <v-col cols="5">
          <label>
            <span>Peso Embalagem</span>(kg)
            <money
              label="Valor da Nota/Seguro"
              class="money"
              v-bind="money"
              v-model.lazy="cartMelhorEnvioFormData.weight"
            ></money>
          </label>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-switch
            v-model="cartMelhorEnvioFormData.own_hand"
            label="Retirada Mão Própria"
          ></v-switch>
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="cartMelhorEnvioFormData.non_commercial"
            label="Envio Não Comercial"
          ></v-switch>
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="cartMelhorEnvioFormData.receipt"
            label="Aviso Recebimento"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="cartMelhorEnvioFormData.shipping"
            :items="shipping_services"
            item-text="name"
            item-value="value"
            label="Cotação de Envio"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right" cols="12">
          <v-btn
            color="green"
            @click="
              addDeliveryInMelhorEnvioCart({
                ...cartMelhorEnvioFormData,
                buy_id: $route.params.id,
              })
            "
            >Enviar</v-btn
          >
          <v-btn
            color="red"
            class="ml-4 mr-4"
            @click="setCartMelhorEnvioModal(false)"
            >Cancelar</v-btn
          >
        </v-col>
      </v-row>
      <span v-for="(error, index) of cartMelhorEnvioErrors" :key="index">
        <p class="red--text">* {{ error }} *</p>
      </span>
    </form>
  </div>
</template>

<script>
import { Money } from "v-money";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Money,
  },
  data: function () {
    return {
      money: {},
      shipping_services: [
        {
          name: "Padrão",
          value: null,
        },
        {
          name: "Sedex Centralizado",
          value: 29,
        },
        {
          name: "PAC Centralizado",
          value: 28,
        },
      ],
    };
  },
  computed: {
    ...mapState("DetailOrder", [
      "cartMelhorEnvioFormData",
      "cartMelhorEnvioErrors",
    ]),
  },
  methods: {
    ...mapActions("DetailOrder", [
      "setCartMelhorEnvioModal",
      "addDeliveryInMelhorEnvioCart",
    ]),
  },
};
</script>

<style>
</style>