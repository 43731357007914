


import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";
// import { updateFunction } from "@/utils/DefaultCrud/defaultsFunctions";
const defaultPath = '/product'

// const toastName = 'Relevância'

const setList = function ({ state, commit }, payload) {
    spinnerChoice('full', true)
    let data = {
        ...payload,
        // showInactive: state.showInactive,
        itens_per_page: state.pagination.itemsPerPage,
        page: state.pagination.currentPage,
        ordenate_field: state.filter.ordenate_field,
        ordenate_type: state.filter.ordenate_type,
        showInactive: state.showInactive,
        definition: state.filter.definition,
        category: state.filter.category,
        subcategory: state.filter.subcategory,
        qtd_cards: state.filter.qtd_cards,
        show_img: state.filter.show_img,
        promotionRule: state.filter.promotionRule,
        gender: state.filter.gender,
        company: state.filter.company_id,
    }

    // console.log(data);

    axios.post(`${baseApiUrl}/product/paginate-index`, data).then(res => {

        // console.log(res.data.data.length)
        let sizeList = res.data.data;

        if (sizeList.length == 0) {
            sessionStorage.setItem(
                "toastMsg",
                `Nenhum produto encontrado, verificar nome dos filtros`
            );

            toastAlert('error')
        }
        commit('SET_LIST', res.data)
    })
        .catch(e => {
            catchError(e)
        })

        .finally(() => {
            spinnerChoice('full', false)
        })
}
const alterRelevance = function ({ commit, state }, payload) {

    console.log(payload);

    spinnerChoice('full', true)

    axios
        .post(`${baseApiUrl + defaultPath}/update`, payload)
        .then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    `relevância foi alterada com sucesso!`
                );
                setList({ commit, state }, state.filter)
                toastAlert('success');
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', false)

        })


}


const cleanItem = function ({ commit }) {
    commit('SET_CLEAN_LIST', {
        definition: "",
        category: "",
        subcategory: "",
        qtd_cards: 1,
        show_img: false,
        ordenate_field: "relevance",
        ordenate_type: "DESC",
        promotionRule: null,
        gender: null,
        company_id: ""
    })
}



export default {

    cleanItem,
    // cleanErrors,
    setList,
    alterRelevance
}