import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
// import router from '@/router/index'
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";

//Melhor Envio
const saveMelhorEnvioToken = function ({ commit }, payload) {
    commit('SET_MELHORENVIOERRORS', null)
    let data = {
        key_name: 'melhor_envio_token',
        ...payload
    }
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {
                check({ commit }, {
                    company_id: data.company_id
                })
                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_MELHORENVIOERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_MELHORENVIOERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', null)

        })
}

const updateMelhorEnvioToken = function ({ commit }, payload) {
    commit('SET_MELHORENVIOERRORS', null)
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `Token alterado com sucesso!`
                );
                toastAlert('success')
                commit('SET_MELHORENVIOCHANGEKEY', false)
            } else {
                commit('SET_MELHORENVIOERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_MELHORENVIOERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('full', null)

        })
}

const check = function ({ commit }, payload) {
    let data = {
        key_name: 'melhor_envio_token',
        ...payload
    }
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_MELHORENVIOID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })
        .finally(() => {
            spinnerChoice('full', null)
        })
}

const resetMelhorEnvio = function ({ commit }) {
    commit('SET_MELHORENVIOERRORS', null),
        commit('SET_MELHORENVIOID', null),
        commit('SET_MELHORENVIOCHANGEKEY', false)
}

const setChangeToken = function ({ commit }, value) {
    commit('SET_MELHORENVIOCHANGEKEY', value)
}

//END MELHOR ENVIO




export default {
    saveMelhorEnvioToken,
    check,
    updateMelhorEnvioToken,
    resetMelhorEnvio,
    setChangeToken
}