<template>
  <v-card class="py-4 px-4">
    <v-simple-table v-if="list.length >= 1">
      <tbody>
        <v-expansion-panels class="size-algn">
          <v-expansion-panel-header
            expand-icon=""
            class="pr-12"
            :ripple="false"
          >
            <v-row align="">
              <v-col
                v-for="header in headers"
                :key="header.value"
                :cols="header.cols"
                :sm="header.sm"
                :align="header.align"
              >
                <v-btn
                  v-if="
                    $vuetify.breakpoint.name !== 'xs' &&
                    $vuetify.breakpoint.name !== 'sm'
                  "
                  text
                  small
                  @click="
                    clearIcon(header),
                      toggleOrder(header),
                      sort(header),
                      setList()
                  "
                >
                  {{ header.name }}
                  <v-icon small>
                    {{ header.sortIcon }}
                  </v-icon>
                </v-btn>

                <v-btn
                  v-else
                  text
                  small
                  @click="
                    clearIcon(header),
                      toggleOrder(header),
                      sort(header),
                      setList()
                  "
                >
                  {{ header.namesm }}
                  <v-icon small>
                    {{ header.sortIcon }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col style="margin-top: -20px; margin-bottom: -10px">
                <v-text-field label="Pesquisar" v-model="search" />
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel v-for="itens in filteredItems" :key="itens">
            <v-expansion-panel-header>
              <v-row align="center">
                <v-col sm="2" cols="4" align="center">
                  <v-text-field
                    @click.native.stop
                    label=""
                    v-model="itens.data.ordenate"
                    :value="itens.data.ordenate"
                    class="ordenate"
                    type="number"
                    min="0"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>

                <v-col sm="4" cols="4" align="center">
                  {{ itens.data.name }}
                </v-col>

                <v-col sm="2" cols="4" align="center">
                  <v-switch
                    style="width: 45px"
                    @click.native.stop
                    v-model="itens.active"
                  />
                </v-col>

                <!-- Buttons -->

                <v-col>
                  <v-row>
                    <v-col cols="3" align="center">
                      <v-btn
                        @click.native.stop
                        fab
                        elevation="0"
                        small
                        @click="deleteItem(itens.id)"
                      >
                        <v-icon> mdi-trash-can </v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="3" align="center">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="createChildren(itens)"
                            @click.native.stop
                            fab
                            elevation="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar Subcategoria</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3" align="center">
                      <v-btn
                        @click.native.stop
                        fab
                        elevation="0"
                        small
                        @click="editItem(itens)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="3" align="center">
                      <v-btn
                        @click.native.stop
                        fab
                        elevation="0"
                        small
                        @click="saveItem(itens)"
                      >
                        <v-icon> mdi-content-save </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row style="box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.3)">
                <v-col
                  v-for="header in headerschildren"
                  :key="header.value"
                  :sm="header.sm"
                  :cols="header.cols"
                  :md="header.md"
                  :lg="header.lg"
                  :align="header.align"
                >
                  <v-btn
                    v-if="
                      $vuetify.breakpoint.name !== 'xs' &&
                      $vuetify.breakpoint.name !== 'sm'
                    "
                    text
                    small
                    min-width="0"
                    @click="
                      clearIcon(header),
                        toggleOrder(header),
                        sort(header),
                        setList()
                    "
                  >
                    {{ header.name }}
                    <v-icon small>
                      {{ header.sortIcon }}
                    </v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    text
                    small
                    min-width="0"
                    @click="
                      clearIcon(header),
                        toggleOrder(header),
                        sort(header),
                        setList()
                    "
                  >
                    {{ header.namesm }}
                    <v-icon small>
                      {{ header.sortIcon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-expansion-panels
                v-for="item in itens.childrens"
                :key="item.id"
              >
                <v-row align="center" class="mt-3 mb-3">
                  <v-col sm="2" cols="3">
                    <v-text-field
                      :value="item.data.ordenate"
                      v-model="item.data.ordenate"
                      class="ordenate-children"
                      type="number"
                      min="0"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col sm="4" cols="5" align="center">
                    {{ item.data.name }}
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col cols="4" align="right">
                        <v-btn
                          @click.native.stop
                          fab
                          elevation="0"
                          small
                          @click="deleteItem(item.id)"
                        >
                          <v-icon> mdi-trash-can </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="4" align="center">
                        <v-btn
                          @click.native.stop
                          fab
                          elevation="0"
                          small
                          @click="editItem(item)"
                        >
                          <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                      </v-col>

                      <v-col cols="4" align="left">
                        <v-btn
                          @click.native.stop
                          fab
                          elevation="0"
                          small
                          @click="saveItemChildren(item)"
                        >
                          <v-icon> mdi-content-save </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </tbody>
    </v-simple-table>

    <div v-else>
      <center>
        <v-icon> mdi-emoticon-sad-outline </v-icon>
        <h3>Nenhum item encontrado</h3>
      </center>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      headers: [
        {
          name: "Ordenação",
          namesm: "Ord.",
          value: "ordenate",
          cols: "4",
          sm: "2",
          align: "center",
          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Nome",
          namesm: "Nome",
          value: "name",
          cols: "4",
          sm: "4",
          align: "center",
          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Ativo",
          namesm: "Ativo",
          value: "active",
          cols: "4",
          sm: "2",
          align: "center",
          sortOrder: "",
          sortIcon: "",
        },
      ],

      headerschildren: [
        {
          name: "Ordenação",
          namesm: "Ord.",
          value: "ordenate",
          sm: "2",
          cols: "3",
          align: "center",
          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Nome",
          namesm: "Nome",
          value: "name",
          sm: "4",
          cols: "5",
          align: "center",
          sortOrder: "",
          sortIcon: "",
        },
      ],
      alertModal: true,
      formData: { active: true },
      search: "",
    };
  },

  computed: {
    ...mapState("NavBarPersonalization", ["list", "alert"]),
    ...mapState("NavBarPersonalization", ["formDataNavbar"]),

    filteredItems() {
      return this.list.filter((item) => {
        return (
          item.data.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },

  props: {
    adm: Boolean,
  },

  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("NavBarPersonalization", [
      "openModal",
      "editItem",
      "createChildren",
      "save",
      "updatedItem",
      "deleteItem",
      "orderBy",
    ]),
    ...mapActions("NavBarPersonalization", ["setList", "cleanItem"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },

    saveItem(item) {
      this.formData = {
        id: item.id,
        name: item.data.name,
        route: item.data.route,
        ordenate: item.data.ordenate,
        params: item.data.params,
        active: item.active,
        type: item.type,
      };
      this.updatedItem(this.formData);
    },

    saveItemChildren(item) {
      this.formData = {
        id: item.id,
        name: item.data.name,
        route: item.data.route,
        ordenate: item.data.ordenate,
        params: item.data.params,
        relationship: item.relationship,
      };
      this.updatedItem(this.formData);
    },
  },

  watch: {
    alert() {
      if (this.alert == true) {
        setTimeout(() => {
          this.alertModal = false;
        }, 5000);
      }
    },
  },

  mounted() {
    if (this.adm == false) {
      this.setList();
    }
  },
};
</script>

<style scoped>
.size-algn {
  margin: 0 auto;
  width: 98%;
}

.size-algn2 {
  margin: auto 20px;
}

.ordenate {
  margin-top: -15px;
}

.ordenate-children {
  margin-top: -15px;
}
</style>