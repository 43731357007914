<template>
  <admin-blade>
    <filters />
    <report />
  </admin-blade>
</template>

<script>
// import { mapActions } from "vuex";
import AdminBlade from "@/Layouts/Blade";
import Filters from "@/views/Reports/Pages/Order/Components/filters";
import Report from "@/views/Reports/Pages/Order/Components/report";

export default {
  methods: {
    // ...mapActions("OrderReport", ["showReport"]),
  },
  components: {
    AdminBlade,
    Filters,
    Report,
  },
};
</script>

<style scoped>
.main-class {
  background: white !important;
  height: 100% !important;
}

.filter-class {
  border-bottom: solid grey 1px;

  padding: 5px 30px 0 30px;
}
.filter-col-class {
  padding-top: 2px !important;
  padding-bottom: 0px !important;
}
</style>