export default {
    formData: [],
    costumerAddressInformation: [],
    errors: [],
    paymentsData: [],
    updateBuyModal: false,
    buyStatusList: [
        { name: 'Pendente', status: 0 },
        { name: 'Pagamento em Analise', status: 1 },
        { name: 'Separação pra Entrega', status: 2 },
        { name: 'Em Transito', status: 3 },
        { name: 'Entregue', status: 4 },
        { name: 'Expirado', status: 5 },
        { name: 'Cancelado', status: 6 },
        { name: 'Pagamento Recusado', status: 7 },
    ],
    antifraudListResponse: [
        // { "created_at": '10/02/2003', json: { "code": "950", "status": "NVO", "score": 96.56 } },
        // { "created_at": "05/12/2012", json: { "packageID": "9971e710-4941-4b8f-8852-f5b58bd226e3", "orders": [{ "code": "956", "status": "NVO", "score": null }] } }
    ],

    antifraudListStatus: [
        { name: 'APA', description: 'Pedido aprovado automaticamente' },
        { name: 'APM', description: 'Pedido aprovado manualmente' },
        { name: 'RPM', description: 'Pedido Reprovado' },
        { name: 'AMA', description: 'Pedido está em fila para análise' },
        { name: 'NVO', description: 'Pedido importado e não classificado Score pela analisadora' },
        { name: 'SUS', description: 'Pedido Suspenso por suspeita de fraude' },
        { name: 'CAN', description: 'Pedido cancelado pelo Cliente' },
        { name: 'FRD', description: 'Fraude Confirmada' },
        { name: 'RPA', description: 'Pedido Reprovado Automaticamente' },
        { name: 'RPP', description: 'Reprovação Por Política' },
        { name: 'APP', description: 'Aprovação Por Política' },
    ],
    paymentTypeList: [
        { name: 'Cartao Credito', status: 0 },
        { name: 'Cartão Débito', status: 1 },
        { name: 'Dinheiro', status: 2 },
    ],
    cartMelhorEnvioModal: false,
    cartMelhorEnvioFormData: {
    },
    cartMelhorEnvioErrors: {},
    dialogPrinter: false,
    recording: false,
    status_history: []
}