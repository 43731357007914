// import IntegrationList from '@/views/Integration/Stock/Pages/StockIntegration/list';
import FaceForm from '@/views/Integration/Marketing/Pages/Facebook/form';
import GoogleForm from '@/views/Integration/Marketing/Pages/Google/form';



const routes = [
    {
        path: '/integrations/marketing/facebook',
        props: true,
        component: FaceForm,
    },

    {
        path: '/integrations/marketing/google',
        props: true,
        component: GoogleForm,
    },


]

export default routes