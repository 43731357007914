const defaultPath = '/product-image'
const toastName = 'A Imagem'
const listDefault = {
  id: null,
  created_at: null,
  updated_at: null,
  images: [],
  product_id: null
}

import { toastAlert } from "@/utils/Alerts/toast";
import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { indexFunction, updateFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit }, payload) {
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/save`, payload)
    .then(res => {
      if (res.data.status == 201) {
        commit('SET_FORMDATA', listDefault)
        sessionStorage.setItem(
          "toastMsg",
          ` ${toastName}, foi cadastrada com sucesso !!`
        );
        setModal({ commit }, false)
        setList({ commit }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

export const changeColor = function ({ commit }, payload) {
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/change-color`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          `a imagem foi apagada com sucesso !!`
        );
        setEditModal({ commit }, false)
        setList({ commit }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

export const deleteItem = function ({ commit }, payload) {
  spinnerChoice('modal', true)

  axios.post(`${baseApiUrl + defaultPath}/delete`, payload)
    .then(res => {
      if (res.data.status == 201) {
        sessionStorage.setItem(
          "toastMsg",
          `a imagem foi apagada com sucesso !!`
        );
        setEditModal({ commit }, false)
        setList({ commit }, payload.product_id)
        toastAlert('success')
      } else {
        commit('SET_ERRORS', res.data.errors)
      }
    })
    .catch(e => {
      if (e.response.status == 422) {
        commit('SET_ERRORS', e.response.data.errors)
      } else {
        catchError(e.status)
      }
    })
    .finally(() => {

      spinnerChoice('modal', false)

    })
}

const update = function ({ commit }, payload) {
  updateFunction(commit, defaultPath, payload, `${toastName + ' ' + payload.fantasy_name}`, 'full')
}

const show = function ({ commit }, payload) {
  showFunction(commit, defaultPath, payload, 'full')
}

const setList = ({ commit }, payload) => {
  indexFunction(commit, defaultPath, `product_id=${payload}`, 'full')
}

const cleanItem = function ({ commit }) {
  commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
  commit('SET_ERRORS', '')
}

const setModal = function ({ commit }, value) {
  commit('SET_MODAL', value)
}

const setEditModal = function ({ commit }, value) {
  commit('SET_EDITMODAL', value)
}


export default {
  save,
  cleanItem,
  cleanErrors,
  setList,
  update,
  show,
  setModal,
  setEditModal,
  deleteItem,
  changeColor
}