<template>
  <span>
    <h1 class="title-topo">Exceções de Entregas</h1>
    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <v-data-table
      class="default-table"
      :headers="headers"
      :items="list.data"
      :search="search"
      :footer-props="footer"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="edit(item.id)" title="Edit"
          >mdi-pencil</v-icon
        >
      </template>
      <template v-slot:item.minimum_buy_value="{ item }">
        <span>R$ {{ item.minimum_buy_value }}</span>
      </template>
      <template v-slot:item.delivery_price="{ item }">
        <span>R$ {{ item.delivery_price }}</span>
      </template>
      <template v-slot:item.active="{ item }">
        <span v-if="item.active">
          <strong>Ativo</strong>
        </span>
        <span v-else>
          <strong>Inativo</strong>
        </span>
      </template>
    </v-data-table>

    <v-row justify="end">
      <v-col cols="4">
        <v-btn @click="registerNewException()" color="green" class="black--text"
          >Cadastrar Nova Exceção</v-btn
        >
      </v-col>
    </v-row>

    <spinner-dialog persistent :dialog="modal">
      <v-card class="dialog-default">
        <delivery-restriction-form
          :companyId="companyId"
          v-if="modal"
          :idExeption="idExeption"
        />
      </v-card>
    </spinner-dialog>
  </span>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import DeliveryRestrictionForm from "@/views/CompanySettings/Pages/DeliverySettings/Components/DeliveryRestriction/form";

export default {
  components: { spinnerDialog, DeliveryRestrictionForm },
  props: { companyId: Number },
  data: function () {
    return {
      idExeption: null,
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        {
          text: "Tipo",
          value: "type",
          class: "black--text",
        },
        {
          text: "Prazo",
          value: "term",
          class: "black--text",
        },
        {
          text: "Cep Inicial",
          value: "init_cep_rate",
          class: "black--text",
        },
        {
          text: "Cep Final",
          value: "final_cep_rate",
          class: "black--text",
        },
        {
          text: "Valor Minimo Compra",
          value: "minimum_buy_value",
          class: "black--text",
        },
        {
          text: "Valor Entrega",
          value: "delivery_price",
          class: "black--text",
        },
        {
          text: "Ativo",
          value: "active",
          class: "black--text",
        },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("DeliveryRestriction", ["list", "modal"]),
  },

  methods: {
    ...mapActions("DeliveryRestriction", ["setList", "setModal"]),
    registerNewException() {
      this.idExeption = null;
      this.setModal(true);
    },
    edit(item) {
      this.idExeption = item;
      this.setModal(true);
    },
  },
  mounted() {
    this.setList(this.companyId);
    toastAlert("success");
  },
};
</script>