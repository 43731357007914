<template>
  <admin-blade iconBtnTop="fas fa-cart-plus" routeBtnTop="/product">
    <span>
      <!-- <h1>TEste {{ productGroupList }}</h1> -->
      <v-form class="mt-5" ref="form" id="formProduct" v-model="valid">
        <div class="default-form" id="formProduct">
          <h3 class="title-topo d-block d-sm-none">Formulario Produto</h3>
          <h1 class="title-topo d-none d-sm-block">Formulario Produto</h1>
          <v-row>
            <v-col sm="6" md="3" cols="12">
              <v-autocomplete
                @change="defineCategory(formData.definition_id)"
                v-model="formData.definition_id"
                :items="definitionList"
                item-text="name"
                item-value="id"
                label="Definição *"
                color="amber darken-4"
                :rules="rulesFunction('Definição', 0)"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-autocomplete
                @change="setSubCategory(formData.category_id)"
                v-model="formData.category_id"
                :items="categoryList"
                item-text="name"
                item-value="id"
                label="Categoria *"
                color="amber darken-4"
                :rules="rulesFunction('Categoria', 0)"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-autocomplete
                v-model="formData.subcategory_id"
                :items="subCategoryList"
                item-text="subcategory_name"
                item-value="id"
                label="Sub Categoria *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-autocomplete
                v-model="formData.brand_id"
                :items="brandList"
                item-text="name"
                item-value="id"
                label="Marca *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="5" cols="12" v-if="adm">
              <v-autocomplete
                v-model="formData.company_id"
                :items="companyList"
                item-text="fantasy_name"
                item-value="id"
                label="Loja *"
                @change="setProductGroupList(formData.company_id)"
                color="amber darken-4"
                :rules="rulesFunction('Loja', 0)"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.company_id }}</strong>
            </v-col>
            <v-col sm="6" md="3" cols="12">
              <v-text-field
                v-model="formData.name"
                :rules="rulesFunction('Nome', 2)"
                label="Nome *"
              ></v-text-field>
              <strong class="red--text">{{ errors.name }}</strong>
            </v-col>
            <v-col sm="4" md="2" cols="12">
              <v-text-field
                v-model="formData.ref"
                label="Referência *"
              ></v-text-field>
              <strong class="red--text">{{ errors.ref }}</strong>
            </v-col>
            <v-col sm="4" md="2" cols="12">
              <v-autocomplete
                v-model="formData.gender"
                :items="genders"
                item-text="name"
                item-value="value"
                label="Genero *"
                color="amber darken-4"
                :rules="rulesFunction('Genero', 0)"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.gender }}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                :rules="rulesFunction('Descrição', 2)"
                label="Descrição"
                v-model="formData.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                label="Descrição(Opcional)"
                v-model="formData.optional_description"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Descrição Catálogo Facebook"
                v-model="formData.facebook_description"
              >
          </v-textarea>
            </v-col>
          </v-row>

          <v-row>
              <v-col>
                <div style="font-size: 13px;">
              <h4>A descrição de cada produto para o catálogo do facebook deve:</h4>
                    <ul>
                      <li>Conter texto simples.</li>
                      <li>Ter entre 30 e cinco mil caracteres.</li>
                      <li>Seguir a gramática, a pontuação e a ortografia corretas. Escrevas frases no formato comum, ou seja, somente a primeira letra da primeira palavra deve estar em maiúsculo.</li>
                    </ul><br>
                    <h4>As descrições do produto não devem incluir:</h4>
                    <ul>
                      <li>Texto em HTML ou links.</li>
                      <li>Conteúdo em Rich Text, como listas de itens ou vários parágrafos.</li>
                      <li>Pontuação em excesso, como “!!!”, ou descrições com todas as LETRAS MAIÚSCULAS ou minúsculas. As pessoas podem associar isso com spam ou marketing enganoso.</li>
                      <li>Informações de envio ou da empresa. Inclua esses dados nos campos adequados do produto.</li>
                      <li>Telefones ou endereços de email.</li>
                    </ul>
            </div>
              </v-col>
            </v-row>
          <strong class="red--text mb-5">{{ errors.description }}</strong>
          <br />
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="formData.relevance"
                :items="relevanceList()"
                item-text="value"
                item-value="value"
                label="Relevancia"
                color="amber darken-4"
              ></v-autocomplete>
              <strong class="red--text">{{ errors.relevance }}</strong>
            </v-col>

            <v-col cols="12">
              <label>
                <strong>Preço</strong>(R$)
                <span class="mr-5"></span>
                <money
                  :rules="rulesFunction('Preço', 3)"
                  class="money"
                  v-bind="money"
                  v-model.lazy="formData.price"
                ></money>
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 class="text-center mt-5">Dados Produto</h3>
              <h3 class="red--text text-center">
                *Não Utilizar Pontos ou Virgulas
              </h3>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.weight_packed"
                label="Peso(Gramas)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.length_packed"
                label="Comprimento(cm)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.width_packed"
                label="Largura(cm)*"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                type="number"
                v-model="formData.height_packed"
                label="Altura(cm)*"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="12">
              <p v-if="formData.updated_at">
                <strong>Ultima Modificação:</strong>
                {{ formData.updated_at }}
              </p>
            </v-col>
            <v-col md="6" cols="12">
              <p v-if="formData.created_at">
                <strong>Data Cadastro:</strong>
                {{ formData.created_at }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="formData.id" sm="3" md="2" cols="12">
              <v-switch
                color="amber darken-4"
                v-model="formData.active"
                label="Ativo"
              ></v-switch>
            </v-col>
            <v-col sm="4" md="2" cols="12">
              <v-switch
                color="amber darken-4"
                v-model="formData.kids"
                label="Infantil"
              ></v-switch>
            </v-col>
            <v-col sm="5" md="3" cols="12">
              <v-autocomplete
                v-model="formData.product_groups_id"
                :items="groupList"
                item-text="name"
                item-value="id"
                label="Grupo *"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col sm="3" md="3" cols="12" class="mt-1">
              <v-switch
                color="amber darken-4"
                v-model="formData.stock_control"
                label="Controlar Estoque"
              ></v-switch>
            </v-col>
            <v-col sm="4" md="3" cols="12" class="mt-1">
              <v-switch
                color="amber darken-4"
                v-model="formData.unique_stock"
                label="Produto sem Grade"
              ></v-switch>
            </v-col>
            <v-col sm="5" md="3" cols="12" class="">
              <v-autocomplete
                v-model="formData.confection_deadline"
                :items="confectiondeadlineList()"
                item-text="value"
                item-value="value"
                label="Tempo Confecção (em dias)"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
            <v-col sm="5" md="3" cols="12" class="">
              <v-autocomplete
                v-model="formData.delivery_rule"
                :items="deliveryRule"
                item-text="name"
                item-value="value"
                label="Regra Frete"
                color="amber darken-4"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" :align="align">
              <v-btn
                v-if="formData.id"
                class="change-btn"
                @click="updateContent()"
                >Salvar Alterações</v-btn
              >
              <v-btn
                v-else
                :disabled="!valid"
                class="register-btn"
                @click="saveContent()"
                >Salvar</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div
          v-if="formData.id && !formData.unique_stock"
          class="mt-2 default-form"
          id="formProduct"
        >
          <grid-list v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <image-list :companyId="formData.company_id" v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <promotion-list v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <product-atribute v-if="formData.id" />
        </div>
        <div v-if="formData.id" class="mt-2 default-form" id="formProduct">
          <payment-rules v-if="formData.id" />
        </div>
      </v-form>
    </span>
  </admin-blade>
</template>

<script>
import ProductAtribute from "@/views/ProductSession/Pages/Product/ProductAtribute/list";
import ImageList from "@/views/ProductSession/Pages/Product/Image/list";
import GridList from "@/views/ProductSession/Pages/Product/Grid/list";
import PromotionList from "@/views/ProductSession/Pages/Product/Promotion/list";
import PaymentRules from "@/views/ProductSession/Pages/Product/ProductDiscountByPaymentMethod/list";
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import AdminBlade from "@/Layouts/Blade";
import { mapState, mapActions } from "vuex";
import { Money } from "v-money";
// import { toastAlert } from "@/utils/Alerts/toast";

export default {
  components: {
    ImageList,
    AdminBlade,
    Money,
    GridList,
    PromotionList,
    ProductAtribute,
    PaymentRules,
  },
  watch: {
    productGroupList: function () {
      this.createGroupList();
    },
    routeId() {
      this.onload();
    },
    definitionList() {
      if (this.definitionList[0] && !this.$route.params.id) {
        this.formData.definition_id = this.definitionList[0].id;
        this.defineCategory(this.formData.definition_id);
      }
    },
  },
  data: function () {
    return {
      groupList: [],
      adm: false,
      valid: false,
      money: {},
    };
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    // ...mapGetters("ProductGroup", ["productGroupListWithNull"]),
    ...mapState("Product", ["userType", "errors", "formData", "deliveryRule"]),
    ...mapState("Company", {
      companyList: "list",
    }),
    ...mapState("Category", {
      categoryList: "list",
    }),
    ...mapState("ProductGroup", {
      productGroupList: "list",
    }),
    ...mapState("SubCategory", {
      subCategoryList: "list",
    }),
    ...mapState("Definition", {
      definitionList: "list",
    }),
    ...mapState("Brand", {
      brandList: "list",
    }),
    ...mapState("Utils", ["genders"]),

    align() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        default:
          return "left";
      }
    },
  },
  methods: {
    createGroupList() {
      this.groupList = [...this.productGroupList, { name: "Nenhum", id: null }];
    },
    saveContent() {
      let test = true;
      if (this.formData.price <= 0) {
        test = confirm(
          "O Valor do Produto é Igual a 0 (Zero), Tem certeza que deseja Salvar mesmo assim? "
        );
      }
      if (this.formData.relevance == null) {
        this.formData.relevance = 0;
      }
      if (test) {
        this.save(this.formData);
      }
    },
    updateContent() {
      if (this.formData.relevance == null) {
        this.formData.relevance = 0;
      }
      this.update(this.formData);
    },
    confectiondeadlineList() {
      let response = [];
      let i = null;
      for (i = 0; i < 46; i++) {
        response.push({ value: i });
      }
      return response;
    },
    relevanceList() {
      let response = [];
      let i = null;
      for (i = 0; i < 1999; i++) {
        response.push({ value: i });
      }

      return response;
    },
    defineCategory(definitionId) {
      this.setCategory(definitionId);
    },
    ...mapActions("Product", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("Definition", {
      setDefinition: "setList",
    }),
    ...mapActions("ProductGroup", {
      setProductGroupList: "setList",
    }),
    ...mapActions("SubCategory", {
      setSubCategory: "setFilteredList",
      setAllSubCategory: "setList",
    }),
    ...mapActions("Category", {
      setCategory: "setFilteredList",
    }),
    ...mapActions("Category", {
      setAllCategory: "setList",
    }),
    ...mapActions("Brand", {
      setBrand: "setList",
    }),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
    onload() {
      // toastAlert("success");
      this.setBrand();
      if (!this.adm) {
        this.setProductGroupList();
      }
      this.adm = JSON.parse(localStorage.getItem("user")).admin;

      if (this.$route.params.id) {
        this.setAllCategory();
        this.setAllSubCategory();
        this.show(this.$route.params.id);
      }
      if (this.adm) {
        this.setCompany();
      }
      this.setDefinition();
    },
  },
  mounted() {
    this.onload();
  },
  beforeDestroy: function () {
    this.cleanItem();
    this.cleanErrors();
  },
  destroyed() {},
};
</script>

<style>
</style>