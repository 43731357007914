
import {s3Url } from "@/global";

const getOrganizedList = function (state) {

    let response = [];
    for (let i = 0; i < state.list.length; i++) {
        if(state.only_face_description === true){
        if(state.list[i].facebook_description){
        response.push(
            {
                id: state.list[i].id,
                title: state.list[i].title,
                ios_url: null,
                ios_app_store_id: null,
                ios_app_name: null,
                android_url: null,
                android_package: null,
                android_app_name: null,
                windows_phone_url: null,
                windows_phone_app_id: null,
                windows_phone_app_name: null,
                description: (state.list[i].facebook_description ? removeJumpleLines(state.list[i].facebook_description) : removeJumpleLines(state.list[i].description)),
                google_product_category: state.list[i].google_product_category,
                product_type: organizeCatexgory(state.list[i].definition, state.list[i].category, state.list[i].subcategory),
                link: callLink(state.list[i].page_url, state.list[i].id, state.list[i].title),
                image_link: setImgUrl(state.list[i].product_image, state.list[i].company_id),
                condition: 'new',
                availability: 'in stock',
                price: setPrice(state.list[i].price, state.list[i].actived_promotions),
                sale_price: setSalePrice(state.list[i].price, state.list[i].actived_promotions),
                sale_price_effective_date: setSaleFinishDate(state.list[i].actived_promotions),
                gtin: null,
                brand: setNullIfNot(state.list[i].brand),
                mpn: null,
                item_group_id: null,
                gender: setGender(state.list[i].gender),
                age_group: null,
                color: null,
                size: null,
                shipping: `BR::Standard:${state.list[i].price} BRL`,
                custom_label_0: null
            }
        )
        }
    }else{
            response.push(
                {
                    id: state.list[i].id,
                    title: state.list[i].title,
                    ios_url: null,
                    ios_app_store_id: null,
                    ios_app_name: null,
                    android_url: null,
                    android_package: null,
                    android_app_name: null,
                    windows_phone_url: null,
                    windows_phone_app_id: null,
                    windows_phone_app_name: null,
                    description: (state.list[i].facebook_description ? removeJumpleLines(state.list[i].facebook_description) : removeJumpleLines(state.list[i].description)),
                    google_product_category: state.list[i].google_product_category,
                    product_type: organizeCatexgory(state.list[i].definition, state.list[i].category, state.list[i].subcategory),
                    link: callLink(state.list[i].page_url, state.list[i].id, state.list[i].title),
                    image_link: setImgUrl(state.list[i].product_image, state.list[i].company_id),
                    condition: 'new',
                    availability: 'in stock',
                    price: setPrice(state.list[i].price, state.list[i].actived_promotions),
                    sale_price: setSalePrice(state.list[i].price, state.list[i].actived_promotions),
                    sale_price_effective_date: setSaleFinishDate(state.list[i].actived_promotions),
                    gtin: null,
                    brand: setNullIfNot(state.list[i].brand),
                    mpn: null,
                    item_group_id: null,
                    gender: setGender(state.list[i].gender),
                    age_group: null,
                    color: null,
                    size: null,
                    shipping: `BR::Standard:${state.list[i].price} BRL`,
                    custom_label_0: null
                }
            )
        }
    }
    return response;

}

//Privates
const setNullIfNot = function (value) {
    if (value) {
        return value
    } else {
        return 'Null'
    }
}
const organizeCatexgory = function (definition, category, subcategory) {
    if (definition && category && subcategory) {
        return `${definition} > ${category} > ${subcategory}`
    }
    if (definition && category && !subcategory) {
        return `${definition} > ${category} `
    }
    if (definition && !category && !subcategory) {
        return `${definition} `
    }
    // return 10;
}
const removeJumpleLines = function (value) {
    let response = value.replace("; ", "")
    response = response.replace(";", "")
    response = response.replace(" ; ", "")
    response = response.replace(" ;", "")
    return response.replace(/(\r\n|\n|\r)/gm, " ").toLowerCase()


}
const setGender = function (value) {
    if (value == 'f') {
        return 'female'
    }
    if (value == 'm') {
        return 'male'
    }
    if (value == 'u') {
        return 'unisex'
    }
}
const setSaleFinishDate = function (promotion) {
    if (promotion[0] && promotion[0].end_date) {
        return promotion[0].end_date
    } else {
        return ''
    }
}
const setSalePrice = function (price, promotion) {
    if (promotion[0]) {
        return price.toFixed(2) + ' BRL'
    } else {
        return ''
    }
}
const setPrice = function (price, promotion) {
    if (promotion[0]) {
        return ((parseFloat(promotion[0].discount_value) + parseFloat(price)).toFixed(2)) + ' BRL'
        // return price + 'Promo'
    } else {

        return price + ' BRL'
    }
}

const setImgUrl = function (imgName, companyId) {
    // let urlServer = 'http://shoptest.gransys.com.br/'
    let urlServer = 'http://marialuizamodas.com.br/image/'
    let data = JSON.parse(window.localStorage.getItem('user'));
    // https://shoptest.gransys.com.br/images/produto/1/110242022145543478821000
    if (imgName[0] && (data.company_id == 1 || companyId == 1) ) {
        return urlServer + imgName[0].img_name + '1000';
    } else if(imgName[0]) {
        return s3Url + companyId + '/' + imgName[0].img_name + '1000.jpg'
    } else {
        return ''
    }
}

const callLink = function (url, id, title) {
    // if (url == 'candycolors.com.br') {
    //     return `https://${url}/buy/${id}/${convertToLink(title)}`
    // } else {
    return `https://${url}/produto/${id}/${convertToLink(title)}`
    // }
}

const convertToLink = function (value) {
    let response = value
        .replace(/_|\s/g, "-")
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

    return response;
}

//End Privates

export default {
    getOrganizedList
}