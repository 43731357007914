import axios from 'axios'
import { baseApiUrl, catchError, publicAppKey } from "@/global";
import { toastAlert } from "@/utils/Alerts/toast";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'


const savePv = function ({ commit }, payload) {
    commit('SET_SPINNER', true)
    let data = {
        key_name: 'pagarme_api_key',
        ...payload
    }

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {
                check({ commit }, {
                    company_id: data.company_id
                })
                commit('SET_DIALOG', false)

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)

        })
}

const sendDataToHub = function ({ commit }, data) {
    const installId = data.installId;
    const baseUrl = "https://hub.pagar.me/pt-BR/apps/";
    let urlToView = `https://hub.pagar.me/apps/${data.publicAppKey}/authorize?redirect=${data.redirectUrl}`;
    if (data.environment === "dev") {
        urlToView = `https://hub.pagar.me/apps/dev/${data.publicAppKey}/authorize?redirect=${data.redirectUrl}`;
    }
    window.open(urlToView, '_blank');


};



const updateValue = function ({ commit }, payload) {
    commit('SET_SPINNER', true)

    axios.post(`${baseApiUrl}/integration-key-value/update`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    `Valor alterado com sucesso!`
                );
                commit('SET_DIALOG', false)
                toastAlert('success')
                // commit('SET_MELHORENVIOCHANGEKEY', false)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)
        })
}

const saveToken = function ({ commit }, payload) {
    commit('SET_SPINNER', true)
    let data = {
        key_name: 'pagarme_api_key',
        ...payload
    }

    axios.post(`${baseApiUrl}/integration-key-value/save`, data)
        .then(res => {
            if (res.data.status == 201) {
                check({ commit }, {
                    company_id: data.company_id
                })
                commit('SET_DIALOG', false)

                sessionStorage.setItem(
                    "toastMsg",
                    `Token foi cadastrado com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            commit('SET_SPINNER', false)

        })
}

const check = function ({ commit }, payload) {
    let data = {
        key_name: 'pagarme_api_key',
        ...payload
    }
    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_PVID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })

    data = {
        key_name: 'pagarme_api_key',
        ...payload
    }
    axios.post(`${baseApiUrl}/integration-key-value/show`, data)
        .then(res => {
            if (res.data.status == 201) {
                commit('SET_TOKENID', res.data.id)
            }
        })
        .catch(e => {
            catchError(e.status)
        })
        .finally(() => {
            spinnerChoice('full', false)
        })
}

const cleanState = function ({ commit }) {
    commit('SET_ERRORS', [])
}

const callHub = function ({ commit }, data) {
    let urlToIntegrate = `https://hub.pagar.me/pt-br/apps/dev/${data.publicAppKey}/authorize?redirect=https://teste.com.br`;
    let urlToView = `https://hub.pagar.me//pt-br/apps/dev/${data.publicAppKey}/edit/${data.installId}`;

    let language = "pt-br";

    window.location.href = urlToIntegrate;
}

const callHu = function (config) {





    this.setup = function (config) {

        if (config.language) {
            this.language = config.language.toLowerCase();
        }

        let url = null

        if (config.installId) {
            url = this.urlToView;
            text = this.locations[this.language].view;
        } else {
            url = this.urlToIntegrate;
            text = this.locations[this.language].integrate;
        }

        if (this.language !== "pt-br")
            url = url.replace("{language}", this.locations[this.language].language);
        else
            url = url.replace("/{language}", "");

        if (config.environment?.toLowerCase().startsWith("dev"))
            url = url.replace("{environment}", config.environment);
        else
            url = url.replace("/{environment}", "");

        url = url.replace("{publicAppKey}", config.publicAppKey);
        url = url.replace("{installId}", config.installId);
        url = url.replace("{redirectUrl}", config.redirectUrl);

        createButton(text, function () {
            window.location.href = url;
        });
    };

    this.createButton = function (text, func) {
        let container = document.getElementById(this.containerId);
        let button = document.createElement(this.elementType);
        button.innerHTML = text + this.space + this.space + this.getImageTag(pagarmeLogo);
        button.onclick = func;
        this.removeAllChilds(container);
        this.createStyles();
        container.appendChild(button);
    };

    this.getImageTag = function (src) {
        return '<img src="' + src + '" />';
    };

    this.removeAllChilds = function (container) {
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
    };

    this.createStyles = function () {
        let container = document.getElementById(this.containerId);
        let styles = document.createElement("style");
        styles.innerHTML = this.styles;
        container.appendChild(styles);
    };

    this.setup(config);

    return this;
}

export default {
    savePv,
    cleanState,
    saveToken,
    check,
    updateValue,
    sendDataToHub
}