import { render, staticRenderFns } from "./NewPagarmeForm.vue?vue&type=template&id=07527546&scoped=true"
import script from "./NewPagarmeForm.vue?vue&type=script&lang=js"
export * from "./NewPagarmeForm.vue?vue&type=script&lang=js"
import style0 from "./NewPagarmeForm.vue?vue&type=style&index=0&id=07527546&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07527546",
  null
  
)

export default component.exports