<template>
  <admin-blade>
    <filters />
    <report />
  </admin-blade>
</template>

<script>
// import { mapActions } from "vuex";
import AdminBlade from "@/Layouts/Blade";
import Filters from "@/views/Reports/Pages/FacebookCatalog/Components/filters";
import Report from "@/views/Reports/Pages/FacebookCatalog/Components/report";

export default {
  methods: {
    // ...mapActions("OrderReport", ["showReport"]),
  },
  components: {
    AdminBlade,
    Filters,
    Report,
  },
};
</script>