const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_KEYFACEBOOKID = (state, obj) => {
    state.facebook.key_id = obj
}

const SET_FACEBOOKCHANGEKEY = (state, obj) => {
    state.facebook.changeKey = obj
}

const SET_FACEBOOKERRORS = (state, obj) => {
    state.facebook.errors = obj
}

const SET_CLEANFORMDATA = (state, obj) => {
    state.formData = obj
    state.facebook = obj
}


export default {
    SET_FORMDATA,
    SET_ERRORS,
    SET_KEYFACEBOOKID,
    SET_FACEBOOKCHANGEKEY,
    SET_FACEBOOKERRORS,
    SET_CLEANFORMDATA
}