var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h1',[_vm._v("Produtos da Variação")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"height":"36","items":_vm.productList,"item-text":"name","item-value":"id","label":"Produtos *"},model:{value:(_vm.formData.product_id),callback:function ($$v) {_vm.$set(_vm.formData, "product_id", $$v)},expression:"formData.product_id"}}),_c('strong',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.product_id))]),_c('strong',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.variation_group_id))])],1),_c('v-col',{attrs:{"align-self":"center","cols":"4"}},[_c('v-text-field',{attrs:{"label":"Valor p/ Variação"},model:{value:(_vm.formData.value),callback:function ($$v) {_vm.$set(_vm.formData, "value", $$v)},expression:"formData.value"}}),_c('strong',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.value))])],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green"},on:{"click":function($event){return _vm.save({
            ..._vm.formData,
            variation_group_id: _vm.variationGroupId,
          })}}},[_vm._v("Adicionar Produto")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"default-table",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"item.main",fn:function({ item }){return [(item.main)?_c('span',[_vm._v("Sim")]):_c('span',[_vm._v("Não")])]}},{key:"item.action",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Excluir","small":""},on:{"click":function($event){return _vm.deleteFunction({
                variation_group_id: _vm.variationGroupId,
                id: item.id,
              })}}},[_vm._v("mdi-delete-outline")]),_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Excluir","small":""},on:{"click":function($event){return _vm.becomeMain({
                variation_group_id: _vm.variationGroupId,
                id: item.id,
              })}}},[_vm._v("mdi-check-circle")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }