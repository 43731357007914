<template>
  <v-card class="default-table">
    <v-container>
      <v-data-iterator
        :items="list.data"
        items-per-page.sync="4"
        hide-default-footer
      >
        <template v-slot:header>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                :items="[25, 50, 100]"
                @input="setList()"
                v-model="pagination.itemsPerPage"
                style="max-width: 150px"
              >
                <template v-slot:prepend>
                  <small>Ítens por Página: </small>
                </template>
              </v-select>
            </v-col>
            <v-col cols="5" class="d-none d-sm-block" />
            <v-col cols="12" sm="3"
              ><small>Total de Itens: {{ list.total }}</small></v-col
            >
          </v-row>
          <v-row justify="center">
            <v-col cols="12">
              <v-pagination
                class="mobile-pagination"
                v-model="page"
                @input="setList()"
                :length="list.last_page"
              >
              </v-pagination>
            </v-col>
          </v-row>
        </template>

        <template v-slot:default>
          <v-row>
            <v-col
              v-for="(item, index) in list.data"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="sub-header">
                  <div class="sub-header-text">
                    {{ item.id }} - {{ item.name }}
                  </div>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Ref:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.ref }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Categoria:</v-list-item-content>
                    <v-list-item-content class="align-start item-text">
                      <v-row
                        no-gutters
                        style="height: 100%; width: 100%"
                        align="center"
                      >
                        <v-col>
                          {{ item.category }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row" v-if="checkUser()">
                    <v-list-item-content>Cliente:</v-list-item-content>
                    <v-list-item-content class="align-start item-text">
                      <v-row
                        no-gutters
                        style="height: 100%; width: 100%"
                        align="center"
                      >
                        <v-col>
                          {{ item.company_name }}
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Valor:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      R$
                      {{
                        item.price.toLocaleString("pt-BR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Ativo:</v-list-item-content>
                    <v-list-item-content
                      class="align-start"
                      style="color: green !important; width: 100%"
                      v-if="item.active == true"
                    >
                      SIM
                    </v-list-item-content>
                    <v-list-item-content class="align-start" v-else>
                      NÃO
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Estoque:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      <v-chip :class="negativeStock(item.balance)">{{
                        item.balance
                      }}</v-chip>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Relevância:</v-list-item-content>
                    <v-list-item-content class="align-start">
                      {{ item.relevance }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn fab color="#272727" @click="edit(item)">
                            <v-icon color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      currentHeader: "",
    };
  },
  props: {
    list: {},
  },
  computed: {
    ...mapState("Product", ["pagination"]),
    itemsPerPage: {
      get() {
        return this.$store.state.Product.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    orderField: {
      get() {
        return this.$store.state.Product.order.field;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERFIELD", value);
      },
    },
    orderValue: {
      get() {
        return this.$store.state.Product.order.value;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERVALUE", value);
      },
    },
  },

  methods: {
    ...mapActions("Product", ["setList"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    updateItemsPerPage(value) {
      this.pagination.itemsPerPage = value;
      this.setList();
    },
    // TODO Finish sorting
    // clearIcon(header) {
    //   this.currentHeader = header.value;
    //   for (let i = 0; i < this.headers.length; i++) {
    //     if (this.headers[i].value != this.currentHeader) {
    //       this.headers[i].sortOrder = "";
    //       this.headers[i].sortIcon = "";
    //     }
    //   }
    // },
    // toggleOrder(header) {
    //   if (header.sortOrder == "" && header.sortIcon == "") {
    //     header.sortOrder = "ASC";
    //     header.sortIcon = "mdi-arrow-up";
    //   } else if (
    //     header.sortOrder == "ASC" &&
    //     header.sortIcon == "mdi-arrow-up"
    //   ) {
    //     header.sortOrder = "DESC";
    //     header.sortIcon = "mdi-arrow-down";
    //   } else if (
    //     header.sortOrder == "DESC" &&
    //     header.sortIcon == "mdi-arrow-down"
    //   ) {
    //     header.sortOrder = "";
    //     header.sortIcon = "";
    //   } else {
    //     header.sortOrder = "ASC";
    //     header.sortIcon = "mdi-arrow-up";
    //   }
    // },
    // sort(header) {
    //   if (header.sortOrder) {
    //     this.orderField = header.value;
    //     this.orderValue = header.sortOrder;
    //   } else {
    //     this.orderField = "id";
    //     this.orderValue = "DESC";
    //   }
    // },
    getPromotionClass(value) {
      if (value) {
        return "inactive-item-price";
      }
    },
    negativeStock(value) {
      if (value < 1) {
        return "negative-stock-class";
      } else {
        return "stock-class";
      }
    },
    edit(item) {
      this.$router.push(`form-product/${item.id}`);
    },
    checkActiveClass(value) {
      if (value == true) {
        return ["Sim", "active-product-list-true"];
      } else {
        return ["Não", "active-product-list-false"];
      }
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");

    // if (JSON.parse(localStorage.getItem("user")).admin) {
    //   this.headers.splice(4, 0, {
    //     name: "Cliente",
    //     value: "company_name",

    //     sortOrder: "",
    //     sortIcon: "",
    //   });
    // }
  },
};
</script>

<style scoped>
.mobile-pagination >>> .v-pagination__item {
  min-width: 0 !important;
  width: 25px !important;
  height: 25px !important;
}

.sub-header-text {
  font-size: 16px !important;
  font-weight: bold;
  height: 34px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
}

.item-row {
  border-top: 1px solid lightgray;
}

.item-text {
  height: 64px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.stock-class {
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.negative-stock-class {
  background: red !important;
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
}
</style>