<template>
  <span
    v-if="chartOptions && series && chartOptions.labels !== null && reportModal"
  >
    <apex-chart
      :height="height"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </span>
</template>

<script>
import ApexChart from "vue-apexcharts";
import { mapState } from "vuex";

export default {
  watch: {
    data: function () {
      this.updateChart();
    },
  },
  props: {
    data: Array,
    height: String,
  },
  computed: {
    ...mapState("Reports", ["reportModal"]),
  },
  components: {
    ApexChart,
  },
  data: function () {
    return {
      series: [4],
      chartOptions: {
        chart: {
          width: 480,
          type: "donut",
        },
        labels: [],
      },
    };
  },
  methods: {
    changeCharacterFromName(value, total) {
      if (value == null) {
        return "Retirada" + " - " + total;
      } else {
        return value + " - " + total;
      }
    },
    isolateGenderName(arr) {
      let self = this;
      let response = arr.map(function (obj) {
        // console.log(obj);
        return self.changeCharacterFromName(obj.state, obj.total);
      });
      return response;
    },
    isolateTotal(arr) {
      let response = arr.map(function (obj) {
        return obj.total;
      });
      return response;
    },
    updateChart() {
      this.series = this.isolateTotal(this.data);
      this.chartOptions = {
        chart: {
          width: 480,
          type: "donut",
        },
        labels: this.isolateGenderName(this.data),
      };
    },
  },
  beforeMount() {
    this.updateChart();
  },
};
</script>

<style>
.teste {
  color: rgb(0, 205, 150);
}
</style>