<template>
  <v-card>
    <!-- <v-simple-table class="default-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.value" class="text-left">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="row-class"
            @click="edit(item)"
            v-for="item in list.data"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.discount_rate }}%</td>
            <td>
              R$
              {{
                parseFloat(item.discount_value).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}
            </td>
            <td>{{ item.init_date }}</td>
            <td>{{ item.expire_date }}</td>
            <td v-if="item.active">SIM</td>
            <td v-else>NÃO</td>
            <td v-if="item.remove_sale">SIM</td>
            <td v-else>NÃO</td>
            <td v-if="checkUser()">{{ item.company_id }}</td>
            <td v-if="checkUser()">{{ item.fantasy_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <v-data-table
      class="default-table"
      :headers="headers"
      :items="list.data"
      :search="search"
      :footer-props="footer"
      @click:row="edit"
    >
      <template v-slot:item="{ item }">
        <tr class="row-class" @click="edit(item)">
          <td style="width: 65px">{{ item.id }}</td>
          <td>{{ item.code }}</td>
          <td style="width: 115px">{{ item.discount_rate }}%</td>
          <td style="width: 115px">
            R$
            {{
              parseFloat(item.discount_value).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}
          </td>
          <td style="width: 110px">{{ item.init_date }}</td>
          <td style="width: 110px">{{ item.expire_date }}</td>
          <td style="width: 85px" v-if="item.active">SIM</td>
          <td style="width: 85px" v-else>NÃO</td>
          <td style="width: 125px" v-if="item.remove_sale">SIM</td>
          <td style="width: 125px" v-else>NÃO</td>
          <td style="width: 95px" v-if="checkUser()">
            {{ item.company_id }}
          </td>
          <td v-if="checkUser()">{{ item.fantasy_name }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapActions } from "vuex";
export default {
  data: function () {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Taxa do Desconto",
          value: "discount_rate",
        },
        {
          text: "Valor do Desconto",
          value: "discount_value",
        },
        {
          text: "Data de Início",
          value: "init_date",
        },
        {
          text: "Data Fim",
          value: "expire_date",
        },
        {
          text: "Ativo",
          value: "active",
        },
        {
          text: "Remover em promoções",
          value: "remove_sale",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },
  props: {
    list: {},
    search: null,
  },
  methods: {
    ...mapActions("Coupon", ["setList"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    edit(item) {
      this.$router.push(`form-coupon/${item.id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");

    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(10, 0, {
        text: "ID do Cliente",
        value: "company_id",
      });
      this.headers.splice(11, 0, {
        text: "Cliente",
        value: "fantasy_name",
      });
    }
  },
};
</script>

<style scoped>
.row-class:hover {
  cursor: pointer !important;
  background-color: #f2f2f2 !important;
}
.inactive-item-price {
  text-decoration: line-through;
  color: rgba(128, 128, 128, 0.571);
}
.active-product-list-true {
  background: green;
  color: white;
  padding: 5px;
  border-radius: 10px;
}

.active-product-list-false {
  background: red;
  color: white;
  padding: 5px;
  border-radius: 10px;
}

.stock-class {
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
}

.negative-stock-class {
  background: red !important;
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
}
</style>