const defaultPath = '/product-discount-by-payment-method'
const toastName = 'Regra de Pagamento'
const listDefault = {
    id: null,
    created_at: null,
    updated_at: null
}

import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
import { toastAlert } from "@/utils/Alerts/toast";

import { indexFunction, showFunction } from "@/utils/DefaultCrud/defaultsFunctions";

export const save = function ({ commit, state }, payload) {
    spinnerChoice('modal', true)
    axios.post(`${baseApiUrl + defaultPath}/save`, payload)
        .then(res => {
            if (res.data.status == 201) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` ${toastName}, foi cadastrado com sucesso !!`
                );
                commit('SET_MODAL', false)
                setList({ commit, state }, payload.product_id)
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

export const update = function ({ commit, state }, payload) {
    spinnerChoice('modal', true)
    axios.post(`${baseApiUrl + defaultPath}/update`, payload)
        .then(res => {
            if (res.data.status == 202) {
                sessionStorage.setItem(
                    "toastMsg",
                    ` ${toastName}, foi cadastrado com sucesso !!`
                );
                commit('SET_MODAL', false)
                setList({ commit, state }, payload.product_id)
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {

            spinnerChoice('modal', false)

        })
}

const show = function ({ commit }, payload) {
    showFunction(commit, defaultPath, payload, 'modal')
}

const setList = ({ commit }, productId) => {
    indexFunction(commit, defaultPath, `product_id=${productId}`, 'full')
}

const cleanItem = function ({ commit }) {
    commit('SET_FORMDATA', listDefault)
}

const cleanErrors = function ({ commit }) {
    commit('SET_ERRORS', '')
}

const setModal = function ({ commit }, value) {
    commit('SET_MODAL', value)
}




export default {
    save,
    cleanItem,
    cleanErrors,
    setList,
    update,
    show,
    setModal
}