<template>
  <span>
    <v-card style="margin: 0 auto" max-width="1500px" elevation="3">
      <h2 style="text-align: center">Selecione a Loja</h2>
      <v-row align="center" justify="center" style="margin: 0 auto !important">
        <v-col sm="4" cols="12" align-self="center">
          <v-autocomplete
            v-model="company_id"
            :value="company_id"
            :items="companyList"
            class="mx-3"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>

        <v-col sm="1" cols="12" align="center">
          <v-btn
            color="green darken-2 white--text"
            class="mr-4"
            @click="setId()"
          >
            Listar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <br />
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      company_id: null,
    };
  },
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),

    // ...mapState("NavBarPersonalization", [
    //   "data",
    //   "formDataNavbar",
    //   "company_id",
    // ]),

    ...mapState("BannerPersonalization", [
      "formBanners",
      "formBannersUpdate",
      "id_company",
      "dialog",
      "carouselItems",
    ]),
  },

  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),

    ...mapActions("BannerPersonalization", [
      // "setList",
      //   "cleanItem",
      // "saveCompany_id",
      "index",
      "setIdCompany",
    ]),

    setId() {
      this.$store.state.id_company = this.company_id;
      this.setIdCompany(this.company_id);
      this.index();
    },
  },

  mounted() {
    this.setCompany();
    this.setIdCompany(null);
    this.index();
  },
};
</script>

<style>
</style>