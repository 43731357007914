const SET_LIST = (state, obj) => {
    state.list = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_SHOWINACTIVE = (state, obj) => {
    state.showInactive = obj
}

const SET_ONLY_DELIVERED = (state, obj) => {
    state.only_delivered = obj
}



export default {
    SET_LIST,
    SET_ERRORS,
    SET_FORMDATA,
    SET_SHOWINACTIVE,
    SET_ONLY_DELIVERED
}