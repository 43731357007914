export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null,
        active: true,
        code: null,
        discount_rate: 0,
        dicount_value: 0,
        init_date: null,
        expire_date: null,
        company_id: null,
        remove_sale: false,
        fantasy_name: null,
    },

    list: [],
    errors: [],
}