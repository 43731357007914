const SET_REPORTMODAL = (state, obj) => {
    state.reportModal = obj
}

const SET_REPORTTITLE = (state, obj) => {
    state.reportTitle = obj
}

const SET_REPORTINFO = (state, obj) => {
    state.reportInfo = obj
}

const SET_REPORTOPTIONALINFO = (state, obj) => {
    state.reportOptionalInfo = obj
}

export default {
    SET_REPORTMODAL,
    SET_REPORTTITLE,
    SET_REPORTINFO,
    SET_REPORTOPTIONALINFO
}