const SET_LIST = (state, obj) => {
    state.list = obj
}

const SET_ERROS = (state, obj) => {
    state.errors = obj
}

const SET_CLEAN_ERROS = (state) => {
    state.errors = null
}

const SET_FORMDATA = (state, obj) => {
    state.formDataNavbar = obj
}

const SET_MODAL = (state, obj) => {
    state.dialog = obj
}

const SET_VERIFYDATA = (state, obj) => {
    state.dataVerified = obj
}

const SET_ALERT = (state, obj) => {
    state.alert = obj
}

const SET_MESSAGE = (state, obj) => {
    state.message = obj
}

const SET_ALERT_ERROR = (state, obj) => {
    state.alertError = obj
}

const SET_CLEAN_SELECT = (state) => {
    state.selected = ""
}

const SET_CLEAN_SELECT_CATEGORY = (state) => {
    state.selectedCategory = ""
}

const SET_COMPANY_ID = (state, obj) => {
    state.company_id = obj
}

const SET_DEFINITION_NAME = (state, obj) => {
    state.definition = obj
}

const SET_ROUTE = (state, obj) => {
    state.route = obj
}


export default {
    SET_LIST,
    SET_ERROS,
    SET_FORMDATA,
    SET_MODAL,
    SET_VERIFYDATA,
    SET_ALERT,
    SET_MESSAGE,
    SET_ALERT_ERROR,
    SET_CLEAN_ERROS,
    SET_CLEAN_SELECT,
    SET_CLEAN_SELECT_CATEGORY,
    SET_COMPANY_ID,
    SET_DEFINITION_NAME,
    SET_ROUTE
}