<template>
  <v-form flat class="v-form mt-5">
    <div class="default-form">
      <v-row>
        <v-col cols="12">
          <h3>Filtros</h3>
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" sm="8">
          <v-text-field
            clearable
            dense
            v-model="productName"
            v-on:keydown.enter="resetPage(), setList()"
            label="Nome do produto"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="reference"
            v-on:keydown.enter="resetPage(), setList()"
            label="Referência"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="definition"
            v-on:keydown.enter="resetPage(), setList()"
            label="Definição"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="category"
            v-on:keydown.enter="resetPage(), setList()"
            label="Categoria"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <v-text-field
            clearable
            dense
            v-model="subcategory"
            v-on:keydown.enter="resetPage(), setList()"
            label="Sub Categoria"
          />
        </v-col>
        <v-col sm="6" md="5" cols="12" v-if="adm">
          <v-autocomplete
            v-model="client"
            :items="companyList"
            item-text="fantasy_name"
            item-value="id"
            label="Loja *"
            @change="resetPage(), setList()"
            color="amber darken-4"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="py-0" cols="8">
          <v-text-field
          clearable dense v-model="filter." label="Cliente" />
        </v-col>
      </v-row> -->
      <v-switch label="Pesquisar por grade" v-model="showGrid"></v-switch>
      <v-row v-if="showGrid">
        <v-col class="py-0" cols="12" md="4">
          <v-text-field
            clearable
            dense
            v-model="gridCode"
            v-on:keydown.enter="resetPage(), setList()"
            label="Código da grade"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="gridColor"
            v-on:keydown.enter="resetPage(), setList()"
            label="Cor"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6" md="4">
          <v-text-field
            clearable
            dense
            v-model="gridSize"
            v-on:keydown.enter="resetPage(), setList()"
            label="Tamanho"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="9" lg="10" align="center">
          <v-btn dark color="green" @click="resetPage(), setList()"
            >Filtrar</v-btn
          >
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2" align="center">
          <v-btn
            dark
            rounded
            small
            color="red"
            @click="cleanFilters(), resetPage(), setList()"
            >Limpar filtros <v-icon>mdi-trash-can</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: function () {
    return {
      adm: false,
    };
  },
  props: {
    list: null,
  },
  computed: {
    ...mapState("Company", {
      companyList: "list",
    }),
    productName: {
      get() {
        return this.$store.state.Product.filter.productName;
      },
      set(value) {
        this.$store.commit("Product/SET_PRODUCTNAME", value);
      },
    },
    reference: {
      get() {
        return this.$store.state.Product.filter.reference;
      },
      set(value) {
        this.$store.commit("Product/SET_REFERENCE", value);
      },
    },
    definition: {
      get() {
        return this.$store.state.Product.filter.definition;
      },
      set(value) {
        this.$store.commit("Product/SET_DEFINITION", value);
      },
    },
    category: {
      get() {
        return this.$store.state.Product.filter.category;
      },
      set(value) {
        this.$store.commit("Product/SET_CATEGORY", value);
      },
    },
    subcategory: {
      get() {
        return this.$store.state.Product.filter.subcategory;
      },
      set(value) {
        this.$store.commit("Product/SET_SUBCATEGORY", value);
      },
    },

    client: {
      get() {
        return this.$store.state.Product.filter.client;
      },
      set(value) {
        this.$store.commit("Product/SET_CLIENTFILTER", value);
      },
    },

    // ---

    showGrid: {
      get() {
        return this.$store.state.Product.filter.grid.showGrid;
      },
      set(value) {
        this.$store.commit("Product/SET_SHOWGRID", value);
      },
    },
    gridCode: {
      get() {
        return this.$store.state.Product.filter.grid.code;
      },
      set(value) {
        this.$store.commit("Product/SET_GRIDCODE", value);
      },
    },
    gridColor: {
      get() {
        return this.$store.state.Product.filter.grid.color;
      },
      set(value) {
        this.$store.commit("Product/SET_COLOR", value);
      },
    },
    gridSize: {
      get() {
        return this.$store.state.Product.filter.grid.size;
      },
      set(value) {
        this.$store.commit("Product/SET_SIZE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
  },
  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    ...mapActions("Product", ["setList"]),
    cleanFilters() {
      this.productName = "";
      this.reference = "";
      this.definition = "";
      this.category = "";
      this.subcategory = "";
      this.code = "";
      this.color = "";
      this.size = "";
      this.client = "";
    },
    onload() {
      // toastAlert("success");

      this.adm = JSON.parse(localStorage.getItem("user")).admin;

      if (this.adm) {
        this.setCompany();
      }
    },
    resetPage() {
      this.page = 1;
    },
  },
  mounted() {
    this.onload();
  },
};
</script>

<style>
</style>