export default {

    dialog: false,
    data: {
        id: null
    },
    list: [],
    formDataNavbar: {
        name: null,
        company_id: null,
        id: null,
        ordenate: null,
        params: {
            definition_name: null,
            many_categories: [],
            gender: null,
            many_subcategories: [],
            kids: false,
            group_name: null,
            only_sale: false,
            grid: null,
        },
        type: 1,
        relationship: null
    },
    category_id: null,
    errors: [],
    alert: false,
    alertError: false,
    message: null,
    dataVerified: {},
    company_id: "",
    definition: "",
    selectedCategory: [],
    route: null,

    nameError: false,
    ordenateError: false,

}