  const reasonInputs = (state) => {
    state.reason.filter(reason => reason.type == 'i')
}

const reasonOutputs = (state) => {
    state.reason.filter(reason => reason.type == 'o')
}
export default{
    reasonInputs,
    reasonOutputs
}