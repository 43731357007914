<template>
  <admin-blade-integration
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/integrations/marketing"
  >
    <span>
      <v-form
        class="mt-3 default-form"
        style="height: 90%"
        ref="form"
        v-model="valid"
      >
        <v-card-title> Cadastrar serviço para Facebook </v-card-title>
        <v-row>
          <v-col sm="3" cols="12" v-if="adm">
            <v-autocomplete
              v-model="formData.company_id"
              :items="companyList"
              item-text="fantasy_name"
              item-value="id"
              label="Loja *"
              color="amber darken-4"
            ></v-autocomplete>
          </v-col>
          <v-col sm="4" cols="12">
            <v-autocomplete
              v-if="adm"
              v-model="formData.key_name"
              @change="
                check({
                  key_name: formData.key_name,
                  company_id: formData.company_id,
                })
              "
              :items="integrators"
              item-text="name"
              item-value="value"
              label="Selecione o serviço *"
              color="amber darken-4"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              v-model="formData.key_name"
              @change="
                check({
                  key_name: formData.key_name,
                })
              "
              :items="integrators"
              item-text="name"
              item-value="value"
              label="Selecione o serviço *"
              color="amber darken-4"
            ></v-autocomplete>
            <br />
            <h4 v-if="facebook.key_id" class="red--text">
              Você já tem um Token cadastrado pra este serviço !!!
            </h4>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :disabled="!formData.key_name"
              v-model="formData.value"
              label="Token *"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right" class="my-3">
            <v-btn
              v-if="facebook.key_id"
              color="green darken-2 white--text"
              class="mr-4"
              :disabled="!formData.key_name || !formData.value"
              @click="update(formData)"
              >Atualizar</v-btn
            >
            <v-btn
              v-else
              :disabled="!formData.key_name || !formData.value"
              color="green darken-2 white--text"
              class="mr-4"
              @click="save(formData)"
            >
              Cadastrar
            </v-btn>
            <v-btn dark rounded small color="red" @click="cleanForm()"
              >Limpar filtros <v-icon>mdi-trash-can</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </span>
  </admin-blade-integration>
</template>

<script>
import AdminBladeIntegration from "@/Layouts/BladeIntegrations";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBladeIntegration,
  },
  data: function () {
    return {
      adm: false,
      acess_token: false,
    };
  },
  watch: {
    company_id() {
      this.check({
        company_id: this.company,
      });
    },
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("MarketingFacebookIntegration", [
      "formData",
      "errors",
      "integrators",
      "facebook",
    ]),
    ...mapState("Company", {
      companyList: "list",
    }),
  },
  methods: {
    ...mapActions("Company", {
      setCompany: "setList",
    }),
    removeButtonIfNotAdmin(value) {
      if (value == true) {
        return "fas fa-file-invoice-dollar";
      }
    },
    ...mapActions("MarketingFacebookIntegration", [
      "save",
      "show",
      "update",
      "check",
      "cleanForm",
    ]),
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    }
  },
};
</script>

<style>
</style>