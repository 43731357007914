export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null
    },
    list: [],
    errors: [],
    intermediatories: [
        { value: 'cielo', name: 'Cielo' },
        { value: 'rede', name: 'Rede' },
        { value: 'pagarme', name: 'Pagar Me' },
        { value: 'pagarmev5', name: 'Pagar Me V5' },
    ],

    intermediatoriesAntifraud: [
        { value: '', name: 'Nenhum' },
        { value: 'clear-sale', name: 'Clear Sale' },
    ],

    apiVersionList: [
        { value: 5, name: 'V5' },
        { value: 1, name: 'V1' },
        { value: 2, name: 'V2' },

    ],

    paymentSlipIntermediatories: [
        { value: 'pagarme', name: 'Pagar Me' },
    ],

    extra_info_receive: {
        pix: null
    }
}