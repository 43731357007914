export default {
    formData: {
        id: null,
        created_at: null,
        updated_at: null
    },
    list: [],
    errors: [],
    modal: false,
    paymentType: [
        { name: "Cartão de Crédito", value: 0 },
        { name: "Cartão de Débito", value: 1 },
        { name: "Dinheiro", value: 2 },
        { name: "Boleto", value: 3 },
        { name: "Pix", value: 4 }
    ],
    paymentRule: [
        { name: "Desconto", value: 0 },
        { name: "Acrescimo", value: 1 },
    ]
}