<template>
  <admin-blade>
    <span>
      <v-btn class="mx-2" fab dark color="primary" @click="$router.go(-1)">
        <v-icon dark center>mdi-arrow-left</v-icon>
      </v-btn>
      <div
        v-if="checkedIfOrdersPrint && checkedIfOrdersPrint.length === 0"
        style="display: inline-block"
      >
        <v-btn
          class="mx-2"
          fab
          dark
          color="primary"
          v-print="printObj"
          @click="printOrder($route.params.id)"
        >
          <v-icon dark center>mdi-printer-settings</v-icon>
        </v-btn>
      </div>

      <div v-else>
        <v-btn
          style="margin-left: 80px !important; margin-top: -80px"
          class="mx-2"
          fab
          dark
          color="primary"
          @click="openDialog()"
        >
          <v-icon dark center>mdi-printer-settings</v-icon>
        </v-btn>
      </div>
      <v-form v-if="formData.length <= 0">
        <h2>Nenhum Item encontrado</h2>
      </v-form>
      <v-form class="mt-5 default-form" v-if="formData.length > 0">
        <br />
        <div id="printMe">
          <v-stepper style="width: 100% !important; padding: 0; margin: 0">
            <v-stepper-header class="custom-stepper-header">
              <template v-for="status in allStatus">
                <v-stepper-step
                  class="custom-stepper-step"
                  :complete="statusIsComplete(status)"
                  :step="status"
                >
                  <span style="font-size: 10px !important">{{
                    getStatusLabel(status)
                  }}</span>
                  <small
                    class="grey--text text-center"
                    style="text-align: left !important; font-size: 9px"
                  >
                    {{ getStatusCreatedAt(status) }}
                  </small>
                </v-stepper-step>
              </template>
            </v-stepper-header>
          </v-stepper>
          <br />
          <h1 class="title-topo">
            Detalhe da compra {{ this.$route.params.id }}
          </h1>
          <h3 class="text-center mb-5 grey--text" style="text-align: center">
            Status Atual do Pedido: {{ changeStatusToName(formData[0].status) }}
          </h3>
          <br />
          <v-row>
            <v-col class="border-detail-order">
              <h3 class="header-detail-order">
                <p>Compra</p>
              </h3>

              <div style="padding: 10px">
                <p>
                  <strong>Comprador:</strong>
                  {{ formData[0].fantasy_name }}
                </p>
                <p v-if="formData[0].phone_number">
                  <b>Telefone:</b>
                  {{ formData[0].phone_number | phone }}
                </p>
                <p>
                  <strong>Email:</strong>
                  {{ formData[0].email }}
                </p>
                <p v-if="formData[0].cpf_cnpj">
                  <b>Cpf/Cnpj:</b>
                  {{ formData[0].cpf_cnpj | cpfCnpj }}
                </p>
                <p v-if="formData[0].coupon">
                  <b>Cupom:</b>
                  {{ formData[0].coupon }}
                </p>
                <p>
                  <b>Valor Compra:</b>
                  R$
                  {{
                    parseFloat(formData[0].products_value).toLocaleString(
                      "pt-BR",
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                    )
                  }}
                </p>
                <p>
                  <b>Compra + Frete:</b>
                  R$
                  {{
                    (
                      parseFloat(formData[0].products_value) +
                      parseFloat(formData[0].delivery_price)
                    ).toLocaleString("pt-BR", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  }}
                </p>
              </div>
              <v-switch
                class="ml-3"
                v-model="showAddress"
                label="Mostrar endereços"
              ></v-switch>
            </v-col>
            <v-col class="border-detail-order">
              <h3 class="header-detail-order">
                <p>Entrega</p>
              </h3>
              <div style="padding: 10px">
                <p v-if="formData[0].send_type !== 'retirada'">
                  <b>Endereço:</b>
                  {{ formData[0].street }}, {{ formData[0].number }},
                  {{ formData[0].neighborhood }} - {{ formData[0].city }},{{
                    formData[0].state
                  }}
                  - CEP: {{ formData[0].cep }}
                </p>
                <p v-if="formData[0].complement">
                  <b>Complemento:</b>
                  {{ formData[0].complement }}
                </p>
                <p>
                  <b>Tipo Entrega:</b>
                  {{ formData[0].send_type }}
                </p>
                <p>
                  <b>Frete:</b>
                  R$
                  {{
                    parseFloat(formData[0].delivery_price).toLocaleString(
                      "pt-BR",
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }
                    )
                  }}
                </p>
                <p v-if="formData[0].date_of_delivery">
                  <strong>Data Entrega: </strong>
                  {{ formData[0].date_of_delivery }}
                </p>
                <p v-if="formData[0].time_of_delivery">
                  <strong>Hora da Entrega: </strong>
                  {{ formData[0].time_of_delivery }}
                </p>
                <p>
                  <b v-if="formData[0].delivery_code">Codigo Rastreio:</b>
                  {{ formData[0].delivery_code }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="showAddress">
            <v-col cols="12" class="border-detail-order">
              <h3>Endereços:</h3>
              <span
                v-for="(item, index) in costumerAddressInformation"
                :key="index"
              >
                <p style="border-top: solid grey 1px">
                  {{
                    `${item.street}, ${item.number} - ${item.number}  ${item.city}/${item.state} - CEP: ${item.cep}`
                  }}
                </p>
              </span>
            </v-col>
          </v-row>
          <v-row v-if="formData[0].note">
            <v-col cols="12" class="border-detail-order">
              <h3>Observações :</h3>
              <p>{{ formData[0].note }} ddd</p>
            </v-col>
          </v-row>
          {{ checkTypeable }}
          <v-row
            class="border-detail-order header-detail-order mt-5"
            justify="center"
          >
            <v-col cols="2">
              <b>Prod.:</b>
            </v-col>
            <v-col cols="2">
              <b>Cód.:</b>
            </v-col>
            <v-col>
              <b>Ref.:</b>
            </v-col>
            <v-col>
              <b>Nome:</b>
            </v-col>
            <v-col>
              <b>Cor:</b>
            </v-col>
            <v-col>
              <b>Tam.:</b>
            </v-col>
            <v-col>
              <b>Qtd.:</b>
            </v-col>
            <v-col>
              <b>Preço:</b>
            </v-col>
            <v-col v-if="recording == true">
              <b>Gravação:</b>
            </v-col>
          </v-row>
          <v-row
            class="border-detail-order"
            justify="center"
            v-for="item in formData"
            :key="item.product_id"
          >
            <v-col cols="2">
              <template>
                <v-row justify="center">
                 <div style="margin: 12px; margin-left:15px "  justify="center">
                  <img
                  
                    class="my-5"
                    @click="(dialogImg = true), getItemImg(item)"
                    v-if="item.product_image[0]"
                    :src="getExactImage(item)"
                    height="150px"
                    max-width="100px"
                    lazy-src="/img/bgWhite.jpg"
                    
                  >
                  </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <div class="img-magnify">
                      <v-icon color="white">mdi mdi-magnify</v-icon>
                    </div>
                  </v-img>

                  <v-dialog
                    v-model="dialogImg"
                    max-height="550px"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-actions>
                        <v-btn
                          color="gray darken-1"
                          style="font-size: 22px"
                          text
                          @click="dialogImg = false"
                        >
                          X
                        </v-btn>
                      </v-card-actions>

                      <v-spacer />

                      <v-img
                        class="pb-6"
                        v-if="item.product_image[0]"
                        :src="imgSrc"
                        contain
                        lazy-src="/img/bgWhite.jpg"
                      />
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-col>
            <v-col cols="2" style="text-align: center">{{
              item.product_id
            }}</v-col>
            <v-col style="text-align: center">{{ item.ref }}</v-col>
            <v-col style="text-align: center">{{ item.name }}</v-col>
            <v-col style="text-align: center">{{ item.color }}</v-col>
            <v-col style="text-align: center">{{ item.size }}</v-col>
            <v-col style="text-align: center">{{ item.amount }}</v-col>
            <v-col style="text-align: center">{{ item.total_price }}</v-col>
            <v-col style="text-align: center" v-if="item.typeable">{{
              item.typeable
            }}</v-col>
          </v-row>
          <v-row
            class="border-detail-order header-detail-order"
            justify="center"
          >
            <v-col cols="2">
              <b>Total:</b>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col>{{ totalAmount }}</v-col>
            <v-col>
              <b>{{
                parseFloat(formData[0].products_value).toLocaleString("pt-BR", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span>
                <payment-history :orderData="formData" />
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span>
                <antifraud-detail />
              </span>
            </v-col>
          </v-row>
        </div>
        <v-dialog width="500px" v-model="dialog">
          <v-card>
            <v-row justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="delivery_code"
                  label="Codigo Rastreio"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="3">
                <v-btn @click="statusChangeCall(delivery_code)">Alterar</v-btn>
              </v-col>
              <v-col cols="3" class="mr-5">
                <v-btn @click="dialog = false" color="red">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <spinner-dialog v-if="updateBuyModal" :dialog="updateBuyModal">
          <v-card>
            <div>
              <update-buy v-if="updateBuyModal" :data="formData[0]" />
            </div>
          </v-card>
        </spinner-dialog>
        <v-row v-if="formData[0].delivery_source !== null">
          <!-- <v-row> -->
          <v-col>
            <delivery-info :data="formData[0]"></delivery-info>
          </v-col>
        </v-row>
        <br />
        <v-btn
        class="white--text"
        color="orange"
        @click="setUpdateBuyModal(!updateBuyModal)"
        >Alterar Pedido
        </v-btn>
      </v-form>
      <br />
    </span>

    <v-dialog
      v-if="checkIfAdminOrders == true"
      v-model="dialogPrinter"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> ATENÇÃO </v-card-title>
        <br />
        <v-card-text>
          Essa ordem de pedido já foi impressa, deseja imprimir novamente?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-print="printObj"
            @click="printOrder($route.params.id)"
            >Sim</v-btn
          >
          <v-btn color="primary" text @click="closeDialog()">Não</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-else v-model="dialogPrinter" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> ATENÇÃO </v-card-title>
        <br />
        <v-card-text>
          Você não possui permissão para imprimir esse pedido novamente!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin-blade>
</template>

<script>
import AdminBlade from "@/Layouts/Blade";
import paymentHistory from "@/views/OrderSession/pages/DetailOrder/components/paymentHistory";
import antifraudDetail from "@/views/OrderSession/pages/DetailOrder/components/antifraudDetail";
import updateBuy from "@/views/OrderSession/pages/DetailOrder/components/updateBuy";
import { mapState, mapActions } from "vuex";
import { s3Url } from "@/global";
import spinnerDialog from "@/Layouts/Components/spinnerDialog";
import DeliveryInfo from "@/views/OrderSession/pages/DetailOrder/components/Delivery/deliveryInfo";
export default {
  watch: {
    formData: function () {
      this.url = s3Url + this.formData[0].company_id + "/";
    },
  },
  filters: {
    cpfCnpj(value) {
      if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      } else {
        value = value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );
      }
      return value;
    },
    phone(value) {
      let v = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/g, "$1 $2"); //Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
      return v.substr(0, 15);
    },
  },
  components: {
    paymentHistory,
    AdminBlade,
    updateBuy,
    spinnerDialog,
    DeliveryInfo,
    antifraudDetail,
  },
  data: function () {
    return {
      imgSrc: "",
  
      showAddress: false,
      dialog: false,
      dialogImg: false,
      url: String,
      delivery_code: null,
      checkedIfOrdersPrint: null,
      checkIfAdminOrders: false,
      checkTypeable: "",
      allStatus: ["0", "1", "2", "3", "4"], // Lista de todos os status disponíveis,
      printObj: {
        id: "printMe",
        popTitle: "Detalhes da compra",
        beforeOpenCallback(vue) {
          vue.getOrdersInLocalStorage();
        },
      },
    };
  },


  computed: {
    ...mapState("DetailOrder", [
      "formData",
      "updateBuyModal",
      "costumerAddressInformation",
      "paymentsData",
      "dialogPrinter",
      "recording",
      "status_history",
    ]),
    ...mapState("Utils", ["paymentStatus"]),
    ...mapState("Auth", ["user"]),
    totalAmount() {
      var sum = 0;
      for (let i = 0; i < this.formData.length; i++) {
        sum += parseFloat(this.formData[i].amount);
      }
      return sum;
    },

    lastCompletedIndex() {
      const lastStatus = this.status_history
        .map((item) => item.status)
        .reduce((prev, current) => {
          return parseInt(current) > parseInt(prev) ? current : prev;
        }, "0");
      return this.allStatus.findIndex((status) => status === lastStatus);
    },
  },
  methods: {
    
    dateFormatDMAHMS(date) {
      let data = new Date(date);
      let day = data.getDate().toString().padStart(2, "0");
      let month = (data.getMonth() + 1).toString().padStart(2, "0");
      let fullYear = data.getFullYear();

      let hours = data.getHours().toString().padStart(2, "0");
      let minutes = data.getMinutes().toString().padStart(2, "0");
      let seconds = data.getSeconds().toString().padStart(2, "0");

      return (
        day +
        "/" +
        month +
        "/" +
        fullYear +
        " às " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },

    statusIsComplete(status) {
      // Verifica se o status está presente na lista de status_history
      return this.allStatus.indexOf(status) <= this.lastCompletedIndex;
    },

    getStatusLabel(status) {
      switch (status) {
        case "0":
          return "Fechamento Pedido";
        case "1":
          return "Pagamento em análise";
        case "2":
          return "Separação para Entrega";
        case "3":
          return "Em transito";
        case "4":
          return "Entregue";
        // Adicione mais casos conforme necessário para outros valores de status
        default:
          return "Status Desconhecido";
      }
    },
    getStatusCreatedAt(status) {
      const matchedStatus = this.status_history.find(
        (item) => item.status === status
      );
      let formatedDate = this.dateFormatDMAHMS(
        matchedStatus ? matchedStatus.created_at : ""
      );
      return matchedStatus ? formatedDate : null;
      // return ;
    },
    getItemImg(item) {
      let response = item.product_image.filter((el) => el.color === item.color);

      if (response[0]) {
        this.imgSrc = this.url + response[0].img_name + "300.jpg";
      } else {
        this.imgSrc = this.url + item.product_image[0].img_name + "300.jpg";
      }
    },
    validChangeOrder() {
      if (
        this.formData[0].status == 4 ||
        this.formData[0].status == 5 ||
        this.formData[0].status == 6
      ) {
        return true;
      } else {
        return false;
      }
    },

    statusChangeCall(value) {
      this.statusChange({
        buy_id: this.$route.params.id,
        status: 3,
        delivery_code: value,
      });
    },

    getOrdersInLocalStorage() {
      let orders = [];
      orders = JSON.parse(localStorage.getItem("printed_orders")) || [];
      this.checkedIfOrdersPrint = orders.filter(
        (e) => e.id === this.$route.params.id
      );
    },

    checkUserFullPermission() {
      let user = JSON.parse(localStorage.getItem("user")) || [];
      this.checkIfAdminOrders = user.full_permission;
    },
    convertData(status) {
      console.log(status);
    },

    getExactImage(item) {
      let response = item.product_image.filter((el) => el.color === item.color);
      if (response[0]) {
        return this.url + response[0].img_name + "300.jpg";
      } else {
        return this.url + item.product_image[0].img_name + "300.jpg";
        // return "";
      }
    },
    ...mapActions("DetailOrder", [
      "cleanItem",
      "cleanErrors",
      "show",
      "statusChange",
      "setUpdateBuyModal",
      "printOrder",
      "closeDialog",
      "openDialog",
    ]),
    changeStatusToName(value) {
      let response = this.paymentStatus.filter((status) => {
        return status.value == value;
      });
      return response[0].name;
    },
  },
  mounted() {
    
    if (this.$route.params.id) {
      this.show(this.$route.params.id);
    }
    this.getOrdersInLocalStorage();
    this.checkUserFullPermission();
  },

  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style scoped>
.img-magnify {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0.212);
  opacity: 0;
}

.img-magnify:hover {
  opacity: 100%;
}
.border-detail-order {
  border: 1px solid rgba(128, 128, 128, 0.482);
  margin-top: 0px;
  padding: 3px;
}

.header-detail-order {
  background-color: #c2c2c22c;
  text-align: center;
}

.custom-stepper-header {
  margin-bottom: -10px; /* Ajuste o valor conforme necessário */
}

.custom-stepper-step {
  margin-bottom: 5px; /* Ajuste o valor conforme necessário */
  margin-left: -20px;
  margin-right: -10px;
}
</style>