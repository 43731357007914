import axios from 'axios'
import { baseApiUrl, catchError } from "@/global";
import { spinnerChoice } from '@/utils/DefaultCrud/spinnerRules'
// import { v4 as uuidv4 } from 'uuid';


import { toastAlert } from "@/utils/Alerts/toast";


export const save = function ({ commit, state }, payload) {
    commit('SET_ERRORS', null)

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-keys-name/save`, payload)
        .then(res => {
            if (res.data.status == 201) {

                commit('SET_ERRORS', '')

                sessionStorage.setItem(
                    "toastMsg",
                    `Chave cadastrada com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
                console.log(state.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            spinnerChoice('full', false)

        })
}

export const show = function ({ commit, state }, payload) {
    commit('SET_ERRORS', null)

    spinnerChoice('full', true)

    axios.post(`${baseApiUrl}/integration-keys-name/index`, payload)
        .then(res => {
            if (res.data.status == 201) {

                commit('SET_ERRORS', '')

                sessionStorage.setItem(
                    "toastMsg",
                    `Chave cadastrada com sucesso!`
                );
                toastAlert('success')
            } else {
                commit('SET_ERRORS', res.data.errors)
                console.log(state.errors)
            }
        })
        .catch(e => {
            if (e.response.status == 422) {
                commit('SET_ERRORS', e.response.data.errors)
            } else {
                catchError(e.status)
            }
        })
        .finally(() => {
            spinnerChoice('full', false)

        })
}


export default {
    save
}