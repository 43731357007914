<template>
  <span style="max-width: 1200px !important">
    <br />
    <br />
    <spinner v-show="spinnerFullScreen" />
    <div class="text-title">
      <h2>Busca Exata de Produtos</h2>
    </div>

    <div class="field-search">
      <v-row align="center">
        <v-col align-self="center" sm="8" md="4" cols="12" xs="2">
          <v-text-field
            v-model="exactSearchProducts.product_name"
            @keyup.enter="exactSearchFilter(exactSearchProducts)"
            label="Nome do Produto"
          ></v-text-field>
        </v-col>

        <v-col align-self="center" sm="8" md="4" cols="12" xs="2">
          <v-text-field
            v-model="exactSearchProducts.ref"
            @keyup.enter="exactSearchFilter(exactSearchProducts)"
            label="Referência do Produto"
          ></v-text-field>
        </v-col>

        <v-col align-self="center" sm="8" md="4" cols="12" xs="2">
          <v-text-field
            v-model="exactSearchProducts.code"
            @keyup.enter="exactSearchFilter(exactSearchProducts)"
            label="Código da grade"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="6" align-self="center">
          <v-btn
            @click="exactSearchFilter(exactSearchProducts)"
            class="btn-search"
          >
            BUSCAR</v-btn
          >
        </v-col>

        <v-col cols="6">
          <v-btn dark rounded small color="red" @click="cleanList()"
            >Limpar filtros <v-icon>mdi-trash-can</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div>
      <list-table
        :listExactProducts="listExactProducts"
        :data="exactSearchProducts"
        class="d-none d-md-block"
      />
    </div>
  </span>
</template>

<script>
import Spinner from "@//Layouts/Components/spinner";
import { mapState, mapActions } from "vuex";
import ListTable from "@/views/ProductSession/Pages/Product/tableExact.vue";
export default {
  components: {
    ListTable,
    Spinner,
  },
  computed: {
    ...mapState("Spinner", ["spinnerFullScreen"]),
    ...mapState("Product", [
      "listExactProducts",
      "exactSearchProducts",
      "errors",
    ]),
  },
  methods: {
    ...mapActions("Product", ["exactSearchFilter", "cleanList"]),
  },
};
</script>

<style>
* {
  font-family: "Montserrat", sans-serif;
}
.text-title {
  margin-top: 5vh;
  text-align: center;
}
.list-products {
  max-width: 1400px;
  margin: 0 auto !important;
}

.field-search {
  margin: 20px auto !important;
  align-items: center !important;
  text-align: center !important;
  max-width: 1200px;
  width: 80%;
}

.btn-search {
  background-color: #169616 !important;
  color: white !important;
  max-width: 100% !important;
  width: 200px;
}

@media screen and (max-width: 960px) {
  .btn-search {
    margin: 0 auto !important;
    width: 75% !important;
    align-items: center !important;
  }
  .field-search {
    margin-left: 150px;
  }
}

@media screen and (max-width: 600px) {
  .field-search {
    margin: 15px auto !important;
    max-width: 450px;
  }
  .btn-search {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .field-search {
    margin: 15px auto !important;
    max-width: 300px;
  }
  .btn-search {
    width: 100% !important;
  }
}
</style>