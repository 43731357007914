<template>
  <admin-blade>
    <h1 class="title-topo">Lista de compras em Separacão</h1>

    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <span>
      <v-data-table
        calculate-widths
        class="default-table"
        :headers="headers"
        :items="list"
        :search="search"
        :footer-props="footer"
        @click:row="edit(item.id)"
      >
        <template v-slot:item.delivery_price="{ item }">
          <span
            >R$
            {{
              parseFloat(item.delivery_price).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </template>
        <template v-slot:item.products_value="{ item }">
          <span
            >R$
            {{
              parseFloat(item.products_value).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </template>
        <template v-slot:item.status="{ item }">
          <span>{{ changeStatusToName(item.status) }}</span>
        </template>
        <template v-slot:item.payment_type="{ item }">
          <span>{{ setPaymentType(item.payment) }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <button @click="edit(item.id)">
            <v-icon small class="mr-2" title="Edit">mdi-pencil</v-icon>
          </button>
        </template>
        <template v-slot:item.total="{ item }">
          R$
          {{ sumTotalValue(item.products_value, item.delivery_price) }}
        </template>
      </v-data-table>
    </span>
  </admin-blade>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import AdminBlade from "@//Layouts/Blade";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBlade,
  },
  data: function () {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id", class: "black--text" },
        { text: "Data", value: "created_at", class: "black--text" },
        { text: "TIPO ENVIO", value: "send_type", class: "black--text" },
        { text: "TIPO PGTO", value: "payment_type", class: "black--text" },
        { text: "FRETE", value: "delivery_price", class: "black--text" },
        {
          text: "VALOR DA COMPRA",
          value: "products_value",
          class: "black--text",
        },
        {
          text: "Total",
          value: "total",
          class: "black--text",
        },
        {
          text: "Comprador",
          value: "people_name",
          class: "black--text",
        },
        { text: "STATUS", value: "status", class: "black--text" },
        {
          text: "AÇÕES",
          value: "action",
          sortable: false,
          class: "black--text",
        },
      ],
      footer: {
        itemsPerPageText: "Itens por página",
        itemsPerPageAllText: "Todos",
      },
    };
  },

  computed: {
    ...mapState("Utils", ["paymentStatus"]),
    ...mapState("SortingOutBuys", ["list"]),
  },

  methods: {
    setPaymentType(value) {
      if (value.length > 0) {
        switch (value[0].type) {
          case 0:
            return "C. Crédito";
          case 1:
            return "C. Débito";
          case 2:
            return "Dinheiro";
          case 3:
            return "Boleto";
          case 4:
            return "Pix";
        }
      } else {
        return "Ver Painel";
      }
    },
    sumTotalValue(a, b) {
      a = parseFloat(a);
      b = parseFloat(b);
      return (a + b).toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    changeStatusToName(value) {
      let response = this.paymentStatus.filter((status) => {
        return status.value == value;
      });
      return response[0].name;
    },
    ...mapActions("SortingOutBuys", ["setList"]),
    edit(id) {
      this.$router.push(`detailOrder/${id}`);
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");
    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(1, 0, {
        text: "Cliente",
        value: "fantasy_name",
        class: "black--text",
      });
    }
  },
};
</script>