import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from '@/store/index.js';
import ViaCep from 'vue-viacep'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import money from 'v-money'
import ImageUploader from 'vue-image-upload-resize'
import Print from 'vue-print-nb'
import VueApexCharts from 'vue-apexcharts'
import DatetimePicker from 'vuetify-datetime-picker'
// import ApexCharts from 'apexcharts'


Vue.use(VueApexCharts)
Vue.component(VueApexCharts)
Vue.use(ImageUploader);
Vue.use(ViaCep);
Vue.use(money, { precision: 2 });
Vue.use(Print);
Vue.use(DatetimePicker);
// Vue.use(ApexCharts);


Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
