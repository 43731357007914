const SET_FORMDATA = (state, obj) => {
    state.formData = obj
}

const SET_ERRORS = (state, obj) => {
    state.errors = obj
}

const SET_LIST = (state, obj) => {
    state.list = obj
}

const SET_MODAL = (state, obj) => {
    state.modal = obj
}

const SET_MODALALLGRID = (state, obj) => {
    state.allGridModal = obj
}

const SET_MODALALLGRIDERRORS = (state, obj) => {
    state.allGridModal = obj
}

const SET_SHOWINACTIVE = (state, obj) => {
    state.showInactive = obj
}

const SET_SIZES = (state, obj) => {
    state.size = obj
}

export default {
    SET_FORMDATA,
    SET_ERRORS,
    SET_LIST,
    SET_MODAL,
    SET_MODALALLGRID,
    SET_MODALALLGRIDERRORS,
    SET_SHOWINACTIVE,
    SET_SIZES
}