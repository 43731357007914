<template>
  <!-- TODO Finish component (based on product iterator) -->
  <v-card class="default-table">
    <v-container>
      <v-data-iterator
        :items="list.data"
        items-per-page.sync="4"
        hide-default-footer
      >
        <template v-slot:default>
          <v-row>
            <v-col
              v-for="item in list.data"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="sub-header">
                  <div class="sub-header-text">
                    {{ item.id }} - {{ item.code }}
                  </div>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Taxa de Desconto:</v-list-item-content>
                    <v-list-item-content>
                      {{ item.discount_rate }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content
                      >Valor do Desconto:</v-list-item-content
                    >
                    <v-list-item-content>
                      {{ item.discount_value }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Data de Início:</v-list-item-content>
                    <v-list-item-content class="item-text">
                      {{ item.init_date }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Data Fim:</v-list-item-content>
                    <v-list-item-content class="item-text">
                      {{ item.expire_date }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Ativo:</v-list-item-content>
                    <v-list-item-content>
                      {{ item.active }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content
                      >Remover em Promoções:</v-list-item-content
                    >
                    <v-list-item-content>
                      {{ item.remove_sale }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>ID do Cliente:</v-list-item-content>
                    <v-list-item-content>
                      {{ item.company_id }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="item-row">
                    <v-list-item-content>Cliente:</v-list-item-content>
                    <v-list-item-content class="item-text">
                      {{ item.fantasy_name }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn fab color="#272727" @click="edit(item)">
                            <v-icon color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { toastAlert } from "@/utils/Alerts/toast";
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      currentHeader: "",
      headers: [
        {
          name: "ID",
          value: "id",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Nome",
          value: "name",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Ref",
          value: "ref",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Categoria",
          value: "category",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Valor",
          value: "price",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Ativo",
          value: "active",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Estoque",
          value: "stock",

          sortOrder: "",
          sortIcon: "",
        },
        {
          name: "Relevancia",
          value: "relevance",

          sortOrder: "",
          sortIcon: "",
        },
      ],
    };
  },
  props: {
    list: {},
  },
  computed: {
    ...mapState("Product", ["pagination"]),
    itemsPerPage: {
      get() {
        return this.$store.state.Product.pagination.itemsPerPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    page: {
      get() {
        return this.$store.state.Product.pagination.currentPage;
      },
      set(value) {
        this.$store.commit("Product/SET_CURRENTPAGE", value);
      },
    },
    orderField: {
      get() {
        return this.$store.state.Product.order.field;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERFIELD", value);
      },
    },
    orderValue: {
      get() {
        return this.$store.state.Product.order.value;
      },
      set(value) {
        this.$store.commit("Product/SET_ORDERVALUE", value);
      },
    },
  },

  methods: {
    ...mapActions("Product", ["setList"]),
    checkUser() {
      if (JSON.parse(localStorage.getItem("user")).admin) {
        return true;
      } else {
        return false;
      }
    },
    updateItemsPerPage(value) {
      this.pagination.itemsPerPage = value;
      this.setList();
    },
    clearIcon(header) {
      this.currentHeader = header.value;
      for (let i = 0; i < this.headers.length; i++) {
        if (this.headers[i].value != this.currentHeader) {
          this.headers[i].sortOrder = "";
          this.headers[i].sortIcon = "";
        }
      }
    },
    toggleOrder(header) {
      if (header.sortOrder == "" && header.sortIcon == "") {
        header.sortOrder = "ASC";
        header.sortIcon = "mdi-arrow-up";
      } else if (
        header.sortOrder == "ASC" &&
        header.sortIcon == "mdi-arrow-up"
      ) {
        header.sortOrder = "DESC";
        header.sortIcon = "mdi-arrow-down";
      } else if (
        header.sortOrder == "DESC" &&
        header.sortIcon == "mdi-arrow-down"
      ) {
        header.sortOrder = "";
        header.sortIcon = "";
      } else {
        header.sortOrder = "ASC";
        header.sortIcon = "mdi-arrow-up";
      }
    },
    sort(header) {
      if (header.sortOrder) {
        this.orderField = header.value;
        this.orderValue = header.sortOrder;
      } else {
        this.orderField = "id";
        this.orderValue = "DESC";
      }
    },
    getPromotionClass(value) {
      if (value) {
        return "inactive-item-price";
      }
    },
    negativeStock(value) {
      if (value < 1) {
        return "negative-stock-class";
      } else {
        return "stock-class";
      }
    },
    edit(item) {
      this.$router.push(`form-coupon/${item.id}`);
    },
    checkActiveClass(value) {
      if (value == true) {
        return ["Sim", "active-product-list-true"];
      } else {
        return ["Não", "active-product-list-false"];
      }
    },
  },
  mounted() {
    this.setList();
    toastAlert("success");

    if (JSON.parse(localStorage.getItem("user")).admin) {
      this.headers.splice(4, 0, {
        name: "Cliente",
        value: "company_name",

        sortOrder: "",
        sortIcon: "",
      });
    }
  },
};
</script>

<style scoped>
.mobile-pagination >>> .v-pagination__item {
  min-width: 0 !important;
  width: 25px !important;
  height: 25px !important;
}

.sub-header-text {
  font-size: 16px !important;
  font-weight: bold;
  height: 34px;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
}

.item-row {
  border-top: 1px solid lightgray;
}

.item-text {
  height: 64px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>