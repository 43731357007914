<template>
  <admin-blade-integration
    :iconBtnTop="removeButtonIfNotAdmin(adm)"
    routeBtnTop="/integrations/settings/add-key-name"
  >
    <span v-if="adm">
      <v-form
        class="mt-3 default-form"
        style="height: 90%"
        ref="form"
        v-model="valid"
      >
        <v-card-title> Cadastrar Nova Integração </v-card-title>

        <v-row>
          <v-col cols="4">
            <v-text-field v-model="formData.name" label="Nome *"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="left" class="my-3">
            <v-btn
              :disabled="!formData.name"
              color="green darken-2 white--text"
              class="mr-4"
              @click="save(formData)"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </span>
  </admin-blade-integration>
</template>

<script>
import AdminBladeIntegration from "@/Layouts/BladeIntegrations";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AdminBladeIntegration,
  },
  data: function () {
    return {
      adm: false,
    };
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("IntegrationSettings", ["formData", "errors"]),
  },
  methods: {
    removeButtonIfNotAdmin(value) {
      if (value == true) {
        return "fas fa-file-invoice-dollar";
      }
    },
    ...mapActions("IntegrationSettings", ["save"]),
  },
  mounted() {
    this.adm = JSON.parse(localStorage.getItem("user")).admin;
    if (this.adm) {
      this.setCompany();
    } else {
      this.show({
        company_id: JSON.parse(localStorage.getItem("user")).company_id,
      });
    }
  },
};
</script>

<style>
</style>