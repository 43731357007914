<template>
  <div>
    <v-card class="melhor-envio-card">
      <h3>Melhor Envio</h3>
      <h3 v-if="melhorEnvio.keyId" class="red--text">
        Você já tem um Token cadastrado pra este serviço !!!
      </h3>
      <v-btn
        @click="setChangeToken(true)"
        color="red"
        class="mt-4"
        v-if="melhorEnvio.keyId && !melhorEnvio.changeKey"
        >Alterar Meu Token</v-btn
      >
      <p class="red--text" v-if="!melhorEnvio.keyId">
        * Você ainda não tem um token cadastrado para realizar as transações
      </p>

      <v-textarea
        v-if="melhorEnvio.changeKey || !melhorEnvio.keyId"
        v-model="token"
        label="Valor(Token)"
      ></v-textarea>
      <p class="red--text" v-if="melhorEnvio.errors">
        {{ melhorEnvio.errors.value }}
      </p>
      <p class="red--text" v-if="melhorEnvio.errors">
        {{ melhorEnvio.errors.message }}
      </p>
      <p></p>
      <v-btn
        v-if="!melhorEnvio.keyId"
        @click="
          saveMelhorEnvioToken({ company_id: company, value: token }),
            (token = null)
        "
        color="green"
        >Cadastrar Meu Token Melhor Envio</v-btn
      >
      <v-btn
        v-if="melhorEnvio.keyId && melhorEnvio.changeKey"
        @click="updateMelhorEnvioToken({ id: melhorEnvio.keyId, value: token })"
        color="green"
        >Alterar Token</v-btn
      >
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    company: Number,
  },
  watch: {
    company() {
      this.check({
        company_id: this.company,
      });
    },
  },

  data: function () {
    return {
      token: null,
    };
  },
  computed: {
    ...mapState("Intrermediary", ["melhorEnvio"]),
  },
  methods: {
    ...mapActions("Intrermediary", [
      "saveMelhorEnvioToken",
      "check",
      "resetMelhorEnvio",
      "setChangeToken",
      "updateMelhorEnvioToken",
    ]),
  },
  mounted() {
    this.check({
      company_id: this.company,
    });
  },
  destroyed() {
    this.resetMelhorEnvio();
  },
};
</script>
<style  scoped>
.melhor-envio-card {
  border: solid grey 1px;
  box-shadow: none !important;
  padding: 20px;
}
</style>