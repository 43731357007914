<template>
  <admin-blade iconBtnTop="mdi-plus" routeBtnTop="/form-product-group">
    <h1 class="title-topo">Grupo de Produtos</h1>

    <v-text-field
      class="default-search"
      v-model="search"
      label="Busca"
      single-line
      hide-details
    ></v-text-field>

    <span>
      <list-iterator class="d-block d-md-none" :list="list" />
      <list-table class="d-none d-md-block" :list="list" :search="search" />
    </span>
  </admin-blade>
</template>

<script>
import AdminBlade from "@//Layouts/Blade";
import ListIterator from "@/views/ProductSession/Pages/ProductGroup/iterator.vue";
import ListTable from "@/views/ProductSession/Pages/ProductGroup/table.vue";
import { mapState } from "vuex";
export default {
  data: function () {
    return {
      search: null,
    };
  },
  components: {
    AdminBlade,
    ListIterator,
    ListTable,
  },
  computed: {
    ...mapState("ProductGroup", ["list"]),
  },
};
</script>

<style>
</style>