<template>
  <div class="default-update-buy" ref="updateBuyModal">
    <span v-if="showForm || (form.status != 1 && form.status != 0 && form.status != 7 || (user.email !== 'isa.boutiqueanak@gmail.com' && user.email !== 'stefane@anak.com.br' && user.email !== 'ale.boutiqueanak@gmail.com' && user.email !== 'ana.anakboutique@gmail.com'))">
      <h2 class="text-center">Formulario Alteração Pedido</h2>
      <v-row>
        <v-col cols="4">
          <v-select
            label="Status Pedido"
            v-model="form.status"
            :items="buyStatusList"
            item-text="name"
            item-value="status"
            @click.stop
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="form.delivery_code"
            label="Codigo Rastreio"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="formData[0].payment_component == 1">
        <v-col cols="10">
          <p><strong>Pagamento</strong></p>
          <hr />
        </v-col>
        <v-col cols="3">
          <v-select
            label="Status Pagamento"
            v-model="form.payment_type"
            :items="paymentTypeList"
            item-text="name"
            item-value="status"
            @click.stop
          ></v-select>
        </v-col>
        <v-col v-if="form.payment_type == 2" cols="4">
          <div style="margin-top: 23px !important">
            <span> Troco (R$) </span>
            <money
              class="money"
              v-model="form.transshipment"
              v-bind="money"
            ></money>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-switch
            color="amber darken-4"
            label="Pago"
            v-model="form.payment_paid"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="right">
          <v-btn color="red" @click="setUpdateBuyModal(false)">Fechar</v-btn>
          <v-btn @click="updateBuy(form)" color="green" class="ml-5">Salvar Alteração</v-btn>
        </v-col>
      </v-row>
    </span>
    <span v-else>
      <h1>Digite a Senha</h1>
      <v-text-field id="verify" v-model="password" label="Senha" type="password"></v-text-field>
      <v-btn @click.stop="passwordCheck(password)">Verificar</v-btn>
      <v-btn style="margin:10px" color="red" @click.stop="setUpdateBuyModal(false)">Fechar</v-btn>      
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      emails: [
        { email: 'isa.boutiqueanak@gmail.com' },
        { email: 'ale.boutiqueanak@gmail.com' },
        { email: 'isa.boutiqueanak@gmail.com' },
        { email: 'desenvolvimento@gransys.com.br' }
      ],
      email: false,
      password: '',
      showForm: false,
      modal: false,
      form: {
        delivery_code: null,
        status: null,
        payment_type: null,
        transshipment: null,
        buy_id: null,
      },
    };
  },
  props: {
    data: Array,
  },
  methods: {
    passwordCheck(password) {
      if (password === 'anakl@2025') {
        this.showForm = true; // Exibe o formulário de alteração de pedido
      } else if (password === '') {
        alert('O campo de senha é obrigatório.');
      } else {
        alert('Senha Incorreta!');
        this.password = ''; // Limpa o campo de senha
      }
    },
    handleClickOutside(event) {
      const dropdown = document.querySelector('.v-menu__content');
      // Verifica se o clique foi fora do modal e fora de qualquer dropdown aberto
      if (
        this.updateBuyModal &&
        !this.$refs.updateBuyModal.contains(event.target) &&
        !(dropdown && dropdown.contains(event.target))
      ) {
        this.setUpdateBuyModal(false); // Fecha o modal
      }
    },
    ...mapActions("DetailOrder", ["setUpdateBuyModal", "updateBuy"]),
  },
  computed: {
    ...mapState("Auth", ["user"]),
    ...mapState("DetailOrder", [
      "updateBuyModal",
      "buyStatusList",
      "paymentTypeList",
      "formData",
    ]),
  },
  mounted() {
    this.form.delivery_code = this.formData[0].delivery_code;
    this.form.status = parseFloat(this.formData[0].status);
    this.form.payment_type = this.formData[0].payment_type;
    this.form.transshipment = this.formData[0].transshipment;
    this.form.payment_paid = this.formData[0].paid;
    this.form.buy_id = this.$route.params.id;
    console.log('STATUS:::', this.form.status);

    this.getStatusModal(this.form.status);

    // Adiciona um pequeno delay antes de ativar o evento para evitar que o clique de abertura feche o modal
    setTimeout(() => {
      document.addEventListener('click', this.handleClickOutside);
    }, 200);  // Adicionando um delay de 200ms
  },

  beforeDestroy() {
    // Remove o evento de clique quando o componente é destruído
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.default-update-buy {
  padding: 30px !important;
}
</style>
