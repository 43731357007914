<template>
  <span>
    <v-form ref="form">
      <!-- Opcoes para Formulario -->
      <h3>Formulario Regra Pagamento</h3>
      <span></span>
      <v-row>
        <v-col align="center" cols="12" sm="2">
          <v-text-field
            :disabled="item"
            type="number"
            v-model="formData.installments"
            label="Parcelas"
          ></v-text-field>
          <strong class="red--text">{{ errors.installments }}</strong>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            type="number"
            v-model="formData.rate"
            label="Porcentagem"
          ></v-text-field>
          <strong class="red--text">{{ errors.rate }}</strong>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            v-model="formData.rule_type"
            :items="paymentRule"
            item-text="name"
            item-value="value"
            label="Regra"
          ></v-select>
          <strong class="red--text">{{ errors.rule_type }}</strong>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            :disabled="item"
            v-model="formData.payment_type"
            :items="paymentType"
            item-text="name"
            item-value="value"
            label="Pagamento"
          ></v-select>
          <strong class="red--text">{{ errors.payment_type }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch
            color="orange"
            v-model="formData.active"
            label="Ativo"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <p v-if="formData.updated_at">
            <strong>Ultima Modificação:</strong>
            {{ formData.updated_at }}
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <p v-if="formData.created_at">
            <strong>Data Cadastro:</strong>
            {{ formData.created_at }}
          </p>
        </v-col>
      </v-row>
      <v-btn
        v-if="formData.id"
        color="green darken-2 white--text"
        class="mr-4"
        @click="update({ ...formData, product_id: $route.params.id, id: item })"
        >Salvar Alterações</v-btn
      >
      <v-btn
        v-else
        color="green darken-2 white--text"
        class="mr-4"
        @click="save({ ...formData, product_id: $route.params.id })"
        >Salvar</v-btn
      >
      <v-btn
        color="red darken-2 white--text"
        class="mr-4"
        @click="setModal(false)"
        >Cancelar</v-btn
      >
    </v-form>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  components: {},
  props: {
    item: Number,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("ProductDiscountByPaymentMethod", [
      "userType",
      "errors",
      "formData",
      "paymentType",
      "paymentRule",
    ]),
  },
  methods: {
    ...mapActions("ProductDiscountByPaymentMethod", [
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
    ]),
    ...mapActions("ProductDiscountByPaymentMethod", [
      // "setList"
      "setModal",
    ]),
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  mounted() {
    if (this.item) {
      this.show({ id: this.item });
    }
  },
  destroyed() {
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style>
</style>